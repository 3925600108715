
import './AdditionalMaterial.css'
import useLoader from "../../../../hooks/useLoader";
import {getCurrencyString, getRubString} from "../../../../features/formatters/forrmaterrub";
import InheritInput from "../../../../components/UI/inputs/inheritinput/InheritInput";
import useCheckAccess from "../../../../hooks/useCheckAccess";
import AccessDenied from "../../../../components/access/AccessDenied";
import TableBootstrap from "../../../../components/tableb/TableBootstrap";
import {Button, Modal} from "react-bootstrap";
import RemainsNew from "../../../remainsnew/RemainsNew";
import CrossIcon from "../../../../components/UI/icons/CrossIcon";
import {useQueryClient} from "@tanstack/react-query";
import useTkp from "../../hooks/useTkp";
import useModals from "../../../../hooks/usemodal/useModals";
import {useParams} from "react-router";
import {useEffect, useMemo, useState} from "react";
import TotalBarTkp from "../totalbartkp/TotalBarTkp";
import ListIcon from "../../../../components/UI/icons/ListIcon";
import ProvidersTkp from "../providers/ProvidersTkp";
const AdditionalMaterial = () => {
    const params = useParams()
    const id = Number(params['id'] || 0)
    const {hideAll} = useLoader()
    const KEY_CLIENT_TKP = 'current-tkp'
    const client = useQueryClient()
    const [visibleProvision, setVisibleProvision] = useState(false)
    const [itemForProvision, setItemForProvision] = useState(null)
    const currentTkp = client.getQueryData(['current-tkp', id])
    const {
        addSummaryAdditionalTkp,
        changeSummaryAdditionalTkp,
        deleteSummaryAdditionalTkp
    } = useTkp();

    useEffect(() => {
        if(itemForProvision){
            let slice = currentTkp?.summaryAdditional?.find(x => x?.id === itemForProvision?.item?.id)
            if(slice)
                setItemForProvision({type: itemForProvision?.type, item: slice})
        }
    }, [currentTkp]);

    const onClickSelectProvision = (item, type) => {
        setVisibleProvision(true)
        setItemForProvision({item, type})
    }

    const [add, modals] = useModals()

    const renderRemains = (item) => {
        if(item?.materialType === 'equipment'){
            return (
                item?.equipment?.remains?.map((remains, idxRemain) => remains?.warehouse?.isVisibleShortReport && (
                    <div title={remains?.warehouse?.fullName || ''} className={'equipments-selector__remains_item'}
                         key={idxRemain}>
                        {remains?.warehouse?.shortName} -
                        <span style={{marginLeft: 5, fontSize: 12}}>⚙️: <b>{remains?.canProduce}</b></span>
                        <span style={{
                            marginLeft: 5,
                            fontSize: 12
                        }}>{item?.equipment?.equipmentInformation?.unitMeasurement?.unitName}</span>
                    </div>
                ))
            )
        }
        else if(item?.materialType === 'component'){
            return (
                item?.component?.remains?.map((remains, idxRemain) => remains?.warehouse?.isVisibleShortReport && (
                    <div title={remains?.warehouse?.fullName || ''} className={'equipments-selector__remains_item'}
                         key={idxRemain}>
                        {remains?.warehouse?.shortName} -
                        <span style={{marginLeft: 5, fontSize: 12}}>⚙️: <b>{remains?.canProduce}</b></span>
                        <span style={{
                            marginLeft: 5,
                            fontSize: 12
                        }}>{item?.equipment?.equipmentInformation?.unitMeasurement?.unitName}</span>
                    </div>
                ))
            )
        }
    }

    const memoTotalPrice = useMemo(() => {
        let result = 0;
        currentTkp?.summaryAdditional.forEach(additional => {
            result += additional.totalPriceIncludingPackaging || 0
        })
        return result
    }, [currentTkp])
    const memoTotalVAT = useMemo(() => {
        let result = 0;
        currentTkp?.summaryAdditional.forEach(additional => {
            result += additional.totalPriceIncludingPackagingVAT || 0
        })
        return result;
    }, currentTkp)
    const popoverActions = [
        {
            icon: <ListIcon classNames={'cl-light-purple'}/>,
            title: 'Обеспечение',
            onClick: item => onClickSelectProvision(item, "additional")
        },
        {
            icon: <CrossIcon classNames={'cl-light-purple'}/>,
            title: 'Удалить',
            onClick: item => deleteSummaryAdditionalTkp(item?.guid)
        },
    ]

    const schema = [
        {
            title: 'Материал',
            callbackItem: item =>
                <div className={'w-100 h-100 pointer'} onClick={() => {
                    let onRemoveItem = add({
                        children:
                            <RemainsNew
                                isSelector
                                clickElement={e => {
                                    if(e?.type === 'equipment')
                                        changeSummaryAdditionalTkp({
                                            ...item,
                                            equipment: e?.element
                                        }, false)
                                    else if(e?.type === 'component')
                                        changeSummaryAdditionalTkp({
                                            ...item,
                                            component: e?.element
                                        }, false)

                                    onRemoveItem()
                                }}
                                selectedIdsEquipments={currentTkp?.summaryAdditional?.reduce((acc, item) => {
                                    if(item?.materialType === 'equipment') return [...acc, item?.equipment?.id]
                                    else return acc
                                }, [])}
                                selectedIdsComponents={currentTkp?.summaryAdditional?.reduce((acc, item) => {
                                    if(item?.materialType === 'component') return [...acc, item?.component?.id]
                                    else return acc
                                }, [])}
                            />,
                        fullscreen: true
                    })
                }}>
                    {item?.materialType === 'equipment' ? item?.equipment?.name : item?.materialType === 'component' ? item?.component?.name : "Не выбран"}
                </div>
            ,
        },
        {
            title: 'Кол-во',
            callbackItem: item =>
                <InheritInput
                    defaultValue={item?.quantity}
                    useDebounce
                    isNumber
                    onChange={newValue => changeSummaryAdditionalTkp({...item, quantity: newValue})}
                    className={'additional-materials-tkp__field'}
                />
        },
        {
            title: 'Цена за литр',
            callbackItem: item => getCurrencyString(item?.priceForUnit || 0, currentTkp?.currencyForHandle?.currencyCode),
        },
        {
            title: 'Скидка',
            callbackItem: item => <InheritInput
                defaultValue={item?.discountManager}
                useDebounce
                isNumber
                suffics={'%'}
                onChange={newValue => changeSummaryAdditionalTkp({...item, discountManager: newValue})}
                className={'additional-materials-tkp__field'}
            />
        },
        {
            title: 'Стоимость кр. таре',
            callbackItem: item => getCurrencyString(item?.totalPriceIncludingPackaging || 0, currentTkp?.currencyForHandle?.currencyCode),
        },
        {
            title: 'Остатки',
            callbackItem: item =>
                <div className={'equipments-selector__item_column equipments-selector__item_remains'}>
                    {renderRemains(item)}
                </div>
        },
    ]

    const access = useCheckAccess("sales/tkp")
    if(!access){
        hideAll()
        return <AccessDenied />
    }

    return (
        <div className={'slide-tkp-body__wrapper'}>
            <div className={'slide-tkp__equipments'}>
                <Button variant={"primary"} onClick={() => addSummaryAdditionalTkp()}>Добавить слой</Button>
                <div className={'slide-tkp__table'}>
                    <TableBootstrap
                        schema={schema}
                        popoverActions={popoverActions}
                        popoverCaption={'Действия'}
                        items={currentTkp?.summaryAdditional}
                    />
                </div>
                <div className={'materials-slide-tkp__stripe_info'}>
                    <span>
                        Итого за доп. материал без НДС: {getCurrencyString(memoTotalPrice - memoTotalVAT, currentTkp?.currencyForHandle?.currencyCode)}
                    </span>
                    <span>
                        НДС: {getCurrencyString(memoTotalVAT, currentTkp?.currencyForHandle?.currencyCode)}
                    </span>
                    <span>
                         Итого за материал c НДС: {getCurrencyString(
                            memoTotalPrice,
                            currentTkp?.currencyForHandle?.currencyCode
                        )}
                    </span>
                </div>
            </div>
            <TotalBarTkp
                keyClient={KEY_CLIENT_TKP}
                idTkp={id}
            />
            {modals}

            <Modal
                show={visibleProvision}
                onHide={() => {
                    setVisibleProvision(false)
                    setItemForProvision(null)
                }}
            >
                <Modal.Header><Modal.Title>Обеспечение</Modal.Title></Modal.Header>
                <Modal.Body>
                    <ProvidersTkp
                        slice={itemForProvision?.item}
                        type={itemForProvision?.type}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"danger"} onClick={() => {
                        setVisibleProvision(false)
                        setItemForProvision(null)
                    }}>Закрыть</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default AdditionalMaterial;