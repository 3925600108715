import './TemplateModal.css'
import {useInteractionBackend} from "../../../../hooks/useInteractionBackend";
import TKPService from "../../TKPService";
import {Button, Modal} from "react-bootstrap";
import InheritInput from "../../../../components/UI/inputs/inheritinput/InheritInput";
import {useEffect, useState} from "react";
import useMessageHub from "../../../../hooks/useMessageHub";

const TemplateModal = ({show, onHide, tkpId}) => {
    const [templateName, setTemplateName] = useState("");
    const sendMessage = useMessageHub();

    const [addTemplateTkp] = useInteractionBackend({
        type: 'axios',
        cb: TKPService.addTemplate,
        thenCb: () => sendMessage('Создан новый шаблон ТКП')
    });

    useEffect(() => {
        if (!show) {
            setTemplateName('');
        }
    }, [show]);

    return (
        <Modal
            show={show}
            onHide={onHide}
            dialogClassName={'tkp-template-modal'}
            centered
        >
            <Modal.Body>
                <InheritInput
                    defaultValue={templateName}
                    onChange={newValue => {setTemplateName(newValue)}}
                    placeholder={'Имя шаблона'}
                    className={'tkp-template-modal__input'}
                />
                <div className="tkp-template-modal__controls">
                    <Button
                        bsPrefix={'tkp-template-modal__btn'}
                        disabled={!templateName}
                        onClick={(e) => {
                            addTemplateTkp(tkpId, templateName)
                            onHide(e);
                        }}
                    >
                        Добавить
                    </Button>
                    <Button
                        bsPrefix={'tkp-template-modal__btn'}
                        className={'tkp-template-modal__btn_cancel'}
                        onClick={(e) => {
                            onHide(e);
                        }}
                    >
                        Отмена
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default TemplateModal;