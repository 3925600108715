import {useRef} from "react";
import {Autocomplete, TextField} from "@mui/material";
import useClientsSearcher from "../../../hooks/clients/useClientsSearcher";
import {Stack} from "react-bootstrap";
import CreatingClientModule from "./modals/add/CreatingClientModule";
import EditClientModule from "./modals/edit/EditClientModule";
import EditIcon from "../../../components/UI/icons/EditIcon";
import {useMutation} from "@tanstack/react-query";
import {AxiosHelper} from "../../../hooks/useAxiosHelper";
import useControlledValue from "../../../hooks/controlled/useControlledValue";


const ClientsEditInputModule = ({defaultValue = null, onChange, label= '', sx, controlled = false}) => {

    const createModuleControlsRef = useRef(null)
    const editModuleControlsRef = useRef(null)
    const {optionsClients, inputClients, updateInputClients, onBlur} = useClientsSearcher()

    const [value, setValue] = useControlledValue({defaultValue, controlled})

    const {mutate: updateClient} = useMutation({
        mutationKey: ['client', 'input', 'update'],
        mutationFn: (id) => AxiosHelper.getMethod({
            urlWithoutBaseUrl: `ancorr/api/client/get/client/${id}`
        }),
        onSuccess: (data) => {
            setValue(data)
            onChange?.(data)
        }
    })

    return (
        <Stack direction={"horizontal"} className={'gap-2'}>
            <Autocomplete
                value={value}
                onChange={(event, newValue) => {
                    if(newValue?.generalName === 'Добавить нового клиента')
                        createModuleControlsRef?.current?.open()
                    else{
                        setValue(newValue)
                        onChange?.(newValue)
                    }
                }}
                onInputChange={(event, newInputValue) => {
                    if(newInputValue !== 'Добавить нового клиента')
                        updateInputClients(newInputValue)
                }}
                onBlur={() => onBlur()}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                inputValue={inputClients}
                options={optionsClients}
                suppressContentEditableWarning={true}
                suppressHydrationWarning={true}
                getOptionLabel={option => option?.generalName || 'Не заполнено'}
                getOptionKey={option => option?.id}
                sx={sx ? sx : { width: 400 }}
                size="small"
                filterOptions={(options, params) => {

                    return [...options, {generalName: 'Добавить нового клиента', id: 999999}]
                }}
                renderInput={(params) => <TextField {...params} variant={'standard'} label={label} placeholder={'Телефон, наименование, инн, фио'} />}
            />
            {value &&
                <Stack onClick={() => editModuleControlsRef.current?.open()} className={'justify-content-center align-content-center pointer'}>
                    <EditIcon/>
                </Stack>
            }
            <CreatingClientModule
                ref={createModuleControlsRef}
                onChange={newValue => {
                    onChange?.(newValue)
                    setValue(newValue)
                    createModuleControlsRef?.current?.close()
                }}
            />
            <EditClientModule
                ref={editModuleControlsRef}
                controlled
                defaultValue={value}
                onChange={newValue => {
                    setValue(newValue)
                    if(newValue?.id)
                        updateClient(newValue?.id)
                    else
                        onChange?.(newValue)
                    editModuleControlsRef?.current?.close()
                }}
            />
        </Stack>
    );
};

export default ClientsEditInputModule;