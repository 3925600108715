import ListIcon from "../../../components/UI/icons/ListIcon";
import {getRubString} from "../../../features/formatters/forrmaterrub";
import {Form} from "react-bootstrap";
import MultipleDropdownDefault from "../../../components/UI/selects/multipledropdowndefault/MultipleDropdownDefault";
import PhotoEntityRenderer from "../../../components/files/photo/PhotoEntityRenderer";



const Label = ({field, value, dependencyEntity, onNeedEditCallback}) => {



    if(field?.type === 'text'){
        return (
            <div className={'field__info'} onClick={() => onNeedEditCallback(field?.guid)}>
                <label className={'field__label'}>{field?.label}</label>
                <div className={'field__input'}>
                    <ListIcon/>
                    <span className={'field__text'} style={{maxWidth: 400}}>{value || ''} {field?.suffics}</span>
                </div>
            </div>
        )
    } else if (field?.type === 'address') {
        return (
            <div className={'field__info'} onClick={() => onNeedEditCallback(field?.guid)}>
                <label className={'field__label'}>{field?.label}</label>
                <div className={'field__input'} >
                    <ListIcon/>
                    <span className={'field__text'}>{value || ''} {field?.suffics}</span>
                </div>
            </div>
        )
    } else if (field?.type === 'crm_client') {
        return (
            <div className={'field__info'} onClick={() => onNeedEditCallback(field?.guid)}>
                <label className={'field__label'}>{field?.label}</label>
                <div className={'field__input'}>
                    <ListIcon/>
                    <span className={'field__text'}>{value?.generalName || 'Не заполнено'} {field?.suffics}</span>
                </div>
            </div>
        )
    } else if (field?.type === 'crm_company') {
        return (
            <div className={'field__info'} onClick={() => onNeedEditCallback(field?.guid)}>
                <label className={'field__label'}>{field?.label}</label>
                <div className={'field__input'}>
                    <ListIcon/>
                    <span className={'field__text'}>{value?.shortCompanyName || 'Не заполнено'} {value?.inn || ''} {field?.suffics}</span>
                </div>
            </div>
        )
    } else if (field?.type === 'crm_contact') {
        return (
            <div className={'field__info'} onClick={() => onNeedEditCallback(field?.guid)}>
                <label className={'field__label'}>{field?.label}</label>
                <div className={'field__input'}>
                    <ListIcon/>
                    <span className={'field__text'}>{value?.fio || 'Не заполнено'} {field?.suffics}</span>
                </div>
            </div>
        )
    } else if (field?.type === 'textarea') {
        return (
            <div className={'field__info'} onClick={() => onNeedEditCallback(field?.guid)}>
                <label className={'field__label'}>{field?.label}</label>
                <div className={'field__input'}>
                    <ListIcon/>
                    <span className={'field__text'}>{value || ''} {field?.suffics}</span>
                </div>
            </div>
        )
    } else if (field?.type === 'number') {
        return (
            <div className={'field__info'} onClick={() => onNeedEditCallback(field?.guid)}>
                <label className={'field__label'}>{field?.label}</label>
                <div className={'field__input'}>
                    <ListIcon/>
                    <span
                        className={'field__text'}>{field?.isMoney ? getRubString(value || 0) : `${value || 0} ${field?.suffics}`}</span>
                </div>
            </div>
        )
    } else if (field?.type === 'enum') {
        return field?.isMultiple
            ?
            <div className={'field__info'} onClick={() => onNeedEditCallback(field?.guid)}>
                <label className={'field__label'}>{field?.label}</label>
                <Form.Group className={'mt-2'}>
                    <MultipleDropdownDefault
                        placeholder={field?.label}
                        options={field?.items}
                        currentValues={value}
                        disabled
                    />
                </Form.Group>
            </div>
            :
            <div className={'field__info'} onClick={() => onNeedEditCallback(field?.guid)}>
                <label className={'field__label'}>{field?.label}</label>
                <div className={'field__input'}>
                    <ListIcon/>
                    <span className={'field__text'}>{value?.value || 'Не заполнено'}</span>
                </div>
            </div>
    } else if (field?.type === 'entity') {
        return (
            <div className={'field__info'} onClick={() => onNeedEditCallback(field?.guid)}>
                <label className={'field__label'}>{field?.label}</label>
                <div className={'field__input'}>
                    <ListIcon/>
                    <span
                        className={'field__text'}>{dependencyEntity?.find(x => x?.method === field?.methodGettingEntities)?.titleSelector?.(value) || 'Не заполнено'}</span>
                </div>
            </div>
        )
    } else if (field?.type === 'selector') {
        return (
            <div className={'field__info'} onClick={() => onNeedEditCallback(field?.guid)}>
                <label className={'field__label'}>{field?.label}</label>
                <div className={'field__input'}>
                    <ListIcon/>
                    <span
                        className={'field__text'}>{dependencyEntity?.find(x => x?.method === field?.methodGettingEntities)?.titleSelector?.(value) || 'Не заполнено'}</span>
                </div>
            </div>
        )
    } else if (field?.type === 'date') {
        return (
            <div className={'field__info'} onClick={() => onNeedEditCallback(field?.guid)}>
                <label className={'field__label'}>{field?.label}</label>
                <div className={'field__input'}>
                    <ListIcon/>
                    <span className={'field__text'}>{new Date(value || null)?.toLocaleDateString() || ''}</span>
                </div>
            </div>
        )
    } else if (field?.type === 'boolean') {
        return (
            <div className={'field__info'} onClick={() => onNeedEditCallback(field?.guid)}>
                <label className={'field__label'}>{field?.label}</label>
                <div className={'field__input'}>
                    <ListIcon/>
                    <span className={'field__text'}>{value ? 'Да' : 'Нет'}</span>
                </div>
            </div>
        )
    } else if (field?.type === 'file') {
        return (
            <div className={'field__info'} onClick={() => onNeedEditCallback(field?.guid)}>
                <label className={'field__label'}>{field?.label}</label>
                <div className={'field__input'}>
                    <ListIcon/>
                    <span className={'field__text'}>{value?.name}</span>
                </div>
            </div>
        )
    } else if (field?.type === 'label') {
        return (
            <div className={'field__info'} onClick={() => onNeedEditCallback(field?.guid)}>
                <label className={'field__label'}>{field?.label}</label>
                <div className={'field__input'}>
                    <ListIcon/>
                    <span className={'field__text'}>{field?.isMoney ? getRubString(value || 0) : value || ''}</span>
                </div>
            </div>
        )
    } else if (field?.type === 'photo') {
        return (
            field?.isMultiple
                ?
                    value?.length > 0
                        ?
                            <div className={'field__info'} onClick={() => onNeedEditCallback(field?.guid)}>
                                <label className={'field__label'}>{field?.label}</label>
                                <PhotoEntityRenderer
                                    isMultiple={field?.isMultiple}
                                    entity={value}
                                />
                            </div>
                        :
                            <div className={'field__info'} onClick={() => onNeedEditCallback(field?.guid)}>
                                <label className={'field__label'}>{field?.label}</label>
                            </div>
                :
                    value
                        ?
                            <div className={'field__info'} onClick={() => onNeedEditCallback(field?.guid)}>
                                <label className={'field__label'}>{field?.label}</label>
                                <PhotoEntityRenderer
                                    isMultiple={field?.isMultiple}
                                    entity={value}
                                />
                            </div>
                        :
                            <div className={'field__info'} onClick={() => onNeedEditCallback(field?.guid)}>
                                <label className={'field__label'}>{field?.label}</label>
                            </div>
        )
    }
};

export default Label;