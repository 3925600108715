import {createContext, useContext, useEffect, useMemo, useState} from 'react';
import axios from "axios";
import {useMutation} from "@tanstack/react-query";
import {AxiosHelper} from "../../hooks/useAxiosHelper";
import {Outlet} from "react-router";
import Login from "../../pages/auth/login/Login";
import {isExpired} from "react-jwt";
import useAxiosErrorHandler from "../../hooks/axioserrorhandler/useAxiosErrorHandler";
export const AuthContext = createContext(null)


/*function replaceOrAddUrlParam(url, paramName, paramValue) {
    let pattern = new RegExp('\\b(' + paramName + '=).*?(&|$)');
    if (url.search(pattern) >= 0) {
        return url.replace(pattern, '$1' + paramValue + '$2');
    }
    // Если параметр не найден, добавляем его к URL
    let separator = url.indexOf('?') > 0 ? '&' : '?';
    return url + separator + paramName + '=' + paramValue;
}*/
const AuthProvider = ({children}) => {
    const [isAdmin, setIsAdmin] = useState(localStorage.getItem("isAdmin"));
    const [accessToken, setAccessToken] = useState(localStorage.getItem("accessToken"));
    const [isCanRendererChildren, setIsCanRendererChildren] = useState(false);
    const [isExpiredAccessToken, setIsExpiredAccessToken] = useState(false);
    const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem("auth")));
    const [isProcessCheckAuth, setIsProccessCheckAuth] = useState(false);
    const handleErrorResponse = useAxiosErrorHandler()

    useEffect(() => {
        if (accessToken && !isProcessCheckAuth) {
            const expired = isExpired(accessToken)
            setIsExpiredAccessToken(expired)
            if(!expired){
                localStorage.setItem('accessToken', accessToken);
                axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
                setIsCanRendererChildren(true)
            }
            else{
                checkAuth({
                    userName: userInfo?.userName,
                    accessToken: accessToken,
                    refreshToken: userInfo?.refreshToken
                })
            }
        } else if(!accessToken && !isProcessCheckAuth) {
            delete axios.defaults.headers.common["Authorization"];
            localStorage.removeItem('accessToken')
            setIsCanRendererChildren(false)
            setIsExpiredAccessToken(false)
        }

        if(userInfo?.roles?.some(x => x?.name === 'Admin') && !isAdmin  && !isProcessCheckAuth){
            localStorage.setItem('isAdmin', true);
            setIsAdmin(true)
        }
        else if(!userInfo?.roles?.some(x => x?.name === 'Admin') && isAdmin && !isProcessCheckAuth){
            localStorage.removeItem('isAdmin');
            setIsAdmin(false)
        }
        else{
            localStorage.removeItem('isAdmin');
            setIsAdmin(false)
        }

        if(userInfo && !isProcessCheckAuth)
            localStorage.setItem('auth', JSON.stringify(userInfo));
        else if(!userInfo && !isProcessCheckAuth)
            localStorage.removeItem('auth');

    }, [accessToken, userInfo]);

    useEffect(() => {
        if(userInfo)
        {
            setIsProccessCheckAuth(true)
            checkAuth({
                userName: userInfo?.userName,
                accessToken: accessToken,
                refreshToken: userInfo?.refreshToken
            })
        }

    }, []);

    useEffect(() => {
        if(isExpiredAccessToken && !isProcessCheckAuth){
            setIsExpiredAccessToken(true)
            checkAuth({
                userName: userInfo?.userName,
                accessToken: accessToken,
                refreshToken: userInfo?.refreshToken
            })
        }

    }, [isExpiredAccessToken, isProcessCheckAuth]);

    const logout = () => {
        setAccessToken('')
        setUserInfo(null)
    }





    const {mutate: checkAuth} = useMutation({
        mutationKey: 'auth',
        mutationFn: (checkModel) => AxiosHelper.postMethod({
            urlWithoutBaseUrl: 'accounts/check/',
            data: checkModel
        }),
        onSuccess: (localUserInfoCheck) => {
            setAccessToken(localUserInfoCheck?.accessToken)
            setUserInfo(localUserInfoCheck)
        },
        onError: (data) => {
            if(data?.response?.data === 'Invalid tokens' || data?.response?.data === 'User is null' || data?.response?.data === 'Refresh token not valid'){
                setAccessToken('')
                setUserInfo(null)
            }
            else if(data?.response?.data?.toLowerCase()?.includes('the token is expired'))
                refreshToken({
                    accessToken: userInfo?.accessToken,
                    refreshToken: userInfo?.refreshToken
                })

            handleErrorResponse(data)
        },
        onSettled: () => setIsProccessCheckAuth(false)
    })
    const {mutate: refreshToken} = useMutation({
        mutationKey: 'auth',
        mutationFn: (checkModel) => AxiosHelper.postMethod({
            urlWithoutBaseUrl: 'accounts/refresh-token/',
            data: checkModel
        }),
        onSuccess: (localUserInfoRefresh) => {
            setAccessToken('')
            setUserInfo(prev => ({
                ...prev,
                accessToken: localUserInfoRefresh?.accessToken,
                refreshToken: localUserInfoRefresh?.refreshToken
            }))
        },
        onError: (data) => {
            setAccessToken('')
            setUserInfo(null)

            handleErrorResponse(data)

        }
    })

    const contextValue = useMemo(
        () => ({
            isCanRendererChildren,
            isProcessCheckAuth,
            isAdmin,
            isExpiredAccessToken,
            accessToken,
            setAccessToken,
            userInfo,
            setUserInfo,
            checkAuth: () => {
                if(!isProcessCheckAuth)
                    checkAuth({
                        userName: userInfo?.userName,
                        accessToken: accessToken,
                        refreshToken: userInfo?.refreshToken
                    })
            },
            logout
        }),
        [accessToken, userInfo, isAdmin, isExpiredAccessToken, isCanRendererChildren, checkAuth, isProcessCheckAuth]
    );

    return (
        <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>

    );
};

export const useAuth = () =>  {
    const context = useContext(AuthContext)
    return {
        isCanRendererChildren: context?.isCanRendererChildren,
        isAdmin: context?.isAdmin,
        isExpiredAccessToken: context?.isExpiredAccessToken,
        accessToken: context?.accessToken,
        setAccessToken: context?.setAccessToken,
        userInfo: context?.userInfo,
        setUserInfo: context?.setUserInfo,
        checkAuth: context?.checkAuth,
        logout: context?.logout,
    }
}

export const ProtectedRoute = () => {
    const {accessToken, isCanRendererChildren} = useAuth();
    if(!accessToken)
        return <Login />

    else if(isCanRendererChildren) return <Outlet/>
}
export default AuthProvider;