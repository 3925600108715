import './TableBootstrap.css'
import PopoverAction from "../popoveraction/PopoverAction";
import PopoverBtn from "../popoveraction/PopoverBtn";
import {Table} from "react-bootstrap";
import TableBootstrapRow from "./row/TableBootstrapRow";
import {useEffect, useState} from "react";
import SortableWrapper from "../../modules/movementdndarray/SortableWrapper";
import {Menu, MenuItem} from "@mui/material";
import {generateGuid} from "../../features/guidgenerator/GuidGenerator";



const TableBootstrap = ({items = [], schema, popoverActions, positionSelector, keySelector, onChangePosition, popoverCaption, rowHeight, draggable = false}) => {

    const [localItems, setLocalItems] = useState([])

    useEffect(() => {
        if(Array.isArray(items) && items?.length > 0){
            if(positionSelector)
                setLocalItems(items?.sort((a, b) => {
                    let selectorA = positionSelector?.(a)
                    let selectorB = positionSelector?.(b)

                    if(selectorA >= selectorB)
                        return 1
                    else if(selectorA === selectorB)
                        return 0
                    else
                        return -1
                }))
            else
                setLocalItems(items)

        }
        else if(Array.isArray(items) && localItems?.length > 0 && items?.length === 0)
            setLocalItems(items)
    }, [items]);


    const renderDraggableContext = () => {
        return (
            <SortableWrapper
                localItems={localItems}
                setLocalItems={setLocalItems}
                keySelector={keySelector}
                positionSelector={positionSelector}
                onChangePosition={onChangePosition}
            >
                <RenderBootstrapRow
                    localItems={localItems}
                    draggable={draggable}
                    rowHeight={rowHeight}
                    keySelector={keySelector}
                    schema={schema}
                    popoverActions={popoverActions}
                    popoverCaption={popoverCaption}
                />
            </SortableWrapper>
        )
    }
    const renderDefaultContext = () => {
        return (
            <RenderBootstrapRow
                localItems={localItems}
                draggable={draggable}
                rowHeight={rowHeight}
                keySelector={keySelector}
                schema={schema}
                popoverActions={popoverActions}
                popoverCaption={popoverCaption}
            />
        )
    }

    return (
        <Table className={'table-b__wrapper rounded-3 overflow-hidden p-0 m-0'}>
            <thead className={'table-b__header'}>
            <tr className={'table-b__header_row'}>
                {draggable &&
                    <th className={'table-b__header_col'}>
                        <div className={'flex center'}>
                            #
                        </div>
                    </th>
                }

                {schema?.map((item, idx) => (
                    <th key={idx} className={'table-b__header_col'}>
                        <div className={'flex center'}>
                            {item?.title}
                        </div>
                    </th>
                ))}
                {popoverActions && <th className={'table-b__header_col'}>
                    <div className={'flex center'}>
                        Действия
                    </div>
                </th>}
            </tr>
            </thead>
            <tbody className={'table-b__body'}>
            {draggable
                ? renderDraggableContext()
                : renderDefaultContext()
            }
            </tbody>
        </Table>
    );
};

const RenderBootstrapRow = ({localItems, draggable, rowHeight, keySelector, schema, popoverActions, popoverCaption}) => {


    return (
        localItems?.map((item) => (
            <TableBootstrapRow
                key={item?.guid}
                draggable={draggable}
                rowHeight={rowHeight}
                childrenItem={item}
                keySelector={keySelector}
                schema={schema}
                popoverActions={popoverActions}
                popoverCaption={popoverCaption}
                item={item}
            />
        ))
    )
}


export default TableBootstrap;