import {Document, Font, Image, Page, StyleSheet, Text, View} from "@react-pdf/renderer";
import {BaseUrl} from "../../../../../features/databaseinfo/BaseUrl";
import React, {useEffect, useMemo, useState} from "react";
import {getCurrencyString} from "../../../../../features/formatters/forrmaterrub";
import TkpHeaderPdfV2 from "./components/TkpHeaderPdfV2";
import TkpSummaryTableEquipmentPdfV2 from "./components/TkpSummaryTableEquipmentPdfV2";
import TkpSummaryTableDiluentPdfV2 from "./components/TkpSummaryTableDiluentPdfV2";
import TkpPlaceItemPdfV2 from "./components/TkpPlaceItemPdfV2";
import TkpSystemPdfV2 from "./components/TkpSystemPdfV2";
import TkpAdditionalMaterialPdf from "./components/TkpAdditionalMaterialPdf";
import TkpInformationPagePdfV2 from "./components/TkpInformationPagePdfV2";


const TkpClientPdfV2 = ({tkp, USD}) => {
    console.log(tkp)
    Font.register({
        family: "Montserrat-Bold",
        src:
            `${BaseUrl}/photo/img/Montserrat/Montserrat-Bold.ttf`,
        fontWeight: "bold"
    });
    Font.register({
        family: "Montserrat-Black",
        src:
            `${BaseUrl}/photo/img/Montserrat/Montserrat-Black.ttf`,
        fontWeight: "Bold"
    });
    Font.register({
        family: "Montserrat-Regular",
        src:
            `${BaseUrl}/photo/img/Montserrat/Montserrat-Regular.ttf`,
        fontWeight: "normal"
    });
    Font.register({
        family: 'Montserrat', fonts: [
            {
                src: `${BaseUrl}/photo/img/Montserrat/Montserrat-Regular.ttf`,
                fontWeight: "normal",
                fontStyle: "normal"
            },
            {
                src: `${BaseUrl}/photo/img/Montserrat/Montserrat-Bold.ttf`,
                fontWeight: "bold",
                fontStyle: "normal"
            },
            {
                src: `${BaseUrl}/photo/img/Montserrat/Montserrat-Black.ttf`,
                fontWeight: "heavy",
                fontStyle: "normal"
            }
        ]
    });
    const [SummaryTableEquipments, setSummaryTableEquipments] = useState([])
    const [SummaryTableDiluents, setSummaryTableDiluents] = useState([])
    const [Systems, setSystems] = useState([])
    const [totalAdditionalMaterialsPrice, setTotalAdditionalMaterialsPrice] = useState(0)

    useEffect(() => {
        if (tkp?.summaryDiluents)
            setSummaryTableDiluents(tkp?.summaryDiluents)
        if (tkp?.summaryEquipments)
            setSummaryTableEquipments(tkp?.summaryEquipments)
        if (tkp?.systems)
            setSystems(tkp?.systems)
    }, [])
    const memoSummaryDiluents = useMemo(() => {
        return SummaryTableDiluents
    }, [SummaryTableDiluents])
    const memoSummaryEquipments = useMemo(() => {
        return SummaryTableEquipments
    }, [SummaryTableEquipments])
    const memoSystems = useMemo(() => {
        return Systems
    }, [Systems])
    const memoVisiblePrice = useMemo(() => tkp?.settings?.visiblePrice, [tkp])
    const memoAdditionalMaterials = useMemo(() => {
        let price = 0;
        tkp?.summaryAdditional.forEach(material => {
            price += material.totalPriceIncludingPackaging;
        })
        setTotalAdditionalMaterialsPrice(price);
        return tkp?.summaryAdditional
    }, [tkp])

    return (
        <Document style={styles.document}>
            <Page
                size={"A4"}
                orientation={"landscape"}
                style={styles.page}
            >
                <View style={[styles.wrapper, {gap: 10, padding: 10}]}>
                    <Image
                        src={
                            `${BaseUrl}/photo/img/BackgroundAncorr.png`
                        }
                        style={styles.backgroundImage}
                    /><Image
                    src={
                        `${BaseUrl}/photo/img/Bo.png`
                    }
                    style={styles.backgroundImage}
                />
                    <View style={styles.titleItems}>
                        <View style={styles.titleItem}>
                            <Image
                                src={
                                    `${BaseUrl}/photo/img/Logo.png`
                                }
                                style={styles.titleLogo}
                            />
                        </View>
                        <View style={[styles.titleItem, {justifyContent: 'flex-start'}]}>
                            <Text style={styles.titleOne}>ТЕХНИКО-КОММЕРЧЕСКОЕ ПРЕДЛОЖЕНИЕ</Text>
                            <Text
                                style={styles.titleTwo}>{tkp?.companyName?.toUpperCase() || "Не заполнено"?.toUpperCase()}</Text>
                            <Text
                                style={styles.titleThree}>СУДНО: {tkp?.ship?.toUpperCase() || 'Не заполнено'?.toUpperCase()}</Text>
                        </View>
                        <View style={[styles.titleItem, {justifyContent: "flex-end"}]}>
                            <Text
                                style={styles.titleAssigned}>ПОДГОТОВИЛ: {tkp?.assigned?.toUpperCase() || 'Не заполнено'.toUpperCase()}</Text>
                            <Text style={styles.titleDate}>ДАТА
                                СОЗДАНИЯ: {new Date(tkp?.createdTimeDb)?.toLocaleDateString()?.toUpperCase() || 'Не заполнено'.toUpperCase()}</Text>
                        </View>
                    </View>
                </View>
            </Page>
            <Page
                size={"A4"}
                orientation={"landscape"}
                style={styles.page}
            >
                <TkpInformationPagePdfV2
                    memoVisiblePrice={memoVisiblePrice}
                    tkp={tkp}
                    styles={styles}
                    totalAdditionalMaterialsPrice={totalAdditionalMaterialsPrice}
                />
            </Page>
            <Page
                size={"A4"}
                orientation={"landscape"}
                style={styles.page}
                wrap
            >
                <View style={[styles.wrapper, {gap: 10, padding: '0 10'}]} wrap>
                    <Image
                        fixed
                        src={
                            `${BaseUrl}/photo/img/BackgroundAncorr.png`
                        }
                        style={styles.backgroundImage}
                    />
                    <TkpHeaderPdfV2 tkp={tkp}/>
                    <Text style={styles.text}>СВОДНАЯ ТАБЛИЦА ПО МАТЕРИАЛАМ</Text>
                    <View style={[styles.items, styles.tableHeader]} wrap={false}>
                        <View style={[styles.item, styles.tableHeader]}>
                            <View style={[styles.column, {flex: 2}]}>
                                <Text style={styles.tableHeaderText}>Материал</Text>
                            </View>
                            <View style={[styles.column, {flex: 0.5}]}>
                                <Text style={styles.tableHeaderText}>Кол-во</Text>
                                <Text style={styles.tableHeaderText}>(л)</Text>
                            </View>
                            {memoVisiblePrice && <View style={[styles.column, {flex: 0.5}]}>
                                <Text style={styles.tableHeaderText}>Цена за литр со скидкой</Text>
                            </View>}
                            {memoVisiblePrice && <View style={[styles.column, {flex: 0.5}]}>
                                <Text style={styles.tableHeaderText}>Цена за м²</Text>
                            </View>}
                            {memoVisiblePrice && <View style={[styles.column, {flex: 0.5}]}>
                                <Text style={styles.tableHeaderText}>Цена за кг</Text>
                            </View>}
                            <View style={[styles.column, {flex: 0.3}]}>
                                <Text style={styles.tableHeaderText}>Вес</Text>
                                <Text>(кг)</Text>
                            </View>
                            <View style={[styles.column, {flex: 0.3}]}>
                                <Text style={styles.tableHeaderText}>Тара</Text>
                                <Text style={styles.tableHeaderText}>(л)</Text>
                            </View>
                            {memoVisiblePrice && <View style={[styles.column, {flex: 1}]}>
                                <Text style={styles.tableHeaderText}>Стоимость кр. таре</Text>
                            </View>}
                        </View>
                    </View>
                    <View style={[styles.items, {flex: '0 0 auto'}]}>
                        {memoSummaryEquipments.map(equipment => (
                            <TkpSummaryTableEquipmentPdfV2 key={equipment?.guid} equipment={equipment}
                                                           defaultStyles={styles}
                                                           visiblePrice={tkp?.settings?.visiblePrice} tkp={tkp}/>
                        ))}
                    </View>
                    {memoVisiblePrice && <View style={styles.price} wrap={false}>
                        <Text style={styles.priceText}>Стоимость без
                            НДС: {getCurrencyString(tkp?.priceForMaterialIncludingPackaging - tkp?.priceForMaterialIncludingPackagingVAT, tkp?.currencyForHandle?.currencyCode)}</Text>
                        {tkp.isHandleVAT &&
                            <>
                                <Text
                                    style={styles.priceText}>НДС {tkp.vat}%: {getCurrencyString(tkp?.priceForMaterialIncludingPackagingVAT, tkp?.currencyForHandle?.currencyCode)}</Text>
                                <Text style={styles.priceText}>
                                    Итого с
                                    НДС: {getCurrencyString(tkp?.priceForMaterialIncludingPackaging, tkp?.currencyForHandle?.currencyCode)}
                                </Text>
                            </>
                        }
                        <Text style={styles.priceText}>Вес: {tkp?.totalWeight?.toFixed(0)} кг</Text>
                        {/*<Text style={styles.priceText}>Объем: {tkp?.[totalVolumeTitle].toFixed(0)} м³</Text>*/}
                    </View>}
                    {/*<View style={styles.price}>*/}
                    {/*</View>*/}
                </View>
            </Page>
            {memoAdditionalMaterials.length > 0 &&
                <Page
                    size={"A4"}
                    orientation={"landscape"}
                    style={styles.page}
                >
                    <View style={[styles.wrapper, {gap: 10, padding: 10}]} wrap>
                        <Image
                            src={
                                `${BaseUrl}/photo/img/BackgroundAncorr.png`
                            }
                            style={styles.backgroundImage}
                            fixed
                        />
                        <TkpHeaderPdfV2 tkp={tkp}/>
                        <Text style={styles.text}>Дополнительный объем ЛКМ</Text>
                        <View style={[styles.items, styles.tableHeader]}>
                            <View style={[styles.item, styles.tableHeader]}>
                                <View style={[styles.column, {flex: 1}]}>
                                    <Text style={styles.tableHeaderText}>Материал</Text>
                                </View>
                                <View style={[styles.column, {flex: 1}]}>
                                    <Text style={styles.tableHeaderText}>Кол-во</Text>
                                </View>
                                {memoVisiblePrice &&
                                    <View style={[styles.column, {flex: 1}]}>
                                        <Text style={styles.tableHeaderText}>Цена за литр</Text>
                                    </View>
                                }
                                {/*{memoVisiblePrice &&*/}
                                {/*    <View style={[styles.column, {flex: 1}]}>*/}
                                {/*        <Text style={styles.tableHeaderText}>Скидка</Text>*/}
                                {/*    </View>*/}
                                {memoVisiblePrice &&
                                    <View style={[styles.column, {flex: 1}]}>
                                        <Text style={styles.tableHeaderText}>Стоимость кр. таре</Text>
                                    </View>
                                }
                            </View>
                        </View>
                        <View style={[styles.items, {flex: '0 0 auto'}]}>
                            {memoAdditionalMaterials.map((material) => (
                                <TkpAdditionalMaterialPdf
                                    tkp={tkp}
                                    key={material?.guid}
                                    material={material}
                                    defaultStyles={styles}
                                    visiblePrice={tkp?.settings?.visiblePrice}
                                />
                            ))}
                        </View>
                        {memoVisiblePrice &&
                            <View style={styles.price} wrap={false}>
                                <Text style={styles.priceText}>
                                    Стоимость без
                                    НДС: {getCurrencyString(totalAdditionalMaterialsPrice - totalAdditionalMaterialsPrice * (tkp?.vat / 100), tkp?.currencyForHandle?.currencyCode)}
                                </Text>
                                {tkp.isHandleVAT &&
                                    <>
                                        <Text style={styles.priceText}>
                                            НДС {tkp.vat}%: {getCurrencyString(totalAdditionalMaterialsPrice * (tkp?.vat / 100), tkp?.currencyForHandle?.currencyCode)}
                                        </Text>
                                        <Text style={styles.priceText}>
                                            Итого с
                                            НДС: {getCurrencyString(totalAdditionalMaterialsPrice, tkp?.currencyForHandle?.currencyCode)}
                                        </Text>
                                    </>
                                }
                            </View>
                        }
                    </View>
                </Page>
            }
            <Page
                size={"A4"}
                orientation={"landscape"}
                style={styles.page}
            >
                <View style={[styles.wrapper, {gap: 10, padding: 10}]} wrap>
                    <Image
                        src={
                            `${BaseUrl}/photo/img/BackgroundAncorr.png`
                        }
                        style={styles.backgroundImage}
                        fixed
                    />
                    <TkpHeaderPdfV2 tkp={tkp}/>
                    <Text style={styles.text}>СВОДНАЯ ТАБЛИЦА ПО РАЗБАВИТЕЛЯМ</Text>
                    <View style={[styles.items, styles.tableHeader]}>
                        <View style={[styles.item, styles.tableHeader]}>
                            <View style={[styles.column, {flex: 3}]}>
                                <Text style={styles.tableHeaderText}>Разбавитель</Text>
                            </View>
                            <View style={[styles.column, {flex: 0.5}]}>
                                <Text style={styles.tableHeaderText}>Кол-во</Text>
                                <Text style={styles.tableHeaderText}>(л)</Text>
                            </View>
                            <View style={[styles.column, {flex: 0.5}]}>
                                <Text style={styles.tableHeaderText}>Вес</Text>
                                <Text style={styles.tableHeaderText}>(кг)</Text>
                            </View>
                            <View style={[styles.column, {flex: 0.5}]}>
                                <Text style={styles.tableHeaderText}>% разбавления</Text>
                            </View>
                            {memoVisiblePrice && <View style={[styles.column, {flex: 0.5}]}>
                                <Text style={styles.tableHeaderText}>Цена за литр</Text>
                            </View>}
                            {memoVisiblePrice && <View style={[styles.column, {flex: 0.5}]}>
                                <Text style={styles.tableHeaderText}>Цена за кг</Text>
                            </View>}
                            {memoVisiblePrice && <View style={[styles.column, {flex: 1}]}>
                                <Text style={styles.tableHeaderText}>Стоимость кр. таре</Text>
                            </View>}
                        </View>
                    </View>
                    <View style={[styles.items, {flex: '0 0 auto'}]}>
                        {memoSummaryDiluents.map(diluent => (
                            <TkpSummaryTableDiluentPdfV2 tkp={tkp} key={diluent?.guid} diluent={diluent}
                                                         defaultStyles={styles}
                                                         visiblePrice={tkp?.settings?.visiblePrice}/>
                        ))}
                    </View>
                    {memoVisiblePrice && <View style={styles.price} wrap={false}>
                        <Text style={styles.priceText}>Стоимость без
                            НДС: {getCurrencyString(tkp?.priceForDiluentIncludingPackaging - tkp?.priceForDiluentIncludingPackagingVAT, tkp?.currencyForHandle?.currencyCode)}</Text>
                        {tkp.isHandleVAT &&
                            <>
                                <Text
                                    style={styles.priceText}>НДС {tkp?.vat}%: {getCurrencyString(tkp?.priceForDiluentIncludingPackagingVAT, tkp?.currencyForHandle?.currencyCode)}</Text>
                                <Text style={styles.priceText}>Итого с
                                    НДС: {getCurrencyString(tkp?.priceForDiluentIncludingPackaging, tkp?.currencyForHandle?.currencyCode)}</Text>
                            </>
                        }
                    </View>}
                    {memoVisiblePrice && <View style={styles.price} wrap={false}>
                        <Text style={styles.priceText}>Общая стоимость без
                            НДС: {getCurrencyString(tkp?.totalPriceIncludingPackaging - tkp?.totalPriceIncludingPackagingVAT, tkp?.currencyForHandle?.currencyCode)}</Text>
                        {tkp.isHandleVAT &&
                            <>
                                <Text style={styles.priceText}>Общий
                                    НДС {tkp?.vat}%: {getCurrencyString(tkp?.totalPriceIncludingPackagingVAT, tkp?.currencyForHandle?.currencyCode)}</Text>
                                <Text style={styles.priceText}>Итого с
                                    НДС: {getCurrencyString(tkp?.totalPriceIncludingPackaging, tkp?.currencyForHandle?.currencyCode)}</Text>
                            </>
                        }
                    </View>}
                </View>
            </Page>
            <Page
                size={"A4"}
                orientation={"landscape"}
                style={styles.page}
            >
                <View style={[styles.wrapper, {gap: 10, padding: 10}]} wrap>
                    <Image
                        src={
                            `${BaseUrl}/photo/img/BackgroundAncorr.png`
                        }
                        style={styles.backgroundImage}
                        fixed
                    />
                    <TkpHeaderPdfV2 tkp={tkp}/>
                    <Text style={styles.text}>СВОДНАЯ ТАБЛИЦА ПО ПОВЕРХНОСТЯМ</Text>
                    <View style={[styles.items, styles.tableHeader]}>
                        <View style={[styles.item, styles.tableHeader]}>
                            <View style={[styles.column, {flex: 0.2}]}>
                                <Text style={styles.tableHeaderText}>№</Text>
                            </View>
                            <View style={[styles.column, {flex: 4, paddingLeft: 10, alignItems: "flex-start"}]}>
                                <Text style={styles.tableHeaderText}>Название поверхности</Text>
                            </View>
                            <View style={[styles.column, {flex: 0.6}]}>
                                <Text style={styles.tableHeaderText}>Площадь</Text>
                            </View>
                        </View>
                    </View>
                    <View style={[styles.items, {flex: '0 0 auto'}]}>
                        {memoSystems.map((system, index) => (
                            <TkpPlaceItemPdfV2 tkp={tkp} Name={system?.title} Guid={system?.guid} Number={index + 1}
                                               Square={system?.square} defaultStyles={styles}/>
                        ))}
                    </View>
                    <View style={[styles.price, {justifyContent: "space-between", paddingLeft: 10, paddingRight: 10}]}>
                        <Text style={[styles.priceText, {textAlign: "left"}]}>Итого площадь:</Text>
                        <Text
                            style={[styles.priceText, {textAlign: "right"}]}>{memoSystems.reduce((acc, item) => acc + (item?.square || 0), 0)} м²</Text>
                    </View>
                </View>
            </Page>
            {memoSystems.map((system, index) => (
                <TkpSystemPdfV2 USD={USD} tkp={tkp} system={system} index={index} defaultStyles={styles}
                                visiblePrice={tkp?.settings?.visiblePrice}/>
            ))}
        </Document>
    );
};

const styles = StyleSheet.create({
    document: {
        zIndex: 30,
        position: "relative",
        fontFamily: 'Montserrat-Regular'

    },
    page: {
        position: "relative",
        zIndex: 30,
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        backgroundColor: '#F3F3F3',
        width: '100%',
        height: '100%',
    },
    wrapper: {
        position: "relative",
        zIndex: 30,
        alignItems: "center",
        justifyContent: "flex-start",
        flexDirection: "column",
        backgroundColor: 'white',
        width: '100%',
        flex: 1,
    },
    titleItems: {
        flex: 1,
        width: '100%',
        flexDirection: "column",
        alignItems: 'flex-start',
        justifyContent: "space-between",

    },
    titleItem: {
        flex: 1,
        width: '100%',
        flexDirection: "column",
        alignItems: 'flex-start',
        justifyContent: "flex-start",
    },
    titleAssigned: {
        fontSize: 12,
        fontFamily: 'Montserrat-Bold'
    },
    titleDate: {
        fontSize: 12,
        fontFamily: 'Montserrat-Bold',
        fontWeight: 400
    },
    titleLogo: {
        width: 200,
        height: 50
    },
    titleOne: {
        fontStyle: 'normal',
        fontSize: 24,
        fontWeight: 800,
        color: '#00a3ff',
        fontFamily: 'Montserrat-Black'
    },
    titleTwo: {
        fontSize: 20,
        fontWeight: 300,
        fontFamily: 'Montserrat-Bold'
    },
    titleThree: {
        fontSize: 20,
        fontWeight: 300,
        fontFamily: 'Montserrat-Bold'
    },
    backgroundImage: {
        zIndex: 15,
        position: "absolute",
        width: '100%',
        height: "100%",
        justifyContent: "center",
        alignItems: "center"
    },
    header: {
        padding: 10,
        alignItems: "center",
        gap: 20,
        justifyContent: "flex-start",
        flex: 0,
        width: '100%',
        flexDirection: "row"
    },

    equipment: {},
    diluent: {},
    tableHeader: {
        alignItems: "center",
        justifyContent: "center",
        flexDirection: 'row',
        backgroundColor: 'white',
        maxHeight: 35,
        height: 35,
        minHeight: 25,
        width: '100%',
        textAlign: 'center',
        fontSize: 9,
        fontFamily: 'Montserrat-Bold',
        fontWeight: 400
    },
    tableHeaderText: {
        fontSize: 9,
        color: "black",

    },
    names: {
        textAlign: 'left',
        fontSize: 9,
        color: "black",
    },
    items: {
        paddingRight: 10,
        paddingLeft: 10,
        alignItems: "center",
        justifyContent: "flex-start",
        flexDirection: "column",
        gap: 5,
        width: '100%',
        flex: 10,
    },
    item: {

        alignItems: "center",
        justifyContent: "center",
        flexDirection: 'row',
        maxHeight: 'auto',
        minHeight: 15,
        width: '100%',
        fontSize: 9,
        textAlign: 'center',
        borderBottom: '1px solid black',
        opacity: 0.8

    },
    column: {
        flex: 1,
        // height: '100%',
        alignItems: "center",
        justifyContent: "center",
        paddingBottom: 5
    },
    text: {
        fontFamily: 'Montserrat-Bold',
        fontWeight: 400
    },
    price: {
        borderRadius: 5,
        backgroundColor: '#00a3ff',
        padding: 5,
        width: '100%',
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        // flex: 0.4,
        gap: 10,
    },
    priceText: {
        color: 'white',
        fontSize: 10,
        width: '100%',
        height: '100%',
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        fontFamily: 'Montserrat-Bold',
        fontWeight: 400

    }

});

export default TkpClientPdfV2;