import './ImageAvatar.css'
import {Image} from "react-bootstrap";

const ImageAvatar = ({urlPath}) => {
    return (
        <Image
            className={'image__avatar'}
            src={urlPath || 'https://static.tildacdn.com/tild3238-3834-4566-b939-643034303766/no-profile.png'}
            onError={e => {
                e.target.src = 'https://static.tildacdn.com/tild3238-3834-4566-b939-643034303766/no-profile.png'
            }}
            alt=""
        />
    );
};

export default ImageAvatar;