import {Form} from "react-bootstrap";
import FilterInput from "../../../UI/filterinput/FilterInput";
import {useEffect, useRef, useState} from "react";
import './DryingTimeInputs.css'


const DryingTimeInputs = ({filters, setFilters, updatePosition}) => {
    const refInputs = useRef(null);
    const [isFirstLoad, setIsFirstLoad] = useState(true);

    useEffect(() => {
        if (isFirstLoad) setIsFirstLoad(false);
        else updatePosition({target: refInputs.current});
    }, [filters.dryingTimeHoursMax, filters.dryingTimeHoursMin])

    return (
        <div className={'remains-filters__none-accordion'}>
            <Form.Label className={'filter-inputs__label'}>Время сушки, час</Form.Label>
            <div
                className="filters-inputs__inputs"
                ref={refInputs}
            >
                <FilterInput
                    value={filters.dryingTimeHoursMin}
                    setValue={value => setFilters({...filters, dryingTimeHoursMin: value})}
                    placeholder={"От"}
                />
                <FilterInput
                    value={filters.dryingTimeHoursMax}
                    setValue={value => setFilters({...filters, dryingTimeHoursMax: value})}
                    placeholder={"До"}
                />
            </div>
        </div>
    );
};

export default DryingTimeInputs;