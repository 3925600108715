import {
    handleMaxPrice,
    getPriceProviderPerLiter,
    getSupplyPriceProviderPerUnit, handleMaxPriceNew
} from "../../../features/pricecalculators/PriceCalculator";
import {generateGuid} from "../../../features/guidgenerator/GuidGenerator";
import BX24 from "bx24-api";
import {handleObjectForm} from "../../../features/handlers/handlerFormData";
import axios from "axios";
import {BaseUrl} from "../../../features/databaseinfo/BaseUrl";

const dateParser = /(\d{2})\.(\d{2})\.(\d{4}) (\d{2}):(\d{2}):(\d{2})/;
const dateParser2 = /(\d{2})\.(\d{2})\.(\d{4}) (\d{1}):(\d{2}):(\d{2})/;


export const getPricesDiluent = (quantity = 0, material, discountManager = 0, USD = 0, pricePerLiterDelivery = 0, pricePerLiterService = 0) => {
    let PriceForLiter = 0;
    let TotalPriceIncludingPackaging = 0;
    let TotalPrice = 0;

    if(material?.equipmentInformation?.diluent?.diluentParent){
        let defaultDiscountValueDiluent= material?.equipmentInformation?.diluent.diluentParent?.discount?.value;
        let defaultDeliveryCurrencyEquipment = material?.equipmentInformation?.diluent?.diluentParent?.deliveryCurrencyEquipment;
        PriceForLiter = handleMaxPrice(getPriceProviderPerLiter(material?.equipmentInformation?.diluent?.diluentParent, true), defaultDiscountValueDiluent, discountManager, defaultDeliveryCurrencyEquipment, USD, false, pricePerLiterDelivery, pricePerLiterService)
        TotalPrice = PriceForLiter * quantity;
        TotalPriceIncludingPackaging = PriceForLiter * getQuantityIncludingPackaging(quantity, material?.equipmentInformation?.diluent?.diluentParent?.packageSize);
    }

    return {
        PriceForLiter,
        TotalPrice,
        TotalPriceIncludingPackaging
    }
}
export const getPricesDiluentV2 = (quantity = 0, material, discountManager = 0, USD = 0, pricePerLiterDelivery = 0, pricePerLiterService = 0) => {
    let PriceForLiter = 0;
    let TotalPriceIncludingPackaging = 0;
    let TotalPrice = 0;

    if(material?.equipmentInformation?.lkmInformation?.diluent?.equipment){
        let defaultDiscountValueDiluent= material?.equipmentInformation?.lkmInformation?.diluent.equipment?.maxDiscount;
        let defaultDeliveryCurrencyEquipment = material?.equipmentInformation?.lkmInformation?.diluent?.equipment?.equipmentInformation?.currency?.currencyCode;
        PriceForLiter = handleMaxPriceNew(getSupplyPriceProviderPerUnit('equipment', material?.equipmentInformation?.lkmInformation?.diluent?.equipment), defaultDiscountValueDiluent, discountManager, pricePerLiterDelivery, pricePerLiterService, defaultDeliveryCurrencyEquipment)
        TotalPrice = PriceForLiter * quantity;
        TotalPriceIncludingPackaging = PriceForLiter * getQuantityIncludingPackaging(quantity, material?.equipmentInformation?.lkmInformation?.diluent?.equipment?.equipmentInformation?.packageSize);
    }

    return {
        PriceForLiter,
        TotalPrice,
        TotalPriceIncludingPackaging
    }
}
const getPricesMaterial = (quantity = 0, material, discountManager = 0, USD = 0, pricePerLiterDelivery = 0, pricePerLiterService = 0) => {
    let PriceForLiter = 0;
    let TotalPriceIncludingPackaging = 0;
    let TotalPrice = 0;

    if(material?.id){
        PriceForLiter = handleMaxPrice(getPriceProviderPerLiter(material), (material?.discount?.value || 0), discountManager, material?.deliveryCurrencyEquipment, USD, false, pricePerLiterDelivery, pricePerLiterService)
        TotalPrice = PriceForLiter * quantity;
        TotalPriceIncludingPackaging = PriceForLiter * getQuantityIncludingPackaging(quantity, material?.packageSize);
    }

    return {
        PriceForLiter,
        TotalPrice,
        TotalPriceIncludingPackaging
    }
}
export const handleHidingPower = (material, thicknessTspCustom, lossFactor = 0) => {
    let dryResidue = 1;
    let thicknessTsp = 1;
    if(material?.name){
        if(material?.equipmentInformation?.dryResidue !== 0){
            dryResidue = material?.equipmentInformation?.dryResidue;
        }
        if(material?.equipmentInformation?.dryLayerThicknessMin !== 0){
            thicknessTsp = material?.equipmentInformation?.dryLayerThicknessMin;
        }
    }
    if(thicknessTspCustom){
        thicknessTsp = thicknessTspCustom;
    }

    return ((10*dryResidue)/thicknessTsp)/(100/(100 - lossFactor));
}
export const handleHidingPowerV2 = (material, thicknessTspCustom, lossFactor = 0) => {
    let dryResidue = 1;
    let thicknessTsp = 1;
    if(material?.name){
        if(material?.equipmentInformation?.lkmInformation?.dryResidue !== 0){
            dryResidue = material?.equipmentInformation?.lkmInformation?.dryResidue;
        }
        if(material?.equipmentInformation?.lkmInformation?.dryLayerThicknessMin !== 0){
            thicknessTsp = material?.equipmentInformation?.lkmInformation?.dryLayerThicknessMin;
        }
    }
    if(thicknessTspCustom){
        thicknessTsp = thicknessTspCustom;
    }

    return ((10*dryResidue)/thicknessTsp)/(100/(100 - lossFactor));
}
export const handleThicknessTms = (thicknessTsp = 1, dryResidue = 1) => {
    return ((100*thicknessTsp)/dryResidue);
}
const handleQuantity = (squareSystem, percentFromSquare, material, thicknessTsp, lossFactor) => ((squareSystem/100)*percentFromSquare)/handleHidingPower(material, thicknessTsp, lossFactor)
const getQuantityIncludingPackaging = (quantity, PackageSize) => Math.ceil(quantity/PackageSize) * PackageSize;
const getCountIncludingPackaging = (quantity, PackageSize) => Math.ceil(quantity/PackageSize);
const getAllPricesFromSlices = (slices = [], USD = 0, indexMaterial = "material", pricePerLiterDelivery = 0, pricePerLiterService = 0) => {

    let PriceForMaterial = 0;
    let PriceForMaterialIncludingPackaging = 0;
    let PriceForDiluent = 0;
    let PriceForDiluentIncludingPackaging = 0;
    let TotalPrice = 0;
    let TotalPriceIncludingPackaging = 0;
    let TotalWeight = 0;
    let TotalVolume = 0;

    let handleMaterials = []
    let handleDiluents = []

    slices.forEach(slice => {
        if(slice[indexMaterial] && slice[indexMaterial].id){

            let quantityAllSlices = slices.reduce((acc, item) => {
                if(item[indexMaterial] && item[indexMaterial].id && item[indexMaterial].name === slice[indexMaterial].name) return acc + item.quantity;
                else return acc;
            }, 0);

            if(!handleMaterials.some(x => x.name === slice[indexMaterial].name)){
                let pricesMaterial = getPricesMaterial(quantityAllSlices, slice[indexMaterial], 0, USD, pricePerLiterDelivery, pricePerLiterService)
                let weight = 0;
                let volume = 0;
                let packageSize = 0;

                packageSize = slice[indexMaterial].packageSize;

                PriceForMaterial = PriceForMaterial + pricesMaterial.totalPrice;
                PriceForMaterialIncludingPackaging = PriceForMaterialIncludingPackaging + pricesMaterial.totalPriceIncludingPackaging;
                TotalPrice = TotalPrice + pricesMaterial.totalPrice;
                TotalPriceIncludingPackaging = TotalPriceIncludingPackaging + pricesMaterial.totalPriceIncludingPackaging;

                weight = slice[indexMaterial].weight;
                volume = slice[indexMaterial].volume;

                TotalWeight = TotalWeight + getCountIncludingPackaging(quantityAllSlices, packageSize) * weight;
                TotalVolume = TotalVolume + getCountIncludingPackaging(quantityAllSlices, packageSize) * volume;

                handleMaterials.push(slice[indexMaterial])
            }
        }
    })
    slices.forEach(slice => {
        if(slice[indexMaterial]
            && slice[indexMaterial].id
            && slice[indexMaterial].equipmentInformation
            && slice[indexMaterial].equipmentInformation.diluent
            && slice[indexMaterial].equipmentInformation.diluent.diluentParent
            && !handleDiluents.some(x => x.name === slice[indexMaterial].equipmentInformation.diluent.diluentParent.name)
        ){

            let percentFromMaterial = 0;
            let weightDiluent = 0;
            let volumeDiluent = 0;
            let diluentPackageSize = 0;

            percentFromMaterial = slice[indexMaterial].equipmentInformation.diluent.percentFromMaterial
            diluentPackageSize = slice[indexMaterial].equipmentInformation.diluent.diluentParent.packageSize

            let quantityDiluentAllSlices = slices.reduce((acc, item) => {
                if(item[indexMaterial]
                    && item[indexMaterial].id
                    && item[indexMaterial].equipmentInformation
                    && item[indexMaterial].equipmentInformation.diluent
                    && item[indexMaterial].equipmentInformation.diluent.DiluentParent
                    && item[indexMaterial].equipmentInformation.diluent.DiluentParent.name === slice[indexMaterial].equipmentInformation.diluent.diluentParent.name
                ) return acc + getQuantityDiluent(item.quantity, percentFromMaterial);
                else return acc;
            }, 0);

            weightDiluent = slice[indexMaterial].equipmentInformation.diluent.diluentParent.weight;
            volumeDiluent = slice[indexMaterial].equipmentInformation.diluent.diluentParent.volume;

            TotalWeight = TotalWeight + getCountIncludingPackaging(quantityDiluentAllSlices, diluentPackageSize) * weightDiluent;
            TotalVolume = TotalVolume + getCountIncludingPackaging(quantityDiluentAllSlices, diluentPackageSize) * volumeDiluent;

            let pricesDiluent = getPricesDiluent(quantityDiluentAllSlices, slice[indexMaterial], 0, USD, pricePerLiterDelivery, pricePerLiterService)

            PriceForDiluent = PriceForDiluent + pricesDiluent.totalPrice;
            PriceForDiluentIncludingPackaging = PriceForDiluentIncludingPackaging + pricesDiluent.totalPriceIncludingPackaging;
            TotalPrice = TotalPrice + pricesDiluent.totalPrice;
            TotalPriceIncludingPackaging = TotalPriceIncludingPackaging + pricesDiluent.totalPriceIncludingPackaging;

            handleDiluents.push(slice[indexMaterial].equipmentInformation.diluent.diluentParent)
        }
    })

    return {
        PriceForMaterial,
        PriceForMaterialIncludingPackaging,
        PriceForDiluent,
        PriceForDiluentIncludingPackaging,
        TotalPrice,
        TotalPriceIncludingPackaging,
        TotalWeight,
        TotalVolume,
    }
}
const handleSlice = (slice, systemSquare, USD, pricePerLiterDelivery = 0, pricePerLiterService = 0) => {

    let newSlice = {...slice};
    const count = handleQuantity(systemSquare, newSlice.percentFromSquare, newSlice.material, newSlice.thicknessTsp, newSlice.lossFactor);

    if(newSlice.material && newSlice.material.name){
        let { PriceForLiter, TotalPrice, TotalPriceIncludingPackaging} = getPricesMaterial(count, newSlice.material, 0, USD, pricePerLiterDelivery, pricePerLiterService);
        let priceForKg = PriceForLiter/newSlice.material.specificWeight;
        let PriceForMeterSquare = PriceForLiter/handleHidingPower(newSlice.material, newSlice.thicknessTsp);

        let quantityIncludingPackaging = getQuantityIncludingPackaging(count, newSlice.material.packageSize);

        let totalWeight = getCountIncludingPackaging(quantityIncludingPackaging, newSlice.material.packageSize)*newSlice.material.weight;
        let totalVolume = getCountIncludingPackaging(quantityIncludingPackaging, newSlice.material.packageSize)*newSlice.material.volume;

        newSlice.priceForLiter = PriceForLiter ? PriceForLiter : 0;
        newSlice.priceForMeterSquare = PriceForMeterSquare ? PriceForMeterSquare : 0;
        newSlice.priceForKg = priceForKg ? priceForKg : 0;
        newSlice.totalPrice = TotalPrice ? TotalPrice : 0;
        newSlice.totalPriceIncludingPackaging = TotalPriceIncludingPackaging ? TotalPriceIncludingPackaging : 0;

        newSlice.totalWeight = totalWeight ? totalWeight : 0;
        newSlice.totalVolume = totalVolume ? totalVolume : 0;

        newSlice.quantityIncludingPackaging = quantityIncludingPackaging ? quantityIncludingPackaging : 0;

    }

    newSlice.quantity = count ? count : 0;
    newSlice.thicknessTsp = newSlice.thicknessTsp && newSlice.thicknessTsp !== 0
        ? newSlice.thicknessTsp
        : newSlice.material && newSlice.material.name
            ? newSlice.material.equipmentInformation.dryLayerThicknessMin
            : 0
    ;


    return newSlice;
}
export const getCoatingTypeFromSlice = (slice, accessor) => {
    let coatingType = 'Не заполнено'
    if(slice.isBandpass){
        coatingType = 'Полосовое'
    }
    else if(slice[accessor] && slice[accessor].name && slice[accessor].equipmentInformation.coatingType){
        coatingType = slice[accessor].equipmentInformation.coatingType.title
    }
    return coatingType
}
export const getCoatingTypeFromSliceV2 = (slice, accessor) => {
    let coatingType = 'Не заполнено'
    if(slice.isBandpass){
        coatingType = 'Полосовое'
    }
    else if(slice[accessor] && slice[accessor]?.name && slice[accessor]?.equipmentInformation?.lkmInformation?.coatingType){
        coatingType = slice[accessor]?.equipmentInformation?.lkmInformation?.coatingType?.value
    }
    return coatingType
}
export const getQuantityDiluent = (quantityFromSlice, percentFromMaterial) => quantityFromSlice/100*percentFromMaterial
export const updateSystems = (systems, USD = 1, pricePerLiterDelivery = 0, pricePerLiterService = 0) => {
    systems.forEach(system => {
        let {
            PriceForMaterial,
            PriceForMaterialIncludingPackaging,
            PriceForDiluent,
            PriceForDiluentIncludingPackaging,
            TotalPrice,
            TotalPriceIncludingPackaging,
            TotalWeight,
            TotalVolume
        } = getAllPricesFromSlices(system.slices, USD, "material", pricePerLiterDelivery, pricePerLiterService)

        system.totalWeight = TotalWeight;
        system.totalVolume = TotalVolume;
        system.priceForMaterial = PriceForMaterial;
        system.priceForMaterialIncludingPackaging = PriceForMaterialIncludingPackaging;
        system.priceForDiluent = PriceForDiluent;
        system.priceForDiluentIncludingPackaging  = PriceForDiluentIncludingPackaging;
        system.totalPrice = TotalPrice;
        system.totalPriceIncludingPackaging = TotalPriceIncludingPackaging;
    })
}
export const addSystem = (systems) => {
    let newSystem = {
        Id: 0,
        Guid: generateGuid(),
        Title: '',
        Number: systems.length + 1,
        Square: 1,
        PriceForMaterial: 0,
        PriceForDiluent: 0,
        TotalPrice: 0,
        PriceForMaterialIncludingPackaging: 0,
        PriceForDiluentIncludingPackaging: 0,
        TotalPriceIncludingPackaging: 0,
        TotalWeight: 0,
        TotalVolume: 0,
        Background: {
            Id: 0,
            Title: '',
            Value: ''
        },
        Roughness: {
            Id: 0,
            Title: '',
            Value: ''
        },
        Preparation: [
            {
                Value: {
                    Id: 0,
                    Title: '',
                    Value: ''
                },
                Percent: 0
            },
            {
                Value: {
                    Id: 0,
                    Title: '',
                    Value: ''
                },
                Percent: 0
            },
            {
                Value: {
                    Id: 0,
                    Title: '',
                    Value: ''
                },
                Percent: 0
            },
        ],
        Slices: [
            {
                Guid: generateGuid(),
                Slice: 1,
                Material: {},
                PercentFromSquare: 100,
                LossFactor: 30,
                ThicknessTsp: 0,
                Quantity: 0,
                QuantityIncludingPackaging: 0,
                PriceForLiter: 0,
                PriceForMeterSquare: 0,
                PriceForKg: 0,
                TotalPrice: 0,
                TotalPriceIncludingPackaging: 0,
                TotalVolume: 0,
                TotalWeight: 0,
                IsBandpass: false,
            },
            {
                Guid: generateGuid(),
                Slice: 2,
                Material: {},
                PercentFromSquare: 100,
                LossFactor: 30,
                ThicknessTsp: 0,
                Quantity: 0,
                QuantityIncludingPackaging: 0,
                PriceForLiter: 0,
                PriceForMeterSquare: 0,
                PriceForKg: 0,
                TotalPrice: 0,
                TotalPriceIncludingPackaging: 0,
                TotalVolume: 0,
                TotalWeight: 0,
                IsBandpass: false,
            },
            {
                Guid: generateGuid(),
                Slice: 3,
                Material: {},
                PercentFromSquare: 100,
                LossFactor: 30,
                ThicknessTsp: 0,
                Quantity: 0,
                QuantityIncludingPackaging: 0,
                PriceForLiter: 0,
                PriceForMeterSquare: 0,
                PriceForKg: 0,
                TotalPrice: 0,
                TotalPriceIncludingPackaging: 0,
                TotalVolume: 0,
                TotalWeight: 0,
                IsBandpass: false,
            },
        ]

    }
    let newArray = [...systems, newSystem]
    return newArray;
}
export const changeSystem = (number, newSystem, systems, USD, pricePerLiterDelivery = 0, pricePerLiterService = 0) => {
    newSystem.slices = newSystem.slices.map(slice => {
        let newSliceHandle = handleSlice(slice, newSystem.square, USD, pricePerLiterDelivery, pricePerLiterService)
        return newSliceHandle;
    });


    let newArray = [...systems.filter(x => x.number !== number), newSystem].sort((a, b) => {
        if(a.number > b.number) return 1
        else if (a.number < b.number) return -1

        return 0
    })

    return newArray;
}
export const deleteSlice = (sliceNumber, systemNumber, systems) => {
    let newArray = systems.map(system => {
        if(system.number === systemNumber){

            let newArr = [...system.slices.filter(x => x.slice !== sliceNumber)]
            system.slices = newArr.map((item, index) => {
                item.slice = index + 1;
                return item;
            })
            return system;
        }
        else return system;
    })
    return newArray;
}
export const addSlice = (systemNumber, systems) => {

    let newArray = systems.map(system => {
        if(system.Number === systemNumber){
            let slice = {
                Guid: generateGuid(),
                Slice: system.Slices.length + 1,
                Material: {},
                PercentFromSquare: 100,
                IsBandpass: false,
                LossFactor: 30,
                ThicknessTsp: 0,
                Quantity: 0,
                QuantityIncludingPackaging: 0,
                PriceForLiter: 0,
                PriceForMeterSquare: 0,
                PriceForKg: 0,
                TotalPrice: 0,
                TotalPriceIncludingPackaging: 0,
                TotalVolume: 0,
                TotalWeight: 0,
            }
            system.Slices.push(slice)
            return system;
        }
        else return system;
    })

    return newArray;
}
export const changeSlice = (systemNumber, newSlice, systems, USD, pricePerLiterDelivery = 0, pricePerLiterService = 0) => {

    let systemSearch = systems.find(x => x.number === systemNumber)

    let sliceHandle = handleSlice(newSlice, systemSearch.square, USD, pricePerLiterDelivery, pricePerLiterService);

    let newArray = systems.map(system => {
        if(system.number === systemNumber){
            system.slices = [...system.slices.filter(x => x.guid !== newSlice.guid), sliceHandle].sort((a, b) => {
                if(a.slice > b.slice) return 1
                else if (a.slice < b.slice) return -1

                return 0
            })
            return system;
        }
        else return system;
    });

    return newArray;
}
export const deleteSystem = (systemNumber, systems) => {
    let newArray = [...systems.filter(x => x.number !== systemNumber)].map((item, index) => {
        item.number = index + 1;
        return item;
    })
    return newArray;
}
export const updateTkpPricesAndUnits = (summaryEquipments, summaryDiluents, selectedDeliveryCourse = '', listCourses = []) => {
    let TotalVolume = 0
    let TotalWeight = 0
    let PriceForDiluent = 0
    let PriceForMaterial = 0
    let TotalPrice = 0
    let PriceForDiluentIncludingPackaging = 0
    let PriceForMaterialIncludingPackaging = 0
    let TotalPriceIncludingPackaging = 0
    let DeliveryPrice = 0
    let TotalLiters = 0
    let TotalLitersProvision = 0


    TotalLiters = (summaryEquipments?.reduce((acc, item) => acc + item.quantityIncludingPackaging, 0)) + (summaryDiluents?.reduce((acc, item) => acc + item.quantityDiluentIncludingPackaging, 0))

    let priceToEndpointFromAstrah = listCourses.find(x => x.inputCourse === selectedDeliveryCourse.inputCourse && x.outputCourse.toLowerCase()?.includes('астрах'))?.price || 1;
    if(selectedDeliveryCourse?.OutputCourse?.includes('астрах'))
        priceToEndpointFromAstrah = selectedDeliveryCourse?.price || 1;
    let totalLitersFromAstrah = (summaryEquipments.reduce((acc, item) => {

        let quantityLitersProvisionItems = item?.provision?.provisionItems?.reduce((accProv, provItem) => {
            if(provItem?.warehouse?.name?.toLowerCase()?.includes('астрах')) return accProv + provItem?.quantityProvision
            else return accProv
        }, 0)
        return acc + quantityLitersProvisionItems
    }, 0)) + (summaryDiluents?.reduce((acc, item) => {

        let quantityLitersProvisionItems = item?.provision?.provisionItems?.reduce((accProv, provItem) => {
            if(provItem?.warehouse?.name?.toLowerCase()?.includes('астрах')) return accProv + provItem?.quantityProvision
            else return accProv
        }, 0)

        return acc + quantityLitersProvisionItems
    }, 0))

    let priceToEndpointFromRostov = listCourses?.find(x => x.inputCourse === selectedDeliveryCourse.inputCourse && x.outputCourse?.toLowerCase()?.includes('ростов'))?.price || 1;
    if(selectedDeliveryCourse?.outputCourse?.includes('ростов'))
        priceToEndpointFromAstrah = selectedDeliveryCourse?.price || 1;
    let totalLitersFromRostov = (summaryEquipments.reduce((acc, item) => {

        let quantityLitersProvisionItems = item?.provision?.provisionItems?.reduce((accProv, provItem) => {
            if(provItem?.warehouse?.name?.toLowerCase()?.includes('ростов')) return accProv + provItem?.quantityProvision
            else return accProv
        }, 0)
        return acc + quantityLitersProvisionItems
    }, 0)) + (summaryDiluents?.reduce((acc, item) => {

        let quantityLitersProvisionItems = item?.provision?.provisionItems?.reduce((accProv, provItem) => {
            if(provItem?.warehouse?.name?.toLowerCase()?.includes('ростов')) return accProv + provItem?.quantityProvision
            else return accProv
        }, 0)

        return acc + quantityLitersProvisionItems
    }, 0))

    let priceToEndpointFromYar = listCourses?.find(x => x.inputCourse === selectedDeliveryCourse.inputCourse && x.outputCourse?.toLowerCase()?.includes('ярославл'))?.price || 1;
    if(selectedDeliveryCourse?.outputCourse?.includes('ярославл'))
        priceToEndpointFromAstrah = selectedDeliveryCourse?.price || 1;
    let totalLitersFromYar = (summaryEquipments.reduce((acc, item) => {

        let quantityLitersProvisionItems = item?.provision?.provisionItems?.reduce((accProv, provItem) => {
            if(provItem?.warehouse?.name?.toLowerCase()?.includes('ярославл')) return accProv + provItem?.quantityProvision
            else return accProv
        }, 0)
        return acc + quantityLitersProvisionItems
    }, 0)) + (summaryDiluents?.reduce((acc, item) => {

        let quantityLitersProvisionItems = item?.provision?.provisionItems?.reduce((accProv, provItem) => {
            if(provItem?.warehouse?.name?.toLowerCase()?.includes('ярославл')) return accProv + provItem?.quantityProvision
            else return accProv
        }, 0)

        return acc + quantityLitersProvisionItems
    }, 0))

    DeliveryPrice = (priceToEndpointFromYar * totalLitersFromYar) + (priceToEndpointFromRostov * totalLitersFromRostov) + (priceToEndpointFromAstrah * totalLitersFromAstrah)
    TotalLitersProvision = totalLitersFromYar + totalLitersFromRostov + totalLitersFromAstrah

    summaryEquipments.forEach(item => {
        TotalVolume = TotalVolume + item.totalVolume;
        TotalWeight = TotalWeight + item.totalWeight;
        PriceForMaterial = PriceForMaterial + item.totalPrice;
        TotalPrice = TotalPrice + item.totalPrice;
        PriceForMaterialIncludingPackaging = PriceForMaterialIncludingPackaging + item.totalPriceIncludingPackaging;
        TotalPriceIncludingPackaging = TotalPriceIncludingPackaging + item.totalPriceIncludingPackaging;



    })
    summaryDiluents.forEach(item => {
        TotalVolume = TotalVolume + item.totalVolume;
        TotalWeight = TotalWeight + item.totalWeight;
        PriceForDiluent = PriceForDiluent + item.totalPrice;
        TotalPrice = TotalPrice + item.totalPrice;
        PriceForDiluentIncludingPackaging = PriceForDiluentIncludingPackaging + item.totalPriceIncludingPackaging;
        TotalPriceIncludingPackaging = TotalPriceIncludingPackaging + item.totalPriceIncludingPackaging;

    })
    
    
    return {
        TotalVolume,
        TotalWeight,
        PriceForDiluent,
        PriceForMaterial,
        TotalPrice,
        PriceForDiluentIncludingPackaging,
        PriceForMaterialIncludingPackaging,
        TotalPriceIncludingPackaging,
        DeliveryPrice,
        TotalLiters,
        TotalLitersProvision
    }
}
export const updateSummary = (newArraySystems, oldSummaryEquipments, oldSummaryDiluents, USD, messageNotificationSender, pricePerLiterDelivery = 0, pricePerLiterService = 0) => {
    let arraySummaryDiluents = []
    let arraySummaryEquipments = []


    newArraySystems.forEach(item => {
        item.slices.forEach(slice => {
            if(slice.material && slice.material.id){
                if(!arraySummaryEquipments.some(x => x.equipment.name === slice.material.name)){

                    let id = 0
                    let guid = generateGuid()
                    let discountManager = 0;
                    let provision = {
                        id: 0,
                        guid: generateGuid(),
                        requiredToProvide: 0,
                        provisionItems: []
                    }

                    let quantity = slice.quantity;
                    let quantityIncludingPackaging = getQuantityIncludingPackaging(quantity, slice.material?.packageSize);

                    if(oldSummaryEquipments.some(x => x.Equipment?.name === slice.material?.name)){
                        let searchOldEquipment = oldSummaryEquipments.find(x => x.equipment?.name === slice.material?.name)
                        discountManager = searchOldEquipment.discountManager;
                        provision = {...searchOldEquipment.provision, requiredToProvide: quantityIncludingPackaging}
                        id = searchOldEquipment.id
                        guid = searchOldEquipment.guid
                    }
                    else{
                        provision.requiredToProvide = quantityIncludingPackaging;
                    }

                    let totalWeight = getCountIncludingPackaging(quantityIncludingPackaging, slice.material?.packageSize)*slice.material?.weight;
                    let totalVolume = getCountIncludingPackaging(quantityIncludingPackaging, slice.material?.packageSize)*slice.material?.volume;

                    let { PriceForLiter, TotalPrice, TotalPriceIncludingPackaging} = getPricesMaterial(quantity, slice.material, discountManager, USD, pricePerLiterDelivery, pricePerLiterService);
                    
                    let priceForKg = PriceForLiter/slice.material?.specificWeight;
                    let PriceForMeterSquare = PriceForLiter/handleHidingPower(slice.material, slice.material.equipmentInformation.dryLayerThicknessMin, 0);
                    
                    arraySummaryEquipments.push({
                        id: id,
                        guid: guid,
                        slice: arraySummaryEquipments.length + 1,
                        discountManager: discountManager,
                        quantity: quantity,
                        quantityIncludingPackaging: quantityIncludingPackaging,
                        priceForLiter: PriceForLiter,
                        priceForMeterSquare: PriceForMeterSquare,
                        priceForKg: priceForKg,
                        totalWeight: totalWeight,
                        totalVolume: totalVolume,
                        totalPrice: TotalPrice,
                        totalPriceIncludingPackaging: TotalPriceIncludingPackaging,
                        equipment: slice.material,
                        provision: provision,
                        isBandpass: slice.isBandpass
                    })
                }
                else{
                    let searchEquipment = arraySummaryEquipments.find(x => x.equipment?.name === slice.material?.name)


                    let discountManager = 0;

                    let quantity = searchEquipment.quantity + slice.quantity;
                    let quantityIncludingPackaging = getQuantityIncludingPackaging(quantity, searchEquipment.equipment?.packageSize);

                    if(oldSummaryEquipments.some(x => x.equipment?.name === searchEquipment.equipment?.name)){
                        let searchOldEquipment = oldSummaryEquipments.find(x => x.equipment?.name === searchEquipment.equipment?.name)
                        discountManager = searchOldEquipment.discountManager;
                        searchEquipment.provision = {...searchOldEquipment.provision, requiredToProvide: quantityIncludingPackaging}
                        searchEquipment.id = searchOldEquipment.id
                        searchEquipment.guid = searchOldEquipment.guid
                    }
                    else{
                        searchEquipment.provision.requiredToProvide = quantityIncludingPackaging;
                    }

                    let totalWeight = getCountIncludingPackaging(quantityIncludingPackaging, searchEquipment.equipment?.packageSize)*searchEquipment.equipment?.weight || 0;
                    let totalVolume = getCountIncludingPackaging(quantityIncludingPackaging, searchEquipment.equipment?.packageSize)*searchEquipment.equipment?.volume || 0;

                    let { PriceForLiter, TotalPrice, TotalPriceIncludingPackaging} = getPricesMaterial(quantity, searchEquipment.equipment, discountManager, USD, pricePerLiterDelivery, pricePerLiterService);

                    let priceForKg = PriceForLiter/searchEquipment.equipment?.specificWeight;
                    let PriceForMeterSquare = PriceForLiter/handleHidingPower(slice);

                    searchEquipment.discountManager = discountManager;
                    searchEquipment.isBandpass = slice.IsBandpass
                    searchEquipment.quantity = quantity;
                    searchEquipment.quantityIncludingPackaging = quantityIncludingPackaging;
                    searchEquipment.priceForLiter = PriceForLiter;
                    searchEquipment.priceForMeterSquare = PriceForMeterSquare;
                    searchEquipment.priceForKg = priceForKg;
                    searchEquipment.totalWeight = totalWeight;
                    searchEquipment.totalVolume = totalVolume;
                    searchEquipment.totalPrice = TotalPrice;
                    searchEquipment.totalPriceIncludingPackaging = TotalPriceIncludingPackaging;
                }

                if(slice.material.equipmentInformation
                    && slice.material.equipmentInformation.diluent
                    && slice.material.equipmentInformation.diluent.diluentParent
                    && !arraySummaryDiluents.some(x => x?.equipment?.name === slice?.material?.equipmentInformation?.diluent?.diluentParent?.name)
                ){
                    let guid = generateGuid()
                    let id = 0;
                    let discountManager = 0;
                    let percentFromMaterial = 0;
                    let packageSize = 0;
                    let diluentWeight = 0;
                    let diluentVolume = 0;
                    let materialDiluent = slice?.material?.equipmentInformation?.diluent?.diluentParent;
                    let provision = {
                        id: 0,
                        guid: generateGuid(),
                        requiredToProvide: 0,
                        provisionItems: []
                    }
                    packageSize = materialDiluent?.packageSize || 0
                    diluentWeight = materialDiluent?.weight || 0
                    diluentVolume = materialDiluent?.volume || 0

                    if(oldSummaryDiluents.some(x => x?.equipment?.name === materialDiluent?.name)){
                        let searchOldDiluent = oldSummaryDiluents.find(x => x?.equipment?.name === materialDiluent?.name)
                        discountManager = searchOldDiluent?.discountManagerForDiluent;
                        percentFromMaterial = searchOldDiluent?.percentFromMaterial;
                        provision = {...searchOldDiluent?.provision}
                        id = searchOldDiluent?.id
                        guid = searchOldDiluent?.guid
                    }
                    else{
                        percentFromMaterial = slice.material?.equipmentInformation?.diluent?.percentFromMaterial;
                    }

                    let quantityDiluent = getQuantityDiluent(slice.quantity || 0, percentFromMaterial) || 0;
                    let QuantityDiluentIncludingPackaging = getQuantityIncludingPackaging(quantityDiluent, packageSize) || 0;

                    provision = {...provision, requiredToProvide: QuantityDiluentIncludingPackaging}

                    let totalWeight = getCountIncludingPackaging(QuantityDiluentIncludingPackaging, packageSize) * diluentWeight;
                    let totalVolume = getCountIncludingPackaging(QuantityDiluentIncludingPackaging, packageSize) * diluentVolume;

                    let { PriceForLiter, TotalPrice, TotalPriceIncludingPackaging} = getPricesMaterial(quantityDiluent, materialDiluent, discountManager, USD, pricePerLiterDelivery, pricePerLiterService);

                    let priceForKg = PriceForLiter/materialDiluent.specificWeight;
                    let PriceForMeterSquare = PriceForLiter/handleHidingPower(materialDiluent, materialDiluent.equipmentInformation.dryLayerThicknessMin);

                    arraySummaryDiluents.push({
                        id: id,
                        guid: guid,
                        slice: arraySummaryDiluents.length + 1,
                        quantityDiluent: quantityDiluent,
                        quantityDiluentIncludingPackaging: QuantityDiluentIncludingPackaging,
                        percentFromMaterial: percentFromMaterial,
                        discountManagerForDiluent: discountManager,
                        priceForLiter: PriceForLiter,
                        priceForMeterSquare: PriceForMeterSquare,
                        priceForKg: priceForKg,
                        totalWeight: totalWeight,
                        totalVolume: totalVolume,
                        totalPrice: TotalPrice,
                        totalPriceIncludingPackaging: TotalPriceIncludingPackaging,
                        equipment: materialDiluent,
                        provision: provision,
                    })
                }
                else {
                    let searchDileunt = arraySummaryDiluents.find(x => x.equipment?.name === slice?.material?.equipmentInformation?.diluent?.diluentParent?.name)

                    if(searchDileunt){
                        let discountManager = 0;
                        let percentFromMaterial = 0;
                        let packageSize = 0;
                        let diluentWeight = 0;
                        let diluentVolume = 0;
                        let materialDiluent = searchDileunt?.equipment;

                        packageSize = materialDiluent?.packageSize || 0
                        diluentWeight = materialDiluent?.weight || 0
                        diluentVolume = materialDiluent?.volume || 0

                        if(oldSummaryDiluents.some(x => x?.equipment?.name === materialDiluent?.name)){
                            let searchOldDiluent = oldSummaryDiluents.find(x => x?.equipment?.name === materialDiluent?.name)
                            discountManager = searchOldDiluent?.DiscountManagerForDiluent;
                            percentFromMaterial = searchOldDiluent?.PercentFromMaterial;
                            searchDileunt.provision = {...searchOldDiluent?.provision}
                            searchDileunt.id = searchOldDiluent?.id
                            searchDileunt.guid = searchOldDiluent?.guid
                        }
                        else{
                            percentFromMaterial = slice.material?.equipmentInformation?.diluent?.PercentFromMaterial  || 0;
                        }

                        let quantityDiluent = (searchDileunt?.QuantityDiluent || 0 + getQuantityDiluent(slice?.quantity, percentFromMaterial)) || 0;
                        let QuantityDiluentIncludingPackaging = getQuantityIncludingPackaging(quantityDiluent, packageSize) || 0;

                        searchDileunt.provision = {...searchDileunt?.provision, requiredToProvide: QuantityDiluentIncludingPackaging}

                        let totalWeight = getCountIncludingPackaging(QuantityDiluentIncludingPackaging, packageSize) * diluentWeight;
                        let totalVolume = getCountIncludingPackaging(QuantityDiluentIncludingPackaging, packageSize) * diluentVolume;

                        let { PriceForLiter, TotalPrice, TotalPriceIncludingPackaging} = getPricesMaterial(quantityDiluent, materialDiluent, discountManager, USD, pricePerLiterDelivery, pricePerLiterService);

                        let priceForKg = PriceForLiter/materialDiluent.SpecificWeight;
                        let PriceForMeterSquare = PriceForLiter/handleHidingPower(materialDiluent, materialDiluent.equipmentInformation.dryLayerThicknessMin);

                        searchDileunt.quantityDiluent = quantityDiluent;
                        searchDileunt.quantityDiluentIncludingPackaging = QuantityDiluentIncludingPackaging;
                        searchDileunt.percentFromMaterial = percentFromMaterial;
                        searchDileunt.piscountManagerForDiluent = discountManager;
                        searchDileunt.priceForLiter = PriceForLiter;
                        searchDileunt.priceForMeterSquare = PriceForMeterSquare;
                        searchDileunt.priceForKg = priceForKg;
                        searchDileunt.totalWeight = totalWeight;
                        searchDileunt.totalVolume = totalVolume;
                        searchDileunt.totalPrice = TotalPrice;
                        searchDileunt.totalPriceIncludingPackaging = TotalPriceIncludingPackaging;
                    }
                }
            }
        })
    })

    return {
        arraySummaryDiluents,
        arraySummaryEquipments
    };
}
export const changeSummaryEquipment = (newSlice, summaryEquipments, systems) => {
    let returnArrays = {
        returnArraySummary: [],
        returnArraySystems: []
    }

    let tryFindOldSlice = summaryEquipments.find(x => x.equipment.name === newSlice.equipment.name)
    if(tryFindOldSlice){
        if(tryFindOldSlice.percentFromMaterial !== newSlice.percentFromMaterial){
            let quantityDiluent = 0;
            quantityDiluent = newSlice.quantity/100*newSlice.percentFromMaterial;
            newSlice.quantityDiluent = quantityDiluent
        }
        else if(tryFindOldSlice.quantityDiluent !== newSlice.quantityDiluent){
            let percentFromMaterial = 7;
            percentFromMaterial = newSlice.quantityDiluent/(newSlice.quantity/100);
            newSlice.percentFromMaterial = percentFromMaterial
        }
    }

    returnArrays.returnArraySummary = [...summaryEquipments.filter(x => x.equipment.name !== newSlice.equipment.name), newSlice].sort((a, b) => {
        if(a.slice > b.slice) return 1
        else if (a.slice < b.slice) return -1

        return 0
    });

    returnArrays.returnArraySummary.forEach(item => {
        if(newSlice.equipment && newSlice.equipment.equipmentInformation.diluent.diluentParent){
            if(item.equipment && item.equipment.equipmentInformation.diluent.diluentParent){
                if(item.equipment.equipmentInformation.diluent.diluentParent.name === newSlice.equipment.equipmentInformation.diluent.diluentParent.name){
                    item.discountManagerForDiluent = newSlice.discountManagerForDiluent
                }
            }
        }
    })

    returnArrays.returnArraySystems = systems.map(system => {
        system.slices = system.slices.map(slice => {
            if(newSlice.equipment
                && newSlice.equipment.equipmentInformation
                && newSlice.equipment.equipmentInformation.diluent
                && newSlice.equipment.equipmentInformation.diluent.diluentParent
            ){
                if(slice.material
                    && slice.material.equipmentInformation
                    && slice.material.equipmentInformation.diluent
                    && slice.material.equipmentInformation.diluent.diluentParent
                ){
                    if(slice.material.equipmentInformation.diluent.diluentParent.name === newSlice.equipment.equipmentInformation.diluent.diluentParent.name){
                        slice.discountManagerForDiluent = newSlice.discountManagerForDiluent
                    }
                }
            }
            if(slice.material && slice.material.name && slice.material.name === newSlice.equipment.name){
                slice.discountManager = newSlice.discountManager
                return slice;
            }
            else return slice;
        })
        return system;
    });

    return returnArrays;
}
export const changeHide = (e, api, apiFooter, setIsHideList) => {
    if(e){
        api.start(() => ({
            height: '10%',
            maxHeight: '10%'
        }))
        apiFooter.start(() => ({
            height: '15%',
            maxHeight: '15%'
        }))
        setIsHideList(true)
    }
    else{
        api.start(() => ({
            height: '280%',
            maxHeight: '280%'
        }))
        apiFooter.start(() => ({
            height: '0%',
            maxHeight: '0%'
        }))
        setIsHideList(false)
    }
}
export const updateBxInfo = async () => {
    let bxInfo = {
        dealId: 0,
        userId: 0,
        ship: 'Нет данных',
        dealName : 'Нет данных',
        companyName : 'Нет данных',
        assigned : 'Нет данных',
    }
    if(window.name.toLowerCase().includes('bitrix')){
        await BX24.placement.info().then(async (res) => {
            if(res.options && res.options.ID){
                bxInfo.dealId = Number(res.options.ID)
            }
            await BX24.callMethod('crm.item.get', {entityTypeId: 2, id: bxInfo.dealId}).then(async (res) => {
                let deal = res.data()
                if(deal && deal.item){
                    bxInfo.dealName = deal.item.title ? deal.item.title : '';
                    if(deal.item.companyId){
                        await BX24.callMethod('crm.item.get', { entityTypeId: 4, id: deal.item.companyId}).then(res => {
                            let company = res.data()
                            if(company && company.item){
                                bxInfo.companyName = company.item.title ? company.item.title : 'Нет данных';
                            }
                        })
                    }

                    bxInfo.ship = deal.item.ufCrm_1685010059729 && deal.item.ufCrm_1685010059729[0] ? deal.item.ufCrm_1685010059729[0] : 'Нет данных';

                    await BX24.callMethod('user.get', {ID: deal.item.assignedById}).then(res => {
                        let user = res.data()
                        if(user && user[0]){
                            bxInfo.assigned = user[0].NAME + ' ' + user[0].LAST_NAME
                            bxInfo.userId = Number(user[0].ID || 0);
                        }
                    })
                }
            })
        })
    }
    return bxInfo;
}
export const updateBxInfoKP = async () => {
    let bxInfo = {
        dealIdB24: 0,
        userIdB24: 0,
        shipB24: 'Нет данных',
        dealNameB24: 'Нет данных',
        companyNameB24: 'Нет данных',
        assignedB24: 'Нет данных',
    }
    if(window.name.toLowerCase().includes('bitrix')){
        await BX24.placement.info().then(async (res) => {
            if(res.options && res.options.ID){
                bxInfo.dealIdB24 = Number(res.options.ID)
            }
            await BX24.callMethod('crm.item.get', {entityTypeId: 2, id: bxInfo.dealIdB24}).then(async (res) => {
                let deal = res.data()
                if(deal && deal.item){
                    bxInfo.dealNameB24 = deal.item.title ? deal.item.title : '';
                    if(deal.item.companyId){
                        await BX24.callMethod('crm.item.get', { entityTypeId: 4, id: deal.item.companyId}).then(res => {
                            let company = res.data()
                            if(company && company.item){
                                bxInfo.companyNameB24 = company.item.title ? company.item.title : 'Нет данных';
                            }
                        })
                    }

                    bxInfo.shipB24 = deal.item.ufCrm_1685010059729 && deal.item.ufCrm_1685010059729[0] ? deal.item.ufCrm_1685010059729[0] : 'Нет данных';

                    await BX24.callMethod('user.get', {ID: deal.item.assignedById}).then(res => {
                        let user = res.data()
                        if(user && user[0]){
                            bxInfo.assignedB24 = user[0].NAME + ' ' + user[0].LAST_NAME
                            bxInfo.userIdB24 = Number(user[0].ID || 0);
                        }
                    })
                }
            })
        })
    }
    return bxInfo;
}
export const filterMaterialArray = (materialArray, optionFilter, isFirstRender) => {
    let newArray = materialArray;
    if(!isFirstRender){
        newArray = materialArray.filter(item => {
            let objResult = {
                moravia: false,
                WEG: false,
                ancorr: false,
                outOfStock: false,
                willArrive: false,
                primer: false,
                intermediate: false,
                finish: false,
                primerEnamel: false,

            }

            if(optionFilter.brand.moravia){
                if(item?.name?.toLowerCase()?.includes("mora")){
                    objResult.moravia = true
                }
                else{
                    objResult.moravia = false
                }
            }
            else {
                objResult.moravia = true
            }

            if(optionFilter.brand.WEG){
                if(item?.name?.toLowerCase()?.includes("w-") || item?.name?.toLowerCase()?.includes("weg")){
                    if(!objResult.moravia){
                        objResult.moravia = true;
                    }
                    objResult.WEG = true
                }
                else{
                    objResult.WEG = false
                }
            }
            else {
                objResult.WEG = true
            }

            if(optionFilter.brand.WEG && optionFilter.brand.moravia){
                if(objResult.moravia || objResult.WEG){
                    objResult.moravia = true
                    objResult.WEG = true;
                }
            }

            if(optionFilter.brand.ancorr){
                objResult.ancorr = true
            }
            else {
                objResult.ancorr = true
            }



            if(optionFilter.available.willArrive){
                if(item?.receivedDate){
                    let match = item?.receivedDate?.match(dateParser);

                    if(!match){
                        match = item?.receivedDate?.match(dateParser2);
                    }

                    if(match){
                        const date = new Date(
                            match[3],  // year
                            match[2]-1,  // monthIndex
                            match[1],  // day
                            match[4],  // hours
                            match[5],  // minutes
                            match[6]  //seconds
                        );
                        const currentDate = new Date()
                        const difference = Math.round((date - currentDate)/1000/60/60/24);

                        if(difference > 0 && difference <= 30){
                            objResult.willArrive = true
                        }
                        else {
                            objResult.willArrive = false
                        }
                    }
                    else {
                        objResult.willArrive = false
                    }
                }
                else {
                    objResult.willArrive = false
                }

                if(item?.availableLiters > 0 || item?.receivedLiters > 0) {
                    objResult.willArrive = true
                }
            }
            else {
                objResult.willArrive = true
            }

            if(optionFilter.available.outOfStock){
                if(item?.receivedLiter >= 0 || item?.availableLiters >= 0){
                    if(!objResult.willArrive){
                        objResult.willArrive = true
                    }
                    objResult.outOfStock = true
                }
                else {
                    objResult.outOfStock = false
                }
            }
            else {
                if(item?.availableLiters > 0) {

                    objResult.outOfStock = true;
                }
                else if(optionFilter.available.willArrive){
                    if(item?.availableLiters > 0 || item?.receivedLiters > 0){
                        objResult.outOfStock = true
                    }
                    else {
                        objResult.outOfStock = false
                    }
                }
                else {
                    objResult.outOfStock = false
                }
            }

            if(optionFilter.coatingType.primer){
                if(item?.equipmentInformation?.coatingType && item?.equipmentInformation?.coatingType?.title === 'Грунтовочный'){
                    objResult.primer = true
                }
            }
            if(optionFilter.coatingType.intermediate){
                if(item?.equipmentInformation?.coatingType && item?.equipmentInformation?.coatingType?.title === 'Промежуточный'){
                    objResult.Intermediate = true
                }
            }
            if(optionFilter.coatingType.finish){
                if(item?.equipmentInformation?.coatingType && item?.equipmentInformation?.coatingType?.title === 'Финишный'){
                    objResult.finish = true
                }
            }
            if(optionFilter?.coatingType?.primerEnamel){
                if(item?.equipmentInformation?.coatingType && item?.equipmentInformation?.coatingType?.title === 'Грунт эмаль'){
                    objResult.primerEnamel = true
                }
            }

            if(!optionFilter.coatingType.primer
                && !optionFilter.coatingType.intermediate
                && !optionFilter.coatingType.finish
                && !optionFilter.coatingType.primerEnamel){

                objResult.primer = true
            }

            if(
                (objResult.moravia
                    && objResult.WEG
                    && objResult.ancorr)
                && (objResult.willArrive
                    && objResult.outOfStock)
                && (objResult.primer
                    || objResult.intermediate
                    || objResult.finish
                    || objResult.primerEnamel)
            ) return true;
            else return false;
        })
    }
    else {
        newArray = materialArray?.filter(item => {
            if(item?.availableLiters > 0) return true;
            else return false;
        }) || []
    }
    return newArray;
}
export const senderTkp = (tkp, isEdit, messageSender, callback) => {
    let formData = handleObjectForm(tkp);
    if(isEdit){
        axios.post(`${BaseUrl}/ancorr/api/tkp/edit`, formData).then(() => {
            callback(true);
        }).catch(res => {
            messageSender(res.message || 'Нет данных ошибки')
            callback(false);
        })
    }
    else{
        axios.post(`${BaseUrl}/ancorr/api/tkp/add`, formData).then(() => {
            callback(true);
        }).catch(res => {
            messageSender(res.message || 'Нет данных ошибки')
            callback(false);
        })
    }
}