import {forwardRef} from 'react';
import {NumericFormat} from "react-number-format";
import {FormControl, Input, InputLabel} from "@mui/material";
import {generateGuid} from "../../../../features/guidgenerator/GuidGenerator";
import useDebouncingFunction from "../../../../hooks/debouncingfunction/useDebouncingFunction";
import useControlledValue from "../../../../hooks/controlled/useControlledValue";

const NumericInput = ({
                          defaultValue = 0,
                          onChange,
                          prefix = '',
                          suffics = '',
                          label = '',
                          useDebounce = false,
                          sx = { width: 300 },
                          controlled = false}) => {

    const [value, setValue] = useControlledValue({defaultValue, controlled, initialStateValue: 0})

    const updateValue = (newValue) => {
        onChange?.(newValue)
    }

    const debounceUpdateValue = useDebouncingFunction(updateValue, 1200)
    const handleChange = (newValue) => {
        let value = newValue ? newValue.target.value : 0;

        if(useDebounce)
            debounceUpdateValue(value)
        else
            updateValue(value)

        setValue(value);
    };

    const guid = generateGuid()
    return (
        <FormControl variant="standard">
            <InputLabel htmlFor={guid}>{label}</InputLabel>
            <Input
                size={"small"}
                value={value}
                sx={sx}
                id={guid}
                onChange={handleChange}
                inputComponent={NumericFormatCustom}
                inputProps={{
                    prefix,
                    suffics
                }}
                variant="standard"
            />
        </FormControl>

    );
};

const NumericFormatCustom = forwardRef(
    function NumericFormatCustom({onChange, prefix = '', suffics = '', ...other}, ref) {
        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {

                    onChange({
                        target: {
                            value: values.floatValue,
                        },
                    });
                }}
                thousandSeparator
                valueIsNumericString
                suffix={suffics}
                prefix={prefix}
            />
        );
    },
);

export default NumericInput;