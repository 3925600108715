import './TitleKpPreview.css'

const TitleKpPreview = ({kp}) => {
    return (
        <div className="kp-preview__title title-kp-preview">
            <div className="title-kp-preview__data">
                <div className="title-kp-preview__data-main">
                    <div>От: {kp?.companyNameB24?.toUpperCase() || "Не заполнено".toUpperCase()}</div>
                    <div>Для: {kp?.client?.toUpperCase() || "Не заполнено".toUpperCase()}</div>
                </div>
                <div className="title-kp-preview__data-second">
                    <div>ПОДГОТОВИЛ: {kp?.createdBy?.fio || 'Не заполнено'.toUpperCase()}</div>
                    <div>ДАТА
                        СОЗДАНИЯ: {new Date(kp?.createdTimeDb).toLocaleDateString().toUpperCase() || 'Не заполнено'.toUpperCase()}</div>
                </div>
            </div>
            <div className="title-kp-preview__name">КОММЕРЧЕСКОЕ ПРЕДЛОЖЕНИЕ</div>
        </div>
    );
};

export default TitleKpPreview;