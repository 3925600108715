import './MultipleDropdownDefault.css'
import {Children, forwardRef, useEffect, useState} from "react";
import {Form} from "react-bootstrap";
import {Autocomplete, TextField} from "@mui/material";
import useDebouncingFunction from "../../../../hooks/debouncingfunction/useDebouncingFunction";
const MultipleDropdownDefault = ({
                                     currentValues = [],
                                     options = [],
                                     onChange,
                                     placeholder = 'Не выбрано',
                                     disabled = false,
                                     useDebounce = false,
                                     sx = { width: 500 },
                                     controlled = false}) => {
    const [inputValue, setInputValue] = useState('')
    const [values, setValues] = useState(currentValues)

    const [optionsArray, setOptionsArray] = useState([])
    useEffect(() => {
        setOptionsArray(prev => [
            {
                id: 999999,
                value: 'Выбрать все',
                title: 'Выбрать все',
                accessor: 'setAll'
            },
            ...options
        ])
    }, [options]);



    useEffect(() => {
        if(controlled)
            setValues(currentValues)
    }, [currentValues]);

    const updateValue = (newValue) => {
        onChange?.(newValue)
    }

    const debounceUpdateValue = useDebouncingFunction(updateValue, 1200)

    return (
        <Autocomplete
            multiple
            disableCloseOnSelect
            value={values}
            onChange={(event, newValues) => {
                if(newValues?.some(x => x?.value === 'Выбрать все') ){
                    setValues(optionsArray)

                    if(useDebounce)
                        debounceUpdateValue(options)
                    else
                        updateValue(options)
                }
                else{
                    setValues(newValues)
                    if(useDebounce)
                        debounceUpdateValue(newValues)
                    else
                        updateValue(newValues)
                }
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            isOptionEqualToValue={(option, value) => option?.value === value?.value}
            inputValue={inputValue}
            options={optionsArray}
            getOptionLabel={option => option?.value || ''}
            sx={sx}
            size="small"
            disabled={disabled}
            renderInput={(params) => <TextField disabled {...params} label={placeholder} />}

        />
        /*<Dropdown  id='multiple-dropdown'>
            <Dropdown.Toggle
                variant={"primary"}
                id={"multiple-dropdown__toggle"}
            >
                    <span className={'multiple-dropdown__toggle-text'}>
                        {placeholder}
                    </span>
            </Dropdown.Toggle>
            <Dropdown.Menu as={CustomMenuMultiple}>
                {optionsArray?.map((option, idx) => (
                    <RemainsCheckbox
                        key={option.guid}
                        checked={currentValues?.some(x => x?.id === option.id)}
                        onClick={(e) => {
                            let newArr = getNewArrayOptionsFromMultipleDropdown(option, currentValues);
                            onChange?.(newArr)
                        }}
                    >
                        {option.value}
                    </RemainsCheckbox>
                ))}
            </Dropdown.Menu>
        </Dropdown>*/
    );
};

export const CustomMenuMultiple = forwardRef(({children, style, className, 'aria-labelledby': labeledBy}, ref) => {
    const [value, setValue] = useState('')
    return (
        <div
            ref={ref}
            style={style}
            className={className}
            aria-labelledby={labeledBy}
        >
            <Form.Control
                autoFocus
                id={'multiple-dropdown__item-input'}
                className={'mx-3 my-2 w-auto'}
                placeholder={'Фильтр'}
                onChange={e => setValue(e.target.value)}
                value={value}
            />
            {Children?.toArray?.(children)?.filter?.(child => !value || child?.props?.children?.toLowerCase?.()?.includes?.(value))}
        </div>
    )
})
export default MultipleDropdownDefault;