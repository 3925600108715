import RemainsCheckbox from "../../remainscheckbox/RemainsCheckbox";
import newArrayIds from "../../../helpers/newArrayIds";
import {useEffect, useRef, useState} from "react";
import {useInteractionBackend} from "../../../../../hooks/useInteractionBackend";
import {getBrands} from "../../../API/remainsAPI";
import ClearBtn from "../../../UI/clearbtn/ClearBtn";
import {useAuth} from "../../../../../features/authprovider/AuthProvider";


const BrandFilter = ({filters, setFilters, updatePosition}) => {
    const [initialFilters, setInitialFilters] = useState(filters.brandsIds);
    const [brands, setBrands] = useState([]);
    const {userInfo} = useAuth();


    const [fetchBrands] = useInteractionBackend({
        type: 'axios',
        cb: getBrands,
        thenCb: ({data}) => setBrands(data)
    });

    useEffect(() => {
        const token = userInfo?.accessToken || '';
        fetchBrands({token});
    }, []);

    return (
        <div>
            {
                brands?.map(brand => {
                    return (
                        <RemainsCheckbox
                            key={brand.guid}
                            checked={filters.brandsIds.includes(brand.id)}
                            onClick={(e) => {
                                let newArr = newArrayIds(brand.id, filters.brandsIds);
                                setFilters({...filters, brandsIds: newArr});
                                updatePosition(e);
                            }}
                        >
                            {brand.name}
                        </RemainsCheckbox>
                    )
                })
            }
            <ClearBtn
                isActive={filters.brandsIds.length > 0}
                onClick={(e) => {
                    setFilters({...filters, brandsIds: initialFilters});
                    updatePosition(e);
                }}
            />
        </div>
    );
};

export default BrandFilter;