import humanizer from "humanize-duration";

export const isNumeric = (string) => /^[+-]?\d+(\.\d+)?$/.test(string)
export const replaceStringToNumber = (string) => Number(string?.match(/\d+/g)?.join(''))
export const converterEntityToOptionOrReverse = ({endpoint, arrayEntities, accessorName, valueCallback, titleCallback, idCallback, searchPropertyForArray}) => {
    if(!searchPropertyForArray)
        return null;
    let findEntity = arrayEntities?.find(x => x?.id === searchPropertyForArray)
    if(findEntity){
        if(endpoint === 'option'){
            return {
                accessor: accessorName,
                title: titleCallback?.(findEntity),
                value: valueCallback?.(findEntity),
                id: idCallback?.(findEntity),
            }
        }
        else if(endpoint === 'entity') return findEntity;
        else return null
    }
}

export const convertFromStatusInfoToFieldItem = (lanes, statusValue) => {
    let lane = lanes?.find(x => x?.statusValue === statusValue)
    return {
        id: lane?.id,
        title: lane?.statusName,
        accessor: lane?.statusValue,
        value: lane?.statusValue,
    }
}

export const formatDate = (dateToFormat) => {
    const date = new Date(dateToFormat);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;
    return `${formattedDay}.${formattedMonth}.${year}`;
}
export const oldDate = (date) => {
    const currentDate = new Date();
    return humanizer(currentDate - new Date(date), {
        language: ['ru'],
        largest: 1,
        maxDecimalPoints: 1
    }) + ' назад';
}
export const arrayRange = (start, stop, step) =>
    Array.from(
        { length: (stop - start) / step + 1 },
        (value, index) => start + index * step
    );
export const getNewArrayOptionsFromMultipleDropdown = (newElem, selectedElements) => {

    const newArr = [...selectedElements];
    if (newArr.some(x => x?.id === newElem?.id)) {
        let index = newArr.findIndex(x => x?.id === newElem?.id);
        newArr.splice(index, 1);
    } else {
        newArr.push(newElem);
    }
    console.log(newArr)
    return newArr
}
export const getOptionsFromArrayEntities = ({entities, accessorName, valueCallback, titleCallback, idCallback}) => {
    let arr = []
    entities?.forEach(item => {
        arr?.push({
            accessor: accessorName,
            title: titleCallback?.(item),
            value: valueCallback?.(item),
            id: idCallback ? idCallback?.(item) : arr.length + 1,
        })
    })
    return arr;
}

export const horizontalWheelScrollCallback = event => {
    if(event.deltaY > 0) {
        event.target.scrollLeft += 100
    }
    else {
        event.target.scrollLeft -=100
    }
}
export const horizontalScrollLeft = target => {
    target.scrollLeft += 100
}
export const horizontalScrollRight = target => {
    target.scrollLeft -= 100
}
