import './ProjectList.css'
import {useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router";
import useLoader from "../../../../hooks/useLoader";
import {AxiosHelper} from "../../../../hooks/useAxiosHelper";
import {usePaginationController} from "../../../../components/pagination/components";
import EditIcon from "../../../../components/UI/icons/EditIcon";
import BackCrossIcon from "../../../../components/UI/icons/BackCrossIcon";
import useCheckAccess from "../../../../hooks/useCheckAccess";
import AccessDenied from "../../../../components/access/AccessDenied";
import GuidsWrapper from "../../../../components/guids/wrapper/GuidsWrapper";
import GuidsHeader from "../../../../components/guids/header/GuidsHeader";
import TitleDetailCard from "../../../../components/UI/titledetailcard/TitleDetailCard";
import GuidsSearchInput from "../../../../components/guids/search/GuidsSearchInput";
import GuidsBody from "../../../../components/guids/body/GuidsBody";
import TableBootstrap from "../../../../components/tableb/TableBootstrap";
import GuidsPagination from "../../../../components/guids/pagination/GuidsPagination";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import useAxiosErrorHandler from "../../../../hooks/axioserrorhandler/useAxiosErrorHandler";
import {Button, Container} from "react-bootstrap";

const ProjectList = ({assignedId, clientId}) => {
    const [searchValue, setSearchValue] = useState('')
    const navigate = useNavigate()
    const {hideAll, show, hide} = useLoader()
    const handleErrorResponse = useAxiosErrorHandler()

    const [filters, setFilters] = useState({
        assignedIds: assignedId ? [assignedId] : [],
        createdIds: [],
        modifyIds: [],
        clientIds: clientId ? [clientId] : [],
    })
    const [page, setPage] = useState(1)

    const client = useQueryClient()
    const {data: items} = useQuery({
        queryKey: ['project-list', page, filters],
        queryFn: ({queryKey: [name, page, filters]}) => AxiosHelper.postMethod({
            urlWithoutBaseUrl: 'ancorr/api/project/list',
            queryParams: {
                offset: 15,
                page: page,
                isKanban: false
            },
            data: filters,
        }),
        onError: (error) => handleErrorResponse(error),
        onMutate: () => show(),
        onSettled: () => hide()
    })

    const {mutate: addItem} = useMutation({
        mutationKey: ['project-list'],
        mutationFn: () => AxiosHelper.postMethod({
            urlWithoutBaseUrl: 'ancorr/api/project/add',
            data: {
                assignedId,
                clientId,
            },
        }),
        onSuccess: () => client.invalidateQueries({queryKey: ['project-list']}),
        onError: (error) => handleErrorResponse(error),
        onMutate: () => show(),
        onSettled: () => hide()
    })
    const {mutate: deleteItem} = useMutation({
        mutationKey: ['project-list'],
        mutationFn: (guid) => AxiosHelper.deleteMethod({
            urlWithoutBaseUrl: 'ancorr/api/project/delete',
            queryParams: {
                guid
            },
        }),
        onSuccess: () => client.invalidateQueries({queryKey: ['project-list']}),
        onError: (error) => handleErrorResponse(error),
        onMutate: () => show(),
        onSettled: () => hide()
    })

    useEffect(() => {
        document.title = "Мой клиент: Проекты"
    }, [])

    const memoData = useMemo(() => items?.data?.filter(x => x?.name?.toLowerCase()?.includes(searchValue?.toLowerCase())), [items, searchValue])


    const {paginationModule} = usePaginationController({
        pagesCount: items?.pages || 1,
        changePageCallback: (data) => {
            setPage(data === 0 ? 1 : data)
        }
    })

    useEffect(() => {
        if(items?.currentPage > items?.pages){
            if(items?.pages === 0)
                setPage(1)
            else
                setPage(items?.pages)
        }
    }, [items]);

    const popoverActions = [
        {
            icon: <EditIcon classNames={'cl-light-purple'}/>,
            title: 'Редактировать',
            onClick: item => navigate(`/projects/detail/${item?.id}`)
        },
        {
            icon: <BackCrossIcon classNames={'cl-red-tomato'}/>,
            title: 'Удалить',
            classNamesText: 'cl-red-tomato',
            onClick: item => deleteItem(item?.guid)
        },
    ]

    const access = useCheckAccess("sales/deal")

    if(!access){
        hideAll()
        return <AccessDenied />
    }

    const schema = [
        {
            title: 'Дата создания',
            callbackItem: item => new Date(item?.createdTimeDb)?.toLocaleDateString('ru-RU') || "Нет данных",
        },
        {
            title: 'Название',
            callbackItem: item => item?.name || "Не заполнено",
        },
    ]

    return (
        <GuidsWrapper>
            <GuidsHeader>
                <TitleDetailCard text={'СПИСОК ПРОЕКТОВ'}/>
                <Container fluid className={'d-flex justify-content-end gap-2'}>
                    <GuidsSearchInput onChange={newValue => setSearchValue(newValue)}/>
                    <Button onClick={() => addItem()}>Добавить</Button>
                </Container>
            </GuidsHeader>
            <GuidsBody>
                <TableBootstrap
                    popoverCaption={'Действия'}
                    items={memoData}
                    schema={schema}
                    popoverActions={popoverActions}
                />
            </GuidsBody>
            <GuidsPagination>
                {
                    paginationModule
                }
            </GuidsPagination>
        </GuidsWrapper>
    );
};

export default ProjectList;