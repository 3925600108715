import './FolderMaterials.css'
import {ReactComponent as FolderIcon} from "../../../../../components/UI/icons/folder.svg";
import {useBreadcrumbsStore} from "../../../../../components/breadcrumbs";
import {useNavigate} from "react-router";

const FolderMaterials = ({subgroup}) => {
    const addBreadcrumb = useBreadcrumbsStore.getState().addBreadcrumb;
    const navigate = useNavigate();
    return (
        <div
            className='folder-materials'
            onClick={e => {
                addBreadcrumb(
                    subgroup.name, () => navigate(`/profile/materials/folder/${subgroup.id}`)
                );
                navigate(`/profile/materials/folder/${subgroup.id}`);
            }}
        >
            <FolderIcon className='folder-materials__icon'/>
            <span className="folder-materials__name">
                {subgroup.name}
            </span>
        </div>
    );
};

export default FolderMaterials;