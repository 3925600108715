
const ReportMaterial = ({type, material}) => {
    const unitMeasurement = type === 'equipment' ? material?.equipmentInformation?.unitMeasurement?.unitName : material?.componentInformation?.unitMeasurement?.unitName
    return (
        <div className={'report-material__wrapper'}>
            <div className={'report-material__info'}>
                <p className={'m-0 p-0'}>Наименование: <b>{material?.name}</b></p>
                <p className={'m-0 p-0'}>Доступно: <b>{material?.available}</b> {unitMeasurement}</p>
                <p className={'m-0 p-0'}>Доступно с поставками: <b>{material?.availableWithSupply}</b> {unitMeasurement}</p>
                <p className={'m-0 p-0'}>Поступит: <b>{material?.supply}</b> {unitMeasurement}</p>
                <p className={'m-0 p-0'}>В наличии: <b>{material?.inStock}</b> {unitMeasurement}</p>
                <p className={'m-0 p-0'}>В резерве: <b>{material?.reserved}</b> {unitMeasurement}</p>
                {type === 'equipment' && <p className={'m-0 p-0'}>Можно собрать: <b>{material?.canProduce}</b> {unitMeasurement}</p>}
                {type === 'equipment' && <p className={'m-0 p-0'}>Можно собрать с будущими поставками: <b>{material?.canProduceWithSupply}</b> {unitMeasurement}</p>}
            </div>
            <div className="report-material__remains mt-3">
                <h4 className="m-0 p-0 mb-2">Остатки по разрешенным складам</h4>
                <div className="report-material__warehouses">
                    {material?.remains?.map((remains, idx) => remains?.warehouse?.isVisible && (
                        <div key={idx} className={'report-material__warehouses_item'}>
                            {remains?.warehouse?.fullName} - <span title={remains?.nearestSupplyDate || ''} style={{marginLeft: 5, fontSize: 12}}>🏚️</span>: <b>{remains?.available}</b> / <span
                            title={remains?.nearestSupplyDate || ''} style={{marginLeft: 5, fontSize: 12}}>⌛</span>: {remains?.supply} / {type === 'equipment' && <span title={'Можно произвести'} style={{marginLeft: 5, fontSize: 12}}>⚙️: {remains?.canProduce}</span>} {unitMeasurement}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ReportMaterial;