import React from 'react';
import {createRoot} from 'react-dom/client';
import {Routes} from './router/router';
import LoaderProvider from "./features/loaderprovider/LoaderProvider";
import AuthProvider from "./features/authprovider/AuthProvider";
import './index.css';
import './assets/vars.css'
import MessageHubProvider from "./features/messagehub/MessageHubProvider";
import SubmitModal from "./components/UI/modals/submitmodal/SubmitModal";
import {
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query'
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import {SnackbarProvider} from "notistack";

const queryClient = new QueryClient();

const root = createRoot(document.getElementById('page'));
root.render(
    <QueryClientProvider client={queryClient}>
        <SnackbarProvider maxSnack={7}>
            <MessageHubProvider>
                <LoaderProvider>
                    <AuthProvider>
                        <Routes />
                        <SubmitModal/>
                    </AuthProvider>
                </LoaderProvider>
            </MessageHubProvider>
        </SnackbarProvider>
    </QueryClientProvider>
);

