import {useState} from 'react';
import './ContactsList.css'
import GuidsHeader from "../../../../../../components/guids/header/GuidsHeader";
import TitleDetailCard from "../../../../../../components/UI/titledetailcard/TitleDetailCard";
import GuidsSearchInput from "../../../../../../components/guids/search/GuidsSearchInput";
import GuidsBody from "../../../../../../components/guids/body/GuidsBody";
import TableBootstrap from "../../../../../../components/tableb/TableBootstrap";
import {Button, Container, Form, Modal} from "react-bootstrap";
import GuidsWrapper from "../../../../../../components/guids/wrapper/GuidsWrapper";
import {AxiosHelper} from "../../../../../../hooks/useAxiosHelper";
import EditIcon from "../../../../../../components/UI/icons/EditIcon";
import BackCrossIcon from "../../../../../../components/UI/icons/BackCrossIcon";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {useAuth} from "../../../../../../features/authprovider/AuthProvider";
import {useParams} from "react-router";

const ContactsList = () => {
    const params = useParams()
    const id = Number(params['id'] || 0);
    const [searchValue, setSearchValue] = useState('')
    const [showModalCheckPhone, setShowModalCheckPhone] = useState(false)
    const [phoneToCheck, setPhoneToCheck] = useState(0)
    const [showModalEditContact, setShowModalEditContact] = useState(false)
    const [contactToEdit, setContactToEdit] = useState(null)
    const {userInfo} = useAuth()

    const client = useQueryClient()

    const currentClient = client.getQueryData(['current-client', id])

    const {mutate: addContact} = useMutation({
        mutationKey: ['current-client', id],
        mutationFn: ({phoneNumberCheck, id, createdUserId}) => AxiosHelper.postMethod({
            urlWithoutBaseUrl: 'ancorr/api/client/add/contact',
            queryParams: {
                phone: phoneNumberCheck,
                createdUserId: createdUserId,
                id: id
            },
        }),
        onSuccess: () => client.invalidateQueries({queryKey: ['current-client', id]}) || setPhoneToCheck(0),
    })
    const {mutate: deleteContactFromClient} = useMutation({
        mutationKey: ['current-client', id],
        mutationFn: ({guid, id}) => AxiosHelper.deleteMethod({
            urlWithoutBaseUrl: 'ancorr/api/client/delete/contact',
            queryParams: {
                id: id,
                guid: guid,
            },
        }),
        onSuccess: () => client.invalidateQueries({queryKey: ['current-client', id]})
    })

    const {mutate: updateContact} = useMutation({
        mutationKey: ['current-client', id],
        mutationFn: (newItem) => AxiosHelper.postMethod({
            urlWithoutBaseUrl: 'ancorr/api/client/update/contact',
            data: newItem,
        }),
        onSuccess: () => client.invalidateQueries({queryKey: ['current-client', id]})
    })

    const popoverActions = [
        {
            icon: <EditIcon classNames={'cl-light-purple'}/>,
            title: 'Редактировать',
            onClick: item =>  setContactToEdit(item) || setShowModalEditContact(true)
        },
        {
            icon: <BackCrossIcon classNames={'cl-red-tomato'}/>,
            title: 'Удалить',
            classNamesText: 'cl-red-tomato',
            onClick: item => deleteContactFromClient({guid: item?.guid, id})
        },
    ]
    return (
        <GuidsWrapper>
            <GuidsHeader>
                <TitleDetailCard text={'СПИСОК КОНТАКТОВ'}/>
                <Container fluid className={'d-flex justify-content-end gap-2'}>
                    <GuidsSearchInput onChange={newValue => setSearchValue(newValue)}/>
                    <Button onClick={() => setShowModalCheckPhone(true)}>Добавить контакт</Button>
                </Container>
            </GuidsHeader>
            <GuidsBody>
                <TableBootstrap
                    schema={schema}
                    items={currentClient?.additionalContactsInfo || []}
                    popoverActions={popoverActions}
                    popoverCaption={"Действия"}
                />
            </GuidsBody>
            <Modal show={showModalCheckPhone} onHide={() => setShowModalCheckPhone(false) || setPhoneToCheck(0)}>
                <Modal.Header><Modal.Title>Привязка компании</Modal.Title></Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Введите номер телефона:</Form.Label>
                            <Form.Control
                                type={'number'}
                                value={phoneToCheck}
                                onChange={e => setPhoneToCheck(Number(e.target.value || 0))}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"danger"} onClick={() => setShowModalCheckPhone(false) || setPhoneToCheck(0)}>Закрыть</Button>
                    <Button variant={"primary"} onClick={() => setShowModalCheckPhone(false) || addContact({phoneNumberCheck: phoneToCheck, id, createdUserId: userInfo?.id})}>Привязать</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showModalEditContact} onHide={() => setShowModalEditContact(false) || setContactToEdit(null)}>
                <Modal.Header><Modal.Title>Редактирование компании</Modal.Title></Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Телефон:</Form.Label>
                            <Form.Control
                                className={'mb-2'}
                                type={'number'}
                                value={contactToEdit?.phone || 0}
                                onChange={e => setContactToEdit({...contactToEdit, phone: Number(e.target.value || 0)})}
                            />
                            <Form.Label>Общая информация:</Form.Label>
                            <Form.Control
                                className={'mb-2'}
                                type={'text'}
                                value={contactToEdit?.info || ''}
                                onChange={e => setContactToEdit({...contactToEdit, info: e.target.value})}
                            />
                            <Form.Label>Email:</Form.Label>
                            <Form.Control
                                className={'mb-2'}
                                type={'text'}
                                value={contactToEdit?.email || ''}
                                onChange={e => setContactToEdit({...contactToEdit, email:e.target.value})}
                            />
                            <Form.Label>Имя:</Form.Label>
                            <Form.Control
                                className={'mb-2'}
                                type={'text'}
                                value={contactToEdit?.firstName || ''}
                                onChange={e => setContactToEdit({...contactToEdit, firstName: e.target.value})}
                            />
                            <Form.Label>Фамилия:</Form.Label>
                            <Form.Control
                                className={'mb-2'}
                                type={'text'}
                                value={contactToEdit?.lastName || ''}
                                onChange={e => setContactToEdit({...contactToEdit, lastName: e.target.value})}
                            />
                            <Form.Label>Отчество:</Form.Label>
                            <Form.Control
                                className={'mb-2'}
                                type={'text'}
                                value={contactToEdit?.secondName || ''}
                                onChange={e => setContactToEdit({...contactToEdit, secondName:e.target.value})}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"danger"} onClick={() => setShowModalEditContact(false) || setContactToEdit(null)}>Закрыть</Button>
                    <Button variant={"primary"} onClick={() => {
                        updateContact(contactToEdit)
                        setShowModalEditContact(false)
                        setContactToEdit(null)
                    }}>Сохранить</Button>
                </Modal.Footer>
            </Modal>
        </GuidsWrapper>
    );
};

const schema = [
    {
        title: 'ID',
        callbackItem: item => item?.id,
    },
    {
        title: 'Телефон',
        callbackItem: item => item?.phone,
    },
    {
        title: 'Фамилия',
        callbackItem: item => item?.lastName,
    },
    {
        title: 'Имя',
        callbackItem: item => item?.firstName,
    },
    {
        title: 'Отчество',
        callbackItem: item => item?.secondName,
    },
]

export default ContactsList;