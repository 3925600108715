import {useEffect} from "react";


const useDocumentTitle = ({title, baseAlt}) => {
    //Базовый заголовок
    const baseTitle = baseAlt || 'Мой клиент';
    useEffect(() => {
        //Устанавливает заголовок на конкатенацию базового и заголовка переданного извне
        document.title = title ? `${baseTitle}: ${title}` : baseTitle;
        //При размонтировании родительского компонента устанавливается базовый заголовок
        return () => {
            document.title = baseTitle;
        };
    }, []);
};

export default useDocumentTitle;