import './KPDetailTS.css'

const KpDetailTs = () => {
    return (
        <div>

        </div>
    );
};

export default KpDetailTs;