import {forwardRef, useImperativeHandle, useState} from "react";
import useLoader from "../../../../../hooks/useLoader";
import {useMutation} from "@tanstack/react-query";
import {AxiosHelper} from "../../../../../hooks/useAxiosHelper";
import {Button, Form, Modal, Stack} from "react-bootstrap";
import TextInput from "../../../../../components/UI/inputs/text/TextInput";
import PhoneInput from "../../../../../components/UI/inputs/phone/PhoneInput";
import NumericInput from "../../../../../components/UI/inputs/numeric/NumericInput";
import useControlledValue from "../../../../../hooks/controlled/useControlledValue";


const EditCompanyModule = forwardRef(({onChange, defaultValue = null, controlled = false}, ref) => {

    const [visibleModal, setVisibleModal] = useState(false)
    const {show, hide} = useLoader()
    const [value, setValue] = useControlledValue({defaultValue, controlled})


    const {mutate: updateContact} = useMutation({
        mutationKey: ['company', 'modal', 'edit'],
        mutationFn: (contactDTO) => AxiosHelper.postMethod({
            urlWithoutBaseUrl: `ancorr/api/company/update`,
            data: contactDTO
        }),
        onMutate: () => show(),
        onSettled: () => hide(),
        onSuccess: (data, contactDTO) => {
            onChange?.(contactDTO)
        }
    })


    useImperativeHandle(ref, () => ({
        open: () => setVisibleModal(true),
        close: () => setVisibleModal(false),
    }), [])
    return (
        <Modal onHide={() => setVisibleModal(false)} show={visibleModal} size={"lg"}>
            <Modal.Header><Modal.Title>Редактирование компании</Modal.Title></Modal.Header>
            <Modal.Body>
                <Form className={'overflow-auto'} style={{maxHeight: '70vh'}}>
                    <Stack className={'mb-3'}>
                        <TextInput
                            sx={{width: 500}}
                            label={'Краткое наименование'}
                            controlled
                            defaultValue={value?.shortCompanyName}
                            onChange={newValue => setValue({...value, shortCompanyName: newValue})}
                        />
                    </Stack>
                    <Stack className={'mb-3'}>
                        <TextInput
                            sx={{width: 500}}
                            label={'Полное наименование'}
                            controlled
                            defaultValue={value?.fullCompanyName}
                            onChange={newValue => setValue({...value, fullCompanyName: newValue})}
                        />
                    </Stack>
                    <Stack className={'mb-3'}>
                        <NumericInput
                            sx={{width: 500}}
                            label={'ИНН'}
                            controlled
                            defaultValue={value?.inn}
                            onChange={newValue => setValue({...value, inn: newValue})}
                        />
                    </Stack>
                    <Stack className={'mb-3'}>
                        <NumericInput
                            sx={{width: 500}}
                            label={'ИНН'}
                            controlled
                            defaultValue={value?.kpp}
                            onChange={newValue => setValue({...value, kpp: newValue})}
                        />
                    </Stack>
                    <Stack className={'mb-3'}>
                        <NumericInput
                            sx={{width: 500}}
                            label={'ИНН'}
                            controlled
                            defaultValue={value?.ogrn}
                            onChange={newValue => setValue({...value, ogrn: newValue})}
                        />
                    </Stack>
                    <Stack className={'mb-3'}>
                        <NumericInput
                            sx={{width: 500}}
                            label={'ИНН'}
                            controlled
                            defaultValue={value?.okpo}
                            onChange={newValue => setValue({...value, okpo: newValue})}
                        />
                    </Stack>
                    <Stack className={'mb-3'}>
                        <TextInput
                            sx={{width: 500}}
                            label={'ОКВЕД'}
                            controlled
                            defaultValue={value?.okved}
                            onChange={newValue => setValue({...value, okved: newValue})}
                        />
                    </Stack>
                    <Stack className={'mb-3'}>
                        <NumericInput
                            sx={{width: 500}}
                            label={'Юридический индекс адреса'}
                            controlled
                            defaultValue={value?.legalAddressIndex}
                            onChange={newValue => setValue({...value, legalAddressIndex: newValue})}
                        />
                    </Stack>
                    <Stack className={'mb-3'}>
                        <TextInput
                            sx={{width: 500}}
                            label={'Юридический адрес'}
                            controlled
                            defaultValue={value?.legalAddress}
                            onChange={newValue => setValue({...value, legalAddress: newValue})}
                        />
                    </Stack>
                    <Stack className={'mb-3'}>
                        <NumericInput
                            sx={{width: 500}}
                            label={'Фактический индекс адреса'}
                            controlled
                            defaultValue={value?.actualAddressIndex}
                            onChange={newValue => setValue({...value, actualAddressIndex: newValue})}
                        />
                    </Stack>
                    <Stack className={'mb-3'}>
                        <TextInput
                            sx={{width: 500}}
                            label={'Фактический адрес'}
                            controlled
                            defaultValue={value?.actualAddress}
                            onChange={newValue => setValue({...value, actualAddress: newValue})}
                        />
                    </Stack>
                    <Stack className={'mb-3'}>
                        <NumericInput
                            sx={{width: 500}}
                            label={'Почтовый индекс адреса'}
                            controlled
                            defaultValue={value?.postalAddressIndex}
                            onChange={newValue => setValue({...value, postalAddressIndex: newValue})}
                        />
                    </Stack>
                    <Stack className={'mb-3'}>
                        <TextInput
                            sx={{width: 500}}
                            label={'Почтовый адрес'}
                            controlled
                            defaultValue={value?.postalAddress}
                            onChange={newValue => setValue({...value, postalAddress: newValue})}
                        />
                    </Stack>
                    <Stack className={'mb-3'}>
                        <TextInput
                            sx={{width: 500}}
                            label={'Email'}
                            controlled
                            defaultValue={value?.companyEmail}
                            onChange={newValue => setValue({...value, companyEmail: newValue})}
                        />
                    </Stack>
                    <Stack className={'mb-3'}>
                        <PhoneInput
                            label={'Телефон компании'}
                            variant={'outlined'}
                            controlled
                            defaultValue={value?.companyPhone}
                            returnNumber
                            onChange={newValue => setValue({...value, companyPhone: newValue})}
                        />
                    </Stack>
                    <Stack className={'mb-3'}>
                        <TextInput
                            sx={{width: 500}}
                            label={'Рассчетный счет'}
                            controlled
                            defaultValue={value?.paymentAccount}
                            onChange={newValue => setValue({...value, paymentAccount: newValue})}
                        />
                    </Stack>
                    <Stack className={'mb-3'}>
                        <TextInput
                            sx={{width: 500}}
                            label={'Корреспондентский счет'}
                            controlled
                            defaultValue={value?.correspondentAccount}
                            onChange={newValue => setValue({...value, correspondentAccount: newValue})}
                        />
                    </Stack>
                    <Stack className={'mb-3'}>
                        <TextInput
                            sx={{width: 500}}
                            label={'Наименование банка'}
                            controlled
                            defaultValue={value?.bankName}
                            onChange={newValue => setValue({...value, bankName: newValue})}
                        />
                    </Stack>
                    <Stack className={'mb-3'}>
                        <TextInput
                            sx={{width: 500}}
                            label={'БИК'}
                            controlled
                            defaultValue={value?.bik}
                            onChange={newValue => setValue({...value, bik: newValue})}
                        />
                    </Stack>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={"danger"} onClick={() => setVisibleModal(false)}>Закрыть</Button>
                <Button
                    onClick={() => {
                        updateContact(value)
                    }}
                >
                    Применить
                </Button>
            </Modal.Footer>
        </Modal>
    )
});

export default EditCompanyModule;