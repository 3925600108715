import './MultiField.css';
import {ReactComponent as PenIcon} from "../UI/icons/pen.svg";
import InputMultiField from "./components/InputMultiField/InputMultiField";
import DropdownMultiField from "./components/DropdownMultiField/DropdownMultiField";
import TextareaMultiField from "./components/TextareaMultiField/TextareaMultiField";
import {useMemo} from "react";

const MultiField = ({title, children, onClick, readonly}) => {
    const memoPenClass = useMemo(() => {
        return onClick && !readonly ? 'multi-field__pen-icon multi-field__pen-icon_clickable' :
            'multi-field__pen-icon'
    }, onClick)
    return (
        <div className={readonly ? "multi-field readonly" : "multi-field"}>
            <div className="multi-field__title">{title}</div>
            {children}
            {!readonly &&
                <PenIcon
                    className={memoPenClass}
                    onClick={e => onClick?.(e)}
                />}
        </div>
    );
};

MultiField.Input = InputMultiField;
MultiField.Dropdown = DropdownMultiField;
MultiField.Textarea = TextareaMultiField;

MultiField.defaultProps = {
    readonly: false,
}

export default MultiField;