import {Image, StyleSheet, Text, View} from "@react-pdf/renderer";
import {BaseUrl} from "../../../../../../features/databaseinfo/BaseUrl";

const TkpHeaderPdfV2 = ({tkp}) => {
    return (
        <View style={styles.headerWrapper}>
            <View style={styles.headerLogo}>
                <Image
                    src={
                        `${BaseUrl}/photo/img/Logo.png`
                    }
                    style={styles.imageLogo}
                />
            </View>
            <View style={styles.headerInfoOne}>
                <Text style={styles.textHeaderInfo}>ТЕХНИКО-КОММЕРЧЕСКОЕ ПРЕДЛОЖЕНИЕ
                    ДЛЯ: {tkp?.companyName?.toUpperCase() || 'Не заполнено'}</Text>
                <Text style={styles.textHeaderInfo}>НАЗВАНИЕ
                    СУДНА: {tkp?.ship?.toUpperCase() || 'Не заполнено'}</Text>
            </View>
            <View style={styles.headerInfoTwo}>
                <Text
                    style={styles.textHeaderInfo}>ПОДГОТОВИЛ: {tkp?.assigned?.toUpperCase() || 'Не заполнено'}</Text>
                <Text style={styles.textHeaderInfo}>ДАТА
                    СОЗДАНИЯ: {new Date(tkp?.createdTimeDb).toLocaleDateString()?.toUpperCase() || 'Не заполнено'}</Text>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    headerWrapper: {
        gap: 10,
        flexDirection: "row",
        justifyContent: 'flex-start',
        width: '100%',
        height: 50,
        alignItems: 'center'
    },
    headerLogo: {
        flex: 3,
    },
    imageLogo: {
        width: '185px',
        height: '50px',
    },
    headerInfoOne: {
        flex: 6
    },
    headerInfoTwo: {
        flex: 4,
    },
    textHeaderInfo: {
        fontSize: 9,
        fontFamily: 'Montserrat-Bold',
        fontWeight: 400
    },
})

export default TkpHeaderPdfV2;