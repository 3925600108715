import {Image, Text, View} from "@react-pdf/renderer";
import styles from "../constants";
import {BaseUrl} from "../../../../../features/databaseinfo/BaseUrl";

const KpPdfHeader = ({kp}) => {
    return (
        <View style={styles.headerWrapper}>
            <View style={styles.headerLogo}>
                <Image
                    src={
                        `${BaseUrl}/photo/img/Logo.png`
                    }
                    style={styles.imageLogo}
                />
            </View>
            <View style={styles.headerInfoOne}>
                <Text style={styles.textHeaderInfo}>От: АНКОРР ООО</Text>
                <Text style={styles.textHeaderInfo}>г. Ярославль, 2-ой Промышленный проезд, дом 11, литера Д, помещение 13</Text>
            </View>
        </View>
    );
};

export default KpPdfHeader;