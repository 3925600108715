import {Modal} from "react-bootstrap";
import {forwardRef, useImperativeHandle, useState} from "react";
import {generateGuid} from "../../features/guidgenerator/GuidGenerator";

const ModalWrapper = forwardRef(({children, buttons = [], fullscreen = false, size = 'lg', guid = generateGuid(), caption = 'Модальное окно', onHide, isBeginOpen = true}, ref) => {

    const [visible, setVisible] = useState(isBeginOpen)

    useImperativeHandle(ref, () => ({
        open: () => setVisible(true),
        close: () => setVisible(false),
        onHide: () => onHide?.()
    }), [])
    return (

        /*<Dialog
            open={visible}
            fullscreen={fullscreen}
            onClose={() => setVisible(false) || onHide?.(guid)}
        >
            <DialogTitle>{caption}</DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
            {buttons &&
                <DialogActions>
                    {buttons}
                </DialogActions>
            }
        </Dialog>*/
        <Modal
            show={visible}
            fullscreen={fullscreen}
            size={size}
            onHide={() => setVisible(false) || onHide?.(guid)}>
            <Modal.Header><Modal.Title>{caption}</Modal.Title></Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            {buttons &&
                <Modal.Footer>
                    {buttons}
                </Modal.Footer>
            }
        </Modal>
    );
});

export default ModalWrapper;