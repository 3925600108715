import {useMutation} from "@tanstack/react-query";
import {AxiosHelper} from "../useAxiosHelper";
import {useState} from "react";
import useDebouncingFunction from "../debouncingfunction/useDebouncingFunction";

const UseClientsSearcher = () => {
    const {mutate: updateClientsOptionFn} = useMutation({
        mutationKey: ['clients', 'options'],
        mutationFn: (prompt) => AxiosHelper.postMethod({
            urlWithoutBaseUrl: 'ancorr/api/client/search',
            queryParams: {
                prompt: prompt
            }
        }),
        onSuccess: (data) => setOptionsClients(data)
    })
    const [optionsClients, setOptionsClients] = useState([])
    const [inputClients, setInputClients] = useState('')
    const updateInputPrompt = useDebouncingFunction((newInputValue) => {
        if(newInputValue)
            updateClientsOptionFn(newInputValue)
    }, 1200)
    return {
        optionsClients,
        inputClients,
        updateInputClients: (newInputValue) => {
            setInputClients(newInputValue)
            updateInputPrompt(newInputValue)
        },
        onBlur: () => {
            setInputClients('')
            setOptionsClients([])
        }
    };
};

export default UseClientsSearcher;