import {useMemo} from "react";
import FolderMaterials from "../components/foldermaterials/FolderMaterials";
import ComponentMaterials from "../components/componentmaterials/ComponentMaterials";
import NomenclatureMaterials from "../components/nomenclaturematerials/NomenclatureMaterials";
import EquipmentNomenclatures from "../components/equipmentnomenclatures/EquipmentNomenclatures";
import Component from "../../component/axios/component/Component";
import Equipment from "../../equipment/axios/equipment/Equipment";


const UsePrintItems = ({materials}) => {
    const memoItems = useMemo(() => {
        const results = [];

        if (materials?.currentFolder) {
            materials.currentFolder.subgroups.forEach((subgroup) => {
                results.push(<FolderMaterials subgroup={subgroup} key={subgroup.guid}/>)
            })
        }

        if (materials?.components) {
            materials.components.forEach((component) => {
                results.push(<ComponentMaterials component={component} key={component.guid}/>)
            })
        }

        if (materials?.component) {
            results.push(<Component component={materials.component} key={materials.component.id}/>)
        }

        if (materials?.equipment) {
            results.push(<Equipment equipment={materials.equipment} key={materials.equipment.id}/>)
        }

        if (materials?.nomenclatures && Object.keys(materials?.nomenclatures).length > 0) {
            results.push(<NomenclatureMaterials
                nomenclatures={materials?.nomenclatures}
                key={'nomenclatures'}
            />)
        }

        if (materials?.equipmentNomenclatures) {
            materials.equipmentNomenclatures.forEach((equipmentNomenclature) => {
                results.push(<EquipmentNomenclatures
                    equipmentNomenclature={equipmentNomenclature}
                    key={equipmentNomenclature.guid}
                />)
            })
        }

        return results
    }, [materials])

    return memoItems;
};

export default UsePrintItems;