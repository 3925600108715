import {useQuery} from "@tanstack/react-query";
import {AxiosHelper} from "../../../../../../hooks/useAxiosHelper";
import {Stack} from "@mui/material";
import DropdownDefault from "../../../../../../components/UI/selects/dropdowndefault/DropdownDefault";
import {
    converterEntityToOptionOrReverse,
    getOptionsFromArrayEntities
} from "../../../../../../features/entityhelper/EntityHelper";
import NumericInput from "../../../../../../components/UI/inputs/numeric/NumericInput";
import {Button} from "react-bootstrap";

const ProvidersItemKp = ({item, changeProvidersItem, removeProvidersItem, remains}) => {
    const {data: warehouses} = useQuery({
        queryKey: ['options', 'warehouses'],
        queryFn: () => AxiosHelper.getMethod({
            urlWithoutBaseUrl: 'ancorr/api/helper/get/warehouses',
        }),
    })

    return (
        <div className={'flex justify-content-between align-items-center mb-3'}>
            <Stack direction={"horizontal"} className={'flex justify-content-start align-items-center gap-3'}>
                {item?.isDefaultProvision
                    ? `Склад: ${item?.warehouse?.fullName || ''}`
                    :
                    <DropdownDefault
                        placeholder={'Склад'}
                        options={getOptionsFromArrayEntities({
                            entities: warehouses?.filter(warehouse => warehouse?.isVisible) || [],
                            accessorName: 'warehouse',
                            valueCallback: warehouse => warehouse?.fullName,
                            titleCallback: warehouse => `${warehouse?.fullName} - ⚙️${remains?.find(remain => remain?.warehouse?.id === warehouse?.id)?.canProduce || 0} л`,
                            idCallback: warehouse => warehouse?.id
                        }) || []}
                        currentValue={{
                            accessor: 'warehouse',
                            id: item?.warehouse?.id,
                            title: item?.warehouse?.fullName,
                            value: item?.warehouse?.fullName,
                        }}
                        onChange={newValue => {
                            changeProvidersItem(
                                {
                                    ...item,
                                    warehouse: converterEntityToOptionOrReverse({
                                        endpoint: 'entity',
                                        arrayEntities: warehouses || [],
                                        searchPropertyForArray: newValue?.id
                                    })
                                }
                            )
                        }}
                    />
                }
                <NumericInput
                    defaultValue={item?.quantityProvision || 0}
                    useDebounce
                    controlled
                    label='Количество'
                    className={'w-25'}
                    onChange={newValue => changeProvidersItem({...item, quantityProvision: newValue})}
                    suffics={'л'}
                    type={'number'}
                />
            </Stack>
            {
                item?.isDefaultProvision
                ? <span>Склад по умолчанию</span>
                    : <Button variant={'outline-danger'} onClick={() => removeProvidersItem(item?.guid)}>Удалить</Button>
            }
        </div>
    );
};

export default ProvidersItemKp;