import {create} from 'zustand';
import {devtools} from "zustand/middleware";



const useRemainsStore = create()(devtools((set) => ({
    remains: {},
    filters: {
        "brandsIds": [],//Бренды
        "nomenclatureIds": [], //Семейства номенклатур
        "currencyIds": [],//Валюты
        "warehouseIds": [],//Склады
        "typeNomenclatureIds": [],//Тип номенклатуры
        "coatingTypeIds": [], // Тип покрытия
        "thicknessMin": 0, // Толщина
        "thicknessMax": 0,
        "volumeMin": 0,// Объем
        "volumeMax": 0,
        "dryResidueMin": 0,// Сухой остаток
        "dryResidueMax": 0,
        "specificWeightMin": 0, // Удельный вес
        "specificWeightMax": 0,
        "overlapMin": 0, // Перекрытие
        "overlapMax": 0,
        "weightMin": 0, // Вес
        "weightMax": 0,
        "priceForUnitMin": 0, // Цена за единицу
        "priceForUnitMax": 0,
        "discountMin": 0, // Скидка
        "discountMax": 0,
        "notInStock": false, // Не в наличии
        "isSupply": false, // Поступит
        "marginMin": 0, // Наценка
        "marginMax": 0,
        "destinationCoveragesIds": [],
        "formCoveragesIds": [],
        "dryingTimeHoursMax": 0,
        "dryingTimeHoursMin": 0,
    },
    page: 1,
    searchQuery: '',
    offset: 20,
    currentTab: 'equipments',
    setRemains: (data) => {
        set({remains: data});
    },
    setFilters: (data) => {
        set({filters: data, page: 1});
    },
    setPage: (numberPage) => {
        set({page: numberPage});
    },
    setSearchQuery: (searchQuery) => {
        set({searchQuery, page: 1});
    },
    setOffset: (offset) => {
        set({offset, page: 1});
    },
    setCurrentTab: (currentTab) => {
        set({currentTab, page: 1});
    },
}), {name: 'remainsStore'}));

export default useRemainsStore;