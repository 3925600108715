import React from 'react';
import './DealsDetail.css'
const DealsDetail = () => {
    return (
        <div>

        </div>
    );
};

export default DealsDetail;