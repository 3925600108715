import './Pagination.css';
import {ReactComponent as SkipBackward} from "../../../UI/icons/skip-backward.svg";
import {ReactComponent as SkipForward} from "../../../UI/icons/skip-forward.svg";
import {ReactComponent as ArrowLeft} from "../../../UI/icons/arrow-left.svg";
import {ReactComponent as ArrowRight} from "../../../UI/icons/arrow-right.svg";
import {useMemo} from "react";

const RemainsPages = ({controllerData, changePage}) => {

    const memoItems = useMemo(() => {
        const paginationLimit = controllerData.paginationLimit;
        const items = [];
        const start = Math.max(1, Math.min(controllerData.page - 5, controllerData.pagesCount - paginationLimit + 1));
        const end = Math.min(start + paginationLimit - 1, controllerData.pagesCount);


        for (let i = start; i <= end; i++) {
            items.push(
                <button
                    className={`pagination__control${i === controllerData.page ? " pagination__control_active" : ""}`}
                    key={i}
                    onClick={() => changePage(i)}
                >
                    {i}
                </button>
            );
        }

        return items;
    }, [controllerData.pagesCount, controllerData.page]);

    return (
        <div className={'pagination'}>
            <button
                className="pagination__control"
                style={controllerData.page === 1 ? {cursor: 'not-allowed'} : {}}
                disabled={controllerData.page === 1}
                onClick={() => changePage(1)}
            >
                <SkipBackward className={'pagination__control-icon'}/>
            </button>
            <button
                className="pagination__control"
                style={controllerData.page === 1 ? {cursor: 'not-allowed'} : {}}
                disabled={controllerData.page === 1}
                onClick={() => changePage(controllerData.page - 1)}
            >
                <ArrowLeft className={'pagination__control-icon'}/>
            </button>
            { controllerData.pagesCount > 1 &&
                memoItems.map((item) => item)
            }
            <button
                className="pagination__control"
                style={controllerData.page >= controllerData.pagesCount ? {cursor: 'not-allowed'} : {}}
                disabled={controllerData.page >= controllerData.pagesCount}
                onClick={() => changePage(controllerData.page + 1)}
            >
                <ArrowRight className={'pagination__control-icon'}/>
            </button>
            <button
                className="pagination__control"
                style={controllerData.page >= controllerData.pagesCount ? {cursor: 'not-allowed'} : {}}
                disabled={controllerData.page >= controllerData.pagesCount}
                onClick={() => changePage(controllerData.pagesCount)}
            >
                <SkipForward className={'pagination__control-icon'}/>
            </button>
        </div>
    );
};

export default RemainsPages;