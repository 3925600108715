export function getFields(equipment) {
    return [[
        {title: 'Наименование', value: equipment?.name},
        {title: 'Наименование группы', value: equipment?.group?.name},
        {title: 'Бренд', value: equipment?.equipmentInformation?.brand?.name || '-'},
        {title: 'Валюта', value: equipment?.equipmentInformation.currency?.name},
        {title: 'Единица измерения', value: equipment?.equipmentInformation.unitMeasurement?.unitName},
        {title: 'Объем единицы', value:  equipment?.equipmentInformation?.volume + '' + equipment?.equipmentInformation.unitVolume?.unitName},
        {title: 'Вес единицы', value:  equipment?.equipmentInformation?.weight + '' + equipment?.equipmentInformation.unitWeight?.unitName},
        {title: 'Цена за единицу', value: equipment?.priceForUnit.toFixed(2) + '' + (equipment?.supplyPrice?.currency?.currencyCode || '')},
        {title: 'Цена закупки', value:  equipment?.supplyPrice?.price + '' + (equipment?.supplyPrice?.currency?.currencyCode || '')},
        {title: 'Доступно', value: equipment?.available},
        {title: 'Доступно с поставкой', value: equipment?.availableWithSupply},
        {title: 'В резерве', value: equipment?.reserved}
    ], [
        {title: 'Отгрузка', value: equipment?.shipment},
        {title: 'Поступит', value: equipment?.supply},
        {title: 'Планируется поступление', value: equipment?.plannedSupply},
        {title: 'Брак', value: equipment?.remainsMarriage},
        {title: 'Неликвид', value: equipment?.remainsUnmarketable},
        {title: 'В наличии', value:  equipment?.inStock},
        {title: 'Ближайшая дата поставки', value:  equipment?.nearestSupplyDate || '-'},
        {title: 'Цена поставки', value: equipment?.deliveryPrice},
        {title: 'Дополнительная цена доставки', value:  equipment?.additionalDeliveryPrice},
        {title: 'Максимальная скидка', value: equipment?.maxDiscount},
        {title: 'Наценка', value: equipment?.margin},
        {title: 'Цвет', value: <>{equipment?.color || "Нет"} {equipment?.color && <div style={{backgroundColor: equipment?.color}} className={'component__color'}></div>}</>}
    ]]
}