import './ClientDetail.css'
import useLoader from "../../../../hooks/useLoader";
import {useNavigate, useParams} from "react-router";
import CompaniesList from "./components/companieslist/CompaniesList";
import ContactsList from "./components/contactslist/ContactsList";
import useCheckAccess from "../../../../hooks/useCheckAccess";
import AccessDenied from "../../../../components/access/AccessDenied";
import {useMultiStepComponent} from "../../../../hooks/usemultistepcomponent/UseMultiStepComponents";
import ButtonTab from "../../../../components/UI/buttons/buttontab/ButtonTab";
import CrossIcon from "../../../../components/UI/icons/CrossIcon";
import RequisitesIcon from "../../../../components/UI/icons/RequisitesIcon";
import ContactIcon from "../../../../components/UI/icons/ContactIcon";
import CompanyIcon from "../../../../components/UI/icons/CompanyIcon";
import TabsBlock from "../../../../components/tabsblock/TabsBlock";
import DetailWrapper from "../../../../components/detailcard/wrapper/DetailWrapper";
import DetailBody from "../../../../components/detailcard/body/DetailBody";
import FieldsBlockRenderer from "../../../../modules/fieldblockrenderer/FieldsBlockRenderer";
import {AxiosHelper} from "../../../../hooks/useAxiosHelper";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import useAxiosErrorHandler from "../../../../hooks/axioserrorhandler/useAxiosErrorHandler";
const ClientDetail = () => {
    const {hideAll} = useLoader()
    const params = useParams()
    const id = Number(params['id'] || 0);
    const navigate = useNavigate()
    const client = useQueryClient()
    const handleErrorResponse = useAxiosErrorHandler()
    const {data: currentClient} = useQuery({
        queryKey: ['current-client', id],
        queryFn: () => AxiosHelper.getMethod({
            urlWithoutBaseUrl: `ancorr/api/client/get/client/${id}`,
        }),
        onError: (error) => handleErrorResponse(error)
    })

    const {data: schema} = useQuery({
        queryKey: ['schema', 'client'],
        queryFn: () => AxiosHelper.getMethod({
            urlWithoutBaseUrl: 'ancorr/api/helper/get/schema',
            queryParams: {
                destinationEntity: 'client'
            },
        }),
        refetchOnWindowFocus: false,
        onError: (error) => handleErrorResponse(error)
    })

    const {mutate: changeCurrentClient} = useMutation({
        mutationKey: ['current-client', id],
        mutationFn: ({newItem}) => AxiosHelper.postMethod({
            urlWithoutBaseUrl: 'ancorr/api/client/update/client',
            data: newItem,
        }),
        onSuccess: (data, {setterCallback}) => {
            setterCallback(true)
            client.invalidateQueries({queryKey: ['current-client', id]})
        },
        onError: (error, {setterCallback}) => {
            setterCallback(false)
            handleErrorResponse(error)
        },
        onMutate: ({newItem}) => client?.setQueryData(['current-client', id], (oldItem) => newItem)
    })

    /*//Счетчик количества рендеров __DEV__
    const countRef = useRef(0)
    countRef.current++;
    console.log('render', countRef.current)*/

    const {
        titleStep,
        step,
        goTO
    } = useMultiStepComponent([
        {
            component: schema && currentClient
                ?
                    <FieldsBlockRenderer
                        schema={schema}
                        model={currentClient}
                        waitingSave={(newItem, setterCallback) => {
                            changeCurrentClient?.({newItem, setterCallback})
                        }}
                        dependencyEntity={[
                            {
                                method: 'ancorr/api/helper/get/users',
                                keySelector: item => item?.id,
                                titleSelector: item => item ? `${item?.lastName || 'Без фамилии'} ${item?.firstName || 'Без имени'} ` : 'Не заполнен',
                                valueSelector: item => item?.id,
                            },
                        ]}
                        backendMode={'axios'}
                    />
                : null,
            titleStep: 'Информация'
        },
        {
            component: <CompaniesList/>,
            titleStep: 'Доп. компании'
        },
        {
            component: <ContactsList/>,
            titleStep: 'Доп. контакты'
        },
    ])

    const access = useCheckAccess("client/detail")

    if(!access){
        hideAll()
        return <AccessDenied />
    }

    return (
        <DetailWrapper>
            <TabsBlock>
                <ButtonTab
                    icon={<RequisitesIcon classNames={'icon-extension'}/>}
                    title={'Информация'}
                    onClick={() => goTO(0)}
                    isActive={titleStep === 'Информация'}
                />
                <ButtonTab
                    icon={<CompanyIcon classNames={'icon-extension'}/>}
                    title={'Доп. компании'}
                    onClick={() => goTO(1)}
                    isActive={titleStep === 'Доп. компании'}
                />
                <ButtonTab
                    icon={<ContactIcon classNames={'icon-extension'}/>}
                    title={'Доп. контакты'}
                    onClick={() => goTO(2)}
                    isActive={titleStep === 'Доп. контакты'}
                />
                <ButtonTab
                    title={'Назад к списку'}
                    onClick={() => navigate(-1)}

                />
                <ButtonTab
                    icon={<CrossIcon classNames={'icon-extension'}/>}
                    title={'Удалить клиента'}
                    classNames={'button-extension_delete'}
                    color={'red'}
                    onClick={() => {}}
                />
            </TabsBlock>
            <DetailBody>
                {step}
            </DetailBody>
        </DetailWrapper>
    );
};

export default ClientDetail;