import {useQueryClient} from "@tanstack/react-query";
import {getCurrencyString} from "../../../../features/formatters/forrmaterrub";
import './TotalBarTkp.css';
import StretchLine from "../../../../components/stretchline";

const TotalBarTkp = ({keyClient, idTkp}) => {
    const client = useQueryClient()
    const currentTkp = client.getQueryData([keyClient, idTkp])

    const getTextOrClassWithDistribute = (distribute, attributeName) => {
        let result = '';
        switch (attributeName) {
            case 'text':
                result = distribute ? 'включена в цену' : 'не включена в цену';
                break;
            case 'class':
                result = distribute ? 'slide-tkp-price__span_in-price' : 'slide-tkp-price__span_out-price';
        }
        return result;
    }

    return (
        <div className="total-bar-tkp">
            <StretchLine
                data={currentTkp}
                itemClasses={['stretch-line__item-custom']}
                rules={[
                    {
                        text: (item) => {
                            let text = ['Стоимость без НДС: ']
                            text.push(getCurrencyString(
                                item?.totalPriceIncludingPackaging - item?.totalPriceIncludingPackagingVAT,
                                item?.currencyForHandle?.currencyCode
                            ))
                            return text
                        },
                        key: (item) => ['СтоимостьP1', 'СтоимостьP2'],
                        itemKey: (item) => 'Стоимость'
                    },
                    {
                        text: (item) => {
                            let text = ['НДС: ']
                            text.push(getCurrencyString(item?.totalPriceIncludingPackagingVAT, item?.currencyForHandle?.currencyCode))
                            return text
                        },
                        key: (item) => ['НДСP1', 'НДСP2'],
                        itemKey: (item) => 'НДС'
                    },
                    {
                        text: (item) => {
                            let text = ['Итого c НДС: ']
                            text.push(getCurrencyString(item?.totalPriceIncludingPackaging, item?.currencyForHandle?.currencyCode))
                            return text
                        },
                        key: (item) => ['Итого c НДСP1', 'Итого c НДСP2'],
                        itemKey: (item) => 'Итого c НДС'
                    },
                    {
                        text: (item) => {
                            let text = ['Вес: ']
                            text.push(`${item?.totalWeight?.toFixed(0) || ''} кг`)
                            return text
                        },
                        key: (item) => ['ВесP1', 'ВесP2'],
                        itemKey: (item) => 'Вес'
                    },
                    {
                        text: (item) => {
                            let text = [];
                            text.push(
                                <>
                                    Сервис (
                                    <span className={getTextOrClassWithDistribute(item?.settings?.distributeServiceToPrice, 'class')}>
                                        {getTextOrClassWithDistribute(item?.settings?.distributeServiceToPrice, 'text')}
                                </span>):
                                </>
                            )
                            text.push(getCurrencyString(item?.settings?.servicePrice || 0, item?.currencyForHandle?.currencyCode));
                            return text
                        },
                        key: (item) => ['СервисP1', 'СервисP2'],
                        itemKey: (item) => 'Сервис'
                    },
                    {
                        text: (item) => {
                            let text = []
                            text.push(
                                <>
                                    Доставка (
                                    <span
                                        className={getTextOrClassWithDistribute(item?.settings?.distributeDeliveryToPrice, 'class')}>
                                        {getTextOrClassWithDistribute(item?.settings?.distributeDeliveryToPrice, 'text')}
                                    </span>):
                                </>
                            )
                            text.push(getCurrencyString(item?.settings?.deliveryPrice, item?.currencyForHandle?.currencyCode))
                            return text
                        },
                        key: (item) => ['ДоставкаP1', 'ДоставкаP2'],
                        itemKey: (item) => 'Доставка'
                    },
                    {
                        text: (item) => {
                            let text = ['Кол-во литров: ']
                            text.push(`${item?.totalLiters?.toFixed(1) || 0} л`)
                            return text
                        },
                        key: (item) => ['Кол-во литровP1', 'Кол-во литровP2'],
                        itemKey: (item) => 'Кол-во литров'
                    },
                    {
                        text: (item) => {
                            let text = ['Обеспечено литров: ']
                            text.push(`${item?.totalLitersProvision?.toFixed(1) || 0} л`)
                            return text
                        },
                        key: (item) => ['Обеспечено литровP1', 'Обеспечено литровP2'],
                        itemKey: (item) => 'Обеспечено литров'
                    },
                ]}
            />
        </div>
    );
};

export default TotalBarTkp;