import './NomenclatureMaterials.css';
import {useMemo} from "react";
import FolderNomenclaturesMaterials from "../foldernomeclaturesmaterials/FolderNomenclaturesMaterials";

const NomenclatureMaterials = ({nomenclatures}) => {

    const memoFolders = useMemo(() => {
        const folders = [];
        const keys = Object.keys(nomenclatures);
        keys.forEach((key) => {
            if (nomenclatures[key].length > 0) {
                const folder = {};
                folder.id = nomenclatures[key][0].nomenclatureId;
                folder.name = key;
                folder.materials = nomenclatures[key];
                folders.push(folder);
            }
        });
        return folders;
    }, [nomenclatures]);

    return (
        <div className="nomenclature-materials">
            {memoFolders.map((folder) => (
                <FolderNomenclaturesMaterials key={folder.id} folder={folder}/>
            ))}
        </div>
    )
};

export default NomenclatureMaterials;