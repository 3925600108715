import {Text, View} from "@react-pdf/renderer";
import styles from "../../constants";
import {getCurrencyString} from "../../../../../../features/formatters/forrmaterrub";
import rubles from "rubles";


const KpPdfTableFooter = ({materials, kp}) => {
    return (
        <View style={styles.infoTable}>
            <View style={styles.price} wrap={false}>
                <Text style={styles.priceText}>
                    Всего наименований: {materials?.length}, на сумму без
                    НДС: {getCurrencyString(kp?.totalPriceIncludingPackaging - kp?.totalPriceIncludingPackagingVAT, kp?.currencyForHandle?.currencyCode)}
                    {kp.isHandleVAT &&
                        <>
                            , НДС {kp?.vat}%: {getCurrencyString(kp?.totalPriceIncludingPackagingVAT, kp?.currencyForHandle?.currencyCode)},
                            итого с НДС: {getCurrencyString(kp?.totalPriceIncludingPackaging, kp?.currencyForHandle?.currencyCode)}
                        </>
                    }
                </Text>
            </View>
            <View style={styles.price} wrap={false}>
                <Text style={styles.priceText}>
                    {kp.isHandleVAT ?
                        rubles.rubles(kp?.totalPriceIncludingPackaging)
                        :
                        rubles.rubles(kp?.totalPriceIncludingPackaging - kp?.totalPriceIncludingPackagingVAT)
                    }
                </Text>
            </View>
        </View>
    );
};

export default KpPdfTableFooter;