import {StyleSheet} from "@react-pdf/renderer";

const styles = StyleSheet.create({
    document: {
        zIndex: 30,
        position: "relative",
        fontFamily: 'Montserrat-Regular'

    },
    page: {
        position: "relative",
        zIndex: 30,
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        backgroundColor: '#F3F3F3',
        width: '100%',
        height: '100%'
    },
    wrapper: {
        padding: '20px 0',
        position: "relative",
        zIndex: 30,
        alignItems: "center",
        justifyContent: "flex-start",
        flexDirection: "column",
        backgroundColor: 'white',
        width: '100%',
        flex: 1,
    },
    container: {
        padding: '0 40px',
        width: '100%',
    },
    titleItems: {
        flex: 1,
        width: '100%',
        flexDirection: "column",
        alignItems: 'flex-start',
        justifyContent: "space-between",

    },
    titleItem: {
        flex: 1,
        width: '100%',
        flexDirection: "column",
        alignItems: 'flex-start',
        justifyContent: "flex-start",
    },
    titleAssigned: {
        fontSize: 12,
        fontFamily: 'Montserrat-Bold'
    },
    titleDate: {
        fontSize: 12,
        fontFamily: 'Montserrat-Bold',
        fontWeight: 400
    },
    titleLogo: {
        width: 200,
        height: 50
    },
    backgroundImage: {
        zIndex: 15,
        position: "absolute",
        objectFit: 'cover',
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
    },
    header: {
        padding: 10,
        alignItems: "center",
        gap: 20,
        justifyContent: "flex-start",
        width: '100%',
        flexDirection: "row"
    },
    tableHeader: {
        flexDirection: 'row',
        borderTop: '1px solid black',
        borderBottom: '1px solid black',
        borderLeft: '1px solid black',
        width: '100%',
        fontSize: 10,
        fontWeight: 400
    },
    tableHeaderText: {
        fontSize: 8,
        textAlign: 'center',
        width: '100%',
    },
    items: {
        width: '100%',
    },
    item: {
        flexDirection: 'row',
        maxHeight: 'auto',
        minHeight: 15,
        width: '100%',
        fontSize: 8,
        borderBottom: '1px solid black',
        borderLeft: '1px solid black',
        opacity: 0.8,

    },
    column: {
        flex: 1,
        padding: "5px",
        height: '100%',
        textAlign: 'center',
        borderRight: '1px solid black',
        alignItems: "center"
    },
    text: {
        fontFamily: 'Montserrat-Bold',
        fontWeight: 400,
        padding: '40px 0 20px 0',
    },
    baseText: {
        fontSize: 10,
        width: "100%",
        padding: '0 40px',
    },
    infoTable: {
        backgroundColor: '#dcdcdc',
        padding: '5px',
    },
    price: {
        width: '100%',
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end",
        gap: 10,
    },
    datePrice: {
        paddingTop: '40px',
        width: '100%',
        justifyContent: 'flex-end',
        fontSize: 10,
        textAlign: 'right',
        fontFamily: 'Montserrat-Italic'
    },
    priceText: {
        fontSize: 10,
        width: '100%',
        height: '100%',
        textAlign: 'right',
        fontWeight: 400
    },
    conditions: {
        paddingTop: '40px',
        width: '100%',
        fontSize: 10,
    },
    footer: {
        paddingTop: '40px',
        width: '100%',
        fontSize: 10,
    },
    //IntroInfo Styles
    introInfo: {
      gap: 10
    },
    // HEADER STYLES
    headerWrapper: {
        padding: '0 20px',
        gap: 40,
        flexDirection: "row",
        justifyContent: 'flex-start',
        width: '100%',
        alignItems: 'center'
    },
    headerLogo: {},
    imageLogo: {
        width: '150px'
    },
    headerInfo: {},
    textHeaderInfo: {
        fontSize: 10,
        fontWeight: 400,
        width: '330px'
    },
});

export default styles;