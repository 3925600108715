import Item from "../item/Item";
import {useEffect, useState} from "react";
import Text from "../text/Text";


const CustomItem = ({data, itemClasses, textClasses, textKey}) => {
    const [items, setItems] = useState(null);

    useEffect(() => {
        if (Array.isArray(data)) {
            const newItems = data.map((text, id) => (
                <Text key={textKey[id]} classes={textClasses}>
                    {text}
                </Text>
            ))
            setItems(newItems);
        } else if (data) {
            setItems(
                <Text key={textKey} classes={textClasses}>
                    {data}
                </Text>
            )
        }
    }, []);

    return (
        <Item classes={itemClasses}>
            {items && items}
        </Item>
    );
};

export default CustomItem;