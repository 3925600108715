import {Text, View} from "@react-pdf/renderer";

const TkpPlaceItemPdfV2 = ({Number, Name, Square, Guid, defaultStyles}) => {
    return (
        <View key={Guid} style={[defaultStyles.item, defaultStyles.diluent]}>
            <View style={[defaultStyles.column, {flex: 0.2}]}>
                <Text style={defaultStyles.tableHeaderText}>{Number}</Text>
            </View>
            <View style={[defaultStyles.column, {flex: 4, paddingLeft: 10, alignItems: "flex-start"}]}>
                <Text style={defaultStyles.names}>{Name || "Нет данных"}</Text>
            </View>
            <View style={[defaultStyles.column, {flex: 0.6}]}>
                <Text style={defaultStyles.tableHeaderText}>{Square?.toFixed(1)} м²</Text>
            </View>
        </View>
    );
};

export default TkpPlaceItemPdfV2;