import GuidsHeader from "../../../../../components/guids/header/GuidsHeader";
import TitleDetailCard from "../../../../../components/UI/titledetailcard/TitleDetailCard";
import GuidsSearchInput from "../../../../../components/guids/search/GuidsSearchInput";
import GuidsBody from "../../../../../components/guids/body/GuidsBody";
import TableBootstrap from "../../../../../components/tableb/TableBootstrap";
import GuidsWrapper from "../../../../../components/guids/wrapper/GuidsWrapper";
import EditIcon from "../../../../../components/UI/icons/EditIcon";
import EditSubmenuElementForm from "./EditSubmenuElementForm";
import BackCrossIcon from "../../../../../components/UI/icons/BackCrossIcon";
import useAxiosHelper from "../../../../../hooks/useAxiosHelper";
import useCaching from "../../../../../store/useCaching";
import {useEffect, useState} from "react";
import {Button, Container} from "react-bootstrap";


const SubmenuPartnersList = ({add, updateItems, KEY_CACHING}) => {
    const [searchValue, setSearchValue] = useState([])
    const {postMethod, deleteMethod} = useAxiosHelper({});
    const getPayload = useCaching.getState().getPayload;
    const setPayload = useCaching.getState().setPayload;
    const removePayload = useCaching.getState().removePayload;
    const [itemStore, setItemStore] = useState({});

    useEffect(() => {
        setItemStore(getPayload(KEY_CACHING));
    }, []);

    const updateItem = (newItem) => {
        setItemStore({...newItem});
        setPayload(KEY_CACHING, {...newItem});
    }
    const addSubItem = () => postMethod({
        urlWithoutBaseUrl: 'ancorr/api/menu/add/subitem',
        queryParams: {
            parentId: itemStore?.id
        },
        successCallback: ({data}) => updateItem({
            ...itemStore,
            subitems: [...itemStore?.subitems, data]
        }) || updateItems()
    })
    const removeSubItem = (guid) => deleteMethod({
        urlWithoutBaseUrl: 'ancorr/api/menu/delete',
        queryParams: {
            guid: guid
        },
        successCallback: () => updateItem({
            ...itemStore,
            subitems: itemStore?.subitems?.filter(x => x?.guid !== guid)
        }) || updateItems()
    })

    const popoverActionsSubitems = [
        {
            icon: <EditIcon classNames={'cl-light-purple'}/>,
            title: 'Редактировать',
            onClick: item => {
                const KEY_CACHING_ITEM = 'subItemToEdit'
                setPayload(KEY_CACHING, {...item});
                add({
                    children: <EditSubmenuElementForm KEY_CACHING={KEY_CACHING_ITEM}/>,
                    isNeedSuccess: true,
                    onCancel: () => removePayload(KEY_CACHING_ITEM),
                    onSuccess: () => {
                        postMethod({
                            urlWithoutBaseUrl: 'ancorr/api/menu/update',
                            data: getPayload(KEY_CACHING_ITEM),
                            successCallback: () => {
                                updateItems();
                                removePayload(KEY_CACHING_ITEM);
                            }
                        })
                    },
                })
            }
        },
        {
            icon: <BackCrossIcon classNames={'cl-red-tomato'}/>,
            title: 'Удалить',
            classNamesText: 'cl-red-tomato',
            onClick: item => removeSubItem(item?.guid)
        },
    ]
    return (
        <GuidsWrapper>
            <GuidsHeader>
                <TitleDetailCard text={'СПИСОК ЭЛЕМЕНТОВ ПОДМЕНЮ'}/>
                <Container fluid className={'d-flex justify-content-end gap-2'}>
                    <GuidsSearchInput onChange={newValue => setSearchValue(newValue)}/>
                    <Button onClick={() => addSubItem()}>Добавить</Button>
                </Container>
            </GuidsHeader>
            <GuidsBody>
                <TableBootstrap
                    schema={schema}
                    items={itemStore?.subitems || []}
                    popoverActions={popoverActionsSubitems}
                    popoverCaption={"Действия"}
                />
            </GuidsBody>
        </GuidsWrapper>
    );
};

const schema = [
    {
        title: 'ID',
        callbackItem: item => item?.id,
    },
    {
        title: 'Name',
        callbackItem: item => item?.name,
    },
    {
        title: 'Title',
        callbackItem: item => item?.title,
    },
    {
        title: 'Color',
        callbackItem: item => item?.color,
    },
    {
        title: 'Path',
        callbackItem: item => item?.path,
    },
    {
        title: 'IsAdmin',
        callbackItem: item => item?.isAdmin ? 'Да' : 'Нет',
    },
]

export default SubmenuPartnersList;