import {useSnackbar} from "notistack";
import {useAuth} from "../../features/authprovider/AuthProvider";

const UseAxiosErrorHandler = () => {
    const {enqueueSnackbar} = useSnackbar()
    const {checkAuth} = useAuth()


    return (axiosError) => {
        if(!axiosError)
            return;

        switch (axiosError.response.status){
            case 401: {
                enqueueSnackbar(`Вы не имеете доступа к элементам запроса`)
                checkAuth()
                break;
            }
            default: {
                if(axiosError?.response?.data?.toLowerCase()?.includes('the token is expired'))
                    enqueueSnackbar(`The token is expired`)
                else
                    enqueueSnackbar(`${axiosError.response.data}`)
                break;
            }
        }

    };
};

export default UseAxiosErrorHandler;