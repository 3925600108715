import {Text, View} from "@react-pdf/renderer";
import styles from "../constants";

const KpPdfIntroInfo = ({kp}) => {
    return (
        <View style={styles.introInfo}>
            <View style={styles.baseText}>
                <Text>Подготовлено: {kp.assignedB24} от {new Date(kp.createdTimeDb).toLocaleDateString()}</Text>
                <Text>Для: {kp.companyNameB24}</Text>
            </View>
            <View style={styles.baseText}>
                <Text>{kp?.previewTitleClient}</Text>
                <Text>Компания АНКОРР ООО предлагает Вам ознакомиться с предложением по следующим
                    позициям:</Text>
            </View>
        </View>
    );
};

export default KpPdfIntroInfo;