import './SubmitModal.css';
import {Button, Modal} from "react-bootstrap";
import {useMemo, useState} from "react";
import useSubmitModalStore from "./store/submitModalStore";

const SubmitModal = () => {
    const [visible, setVisible] = useState(false);
    const stack = useSubmitModalStore(state => state.stack);
    const removeLatsItemInStack = useSubmitModalStore.getState().removeLast;

    const {submit, children} = useMemo(() => {
        if (stack.length > 0) {
            setVisible(true);
            return stack[stack.length - 1];
        } else {
            setVisible(false);
            return {submit: null, children: null};
        }
    }, [stack]);

    const onHide = () => {
        setVisible(false);
        removeLatsItemInStack();
    }

    return (
        <Modal show={visible} onHide={onHide} centered dialogClassName="submit-modal">
            <Modal.Body>
                <div className="submit-modal__content">
                    {
                        children ?
                            children
                            : 'Подтвердите действие'
                    }
                </div>
                <div className="submit-modal__controls">
                    <Button
                        onClick={() => {
                            submit?.()
                            onHide();
                        }}
                        bsPrefix={'submit-modal__btn'}
                    >
                        Подтвердить
                    </Button>
                    <Button
                        onClick={onHide}
                        className="submit-modal__btn_close"
                        bsPrefix={'submit-modal__btn'}
                    >
                        Отмена
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default SubmitModal;