import Wrapper from "./components/wrapper/Wrapper";
import ArrayData from "./components/datatypes/ArrayData";
import ObjectData from "./components/datatypes/ObjectData";


const StretchLine = ({data, wrapperClasses = [], itemClasses = [], textClasses = [], rules = []}) => {

    return (
        <Wrapper classes={wrapperClasses}>
            {
                Array.isArray(data) ?
                    <ArrayData
                        data={data}
                        rules={rules}
                        textClasses={textClasses}
                        itemClasses={itemClasses}
                    />
                    :
                    <ObjectData
                        data={data}
                        rules={rules}
                        textClasses={textClasses}
                        itemClasses={itemClasses}
                    />
            }
        </Wrapper>
    );
};

export default StretchLine;