import TkpSummaryMaterial from "../../../tkpsummarymaterial/TkpSummaryMaterial";
import TkpLabel from "../../../tkplabel/TkpLabel";
import {getRubString} from "../../../../../../features/formatters/forrmaterrub";
import React from "react";

const ComponentClientDiluentV2 = (visiblePrice) => {
    const classColumn = 'tkp-client-table__column'
    if(visiblePrice) {
        return [
            {
                Key: 1,
                TitleColumn: 'Разбавитель',
                invokeComponent: (item, callbackAction) => {
                    return (
                        <TkpSummaryMaterial materialSlice={item} sliceNumber={item.slice} classSummaryMaterial={classColumn}/>
                    )
                }
            },
            {
                Key: 2,
                TitleColumn: 'Кол-во',
                invokeComponent: (item, callbackAction) => {
                    return (
                        <TkpLabel classLabel={classColumn} text={item?.quantityDiluentIncludingPackaging?.toFixed(1) + ' л'}/>
                    )
                }
            },
            {
                Key: 3,
                TitleColumn: 'Вес кг',
                invokeComponent: (item, callbackAction) => {
                    return (
                        <TkpLabel classLabel={classColumn} text={item?.totalWeight?.toFixed(0) + ' кг'}/>
                    )
                }
            },
            {
                Key: 4,
                TitleColumn: '% разбавления',
                invokeComponent: (item, callbackAction) => {
                    return (
                        <TkpLabel classLabel={classColumn} text={item?.equipment?.equipmentInformation?.packageSize}/>
                    )
                }
            },
            {
                Key: 5,
                TitleColumn: 'Цена за литр',
                invokeComponent: (item, callbackAction) => {
                    return (
                        <TkpLabel classLabel={classColumn} text={getRubString(item?.priceForLiter)}/>
                    )
                }
            },
            {
                Key: 6,
                TitleColumn: 'Цена за кг',
                invokeComponent: (item, callbackAction) => {
                    return (
                        <TkpLabel classLabel={classColumn} text={getRubString(item?.priceForKg)}/>
                    )
                }
            },
            {
                Key: 7,
                TitleColumn: 'Стоимость кр. таре',
                invokeComponent: (item, callbackAction) => {
                    return (
                        <TkpLabel classLabel={classColumn} text={getRubString(item?.totalPriceIncludingPackaging)}/>
                    )
                }
            },
        ]
    }
    else {
        return [
            {
                Key: 1,
                TitleColumn: 'Разбавитель',
                invokeComponent: (item, callbackAction) => {
                    return (
                        <TkpSummaryMaterial materialSlice={item} sliceNumber={item.slice}/>
                    )
                }
            },
            {
                Key: 2,
                TitleColumn: 'Кол-во',
                invokeComponent: (item, callbackAction) => {
                    return (
                        <TkpLabel classLabel={classColumn} text={item?.quantityDiluentIncludingPackaging?.toFixed(1) + ' л'}/>
                    )
                }
            },
            {
                Key: 3,
                TitleColumn: 'Вес кг',
                invokeComponent: (item, callbackAction) => {
                    return (
                        <TkpLabel classLabel={classColumn} text={item?.totalWeight?.toFixed(0) + ' кг'}/>
                    )
                }
            },
            {
                Key: 4,
                TitleColumn: '% разбавления',
                invokeComponent: (item, callbackAction) => {
                    return (
                        <TkpLabel classLabel={classColumn} text={item?.equipment?.equipmentInformation?.packageSize}/>
                    )
                }
            },
        ]
    }
};

export default ComponentClientDiluentV2;