import './InputCourse.css';
import {Accordion, AccordionDetails, IconButton} from "@mui/material";
import AccordionSummaryCourse from "../courseItem/AccordionSummaryCourse";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {AxiosHelper} from "../../../../../hooks/useAxiosHelper";
import {useMemo, useState} from "react";
import {Add, Delete} from "@mui/icons-material";
import InputPriceCourse from "../inputPriceCourse/InputPriceCourse";
import DropdownDefault from "../../../../../components/UI/selects/dropdowndefault/DropdownDefault";
import {
    converterEntityToOptionOrReverse,
    getOptionsFromArrayEntities
} from "../../../../../features/entityhelper/EntityHelper";
import useLoader from "../../../../../hooks/useLoader";


const InputCourse = ({inputCourse, stateCourseKey}) => {
    const [isOpened, setIsOpened] = useState(false);
    const {show, hide} = useLoader()

    const memoInputCourse = useMemo(() => inputCourse, [inputCourse])

    const client = useQueryClient();
    const {data: warehouses} = useQuery({
        queryKey: ['warehouses'],
        queryFn: () => AxiosHelper.getMethod({
            urlWithoutBaseUrl: 'ancorr/api/helper/get/warehouses',
        })
    })
    //Функция поиска активного option для Autocomplete компонента для старых данных
    const findWarehouse = (warehouseName) => {
        return memoWarehouses?.find(warehouse => warehouseName?.length > 0
            ? warehouse?.fullName.toLowerCase().includes(warehouseName.toLowerCase())
            : warehouse?.fullName === warehouseName)
    }
    //Мемоизация списка складов с добавлением поля label для компонента Autocomplete
    const memoWarehouses = useMemo(() => {
        let options = warehouses?.map((warehouse) => ({...warehouse, label: warehouse.fullName})) || []
        options.push({label: 'Направление', fullName: ''})
        return options
    }, [warehouses])

    const {mutate: updateInputCourse} = useMutation({
        mutationKey: [stateCourseKey],
        mutationFn: (updatedInputCourse) => AxiosHelper.postMethod({
            urlWithoutBaseUrl: 'ancorr/api/course/update/info',
            data: updatedInputCourse,
        }),
        onMutate: (updatedInputCourse) => client.setQueryData([stateCourseKey], (oldItem) => updatedInputCourse),
        onSuccess: () => client.invalidateQueries({queryKey: [stateCourseKey]}),
    })
    const {mutate: removeInputCourse} = useMutation({
        mutationKey: [stateCourseKey],
        mutationFn: ([guid, idGuidCourse]) => AxiosHelper.deleteMethod({
            urlWithoutBaseUrl: 'ancorr/api/course/delete/info',
            queryParams: {idGuidCourse, guid},
        }),
        onMutate: () => show(),
        onSettled: () => hide(),
        onSuccess: () => client.invalidateQueries({queryKey: [stateCourseKey]})
    })
    const {mutate: addPrice} = useMutation({
        mutationKey: [stateCourseKey],
        mutationFn: (id) => AxiosHelper.postMethod({
            urlWithoutBaseUrl: 'ancorr/api/course/add/price',
            queryParams: {idInfoCourse: id}
        }),
        onMutate: () => show(),
        onSettled: () => hide(),
        onSuccess: () => client.invalidateQueries({queryKey: [stateCourseKey]}),
    })

    return (
        <Accordion
            onChange={() => setIsOpened(!isOpened)}
            sx={{
                backgroundColor: '#eef2f4;'
            }}
        >
            <AccordionSummaryCourse
                id={memoInputCourse?.id + '-header'}
                aria-controls={`${memoInputCourse?.id}-content`}
            >
                <div className="input-course__fields">
                    <div className="input-course__fields-group">
                        {warehouses && warehouses.length > 1 && memoInputCourse &&
                            <DropdownDefault
                                options={getOptionsFromArrayEntities({
                                    entities: warehouses,
                                    accessorName: 'warehouse',
                                    valueCallback: item => item?.fullName,
                                    titleCallback: item => item?.fullName,
                                    idCallback: item => item?.guid
                                })}
                                currentValue={converterEntityToOptionOrReverse({
                                    arrayEntities: warehouses,
                                    endpoint: 'option',
                                    accessorName: 'warehouse',
                                    valueCallback: item => item?.fullName,
                                    titleCallback: item => item?.fullName,
                                    idCallback: item => item?.guid,
                                    searchPropertyForArray: memoInputCourse?.inputWarehouse?.id
                                })}
                                placeholder='Склад'
                                onChange={(newWarehouse) => {
                                    const warehouse = warehouses?.find(warehouse => warehouse.guid === newWarehouse.id)
                                    updateInputCourse({
                                        ...inputCourse,
                                        inputWarehouse: warehouse.fullName === '' ? null : warehouse
                                    })
                                }}
                            />
                        }
                    </div>
                    {isOpened &&
                        <div className="input-course__fields-group">
                            <IconButton
                                onClick={event => {
                                    event.stopPropagation();
                                    addPrice(memoInputCourse.id);
                                }}
                            >
                                <Add/>
                            </IconButton>
                            <IconButton
                                onClick={event => {
                                    event.stopPropagation();
                                    removeInputCourse([memoInputCourse.guid, memoInputCourse.guidCourseId])
                                }}
                            >
                                <Delete/>
                            </IconButton>
                        </div>
                    }
                </div>
            </AccordionSummaryCourse>
            <AccordionDetails>
                <div className="input-course__content">
                    {memoInputCourse.inputPriceCourse.map((inputPriceCourse, idx) => (
                        <InputPriceCourse
                            key={inputPriceCourse.id}
                            inputPriceCourse={inputPriceCourse}
                            stateCourseKey={stateCourseKey}
                            idx={idx}
                        />
                    ))}
                </div>
            </AccordionDetails>
        </Accordion>
    );
};

export default InputCourse;