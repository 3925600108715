import {useNavigate, useParams} from "react-router";
import {AxiosHelper} from "../../../../../hooks/useAxiosHelper";
import DetailWrapper from "../../../../../components/detailcard/wrapper/DetailWrapper";
import TabsBlock from "../../../../../components/tabsblock/TabsBlock";
import ButtonTab from "../../../../../components/UI/buttons/buttontab/ButtonTab";
import BackCrossIcon from "../../../../../components/UI/icons/BackCrossIcon";
import DetailBody from "../../../../../components/detailcard/body/DetailBody";
import FieldsBlockRenderer from "../../../../../modules/fieldblockrenderer/FieldsBlockRenderer";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import useAxiosErrorHandler from "../../../../../hooks/axioserrorhandler/useAxiosErrorHandler";

const RentOfferDetail = () => {
    const params = useParams();
    const id = Number(params['id'] || 0);
    const navigate = useNavigate()
    const client = useQueryClient()
    const handleErrorResponse = useAxiosErrorHandler()
    const {data: schema} = useQuery({
        queryKey: ['schema', 'offer'],
        queryFn: () => AxiosHelper.getMethod({
            urlWithoutBaseUrl: 'ancorr/api/helper/get/schema',
            queryParams: {
                destinationEntity: 'offerRent'
            },
        }),
        refetchOnWindowFocus: false,
        onError: (error) => handleErrorResponse(error)
    })
    const {data: currentEntity} = useQuery({
        queryKey: ['current-offerRent', id],
        queryFn: () => AxiosHelper.getMethod({
            urlWithoutBaseUrl: `ancorr/api/offer/rent/get/${id}`,
        }),
        onError: (error) => handleErrorResponse(error)
    })

    const {mutate: updateChangesEntity} = useMutation({
        mutationKey: ['current-offerRent', id],
        mutationFn: ({newItem}) => AxiosHelper.postMethod({
            urlWithoutBaseUrl: 'ancorr/api/offer/rent/update',
            data: newItem,
        }),
        onSuccess: (data, {setterCallback}) => {
            setterCallback(true)
            client.invalidateQueries({queryKey: ['current-offerRent', id]})
        },
        onError: (error, {setterCallback}) => {
            setterCallback(false)
            handleErrorResponse(error)
        },
        onMutate: ({newItem}) => client?.setQueryData(['current-offerRent', id], (oldItem) => newItem),
    })

    return (
        <DetailWrapper>
            <TabsBlock>
                <ButtonTab
                    icon={<BackCrossIcon />}
                    title={'Назад'}
                    onClick={() => navigate(-1)}
                />
            </TabsBlock>
            <DetailBody>
                <FieldsBlockRenderer
                    schema={schema}
                    model={currentEntity}
                    waitingSave={(newItem, setterCallback) => {
                        updateChangesEntity?.({newItem, setterCallback})
                    }}
                    dependencyEntity={[
                        {
                            method: 'ancorr/api/helper/get/users',
                            keySelector: item => item?.id,
                            titleSelector: item => item ? `${item?.lastName || 'Без фамилии'} ${item?.firstName || 'Без имени'} ` : 'Не заполнен',
                            valueSelector: item => item?.id,
                        },
                        {
                            method: 'ancorr/api/helper/get/clients',
                            keySelector: item => item?.id,
                            titleSelector: item => item ? item?.generalName : 'Не заполнен',
                            valueSelector: item => item?.id,
                        },
                        {
                            method: 'ancorr/api/helper/get/variation/rent',
                            keySelector: item => item?.id,
                            titleSelector: item => item ? `${item?.name} ${item?.project?.address}` : 'Не заполнен',
                            valueSelector: item => item?.id,
                        },
                    ]}
                    backendMode={'axios'}
                />
            </DetailBody>
        </DetailWrapper>
    );
};

export default RentOfferDetail;