import './ItemKpDetail.css'
import {ReactComponent as PenIcon} from "../../../../../../components/UI/icons/pen.svg";
import PropTypes from "prop-types";
import {useRef, useState} from "react";
import EntitiesSelector from "../../../../../../modules/entitiesselector/EntitiesSelector";
import {Button, Modal} from "react-bootstrap";

const ItemKpDetail = ({title, text, large, onChange, templatesValue, ...props}) => {
    const textareaRef = useRef(null);
    const [showModalSelectTemplate, setShowModalSelectTemplate] = useState(false)
    return (
        <div
            className={"item-kp-detail" + (large ? ' item-large' : '')}
            onClick={() => {
                textareaRef.current.focus();
            }}
        >
            <div
                className={'item-kp-detail__icon_container'}
                onClick={() => {
                    if(templatesValue && !text)
                        setShowModalSelectTemplate(true)
                }}
            >
                <PenIcon className='item-kp-detail__icon'/>
            </div>

            <div className="item-kp-detail__title">{title}</div>
            <textarea
                ref={textareaRef}
                className={'item-kp-detail__textarea'} value={text}
                onChange={e => onChange(e.target.value)}
                {...props}
            >
            </textarea>
            <Modal show={showModalSelectTemplate} onHide={() => setShowModalSelectTemplate(false)}>
                <Modal.Header><Modal.Title>Выбор</Modal.Title></Modal.Header>
                <Modal.Body>
                    <EntitiesSelector
                        entities={templatesValue}
                        nameSelector={item => item?.title}
                        keySelector={item => item?.id}
                        onClickItem={item => setShowModalSelectTemplate(false) || onChange(item?.item?.value || '')}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setShowModalSelectTemplate(false)} variant={"danger"}>Закрыть</Button>
                </Modal.Footer>
            </Modal>

        </div>
    );
};

ItemKpDetail.defaultProps = {
    large: false,
}

ItemKpDetail.propTypes = {
    large: PropTypes.bool,
}

export default ItemKpDetail;