import './RemainsComponentsModule.css';
import {Accordion} from "react-bootstrap";
import useRemainsStore from "../../store/remainsStore";
import RemainsLine from "../../components/remainsline/RemainsLine";

const RemainsComponentsModule = ({clickElement, isSelector, selectedIdsComponents}) => {
    const filteredRemains = useRemainsStore(state => state.remains);
    const type = 'component';

    return (
        <div className='remains-components'>
            <Accordion alwaysOpen className='remains-components__accordion'>
                {filteredRemains?.components?.map(equipment => (
                    <RemainsLine
                        data={equipment}
                        key={equipment.guid}
                        type={type}
                        clickElement={clickElement}
                        isSelector={isSelector}
                        isSelect={selectedIdsComponents?.some(x => x === equipment?.id)}
                    />
                ))}
            </Accordion>
        </div>
    );
};

export default RemainsComponentsModule;