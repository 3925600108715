import './TableKpPreview.css'
import TableBootstrap from "../../../../../../components/tableb/TableBootstrap";
import {getRubString} from "../../../../../../features/formatters/forrmaterrub";

const TableKpPreview = ({kp}) => {
    return (
        <div className='table-kp-preview'>
            <TableBootstrap
                schema={schema}
                items={kp.materials}
            />
        </div>
    );
};

const schema = [
    {
        title: 'Наименование',
        callbackItem: item => {
            const type = item.materialType;
            return item[type].name;
        }
    },
    {
        title: 'Кол-во',
        callbackItem: item => item?.quantity || '0'
    },
    {
        title: 'Ед. изм.',
        callbackItem: item => item?.unitMeasurement?.unitName || 'Не определено'
    },
    {
        title: 'Срок поставки',
        callbackItem: item => item?.supplyDate ? new Date(item?.supplyDate).toLocaleDateString() : 'Не определено'
    },
    {
        title: 'Цена за единицу',
        callbackItem: item => getRubString(item?.price || 0)
    },
    {
        title: 'Сумма',
        callbackItem: item => getRubString(item?.totalPriceIncludingPackaging || 0)
    },
]
export default TableKpPreview;