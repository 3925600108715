import {Button, Form, Modal, Stack} from "react-bootstrap";
import {forwardRef, useImperativeHandle, useState} from "react";
import useCompanyINNSearcher from "../../../../../hooks/clients/useCompanyINNSearcher";
import {Autocomplete, TextField} from "@mui/material";
import TextInput from "../../../../../components/UI/inputs/text/TextInput";
import {useMutation} from "@tanstack/react-query";
import {AxiosHelper} from "../../../../../hooks/useAxiosHelper";
import useLoader from "../../../../../hooks/useLoader";

const CreatingCompanyModule = forwardRef(({onChange}, ref) => {

    const [visibleModal, setVisibleModal] = useState(false)
    const {show, hide} = useLoader()
    const [value, setValue] = useState(null)
    const [inn] = useState(null)

    const {mutate: addCompany} = useMutation({
        mutationKey: ['company', 'modal', 'add'],
        mutationFn: ({companyDTO, inn}) => AxiosHelper.postMethod({
            urlWithoutBaseUrl: `ancorr/api/company/add`,
            data: {
                companyDTO,
                inn
            }
        }),
        onMutate: () => show(),
        onSettled: () => hide(),
        onSuccess: (data) => {
            setValue(data)
            onChange?.(data)
        }
    })

    const {optionsCompanies, inputCompanies, updateInputCompanies, onBlur: onBlurCompanies} = useCompanyINNSearcher()

    useImperativeHandle(ref, () => ({
        open: () => setVisibleModal(true),
        close: () => setVisibleModal(false),
    }), [])
    return (
        <Modal onHide={() => setVisibleModal(false)} show={visibleModal} size={"lg"}>
            <Modal.Header><Modal.Title>Создание компании</Modal.Title></Modal.Header>
            <Modal.Body>
                <Form>
                    <Stack className={'mb-3'}>
                        <TextInput
                            label={'Наименование'}
                            variant={'outlined'}
                            controlled
                            defaultValue={value?.shortCompanyName}
                            onChange={newValue => setValue({...value, shortCompanyName: newValue})}
                        />
                    </Stack>
                    <Stack className={'mb-3'}>
                        <Autocomplete
                            value={value}
                            onChange={(event, newValue) => {
                                setValue(newValue)
                            }}
                            onInputChange={(event, newInputValue) => {
                                updateInputCompanies(newInputValue)
                            }}
                            onBlur={() => onBlurCompanies()}
                            isOptionEqualToValue={(option, value) => option?.shortCompanyName === value?.shortCompanyName}
                            inputValue={inputCompanies}
                            options={optionsCompanies}
                            getOptionLabel={option => `${option?.shortCompanyName} ${option?.inn === 0 ?  '' : option?.inn}`}
                            sx={{ width: 400 }}
                            size="small"
                            filterOptions={(options) => {
                                return options
                            }}
                            renderInput={(params) => <TextField {...params} label={'ИНН'} />}
                        />
                    </Stack>

                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={"danger"} onClick={() => setVisibleModal(false)}>Закрыть</Button>,
                <Button
                    onClick={() => {
                        addCompany?.({companyDTO: value, inn})
                    }}
                >
                    Привязать
                </Button>
            </Modal.Footer>
        </Modal>
    );
});

export default CreatingCompanyModule;