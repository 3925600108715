import './TkpDetailN.css'
import {useEffect, useState} from "react";
import BX24 from "bx24-api";
import {
    getCoatingTypeFromSliceV2,
    updateBxInfo
} from "../features/TkpHandlers";
import useTkp from "../hooks/useTkp";
import UpdateInformationBx24 from "../components/updateinformationbx24/UpdateInformationBX24";
import {Button, Form, Offcanvas} from "react-bootstrap";
import OffcanvasTkp from "../components/offcanvas/OffcanvasTkp";
import {getCurrencyString} from "../../../features/formatters/forrmaterrub";
import BackCrossIcon from "../../../components/UI/icons/BackCrossIcon";
import TableBootstrap from "../../../components/tableb/TableBootstrap";
import {AxiosHelper} from "../../../hooks/useAxiosHelper";
import useMessageHub from "../../../hooks/useMessageHub";
import InheritInput from "../../../components/UI/inputs/inheritinput/InheritInput";
import RemainsNew from "../../remainsnew/RemainsNew";
import SortableWrapper from "../../../modules/movementdndarray/SortableWrapper";
import TkpDetailSystem from "../components/system/TkpDetailSystem";
import {ReactComponent as MenuIcon} from "../../../components/UI/icons/three-dots-vertical.svg";
import useSubmitModalStore from "../../../components/UI/modals/submitmodal/store/submitModalStore";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {useParams} from "react-router";
import {useAuth} from "../../../features/authprovider/AuthProvider";
import useModal from "../../../hooks/usemodal/useModal";


const TkpDetailN = () => {

    const addMessage = useMessageHub()
    const [bxInfoIsUpdate, setBxInfoIsUpdate] = useState(false)
    const [showOffcanvas, setShowOffcanvas] = useState(false)
    const addSubmitModalItem = useSubmitModalStore.getState().addItem;
    const params = useParams()
    const id = Number(params['id'] || 0)
    const [modal, renderModal, closeModal] = useModal()

    const {userInfo} = useAuth()

    const client = useQueryClient()
    const {data: currentTkp} = useQuery({
        queryKey: ['current-tkp', id],
        queryFn: () => AxiosHelper.getMethod({
            urlWithoutBaseUrl: `ancorr/api/tkp/v2/get/${id}`,
            queryParams: {
                token: userInfo?.accessToken
            },
        })
    })

    useEffect(() => {
        if (window.name.toLowerCase().includes('bitrix')) {
            BX24.resizeWindow(document.body.clientWidth, 1000).then(() => {

            })
        }
    }, [])

    const {
        changeSystemPosition,
        changeSlicePosition,
        handleBxDealProfit,
        addSliceTkp,
        addSystemTkp,
        changeTkpEx,
        changeSliceTkp,
        changeSystemTkp,
        deleteSliceTkp,
        deleteSystemTkp,
    } = useTkp();

    useEffect(() => {
        if (!bxInfoIsUpdate && currentTkp?.id) {
            updateBxInfo().then(res => {
                setTimeout(() => {
                    renderModal({
                        children:
                            <UpdateInformationBx24
                                newBxInfo={res}
                                currentTkp={currentTkp}
                            />,
                        buttons: [
                            <Button variant={"danger"} onClick={() => closeModal()}>Закрыть</Button>,
                            <Button onClick={() => {
                                closeModal()
                                changeTkpEx({...currentTkp, ...res})
                            }}>Применить</Button>
                        ]
                    })
                }, 5000)

                if (!res?.dealId) {
                    addMessage('Внимание вы находитесь вне зоны видимости сделки, данное ТКП не будет закрепленно за сделкой')
                } else if (res?.dealId && currentTkp?.dealId !== res?.dealId) {
                    addMessage('Внимание, данные из битрикса строго перепривязываются при открытии ТКП из сделки, обновление данных успешно произведенно, ТКП закреплено за вами')
                }
            })
            setBxInfoIsUpdate(true)
        }
    }, [currentTkp, bxInfoIsUpdate])

    const schemaSlice = [
        {
            title: 'Материал',
            callbackItem: item => (
                <div
                    className={'tkp-slice__name'}
                    onClick={() => {
                        renderModal({
                            children:
                                <RemainsNew
                                    isSelector
                                    clickElement={e => {
                                        changeSliceTkp(item?.tkpSystemId, {
                                            ...item,
                                            material: e?.element
                                        }, false)
                                        closeModal()
                                    }}
                                />,
                            buttons: [
                                <Button variant={"danger"} onClick={() => {
                                    closeModal()
                                }}>Закрыть</Button>
                            ],
                            fullscreen: true
                        })
                    }}
                >
                    {item?.material?.name || 'Не выбран'}
                </div>
            ),
        },
        {
            title: '% от S',
            callbackItem: item => <InheritInput
                defaultValue={item?.percentFromSquare}
                useDebounce
                isNumber
                suffics={'%'}
                onChange={newValue => changeSliceTkp(item?.tkpSystemId, {
                    ...item,
                    percentFromSquare: newValue
                }, true)}
            />
        },
        {
            title: 'Тип покрытия',
            callbackItem: item => getCoatingTypeFromSliceV2(item, 'material'),
        },
        {
            title: 'Полос. покрытие',
            callbackItem: item => (
                <Form.Check
                    type={'switch'}
                    checked={item?.isBandpass}
                    onChange={e => changeSliceTkp(item?.tkpSystemId, {...item, isBandpass: !item?.isBandpass}, false)}
                />
            ),
        },
        {
            title: 'Фактор потерь',
            callbackItem: item => <InheritInput
                defaultValue={item?.lossFactor}
                useDebounce
                isNumber
                suffics={'%'}
                onChange={newValue => changeSliceTkp(item?.tkpSystemId, {...item, lossFactor: newValue}, true)}
            />,
        },
        {
            title: 'Толщина ТСП',
            callbackItem: item => <InheritInput
                defaultValue={item?.thicknessTsp}
                useDebounce
                controlled
                isNumber
                suffics={' мкм'}
                onChange={newValue => changeSliceTkp(item?.tkpSystemId, {...item, thicknessTsp: newValue}, true)}
            />,
        },
        {
            title: 'Кол-во',
            callbackItem: item => `${item?.quantity?.toFixed(1)} л`,
        },
        {
            title: 'Цена за литр',
            callbackItem: item => getCurrencyString(item?.priceForLiter || 0, currentTkp?.currencyForHandle?.currencyCode),
        },
        {
          title: 'Цена за (м²)',
            callbackItem: item => getCurrencyString(item?.priceForMeterSquare || 0, currentTkp?.currencyForHandle?.currencyCode)
        },
        {
            title: 'Итого за материал',
            callbackItem: item => getCurrencyString(item?.totalPrice || 0, currentTkp?.currencyForHandle?.currencyCode),
        },
    ]

    return (
        <div className={'tkp__container_n'}>
            <div className={'tkp-header__container'}>
                <div className={'tkp-header__info'}>
                    <div className={'tkp-header__preview'}>
                        <div className={'tkp-header_title'}>Технико коммерческое предложение
                            - {currentTkp?.title || 'Не заполнено'}</div>
                    </div>
                    <div className={'tkp-header__info_deal'}>
                        <span className={'tkp-header__title_preview'}>Сделка -</span>
                        <span
                            className={'tkp-header__title_name'}>{currentTkp?.dealName || 'Не удалось получить сделку'}</span>
                    </div>
                    <Button className={'button-summary'} variant={'primary'} onClick={() => setShowOffcanvas(true)}><MenuIcon /></Button>
                </div>
            </div>
            <div className={'tkp-systems__wrapper'}>
                <SortableWrapper
                    localItems={currentTkp?.systems || []}
                    keySelector={item => item?.id}
                    positionSelector={item => item?.position}
                    setLocalItems={newArray => client.invalidateQueries({queryKey: 'current-tkp'})}
                    onChangePosition={item => changeSystemPosition(item?.oldItemKey, item?.newItemKey)}
                >
                    {currentTkp?.systems?.map((system, indexSystem) => (
                        <TkpDetailSystem
                            key={system?.guid}
                            system={system}
                            changeSystemTkp={changeSystemTkp}
                            indexSystem={indexSystem}
                            currencyCode={currentTkp?.currencyForHandle?.currencyCode}
                        >
                            <div className={'tkp-slices__wrapper'}>
                                <TableBootstrap
                                    popoverActions={[
                                        {
                                            icon: <BackCrossIcon classNames={'cl-light-purple'}/>,
                                            title: 'Удалить',
                                            onClick: item => addSubmitModalItem(() => deleteSliceTkp(system?.id, item?.guid), 'Удалить слой?')
                                        },
                                    ]}
                                    popoverCaption={'Действия'}
                                    items={system?.slices || []}
                                    schema={schemaSlice}

                                    draggable
                                    keySelector={item => item?.id}
                                    positionSelector={item => item?.position}
                                    onChangePosition={item => changeSlicePosition(item?.oldItemKey, item?.newItemKey)}
                                />
                            </div>
                            <div className={'tkp-system__footer'}>
                                <Button variant={"outline-primary"} onClick={() => addSliceTkp(system?.id)}>Добавить
                                    слой</Button>
                                <Button variant={"outline-danger"}
                                        onClick={() => {
                                            addSubmitModalItem(() => deleteSystemTkp(system?.guid), 'Удалить систему?')
                                        }}>Удалить
                                    систему</Button>
                            </div>
                        </TkpDetailSystem>
                    ))}
                </SortableWrapper>
            </div>
            <div className={'tkp-footer__wrapper'}>
                <div className={'tkp-footer__container'}>
                    <div className={'tkp-footer__btns'}>
                        <Button variant={"primary"} className={'w-100'} onClick={() => addSystemTkp()}>ДОБАВИТЬ
                            СИСТЕМУ</Button>
                    </div>
                    <div className={'tkp-footer__btns'}>
                        <Button className={'w-75'} variant={"primary"} onClick={() => {
                            handleBxDealProfit()
                        }}>СОХРАНИТЬ ТКП</Button>
                        <Button className={'w-25'} variant={"danger"} onClick={() => handleBxDealProfit()}>ЗАКРЫТЬ</Button>
                    </div>
                </div>
            </div>
            <Offcanvas className={'offcanvas-tkp__wrapper'} show={showOffcanvas}
                       onHide={() => setShowOffcanvas(false)} placement={'end'}>
                <Offcanvas.Header>
                    <Offcanvas.Title>Слайд</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <OffcanvasTkp/>
                </Offcanvas.Body>
            </Offcanvas>
            {modal}
        </div>
    );
};


export default TkpDetailN;