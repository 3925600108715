import {Carousel, Image, Stack} from "react-bootstrap";
import {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import './PhotoEntityRenderer.css'
import UseFileUploaderToBackend from "../../../hooks/useFileUploaderToBackend";
import {ReactComponent as TrashIcon} from "../../../components/UI/icons/trash-icon.svg";
import {ReactComponent as EditIcon} from "../../../components/UI/icons/edit-icon.svg";
import {generateGuid} from "../../../features/guidgenerator/GuidGenerator";
import findFilesInEvent from "../helpers/findFilesInEvent";
import fileValidator from "../helpers/fileValidator";


const PhotoEntityRenderer = ({
                                 entity,
                                 uniqName = '',
                                 typeAccept = '',
                                 isMultiple = false,
                                 isEditable = false,
                                 sx = {width: 400, height: 400},
                                 onChange
}) => {

    const [fileEntity, setFileEntity] = useState(null)

    const {sendFile, sendFiles, editFile} = UseFileUploaderToBackend();

    useEffect(() => {
        if(!fileEntity && entity)
            setFileEntity(entity)
    }, [entity]);

    const handleBlobToEntity = (files, isEdit, dbFileId) => {

        if(isEdit){
            editFile(files, dbFileId, uniqName, false, (data) => {
                if(isMultiple){
                    let newArray = [...fileEntity?.filter(x => x?.id !== dbFileId), data]
                    setFileEntity(newArray)
                    onChange?.(newArray)
                }
                else{
                    setFileEntity(data)
                    onChange?.(data)
                }
            })

            return;
        }

        if(files?.length > 0){

            sendFiles(files, uniqName, true, (data) => {
                let newArray = [...fileEntity?.filter(x => !data?.some(w => w?.id === x?.id)), ...data]
                setFileEntity(newArray)
                onChange?.(newArray)
            })
        }
        else if(files){
            sendFile(files, uniqName, true, (data) => {
                setFileEntity(data)
                onChange?.(data)
            })
        }
    }

    const deleteHandle = (entity) => {
        if(isMultiple){
            let newArray = fileEntity?.filter(x => x?.id !== entity?.id)
            setFileEntity(newArray)
            onChange?.(newArray)
        }
        else{
            setFileEntity(null)
            onChange?.(null)
        }
    }

    if(isMultiple)
        return (
            <Stack direction={'horizontal'}>
                <Carousel touch className={'text-black overflow-hidden'} style={fileEntity?.length > 0 ? sx : {}}>
                    {fileEntity?.length > 0
                        ?
                            fileEntity?.map(file => (
                                <Carousel.Item key={file?.guid}>
                                    <Carousel.Caption>

                                    </Carousel.Caption>
                                        <PhotoRender
                                            entity={file}
                                            sx={sx}
                                            isMultiple={isMultiple}
                                            isEditable={isEditable}
                                            typeAccept={typeAccept}
                                            onChange={handleBlobToEntity}
                                            onDelete={deleteHandle}
                                        />
                                    <Carousel.Caption>
                                        <h3>{file?.name}</h3>
                                    </Carousel.Caption>
                                </Carousel.Item>
                            ))
                        :
                            <Carousel.Item>
                                <PhotoRender
                                    entity={null}
                                    sx={sx}
                                    isMultiple={isMultiple}
                                    isEditable={isEditable}
                                    typeAccept={typeAccept}
                                    onChange={handleBlobToEntity}
                                />
                            </Carousel.Item>
                    }
                </Carousel>
                {isEditable && fileEntity?.length > 0 &&
                    <div
                        className={'carousel__add_container rounded-end-3 opacity-50 d-flex justify-content-center align-items-center'}
                        style={fileEntity?.length > 0 ? {width: 200, height: sx.height} : {}}
                    >
                        <PhotoRender
                            entity={null}
                            sx={sx}
                            isMultiple={isMultiple}
                            isEditable={isEditable}
                            typeAccept={typeAccept}
                            onChange={handleBlobToEntity}
                        />
                    </div>
                }

            </Stack>
        )

    /*if(!isMultiple)
        return (
            <Stack>
                {fileEntity?.urlPath
                    ?
                        <Image style={sx} className={'link-underline-opacity-75-hover'} src={fileEntity?.urlPath}/>
                    :
                        <Stack className={'p-4'}>
                            <Card className={''}>
                                <CardHeader>

                                </CardHeader>
                            </Card>
                        </Stack>
                }
            </Stack>
        )*/
    if(!isMultiple)
        return (
            <PhotoRender
                entity={fileEntity}
                sx={sx}
                isMultiple={isMultiple}
                isEditable={isEditable}
                typeAccept={typeAccept}
                onChange={handleBlobToEntity}
                onDelete={deleteHandle}
            />
        )
};

export default PhotoEntityRenderer;


const PhotoRender = forwardRef(({entity, sx = {width: 400, height: 400}, isMultiple = false, isEditable = false, typeAccept = '', onChange, onDelete}, ref) => {
    const guid = entity ? entity?.guid : generateGuid()
    const loadFile = (event, isEdit) => {
        let files = findFilesInEvent(event);
        if (!isMultiple || isEdit) {
            files = files[0];
        }
        if (fileValidator(files, typeAccept)) {
            onChange?.(files, isEdit, entity?.id)
        }
        else {
            const str = isMultiple && files.length > 1 ? 'файлов' : 'файла'
            alert(`Неверный формат ${str}!`)
        }
    }

    useImperativeHandle(ref, () => ({
        loadFile: loadFile
    }), [loadFile])

    if(entity?.urlPath)
        return (
            <Stack className={'file-renderer__image_stack'}>
                <Image style={sx} className={'file-renderer__image_endpoint'} src={entity?.urlPath}/>
                {isEditable &&
                    <Stack direction={'horizontal'} className={'file-renderer__image_mask'} style={sx}>
                        <TrashIcon width={30} height={30} className={'pointer'} onClick={() => onDelete?.(entity)}/>
                        <label htmlFor={guid}>
                            <EditIcon width={30} height={30} className={'pointer'}/>
                        </label>
                        <input multiple={isMultiple} id={guid} type="file" className="d-none" onChange={e => loadFile(e, true)}/>
                    </Stack>
                }
            </Stack>
        )

    if(isEditable)
        return (
            <Stack
                className={'file-renderer__dropzone align-items-center'}
                onDragOver={e => e.preventDefault()}
                onDrop={e => {
                    e.preventDefault()
                    if(isEditable)
                        loadFile(e)
                }}
            >
                <Stack className="text-center">
                    <p className={'file-renderer__title p-0 m-0'}>Перетащите файл в эту область</p>
                </Stack>
                <Stack className="w-100 align-items-center">
                    <Image src="http://100dayscss.com/codepen/upload.svg" className={'file-renderer__image'} />
                    <input multiple={isMultiple} id={guid} type="file" className="d-none" onChange={e => {
                        if(isEditable)
                            loadFile(e)
                    }}/>
                </Stack>
                <label htmlFor={guid} className="btn btn-primary" >Выбрать файл</label>
            </Stack>
        )
})