import './RemainsNomenclaturesModule.css'
import {Accordion} from "react-bootstrap";
import useRemainsStore from "../../store/remainsStore";
import RemainsLine from "../../components/remainsline/RemainsLine";

const RemainsNomenclaturesModule = ({clickElement, isSelector, selectedIdsEquipments, USD}) => {
    const remains = useRemainsStore(state => state.remains);
    const type = 'equipment';

    return (
        <div className='remains-equipment'>
            <Accordion alwaysOpen className='remains-equipment__accordion'>
                {remains?.equipments?.map(equipment => (
                    <RemainsLine
                        USD={USD}
                        data={equipment}
                        key={equipment.guid}
                        type={type}
                        clickElement={clickElement}
                        isSelector={isSelector}
                        isSelect={selectedIdsEquipments?.some(x => x === equipment?.id)}
                    />
                ))}
            </Accordion>
        </div>
    );
};

export default RemainsNomenclaturesModule;