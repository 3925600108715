import {useEffect, useState} from 'react';
import './GuidsComponentsDetail.css'
import {useNavigate, useParams} from "react-router";
import {BaseUrl} from "../../../../../features/databaseinfo/BaseUrl";
import {createSignalRContext} from "react-signalr/signalr";
import FieldsBlockRenderer from "../../../../../modules/fieldblockrenderer/FieldsBlockRenderer";
import TabsBlock from "../../../../../components/tabsblock/TabsBlock";
import ButtonTab from "../../../../../components/UI/buttons/buttontab/ButtonTab";
import BackCrossIcon from "../../../../../components/UI/icons/BackCrossIcon";
import DetailBody from "../../../../../components/detailcard/body/DetailBody";
import DetailWrapper from "../../../../../components/detailcard/wrapper/DetailWrapper";
import useCheckConnectionToHub from "../../../../../hooks/useCheckConnectionToHub";
import useSignalRFunk from "../../../../../hooks/useSignalRFunk";
import useChangeItemSignalR from "../../../../../hooks/useChangeItemSignalR";
import ListIcon from "../../../../../components/UI/icons/ListIcon";
import {Button, Modal} from "react-bootstrap";
import TableBootstrap from "../../../../../components/tableb/TableBootstrap";
import EditIcon from "../../../../../components/UI/icons/EditIcon";
import ReservedList from "../modals/reservedlist/ReservedList";
import SupplyList from "../modals/supplylist/SupplyList";
import ShipmentList from "../modals/shipmentlist/ShipmentList";
import {useAuth} from "../../../../../features/authprovider/AuthProvider";
const SignalRContext = createSignalRContext()


const GuidsComponentsDetail = () => {
    const params = useParams()
    const [currentComponent, setCurrentComponent] = useState(null)
    const [schema, setSchema] = useState([])
    const {userInfo} = useAuth()
    const navigate = useNavigate()


    useEffect(() => {
        document.title = "ANCORR: Компонент"
    }, [])

    const {
        checkConnection,
        onReconnect,
        onConnect,
        onError
    } = useCheckConnectionToHub({
        SignalRContext: SignalRContext,
        initialFunk: () => {
            updateSchema()
            updateCurrentComponent()
        },
        onReconnectCallback: () => {
            updateSchema()
            updateCurrentComponent()
        }
    })
    const {changeItem} = useChangeItemSignalR({
        SignalRContext,
        checkConnection,
        method: 'ChangeComponent',
        delay: 1000,
        callbackChangeItemFunk: newItem => setCurrentComponent(newItem)

    })
    const {signalRFunk} = useSignalRFunk({SignalRContext, checkConnection})

    const updateCurrentComponent = () => signalRFunk('GetCurrentDetailComponent', [Number(params['id'])], res => {
        setCurrentComponent(res)
        document.title = `ANCORR: ${res?.name || 'Компонент'}`
    })
    const updateSchema = () => signalRFunk('GetSchemaForRender', ['component'], res => {
        setSchema(res)
    })

    SignalRContext.useSignalREffect("UpdateRemains", () => {
        updateCurrentComponent()
    })

    const popoverActions = [
        {
            icon: <EditIcon classNames={'cl-light-purple'}/>,
            title: 'Тест',
            onClick: item => {}
        },
    ]

    const [showModalRemainsList, setShowModalRemainsList] = useState(false)
    const [componentForRemainsList, setComponentForRemainsList] = useState(null)

    const [showModalSupplyList, setShowModalSupplyList] = useState(false)
    const [componentForSupplyList, setComponentForSupplyList] = useState(null)

    const [showModalReservedList, setShowModalReservedList] = useState(false)
    const [componentForReservedList, setComponentForReservedList] = useState(null)

    const [showModalShipmentList, setShowModalShipmentList] = useState(false)
    const [componentForShipmentList, setComponentForShipmentList] = useState(null)

    return (
        <SignalRContext.Provider
            withCredentials={false}
            connectEnabled={true}
            url={`${BaseUrl}/ancorr/api/componentdetailhub/?token=${userInfo?.accessToken || ''}`}
            accessTokenFactory={() => userInfo?.accessToken || ''}
            onReconnect={onReconnect}
            onOpen={onConnect}
            onError={onError}
        >
            <DetailWrapper>
                <TabsBlock>
                    <ButtonTab
                        icon={<ListIcon classNames={'icon-extension'}/>}
                        title={'Информация'}
                        onClick={() => {
                            /*setShowModalShipmentList(false)
                            setShowModalRemainsList(false)
                            setShowModalSupplyList(false)
                            setShowModalReservedList(false)
                            setComponentForShipmentList(null)
                            setComponentForRemainsList(null)
                            setComponentForSupplyList(null)
                            setComponentForReservedList(null)*/
                        }}
                    />
                    <ButtonTab
                        icon={<ListIcon classNames={'icon-extension'}/>}
                        title={'Остатки'}
                        onClick={() => { setShowModalRemainsList(true); setComponentForRemainsList(currentComponent);}}
                    />
                    <ButtonTab
                        icon={<ListIcon classNames={'icon-extension'}/>}
                        title={'Поставки'}
                        onClick={() => { setShowModalSupplyList(true); setComponentForSupplyList(currentComponent);}}
                    />
                    <ButtonTab
                        icon={<ListIcon classNames={'icon-extension'}/>}
                        title={'Резервирование'}
                        onClick={() => { setShowModalReservedList(true); setComponentForReservedList(currentComponent);} }
                    />
                    <ButtonTab
                        icon={<ListIcon classNames={'icon-extension'}/>}
                        title={'Отгрузки'}
                        onClick={() => { setShowModalShipmentList(true); setComponentForShipmentList(currentComponent);}}
                    />
                    <ButtonTab
                        icon={<BackCrossIcon classNames={'icon-extension'}/>}
                        title={'Назад к списку'}
                        onClick={() => navigate(-1)}
                    />
                </TabsBlock>
                <DetailBody>
                    <div className={'flex left gap-4'}>
                        <FieldsBlockRenderer
                            schema={schema}
                            model={currentComponent}
                            signalRFunk={signalRFunk}
                            dependencyEntity={[
                                'GetAllCurrencies',
                                'GetAllPackaging',
                                'GetAllBrands',
                            ]}
                            onSave={newItem => {
                                changeItem(newItem)
                            }}
                        />
                        <FieldsBlockRenderer
                            schema={schemaRemains}
                            model={currentComponent}
                            onSave={newItem => {
                                changeItem(newItem)
                            }}
                            isEditable={false}
                        />
                    </div>
                </DetailBody>
            </DetailWrapper>
            <Modal fullscreen={true} show={showModalRemainsList} onHide={() => {
                setShowModalRemainsList(false)
                setComponentForRemainsList(null)
            }}>
                <Modal.Header>
                    <Modal.Title>Остатки</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <TableBootstrap
                        items={currentComponent?.remains || []}
                        popoverActions={popoverActions}
                        schema={tableSchemaRemains}
                        popoverCaption={'Действия'}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"primary"} onClick={() => {
                        setShowModalRemainsList(false)
                        setComponentForRemainsList(null)
                    }}>Закрыть</Button>
                </Modal.Footer>
            </Modal>
            <Modal fullscreen={true} show={showModalSupplyList} onHide={() => {
                setShowModalSupplyList(false)
                setComponentForSupplyList(null)
            }}>
                <Modal.Header>

                </Modal.Header>
                <Modal.Body>
                    <SupplyList
                        component={componentForSupplyList}
                        updateRemainsCallback={updateCurrentComponent}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"primary"} onClick={() => {
                        setShowModalSupplyList(false)
                        setComponentForSupplyList(null)
                    }}>Закрыть</Button>
                </Modal.Footer>
            </Modal>
            <Modal fullscreen={true} show={showModalReservedList} onHide={() => {
                setShowModalReservedList(false)
                setComponentForReservedList(null)
            }}>
                <Modal.Header>

                </Modal.Header>
                <Modal.Body>
                    <ReservedList
                        component={componentForReservedList}
                        updateRemainsCallback={updateCurrentComponent}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"primary"} onClick={() => {
                        setShowModalReservedList(false)
                        setComponentForReservedList(null)
                    }}>Закрыть</Button>
                </Modal.Footer>
            </Modal>
            <Modal fullscreen={true} show={showModalShipmentList} onHide={() => {
                setShowModalShipmentList(false)
                setComponentForShipmentList(null)
            }}>
                <Modal.Header>
                </Modal.Header>
                <Modal.Body>
                    <ShipmentList
                        component={componentForShipmentList}
                        updateRemainsCallback={updateCurrentComponent}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"primary"} onClick={() => {
                        setShowModalShipmentList(false)
                        setComponentForShipmentList(null)
                    }}>Закрыть</Button>
                </Modal.Footer>
            </Modal>
        </SignalRContext.Provider>
    );
};

const schemaRemains = [
    {
        blockTitle: 'Остатки',
        fields: [
            {
                type: 'label',
                accessor: 'available',
                label: 'Всего доступно',
            },
            {
                type: 'label',
                accessor: 'availableWithSupply',
                label: 'В наличии с поставками',
            },
            {
                type: 'label',
                accessor: 'inStock',
                label: 'В наличии',
            },
            {
                type: 'label',
                accessor: 'reserved',
                label: 'В резерве',
            },
            {
                type: 'label',
                accessor: 'shipment',
                label: 'К отгрузке',
            },
            {
                type: 'label',
                accessor: 'supply',
                label: 'Поступит',
            },
            {
                type: 'label',
                accessor: 'nearestSupplyDate',
                label: 'Ближайшая дата доставки',
            },
        ],
    },
]
const tableSchemaRemains = [
    {
        title: 'ID',
        callbackItem: item => item?.id,
    },
    {
        title: 'Склад',
        callbackItem: item => item?.warehouse?.fullName,
    },
    {
        title: 'Доступно',
        callbackItem: item => `${item?.available || 0}`,
    },
    {
        title: 'В наличии с поставками',
        callbackItem: item => `${item?.availableWithSupply || 0}`,
    },
    {
        title: 'В наличии',
        callbackItem: item => `${item?.inStock || 0}`,
    },
    {
        title: 'В резерве',
        callbackItem: item => `${item?.reserved || 0}`,
    },
    {
        title: 'Поступит',
        callbackItem: item => `${item?.supply || 0}`,
    },
    {
        title: 'Ближайшая дата поставки',
        callbackItem: item => item?.nearestSupplyDate,
    },
]

export default GuidsComponentsDetail;