import {useRef} from "react";
import './ClearBtn.css'


const ClearBtn = ({onClick, isActive}) => {
    const refClearBtn = useRef(null);

    return (
        <div ref={refClearBtn}>
            <button
                className={`clear-btn${isActive ?
                    ' clear-btn_active'
                    : ''}`}
                onClick={(e) => {
                    e.target = refClearBtn.current;
                    onClick(e);
                }}
            >
                Сбросить
            </button>
        </div>
    );
};

export default ClearBtn;