import './Login.css'
import {Nav, NavItem, Image} from "react-bootstrap";
import {useMultiStepComponent} from "../../../hooks/usemultistepcomponent/UseMultiStepComponents";
import Register from "../register/Register";
import LoginForm from "../loginform/LoginForm";
import {useState} from "react";

const Login = () => {

    const [isBadCredentials, setIsBadCredentials] = useState(false)

    const {step, titleStep, goTO} = useMultiStepComponent([
        {
            titleStep: 'login',
            component: <LoginForm
                setIsBadCredentials={setIsBadCredentials}
                isBadCredentials={isBadCredentials}
            />
        },
        {
            titleStep: 'register',
            component: <Register/>
        },
    ])

    return (
        <div  className={'login__wrapper'}>
            <div className="login__sidebar">
                <h1 className={'login__sidebar_title'}>Мой клиент</h1>
                <h3 className={'login__sidebar_subtitle'}>Управление бизнес-процессами</h3>
            </div>
            <div   className={isBadCredentials ? 'login__container login__container_red' : 'login__container'}>
                <div  className={'login__header'}>
                    <Image src={"https://it.ancorr.ru/photo/img/logo2.png"} rounded/>
                    <Nav className={'login__tabs p-1'} variant={'tabs'}>
                        <NavItem
                            className={`login__tab ${titleStep === 'login' ? 'login__tab_active' : ''}`}
                            onClick={() => goTO(0)}
                        >
                            Войти
                        </NavItem>
                        <NavItem
                            className={`login__tab ${titleStep === 'register' ? 'login__tab_active' : ''}`}
                            onClick={() => goTO(1)}
                        >
                            Запрос на регистрацию
                        </NavItem>
                    </Nav>
                </div>
                {step}
            </div>
        </div>
    );
};

export default Login;