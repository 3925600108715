import useLoader from "./useLoader";
import {useMutation} from "@tanstack/react-query";
import {AxiosHelper} from "./useAxiosHelper";
import useAxiosErrorHandler from "./axioserrorhandler/useAxiosErrorHandler";

const UseFileUploaderToBackend = () => {
    const {show, hide} = useLoader()
    const handleErrorResponse = useAxiosErrorHandler()

    const {mutate: addFile} = useMutation({
        mutationKey: ['component', 'photo', 'uploader'],
        mutationFn: ({uniqName, data}) => AxiosHelper.postFiles({
            urlWithoutBaseUrl: `ancorr/api/files/add?uniqName=${uniqName}`,
            data: data

        }),
        onSuccess: (data, {successCallback}) => successCallback?.(data),
        onError: (error) => handleErrorResponse(error),
        onMutate: () => show(),
        onSettled: () => hide()
    })
    const {mutate: editFileBackend} = useMutation({
        mutationKey: ['component', 'photo', 'uploader'],
        mutationFn: ({uniqName, dbFileId, data}) => AxiosHelper.postFiles({
            urlWithoutBaseUrl: `ancorr/api/files/edit?uniqName=${uniqName}&dbFileId=${dbFileId}`,
            data: data

        }),
        onSuccess: (data, {successCallback}) => successCallback?.(data),
        onError: (error) => handleErrorResponse(error),
        onMutate: () => show(),
        onSettled: () => hide()
    })

    const {mutate: addFiles} = useMutation({
        mutationKey: ['component', 'photo', 'uploader'],
        mutationFn: ({uniqName, data}) => AxiosHelper.postFiles({
            urlWithoutBaseUrl: `ancorr/api/files/addRange?uniqName=${uniqName}`,
            data: data
        }),
        onSuccess: (data, {successCallback}) => successCallback?.(data),
        onError: (error) => handleErrorResponse(error),
        onMutate: () => show(),
        onSettled: () => hide()
    })

    const editFile = (newFile, dbFileId,  uniqName = '', useLoader = false, successCallback) => {
        const fd = new FormData();
        fd.append("file", newFile)

        editFileBackend({uniqName, dbFileId, data: fd, successCallback})
    }
    const sendFile = (newFile, uniqName = '', useLoader = false, successCallback) => {
        const fd = new FormData();
        fd.append("file", newFile)

        addFile({uniqName, data: fd, successCallback})
    }
    const sendFiles = (newFiles, uniqName = '', useLoader = false, successCallback) => {
        let fd = new FormData();

        Array.from(newFiles)?.forEach(file => fd.append("listFiles", file))

        addFiles({uniqName, data: fd, successCallback})
    }
    return {
        sendFile,
        sendFiles,
        editFile
    };
};

export default UseFileUploaderToBackend;