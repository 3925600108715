import {Text, View} from "@react-pdf/renderer";
import styles from "../constants";


const KpPdfConditions = ({kp}) => {

    const isHaveConditions = kp?.paymentTerms || kp?.deliveryAddress || kp?.deliveryMethod ||
        kp?.shippingTime || kp?.supplyTime || kp?.supplyWarehouse || kp?.supplyWarehouse || kp?.otherTerms || false;

    return (
        <View>
            {isHaveConditions && <View style={styles.conditions}>
                <Text style={{fontFamily: 'Montserrat-Italic'}}>Предлагаем Вам ознакомиться с остальными условиями
                    нашего предложения:</Text>
                {kp?.paymentTerms &&
                    <Text>Условия оплаты: {kp.paymentTerms}</Text>
                }
                {kp?.deliveryAddress &&
                    <Text>Адрес доставки: {kp.deliveryAddress}</Text>
                }
                {kp?.deliveryMethod &&
                    <Text>Способ доставки: {kp.deliveryMethod}</Text>
                }
                {kp?.shippingTime &&
                    <Text>Срок отгрузки: {kp.shippingTime}</Text>
                }
                {kp?.supplyTime &&
                    <Text>Срок доставки: {kp.supplyTime}</Text>
                }
                {kp?.supplyWarehouse &&
                    <Text>Склад поставщика: {kp.supplyWarehouse}</Text>
                }
                {kp?.otherTerms &&
                    <Text>Иные условия: {kp.otherTerms}</Text>
                }
            </View>}
        </View>
    );
};

export default KpPdfConditions;