import React from 'react';
import './TkpSummaryMaterial.css';

const TkpSummaryMaterial = ({materialSlice, sliceNumber, classSummaryMaterial}) => {
    return (
        <div className={'tkp-symmary-material__container'}>
            {sliceNumber && <label
                className={`tkp-symmary-label__material ${classSummaryMaterial ? classSummaryMaterial : ''}`}>{sliceNumber}
            </label>}
            <label
                title={materialSlice?.equipment?.name}
                className={`tkp-summary-material__container_label ${classSummaryMaterial ? classSummaryMaterial : ''}`}
            >
                {materialSlice?.equipment?.name}
            </label>

        </div>
    );
};

export default TkpSummaryMaterial;