import {useEffect, useState} from "react";

const UseControlledValue = ({defaultValue = null, controlled = false, initialStateValue = null}) => {

    const [value, setValue] = useState((defaultValue || initialStateValue))

    useEffect(() => {
        if(controlled && defaultValue)
            setValue(defaultValue)
    }, [defaultValue, controlled]);

    return [value, setValue];
};

export default UseControlledValue;