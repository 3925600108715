import styles from "../../constants";
import KpPdfEquipmentItem from "../KpPdfEquipmentItem";
import KpPdfComponentItem from "../KpPdfComponentItem";
import {View} from "@react-pdf/renderer";

const KpPdfTableBody = ({materials, kp}) => {
    return (
        <View style={styles.items} wrap>
            {
                materials.map((material, index) => (
                    material.materialType === 'equipment'
                        ?
                        <KpPdfEquipmentItem index={index} material={material} key={material.guid} kp={kp}/>
                        :
                        <KpPdfComponentItem index={index} material={material} key={material.guid} kp={kp}/>
                ))
            }
        </View>
    );
};

export default KpPdfTableBody;