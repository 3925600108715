import useRemainsStore from "../store/remainsStore";
import {useInteractionBackend} from "../../../hooks/useInteractionBackend";
import {getRemains, getFamiliesNomenclatures} from "../API/remainsAPI";
import {useEffect} from "react";

const UseRemainsController = () => {
    const filters = useRemainsStore(state => state.filters);
    const page = useRemainsStore(state => state.page);
    const searchQuery = useRemainsStore(state => state.searchQuery);
    const offset = useRemainsStore(state => state.offset);
    const setRemains = useRemainsStore.getState().setRemains;
    const setFamilies = useRemainsStore.getState().setFilterFamiliesNomenclatures;

    const [fetchData] = useInteractionBackend({
        type: 'axios',
        cb: getRemains,
        thenCb: (data) => setRemains(data.data)
    });

    useEffect(() => {
        fetchData({searchQuery, page, filters, offset});
    }, [filters, page, searchQuery, offset]);
};

export default UseRemainsController;