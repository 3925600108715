import './OffersList.css'
import ListIcon from "../../../../components/UI/icons/ListIcon";
import {useMultiStepComponent} from "../../../../hooks/usemultistepcomponent/UseMultiStepComponents";
import DetailWrapper from "../../../../components/detailcard/wrapper/DetailWrapper";
import TabsBlock from "../../../../components/tabsblock/TabsBlock";
import ButtonTab from "../../../../components/UI/buttons/buttontab/ButtonTab";
import DetailBody from "../../../../components/detailcard/body/DetailBody";
import BuyOfferList from "./buyofferlist/BuyOfferList";
import RentOfferList from "./rentofferlist/RentOfferList";

const OffersList = ({assignedId, clientId, projectSellVariationId, projectRentVariationId}) => {

    const {
        step,
        titleStep,
        goTO
    } = useMultiStepComponent([
        {
            titleStep: 'Фиксации аренды',
            component: <RentOfferList assignedId={assignedId} clientId={clientId} projectRentVariationId={projectRentVariationId}/>
        },
        {
            titleStep: 'Фиксации покупки',
            component: <BuyOfferList assignedId={assignedId} clientId={clientId} projectSellVariationId={projectSellVariationId}/>
        },
    ])

    return (
        <DetailWrapper>
            <TabsBlock>
                <ButtonTab
                    icon={<ListIcon />}
                    title={'Фиксации аренды'}
                    onClick={() => goTO(0)}
                    isActive={titleStep === "Фиксации аренды"}
                />
                <ButtonTab
                    icon={<ListIcon />}
                    title={'Фиксации покупки'}
                    onClick={() => goTO(1)}
                    isActive={titleStep === "Фиксации покупки"}
                />
            </TabsBlock>
            <DetailBody>
                {step}
            </DetailBody>
        </DetailWrapper>
    );
};

export default OffersList;