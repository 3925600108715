import './TkpSettings.css'
import {Form, Stack} from "react-bootstrap";
import TkpField from "../tkpField/TkpField";
import {deliveryMethod, paymentTerms, shippingTime, supplyWarehouse} from "../../helpers/constants";
import DropdownDefault from "../../../../components/UI/selects/dropdowndefault/DropdownDefault";
import {
    converterEntityToOptionOrReverse,
    getOptionsFromArrayEntities
} from "../../../../features/entityhelper/EntityHelper";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {AxiosHelper} from "../../../../hooks/useAxiosHelper";
import useTkp from "../../hooks/useTkp";
import {useParams} from "react-router";
import TextInput from "../../../../components/UI/inputs/text/TextInput";
import NumericInput from "../../../../components/UI/inputs/numeric/NumericInput";
import PhotoEntityRenderer from "../../../../components/files/photo/PhotoEntityRenderer";
import {InputAdornment, TextField} from "@mui/material";

const TkpSettings = () => {
    const params = useParams()
    const id = Number(params['id'] || 0)
    const {data: warehouses} = useQuery({
        queryKey: ['dependency', 'entities', 'ancorr/api/helper/get/warehouses'],
        queryFn: () => AxiosHelper.getMethod({
            urlWithoutBaseUrl: 'ancorr/api/helper/get/warehouses',
        }),
        refetchOnWindowFocus: false
    })
    const {data: currencies} = useQuery({
        queryKey: ['dependency', 'entities', 'ancorr/api/helper/get/currencies'],
        queryFn: () => AxiosHelper.getMethod({
            urlWithoutBaseUrl: 'ancorr/api/helper/get/currencies',
        }),
        refetchOnWindowFocus: false
    })
    const {data: listCourses} = useQuery({
        queryKey: ['dependency', 'entities', 'ancorr/api/helper/get/courses'],
        queryFn: () => AxiosHelper.getMethod({
            urlWithoutBaseUrl: 'ancorr/api/helper/get/courses',
        }),
        refetchOnWindowFocus: false
    })
    const client = useQueryClient()
    const currentTkp = client.getQueryData(['current-tkp', id])
    const {
        changeTkpEx
    } = useTkp();

    return (
        <div className={'tkp-settings'}>
            <div className={'tkp-settings__items'}>
                <Form className={'w-100'}>
                    <Form.Group>
                        <TextInput
                            defaultValue={currentTkp?.title}
                            onChange={newValue => changeTkpEx({...currentTkp, title: newValue})}
                            useDebounce
                            label={'Наименование'}
                        />
                        <Form.Check
                            checked={currentTkp?.settings?.distributeDeliveryToPrice}
                            onChange={e => changeTkpEx({
                                ...currentTkp,
                                settings: {
                                    ...currentTkp?.settings,
                                    distributeDeliveryToPrice: !currentTkp?.settings?.distributeDeliveryToPrice
                                }

                            }, false)}
                            label={'Распределить стоимость доставки по литрам'}
                        />
                        <Form.Check
                            checked={currentTkp?.settings?.distributeServiceToPrice}
                            onChange={e => changeTkpEx({
                                ...currentTkp,
                                settings: {
                                    ...currentTkp?.settings,
                                    distributeServiceToPrice: !currentTkp?.settings?.distributeServiceToPrice
                                }
                            }, false)}
                            label={'Распределить стоимость сервиса по литрам'}
                        />
                        <Form.Check
                            checked={currentTkp?.settings?.visiblePrice}
                            onChange={e => changeTkpEx({
                                ...currentTkp,
                                settings: {
                                    ...currentTkp?.settings,
                                    visiblePrice: !currentTkp?.settings?.visiblePrice
                                }
                            }, false)}
                            label={'Отображать цены для клиента'}
                        />
                        <Form.Check
                            checked={currentTkp?.isUseDynamicCourse}
                            onChange={e => changeTkpEx({
                                ...currentTkp,
                                isUseDynamicCourse: !currentTkp?.isUseDynamicCourse
                            }, false)}
                            label={'Использовать динамический курс выбраной валюты'}
                        />
                        {currentTkp?.isUseDynamicCourse &&
                            <Stack className={'mt-3'}>
                                <NumericInput
                                    label={'Динамический курс'}
                                    defaultValue={currentTkp?.dynamicCourse}
                                    onChange={newValue => changeTkpEx({...currentTkp, dynamicCourse: newValue})}
                                />
                            </Stack>
                        }

                        <Stack className={'mt-3'}>
                            <DropdownDefault
                                currentValue={{
                                    id: currentTkp?.currencyForHandle?.id,
                                    title: currentTkp?.currencyForHandle?.name,
                                    value: currentTkp?.currencyForHandle?.name,
                                    accessor: 'currency'
                                }}
                                options={getOptionsFromArrayEntities({
                                    entities: currencies,
                                    valueCallback: item => `${item?.name} ${item?.courseToRUB}`,
                                    titleCallback: item => `${item?.name} ${item?.courseToRUB}`,
                                    idCallback: item => item?.id,
                                    accessorName: 'currency'
                                })}
                                onChange={(option) => {
                                    const currencyFound = currencies?.find(currency => currency?.id === option?.id);
                                    changeTkpEx({...currentTkp, currencyForHandle: currencyFound}, false)
                                }}
                                placeholder={'Валюта расчета'}
                                controlled
                            />
                        </Stack>
                        <Stack className={'mt-3'}>
                            <DropdownDefault
                                controlled
                                placeholder={'Склад по умолчанию'}
                                options={getOptionsFromArrayEntities({
                                    entities: warehouses,
                                    accessorName: 'brand',
                                    valueCallback: itemC => itemC?.fullName,
                                    titleCallback: itemC => itemC?.fullName,
                                    idCallback: itemC => itemC?.id
                                })}
                                currentValue={{
                                    id: currentTkp?.settings?.defaultWarehouse?.id,
                                    title: currentTkp?.settings?.defaultWarehouse?.fullName,
                                    value: currentTkp?.settings?.defaultWarehouse?.fullName,
                                    accessor: 'warehouse'
                                }}
                                onChange={newValue => changeTkpEx({
                                    ...currentTkp,
                                    settings: {
                                        ...currentTkp.settings,
                                        defaultWarehouse: converterEntityToOptionOrReverse({
                                            endpoint: 'entity',
                                            arrayEntities: warehouses,
                                            searchPropertyForArray: newValue?.id
                                        })
                                    }
                                })}
                            />
                        </Stack>
                        <Stack className={'mt-3'}>
                            <DropdownDefault
                                controlled
                                placeholder={'Направление доставки'}
                                options={getOptionsFromArrayEntities({
                                    entities: listCourses,
                                    accessorName: 'course',
                                    valueCallback: itemC => itemC?.outputCourse,
                                    titleCallback: itemC => itemC?.outputCourse,
                                    idCallback: itemC => itemC?.id
                                })}
                                currentValue={{
                                    id: currentTkp?.settings?.selectedCourse?.id,
                                    title: currentTkp?.settings?.selectedCourse?.outputCourse,
                                    value: currentTkp?.settings?.selectedCourse?.outputCourse,
                                    accessor: 'course'
                                }}
                                onChange={newValue => changeTkpEx({
                                    ...currentTkp,
                                    settings: {
                                        ...currentTkp.settings,
                                        selectedCourse: converterEntityToOptionOrReverse({
                                            endpoint: 'entity',
                                            arrayEntities: listCourses,
                                            searchPropertyForArray: newValue?.id
                                        })
                                    }
                                })}
                            />
                        </Stack>

                        <Stack className={'mt-3'}>
                            <NumericInput
                                label={'Стоимость сервиса'}
                                defaultValue={currentTkp?.settings?.servicePrice}
                                onChange={newValue => changeTkpEx({
                                    ...currentTkp,
                                    settings: {
                                        ...currentTkp?.settings,
                                        servicePrice: newValue
                                    }
                                })}
                            />
                        </Stack>
                        <Form.Check
                            type="switch"
                            label='Выделить НДС'
                            bsPrefix={'tkp-settings__switch'}
                            checked={currentTkp?.isHandleVAT}
                            onChange={e => {
                                changeTkpEx({
                                    ...currentTkp,
                                    isHandleVAT: !currentTkp?.isHandleVAT
                                })
                            }}
                            style={{marginTop: '20px'}}
                        />
                        <TextField
                            variant="standard"
                            defaultValue={currentTkp?.vat || '0'}
                            onChange={e => {
                                changeTkpEx({...currentTkp, vat: e.target.value})
                            }}
                            label={'НДС'}
                            InputProps={{
                                endAdornment: <InputAdornment position="start">%</InputAdornment>,
                            }}
                            sx={{maxWidth: '50px', width: '100%', marginTop: '20px'}}
                        />
                        <div>
                            <Form.Label
                                className={'mt-3'}
                            >
                                Фото судна:
                            </Form.Label>
                        </div>
                        <PhotoEntityRenderer
                            entity={currentTkp?.shipPhoto}
                            uniqName={'tkp'}
                            isEditable
                            onChange={newValue => changeTkpEx({...currentTkp, shipPhoto: newValue})}
                        />
                    </Form.Group>
                    <div className="tkp-settings__ext-settings">
                        <div className="tkp-settings__ext-settings-column">
                            <TkpField
                                title={'Условия оплаты'}
                                text={currentTkp?.paymentTerms || ''}
                                setValue={(value) => changeTkpEx({...currentTkp, paymentTerms: value})}
                                templatesValue={paymentTerms}
                            />
                            <TkpField
                                title={'Сроки отгрузки'}
                                text={currentTkp?.shippingTime || ''}
                                setValue={(value) => changeTkpEx({...currentTkp, shippingTime: value})}
                                templatesValue={shippingTime}
                            />
                            <TkpField
                                title={'Склад поставщика'}
                                text={currentTkp?.supplyWarehouse || ''}
                                setValue={(value) => changeTkpEx({...currentTkp, supplyWarehouse: value})}
                                templatesValue={supplyWarehouse}
                            />
                            <TkpField
                                title={'Ценовая заметка'}
                                text={currentTkp?.priceNote || ''}
                                setValue={(value) => changeTkpEx({...currentTkp, priceNote: value})}
                            />
                        </div>
                        <div className="tkp-settings__ext-settings-column">
                            <TkpField
                                title={'Способ поставки'}
                                text={currentTkp?.deliveryMethod || ''}
                                setValue={(value) => changeTkpEx({...currentTkp, deliveryMethod: value})}
                                templatesValue={deliveryMethod}
                            />
                            <TkpField
                                title={'Адрес доставки'}
                                text={currentTkp?.deliveryAddress || ''}
                                setValue={(value) => changeTkpEx({...currentTkp, deliveryAddress: value})}
                            />
                            <TkpField
                                title={'Иные условия'}
                                text={currentTkp?.otherTerms || ''}
                                setValue={(value) => changeTkpEx({...currentTkp, otherTerms: value})}
                            />
                            <TkpField
                                title={'Заметка условий'}
                                text={currentTkp?.termsNote || ''}
                                setValue={(value) => changeTkpEx({...currentTkp, termsNote: value})}
                            />
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    );
};

export default TkpSettings;