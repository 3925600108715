import './Text.css'
import useClassNames from "../../../../hooks/useClassNames";

const Text = ({children, classes = []}) => {

    const textClasses = useClassNames ({
        cls: 'stretch-line__text',
        additional: classes
    })

    return (
        <p className={textClasses}>
            {children}
        </p>
    );
};

export default Text;