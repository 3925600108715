import './GuidsFieldsDetail.css'
import DetailWrapper from "../../../../../components/detailcard/wrapper/DetailWrapper";
import TabsBlock from "../../../../../components/tabsblock/TabsBlock";
import ButtonTab from "../../../../../components/UI/buttons/buttontab/ButtonTab";
import BackCrossIcon from "../../../../../components/UI/icons/BackCrossIcon";
import DetailBody from "../../../../../components/detailcard/body/DetailBody";
import FieldsBlockRenderer from "../../../../../modules/fieldblockrenderer/FieldsBlockRenderer";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router";
import {useMultiStepComponent} from "../../../../../hooks/usemultistepcomponent/UseMultiStepComponents";
import BlocksList from "../blocks/BlocksList";
import ListIcon from "../../../../../components/UI/icons/ListIcon";
import CompanyIcon from "../../../../../components/UI/icons/CompanyIcon";
import useAxiosHelper from "../../../../../hooks/useAxiosHelper";
const GuidsFieldsDetail = () => {


    const [currentEntity, setCurrentEntity] = useState(null)
    const [blocksEntity, setBlocksEntity] = useState([])
    const params = useParams()
    const navigate = useNavigate()
    const {postMethod, getMethod} = useAxiosHelper({})

    useEffect(() => {
        updateCurrentEntity()
        updateBlocks()
    }, []);
    const updateCurrentEntity = () => getMethod({
        urlWithoutBaseUrl: `ancorr/api/field/get/entity/${Number(params['id'] || 0)}`,
        successCallback: ({data}) => setCurrentEntity(data)
    })
    const updateBlocks = () => getMethod({
        urlWithoutBaseUrl: `ancorr/api/field/get/blocks/${Number(params['id'] || 0)}`,
        successCallback: ({data}) => setBlocksEntity(data)
    })
    const changeEntity = (newItem) => postMethod({
        urlWithoutBaseUrl: 'ancorr/api/field/update/entity',
        data: newItem,
        successCallback: () => updateCurrentEntity()
    })



    const {
        step,
        titleStep,
        goTO
    } = useMultiStepComponent([
        {
            titleStep: 'Информация',
            component: <FieldsBlockRenderer
                schema={schemaEntity}
                model={currentEntity}
                onSave={newItem => {
                    changeEntity(newItem)
                }}
            />
        },
        {
            titleStep: 'Блоки',
            component: <BlocksList
                blocks={blocksEntity}
                entityId={Number(params['id'] || 0)}
                setNewBlocks={newArray => setBlocksEntity(newArray)}
                updateBlocks={() => updateBlocks()}
            />
        },
    ])


    return (
        <DetailWrapper>
            <TabsBlock>
                <ButtonTab
                    icon={<CompanyIcon classNames={'icon-extension'}/>}
                    title={'Информация'}
                    onClick={() => goTO(0)}
                    isActive={titleStep === 'Информация'}
                />
                <ButtonTab
                    icon={<ListIcon classNames={'icon-extension'}/>}
                    title={'Блоки'}
                    onClick={() => goTO(1)}
                    isActive={titleStep === 'Блоки'}
                />
                <ButtonTab
                    icon={<BackCrossIcon classNames={'icon-extension'}/>}
                    title={'Назад к списку'}
                    onClick={() => navigate(-1)}
                />
            </TabsBlock>
            <DetailBody>
                {step}
            </DetailBody>
        </DetailWrapper>
    );
};

const schemaEntity = [
    {
        blockTitle: 'Общая информация',
        fields: [
            {
                type: 'text',
                accessor: 'name',
                label: 'Наименование',
            },
            {
                type: 'text',
                accessor: 'description',
                label: 'Описание',
            },
        ],
    },
]

export default GuidsFieldsDetail;