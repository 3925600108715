import {Accordion} from "react-bootstrap";

const Item = ({children, eventKey}) => {
    return (
        <Accordion.Item eventKey={eventKey} className='w-100' id={'remains__accordion-item'}>
            {children}
        </Accordion.Item>
    );
};

export default Item;