import {BaseUrl} from "../../../features/databaseinfo/BaseUrl";
import axios from "axios";

const updateDiscount = ({type, id, value}) => {
    return axios.get(`${BaseUrl}/ancorr/api/materials/edit/discount`, {
        params: {
            type: type,
            itemId: id,
            newValue: value
        }
    });
}
const getUSD = () => axios.get(`${BaseUrl}/ancorr/api/getUsdValue`);
const updateUSD = ({USD}) => {
    let fd = new FormData();
    fd.append('Id', USD.Id);
    fd.append('Name', USD.Name);
    fd.append('Value', `${USD.Value}`);
    return axios.post(`${BaseUrl}/ancorr/api/updateUsdValue`, fd, {'Access-Control-Allow-Origin': '*'})
}

const getRemains = ({searchQuery, page = 1, filters = {}, offset = 20}) => {
    if (searchQuery) {
        return axios.post(`${BaseUrl}/ancorr/api/materials/list?search=${searchQuery}&offset=${offset}&page=${page}`,
            JSON.stringify(filters),
            {headers: {'Content-Type': 'application/json'}}
        );
    }
    if (!searchQuery && typeof searchQuery === "string") {
        return axios.post(`${BaseUrl}/ancorr/api/materials/list/?offset=${offset}&page=${page}`,
            JSON.stringify(filters),
            {headers: {'Content-Type': 'application/json'}}
        );
    }
    return axios.post(`${BaseUrl}/ancorr/api/materials/list?uploadAll=true&offset=${offset}&page=${page}`,
        JSON.stringify(filters),
        {headers: {'Content-Type': 'application/json'}}
    );
}

const getBrands = () => {
    return axios.get(`${BaseUrl}/ancorr/api/helper/get/brands`);
}

const getCurrencies = () => {
    return axios.get(`${BaseUrl}/ancorr/api/helper/get/currencies`);
}

const getWarehouses = () => {
    return axios.get(`${BaseUrl}/ancorr/api/helper/get/warehouses`);
}

const getByAccessor = ({accessor}) => {
    return axios.get(`${BaseUrl}/ancorr/api/helper/get/enums?&accessor=${accessor}`);
}

const getFamiliesNomenclatures = ({ids}) => {
    return axios.post(`${BaseUrl}/ancorr/api/helper/get/brands/nomenclature`, {brandIds: ids});
}

export {
    getRemains,
    getBrands,
    getCurrencies,
    getWarehouses,
    getUSD,
    updateUSD,
    updateDiscount,
    getByAccessor,
    getFamiliesNomenclatures
}