import Test from "../testpage/Test";
import TabsBlock from "../../components/tabsblock/TabsBlock";
import ButtonTab from "../../components/UI/buttons/buttontab/ButtonTab";
import DetailBody from "../../components/detailcard/body/DetailBody";
import DetailWrapper from "../../components/detailcard/wrapper/DetailWrapper";
import {useMultiStepComponent} from "../../hooks/usemultistepcomponent/UseMultiStepComponents";
import UserIcon from "../../components/UI/icons/UserIcon";
import MultiUserIcon from "../../components/UI/icons/MultiUserIcon";
import SettingsIcon from "../../components/UI/icons/SettingsIcon";
import './Profile.css'
import DotIcon from "../../components/UI/icons/DotIcon";
import PartnerUsers from "./modules/users/PartnerUsers";
import GuidsWarehousesList from "../guids/warehouses/modules/list/GuidsWarehousesList";
import GuidsBrandsList from "../guids/brands/modules/list/GuidsBrandsList";
import GuidsSupplierList from "../guids/suppliers/modules/guidssuppliers/GuidsSupplierList";
import GuidsCurrencyList from "../guids/currency/modules/list/GuidsCurrencyList";
import GuidsPackagingList from "../guids/packaging/modules/list/GuidsPackagingList";
import Materials from "../guids/materials/Materials";
import {AxiosHelper} from "../../hooks/useAxiosHelper";
import FieldsBlockRenderer from "../../modules/fieldblockrenderer/FieldsBlockRenderer";
import TkpTemplateList from "../guids/tkpTemplates/modules/list/TkpTemplateList";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {useAuth} from "../../features/authprovider/AuthProvider";


const Profile = () => {
    const {userInfo, isAdmin} = useAuth()
    const client = useQueryClient()

    const {data: currentEntity} = useQuery({
        queryKey: ['current-partner'],
        queryFn: () => AxiosHelper.getMethod({
            urlWithoutBaseUrl: `ancorr/api/partner/get/${userInfo?.partnerId || 0}`,
        }),
    })

    const {data: schema} = useQuery({
        queryKey: ['schema', 'partner'],
        queryFn: () => AxiosHelper.getMethod({
            urlWithoutBaseUrl: 'ancorr/api/helper/get/schema',
            queryParams: {
                destinationEntity: 'partner'
            },
        }),
        refetchOnWindowFocus: false
    })

    const {mutate: changeCurrentEntity} = useMutation({
        mutationKey: ['current-partner'],
        mutationFn: (newItem) => AxiosHelper.postMethod({
            urlWithoutBaseUrl: 'ancorr/api/partner/update',
            data: newItem,
        }),
        onSuccess: () => client.invalidateQueries({queryKey: ['current-partner']}),
        onMutate: (newItem) => client?.setQueryData(['current-partner'], (oldItem) => newItem)
    })

    const {
        step,
        titleStep,
        goTO
    } = useMultiStepComponent([
        {
            titleStep: 'Мой профиль',
            component: <FieldsBlockRenderer
                schema={schema}
                model={currentEntity}
                onSave={newItem => {
                    changeCurrentEntity?.(newItem)
                }}
                dependencyEntity={[
                    {
                        method: 'ancorr/api/helper/get/companies',
                        keySelector: item => item?.id,
                        titleSelector: item => item ? `${item?.shortCompanyName} ${item?.inn ? `ИНН: ${item?.inn}` : ""}` : 'Не заполнен',
                        valueSelector: item => item?.id,
                    },
                ]}
                backendMode={'axios'}

            />
        },
        {
            titleStep: 'Мои сотрудники',
            component: <PartnerUsers/>
        },
        {
            titleStep: 'Справочники',
            component: <Test/>
        },
        {
            titleStep: 'Настройки',
            component: <Test/>
        }
    ])
    const guidsMultiStep = useMultiStepComponent([
        {
            titleStep: 'Склады',
            component: <GuidsWarehousesList />
        },
        {
            titleStep: 'Материалы',
            component: <Materials/>
        },
        {
            titleStep: 'Бренды',
            component: <GuidsBrandsList />
        },
        {
            titleStep: 'Упаковки',
            component: <GuidsPackagingList />
        },
        {
            titleStep: 'Поставщики',
            component: <GuidsSupplierList />
        },
        {
            titleStep: 'Валюты',
            component: <GuidsCurrencyList />
        },
        {
            titleStep: 'Прайс-листы',
            component: <Test />
        },
        {
            titleStep: 'Аналоги продукции',
            component: <Test />
        },
        {
            titleStep: 'Шаблоны ТКП',
            component: <TkpTemplateList />
        },
    ])

    return (
        <DetailWrapper>
            <TabsBlock>
                <ButtonTab
                    icon={<UserIcon classNames={'icon-extension'}/>}
                    title={'Мой профиль'}
                    onClick={() => goTO(0)}
                    isActive={titleStep === "Мой профиль"}
                />
                {isAdmin &&
                    <ButtonTab
                        icon={<MultiUserIcon classNames={'icon-extension'}/>}
                        title={'Мои сотрудники'}
                        onClick={() => goTO(1)}
                        isActive={titleStep === "Мои сотрудники"}
                    />
                }

                {userInfo?.partnerId === 1 &&
                    <ButtonTab
                        icon={<UserIcon classNames={'icon-extension'}/>}
                        title={'Справочники'}
                        onClick={() => goTO(2)}
                    />
                }
                {titleStep === "Справочники" &&
                    <div className={'sub-buttons'}>
                        <ButtonTab
                            icon={<DotIcon classNames={'icon-extension'}/>}
                            title={'Склады'}
                            onClick={() => guidsMultiStep.goTO(0)}
                            isActive={guidsMultiStep.titleStep === "Склады"}
                        />
                        <ButtonTab
                            icon={<DotIcon classNames={'icon-extension'}/>}
                            title={'Материалы'}
                            onClick={() => guidsMultiStep.goTO(1)}
                            isActive={guidsMultiStep.titleStep === "Материалы"}
                        />
                        <ButtonTab
                            icon={<DotIcon classNames={'icon-extension'}/>}
                            title={'Бренды'}
                            onClick={() => guidsMultiStep.goTO(2)}
                            isActive={guidsMultiStep.titleStep === "Бренды"}
                        />
                        <ButtonTab
                            icon={<DotIcon classNames={'icon-extension'}/>}
                            title={'Упаковки'}
                            onClick={() => guidsMultiStep.goTO(3)}
                            isActive={guidsMultiStep.titleStep === "Упаковки"}
                        />
                        <ButtonTab
                            icon={<DotIcon classNames={'icon-extension'}/>}
                            title={'Поставщики'}
                            onClick={() => guidsMultiStep.goTO(4)}
                            isActive={guidsMultiStep.titleStep === "Поставщики"}
                        />
                        <ButtonTab
                            icon={<DotIcon classNames={'icon-extension'}/>}
                            title={'Валюты'}
                            onClick={() => guidsMultiStep.goTO(5)}
                            isActive={guidsMultiStep.titleStep === "Валюты"}
                        />
                        <ButtonTab
                            icon={<DotIcon classNames={'icon-extension'}/>}
                            title={'Прайс-листы'}
                            onClick={() => guidsMultiStep.goTO(6)}
                            isActive={guidsMultiStep.titleStep === "Прайс-листы"}
                        />
                        <ButtonTab
                            icon={<DotIcon classNames={'icon-extension'}/>}
                            title={'Аналоги продукции'}
                            onClick={() => guidsMultiStep.goTO(7)}
                            isActive={guidsMultiStep.titleStep === "Аналоги продукции"}
                        />
                        <ButtonTab
                            icon={<DotIcon classNames={'icon-extension'}/>}
                            title={'Шаблоны ТКП'}
                            onClick={() => guidsMultiStep.goTO(8)}
                            isActive={guidsMultiStep.titleStep === "Шаблоны ТКП"}
                        />
                    </div>
                }
                <ButtonTab
                    icon={<SettingsIcon classNames={'icon-extension'}/>}
                    title={'Настройки'}
                    onClick={() => goTO(3)}
                    isActive={titleStep === "Настройки"}
                />
            </TabsBlock>
            <DetailBody>
                {titleStep === 'Справочники'
                    ? guidsMultiStep.step
                    : step}
            </DetailBody>
        </DetailWrapper>
    );
};



export default Profile;