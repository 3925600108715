export function getFields(component) {
    return [[
        {title: 'Наименование', value: component?.name},
        {title: 'Наименование группы', value: component?.group?.name},
        {title: 'Бренд', value: component?.componentInformation?.brand?.name || '-'},
        {title: 'Валюта', value: component?.componentInformation.currency?.name},
        {title: 'Единица измерения', value: component?.componentInformation.unitMeasurement?.unitName},
        {title: 'Объем единицы', value:  component?.componentInformation?.volume + '' + component?.componentInformation.unitVolume?.unitName},
        {title: 'Вес единицы', value:  component?.componentInformation?.weight + '' + component?.componentInformation.unitWeight?.unitName},
        {title: 'Цена за единицу', value: component?.priceForUnit.toFixed(2) + '' + (component?.supplyPrice?.currency?.currencyCode || '')},
        {title: 'Цена закупки', value:  component?.supplyPrice?.price + '' + (component?.supplyPrice?.currency?.currencyCode || '')},
        {title: 'Доступно', value: component?.available},
        {title: 'Доступно с поставкой', value: component?.availableWithSupply},
        {title: 'В резерве', value: component?.reserved}
    ], [
        {title: 'Отгрузка', value: component?.shipment},
        {title: 'Поступит', value: component?.supply},
        {title: 'Планируется поступление', value: component?.plannedSupply},
        {title: 'Брак', value: component?.remainsMarriage},
        {title: 'Неликвид', value: component?.remainsUnmarketable},
        {title: 'В наличии', value:  component?.inStock},
        {title: 'Ближайшая дата поставки', value:  component?.nearestSupplyDate || '-'},
        {title: 'Цена поставки', value: component?.deliveryPrice},
        {title: 'Дополнительная цена доставки', value:  component?.additionalDeliveryPrice},
        {title: 'Максимальная скидка', value: component?.maxDiscount},
        {title: 'Наценка', value: component?.margin},
        {title: 'Цвет', value: <>{component?.color || "Нет"} {component?.color && <div style={{backgroundColor: component?.color}} className={'component__color'}></div>}</>}
    ]]
}