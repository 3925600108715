import './GuidsPagination.css'

const GuidsPagination = ({children}) => {
    return (
        <div className={'guids-pagination-items'}>
            {children}
        </div>
    );
};

export default GuidsPagination;