import './KpPreview.css'
import {useParams} from "react-router";
import {useEffect, useState} from "react";
import {useInteractionBackend} from "../../../../hooks/useInteractionBackend";
import axios from "axios";
import {BaseUrl} from "../../../../features/databaseinfo/BaseUrl";
import TitleKpPreview from "./components/titlekppreview/TitleKpPreview";
import {Button} from "react-bootstrap";
import {PDFDownloadLink} from "@react-pdf/renderer";
import KpPdf from "../kppdf/KpPdf";
import TableKpPreview from "./components/tablekppreview/TableKpPreview";
import LineKpPreview from "./components/linekppreview/LineKpPreview";

const KpPreview = () => {
    const params = useParams();
    const [kp, setKp] = useState({});

    const [getKp] = useInteractionBackend({
        type: 'axios',
        cb: ({guid}) => {
            return axios.get(`${BaseUrl}/ancorr/api/kp/get/preview/${guid}`)
        },
        thenCb: ({data}) => setKp(data)
    })

    useEffect(() => {
        getKp({guid: params.guid});
    }, []);

    return (
        <div className='kp-preview'>
            <div className="kp-preview__header">
                <img
                    src={`${BaseUrl}/photo/img/Logo.png`}
                    alt={"Logo.png"}
                    className='kp-preview__header-logo'
                />
                <div className="kp-preview__controls">
                    <PDFDownloadLink document={<KpPdf kp={kp}/>} fileName={'KP.pdf'}>
                        <Button className='kp-preview__btn'>Скачать PDF</Button>
                    </PDFDownloadLink>
                </div>
            </div>
            <TitleKpPreview kp={kp}/>
            <div className="kp-preview__content">
                <h2 className="kp-preview__title">
                    Сводная таблица
                </h2>
                <TableKpPreview kp={kp}/>
            </div>
            <LineKpPreview kp={kp}/>
        </div>
    );
};

export default KpPreview;