import {useEffect, useRef, useState} from "react";
import {Form} from "react-bootstrap";
import FilterInput from "../../../UI/filterinput/FilterInput";


const MarginInputs = ({filters, setFilters, updatePosition}) => {
    const refInputs = useRef(null);
    const [isFirstLoad, setIsFirstLoad] = useState(true);

    useEffect(() => {
        if (isFirstLoad) setIsFirstLoad(false);
        else updatePosition({target: refInputs.current});
    }, [filters.marginMax, filters.marginMin])

    return (
        <div className={'remains-filters__none-accordion'}>
            <Form.Label className={'filter-inputs__label'}>Наценка</Form.Label>
            <div
                className="filters-inputs__inputs"
                ref={refInputs}
            >
                <FilterInput
                    value={filters.marginMin}
                    setValue={value => setFilters({...filters, marginMin: value})}
                    placeholder={"От"}
                />
                <FilterInput
                    value={filters.marginMax}
                    setValue={value => setFilters({...filters, marginMax: value})}
                    placeholder={"До"}
                />
            </div>
        </div>
    );
};

export default MarginInputs;