import styles from "../constants";
import {Text, View} from "@react-pdf/renderer";

const KpPdfFooter = ({kp}) => {
    const getPhone = (number) => {
        if (!number) return '';
        const template = '+7 ($2) $3-$4-$5'
        const regexpPhone = /^(\d)(\d{3})(\d{3})(\d{2})(\d{2})$/;
        return number.toString().replace(regexpPhone, template);
    }
    return (
        <View style={styles.footer}>
            <Text>С уважением,</Text>
            <Text>{kp.assignedB24}</Text>
            <Text>г. Ярославль, 2-ой Промышленный проезд, дом</Text>
            <Text>11, литера Д, помещение 13</Text>
            <Text>
                {getPhone(kp?.assigned?.phone)}
            </Text>
            <Text>{kp?.assigned?.email || ''}</Text>
        </View>
    );
};

export default KpPdfFooter;