import './DropdownMultiField.css';
import {Dropdown} from "react-bootstrap";
import ToggleDropdownMultiField from "./toggleDropdownMultiField/ToggleDropdownMultiField";
import {useEffect, useState} from "react";

const DropdownMultiField = ({value, items, onChange}) => {

    const [optionsArray, setOptionsArray] = useState([])
    useEffect(() => {
        setOptionsArray([{
            accessor: 'default',
            title: 'Не выбрано',
            value: 'Не выбрано',
            id: 99999,
        }, ...items])
    }, [items]);
    return (
        <Dropdown>
            <Dropdown.Toggle as={ToggleDropdownMultiField}>
                {value?.value || 'Не выбрано'}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {optionsArray?.map((item, index) => (
                    <Dropdown.Item
                        key={item?.id}
                        onClick={(e) => {
                            e.target.value = item?.value;
                            onChange?.(e);
                        }}
                        eventKey={index}
                    >
                        {item?.value}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default DropdownMultiField;