import './InputPriceCourse.css';
import {Checkbox, FormControlLabel, IconButton} from "@mui/material";
import {useState} from "react";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {AxiosHelper} from "../../../../../hooks/useAxiosHelper";
import {Delete} from "@mui/icons-material";
import NumericInput from "../../../../../components/UI/inputs/numeric/NumericInput";
import useLoader from "../../../../../hooks/useLoader";

const InputPriceCourse = ({inputPriceCourse, stateCourseKey, idx}) => {
    const [localPriceCourse, setLocalPriceCourse] = useState(inputPriceCourse);
    const {show, hide} = useLoader()

    const client = useQueryClient();
    const {mutate: updatePriceCourse} = useMutation({
        mutationKey: [stateCourseKey],
        mutationFn: (newPriceCourse) => AxiosHelper.postMethod({
            urlWithoutBaseUrl: 'ancorr/api/course/update/price',
            data: newPriceCourse
        }),
        onSuccess: () => client.invalidateQueries({queryKey: [stateCourseKey]}),
    })
    const {mutate: deletePrice} = useMutation({
        mutationKey: [stateCourseKey],
        mutationFn: ([guid, idInfoCourse]) => AxiosHelper.deleteMethod({
            urlWithoutBaseUrl: 'ancorr/api/course/delete/price',
            queryParams: {
                guid,
                idInfoCourse
            }
        }),
        onMutate: () => show(),
        onSettled: () => hide(),
        onSuccess: () => client.invalidateQueries({queryKey: [stateCourseKey]}),
    })

    return (
        <div className="price-course">
            <span className="price-course__number">
                {idx + 1}.
            </span>
            <div className="price-course__default-unit">
                <NumericInput
                    label='От'
                    useDebounce
                    defaultValue={localPriceCourse.defaultUnitFrom}
                    onChange={newValue => {
                        const newData = {...localPriceCourse, defaultUnitFrom: newValue};
                        updatePriceCourse(newData);
                        setLocalPriceCourse(newData);
                    }}
                />
                <NumericInput
                    label='До'
                    useDebounce
                    defaultValue={localPriceCourse.defaultUnitTo}
                    onChange={newValue => {
                        const newData = {...localPriceCourse, defaultUnitTo: newValue};
                        updatePriceCourse(newData);
                        setLocalPriceCourse(newData);
                    }}
                />
                л
            </div>
            <NumericInput
                label='Цена'
                defaultValue={localPriceCourse.price}
                useDebounce
                suffics={'₽'}
                onChange={newValue => {
                    const newData = {...localPriceCourse, price: newValue};
                    updatePriceCourse(newData);
                    setLocalPriceCourse(newData)
                }}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={localPriceCourse.isCombined}
                        onChange={() => {
                            const newData = {
                                ...localPriceCourse,
                                isCombined: !localPriceCourse.isCombined
                            };
                            updatePriceCourse(newData);
                            setLocalPriceCourse(newData)
                        }}
                        name="Сборный груз"
                    />
                }
                label="Сборный груз"
            />
            <IconButton
                onClick={event => {
                    deletePrice([localPriceCourse.guid, localPriceCourse.infoCourseId]);
                }}
            >
                <Delete/>
            </IconButton>
        </div>
    );
};

export default InputPriceCourse;