import React from 'react';
import './SelectorComponent.css'

const SelectorComponent = ({components, onSelect, currentComponentsIds}) => {
    const checkIsCurrentComponents = (id) => {
        let idFromCurrent = currentComponentsIds.find(x => x === id)
        if(idFromCurrent) return true;
        else return false
    }
    return (
        <div className={'selector-component__wrapper'}>
            <div className={'selector-component__list'}>
                {components?.map(item => (
                    <div key={item?.guid} onClick={() => {
                        if(!checkIsCurrentComponents(item?.id))
                            onSelect(item?.id)
                    }} className={'selector-component__item'}>
                        <span>{item?.name}</span>
                        {checkIsCurrentComponents(item?.id) && <span style={{color: 'red', fontWeight: 600}}>добавлен в рецепт</span>}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SelectorComponent;