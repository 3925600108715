import './BlocksList.css'
import {
    Accordion, AccordionContext,
    Button,
    Card, Container,
    Form,
    InputGroup,
    Modal,
    OverlayTrigger,
    Popover,
    useAccordionButton
} from "react-bootstrap";
import {useContext, useState} from "react";
import ListIcon from "../../../../../components/UI/icons/ListIcon";
import PopoverBtn from "../../../../../components/popoveraction/PopoverBtn";
import EditIcon from "../../../../../components/UI/icons/EditIcon";
import BackCrossIcon from "../../../../../components/UI/icons/BackCrossIcon";
import ChevronDownIcon from "../../../../../components/UI/icons/ChevronDownIcon";
import FieldItemList from "../fielditemlist/FieldItemList";
import useAxiosHelper from "../../../../../hooks/useAxiosHelper";
import GuidsSearchInput from "../../../../../components/guids/search/GuidsSearchInput";
const BlocksList = ({blocks, setNewBlocks, updateBlocks, entityId}) => {

    const {postMethod, deleteMethod} = useAxiosHelper({})

    const [itemForItemsList, setItemForItemsList] = useState(null)
    const [showModalItemsList, setShowModalItemsList] = useState(false)

    const [showModalEdit, setShowModalEdit] = useState(false)
    const [fieldForEdit, setFieldForEdit] = useState(null)

    const [showModalEditBlock, setShowModalEditBlock] = useState(false)
    const [blockForEdit, setBlockForEdit] = useState(null)

    const createBlock = () => postMethod({
        urlWithoutBaseUrl: 'ancorr/api/field/add/block',
        queryParams: {
            entityId
        },
        successCallback: ({data}) => updateBlocks()
    })
    const deleteBlock = (guid) => deleteMethod({
        urlWithoutBaseUrl: 'ancorr/api/field/delete/block',
        queryParams: {
            guid
        },
        successCallback: () => updateBlocks()
    })
    const createField = (blockId) => postMethod({
        urlWithoutBaseUrl: 'ancorr/api/field/add/field',
        queryParams: {
            blockId,
            entityId
        },
        successCallback: ({data}) => {
            let newArray = blocks?.map(block => {
                if(block?.id === blockId){
                    return { ...block, fields: [...block?.fields, data]}
                }
                else return block;
            })

            setNewBlocks(newArray)
        }
    })
    const createFieldFromField = (blockId, fieldId) => postMethod({
        urlWithoutBaseUrl: 'ancorr/api/field/add/field/subfield',
        queryParams: {
            blockId,
            entityId,
            fieldId
        },
        successCallback: () => updateBlocks()
    })
    const deleteField = (guid) => deleteMethod({
        urlWithoutBaseUrl: 'ancorr/api/field/delete/field',
        queryParams: {
            guid
        },
        successCallback: () => updateBlocks()
    })
    const openEditField = (field) => {
        setFieldForEdit(field)
        setShowModalEdit(true)
    }
    const openEditBlock = (block) => {
        setBlockForEdit(block)
        setShowModalEditBlock(true)
    }

    const openItemsList = item => {
        setItemForItemsList(item)
        setShowModalItemsList(true)
    }
    const closeItemsList = item => {
        setItemForItemsList(null)
        setShowModalItemsList(false)
    }

    const renderAccordionItem = (block, field, idxField) => {
        return (
            <Card eventKey={idxField} className={'blocks-list__item border-0 m-left-sm blocks-list__item_children mt-1'}>
                <Card.Header className={'border-0 rounded-1 blocks-list__item_header'}>
                    – {field?.label}
                    <div className={'flex center gap-1'}>
                        <CustomToggle eventKey={idxField}>Click</CustomToggle>
                        <OverlayTrigger
                            rootClose
                            trigger={'click'}
                            placement={'left'}
                            overlay={
                                <Popover id="popover-basic">
                                    <Popover.Header as="h3">{"Действия"}</Popover.Header>
                                    <Popover.Body className={'flex flex-column p-1'}>

                                        {field?.type === 'enum' &&
                                            <PopoverBtn
                                                icon={<ListIcon/>}
                                                title={'Создать field'}
                                                onClick={() => createFieldFromField(block?.id, field?.id)}
                                            />
                                        }
                                        {field?.type === 'object' &&
                                            <PopoverBtn
                                                icon={<ListIcon/>}
                                                title={'Создать field'}
                                                onClick={() => createFieldFromField(block?.id, field?.id)}
                                            />
                                        }
                                        <PopoverBtn
                                            icon={<EditIcon/>}
                                            title={'Редактировать'}
                                            onClick={() => openEditField(field)}
                                        />
                                        <PopoverBtn
                                            icon={<BackCrossIcon/>}
                                            title={'Удалить'}
                                            onClick={() => deleteField(field?.guid)}
                                        />
                                    </Popover.Body>
                                </Popover>
                            }
                        >
                            <i className="fa-solid fa-ellipsis-vertical icon icon-action"></i>
                        </OverlayTrigger>
                    </div>
                </Card.Header>
                <Accordion.Collapse className={'border-0'} eventKey={idxField}>
                    <Card.Body className={'p-0'}>
                        <Accordion className="blocks-list__items" alwaysOpen defaultActiveKey={[0]}>
                            {field?.fields?.map((fieldItem, idxFieldItem) => (
                                <>
                                    {fieldItem?.type === 'object' ? renderAccordionItem(block, fieldItem, idxFieldItem) : renderDefaultField(block, fieldItem, idxFieldItem)}
                                </>
                            ))}
                        </Accordion>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        )
    }
    const renderDefaultField = (block, field, idxField) => {
        return (
            <Card key={idxField} className={'blocks-list__item m-left-sm border-0 blocks-list__item_children mt-1'}>
                <Card.Header className={'border-0 rounded-1 blocks-list__item_header'}>
                    – {field?.label}
                    <OverlayTrigger
                        rootClose
                        trigger={'click'}
                        placement={'left'}
                        overlay={
                            <Popover id="popover-basic">
                                <Popover.Header as="h3">{"Действия"}</Popover.Header>
                                <Popover.Body className={'flex flex-column p-1'}>
                                    {field?.type === 'enum' &&
                                        <PopoverBtn
                                            icon={<ListIcon/>}
                                            title={'Список value'}
                                            onClick={() => openItemsList(field)}
                                        />
                                    }
                                    {field?.type === 'object' &&
                                        <PopoverBtn
                                            icon={<ListIcon/>}
                                            title={'Создать field'}
                                            onClick={() => createFieldFromField(block?.id, field?.id)}
                                        />
                                    }
                                    <PopoverBtn
                                        icon={<EditIcon/>}
                                        title={'Редактировать'}
                                        onClick={() => openEditField(field)}
                                    />
                                    <PopoverBtn
                                        icon={<BackCrossIcon/>}
                                        title={'Удалить'}
                                        onClick={() => deleteField(field?.guid)}
                                    />
                                </Popover.Body>
                            </Popover>
                        }
                    >
                        <i className="fa-solid fa-ellipsis-vertical icon icon-action"></i>
                    </OverlayTrigger>
                </Card.Header>
                <Card.Body className={'p-0'}>

                </Card.Body>
            </Card>
        )
    }
    const changeField = () => postMethod({
        urlWithoutBaseUrl: 'ancorr/api/field/update/field',
        data: fieldForEdit,
        successCallback: () => {
            setFieldForEdit(null)
            setShowModalEdit(false)
            updateBlocks()
        }
    })
    const changeBlock = () => postMethod({
        urlWithoutBaseUrl: 'ancorr/api/field/update/block',
        data: blockForEdit,
        successCallback: () => {
            setBlockForEdit(null)
            setShowModalEditBlock(false)
            updateBlocks()
        }
    })

    return (
        <div className={'blocks-list__wrapper'}>
            <div className="blocks-list__header">
                <Button onClick={() => createBlock()}>Добавить</Button>
            </div>
            <Accordion className="blocks-list__items border-0" alwaysOpen defaultActiveKey={[0]}>
                {blocks?.map((block, idxBlock) => (
                    <Card className={'blocks-list__item border-0'}>
                        <Card.Header className={'border-0 rounded-1 blocks-list__item_header'}>
                            {block?.blockTitle}
                            <div className={'flex center gap-1'}>
                                <CustomToggle eventKey={idxBlock}>Click</CustomToggle>
                                <OverlayTrigger
                                    rootClose
                                    trigger={'click'}
                                    placement={'left'}
                                    overlay={
                                        <Popover id="popover-basic">
                                            <Popover.Header as="h3">{"Действия"}</Popover.Header>
                                            <Popover.Body className={'flex flex-column p-1'}>
                                                <PopoverBtn
                                                    icon={<ListIcon/>}
                                                    title={'Создать field'}
                                                    onClick={() => createField(block?.id)}
                                                />
                                                <PopoverBtn
                                                    icon={<EditIcon/>}
                                                    title={'Редактировать'}
                                                    onClick={() => openEditBlock(block)}
                                                />
                                                <PopoverBtn
                                                    icon={<BackCrossIcon/>}
                                                    title={'Удалить'}
                                                    onClick={() => deleteBlock(block?.guid)}
                                                />
                                            </Popover.Body>
                                        </Popover>
                                    }
                                >
                                    <i className="fa-solid fa-ellipsis-vertical icon icon-action"></i>
                                </OverlayTrigger>
                            </div>
                        </Card.Header>
                        <Accordion.Collapse eventKey={idxBlock}>
                            <Card.Body className={'p-0'}>
                                <Accordion className="blocks-list__items border-0" alwaysOpen defaultActiveKey={[0]}>
                                    {block?.fields?.map((field, idxField) => (
                                        <>
                                            {field?.type === 'object' ? renderAccordionItem(block, field, idxField) : renderDefaultField(block, field, idxField)}
                                        </>
                                    ))}
                                </Accordion>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                ))}
            </Accordion>
            <Modal show={showModalEdit} onHide={() => {
                setFieldForEdit(null)
                setShowModalEdit(false)
            }}>
                <Modal.Header>
                    <Modal.Title>Редактирование field</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <InputGroup className={'mb-2'}>
                            <InputGroup.Text><ListIcon/></InputGroup.Text>
                            <Form.Control
                                value={fieldForEdit?.name || ''}
                                placeholder={'Наименование'}
                                onChange={e => setFieldForEdit({...fieldForEdit, name: e.target.value})}
                            />
                        </InputGroup>
                        <InputGroup className={'mb-2'}>
                            <InputGroup.Text><ListIcon/></InputGroup.Text>
                            <Form.Control
                                value={fieldForEdit?.accessor || ''}
                                placeholder={'Accessor'}
                                onChange={e => setFieldForEdit({...fieldForEdit, accessor: e.target.value})}
                            />
                        </InputGroup>
                        <InputGroup className={'mb-2'}>
                            <InputGroup.Text><ListIcon/></InputGroup.Text>
                            <Form.Control
                                value={fieldForEdit?.type || ''}
                                placeholder={'Тип'}
                                onChange={e => setFieldForEdit({...fieldForEdit, type: e.target.value})}
                            />
                        </InputGroup>
                        <InputGroup className={'mb-2'}>
                            <InputGroup.Text><ListIcon/></InputGroup.Text>
                            <Form.Control
                                value={fieldForEdit?.suffics || ''}
                                placeholder={'Суффикс'}
                                onChange={e => setFieldForEdit({...fieldForEdit, suffics: e.target.value})}
                            />
                        </InputGroup>
                        <InputGroup className={'mb-2'}>
                            <InputGroup.Text><ListIcon/></InputGroup.Text>
                            <Form.Control
                                value={fieldForEdit?.prefix || ''}
                                placeholder={'Префикс'}
                                onChange={e => setFieldForEdit({...fieldForEdit, prefix: e.target.value})}
                            />
                        </InputGroup>
                        <InputGroup className={'mb-2'}>
                            <InputGroup.Text><ListIcon/></InputGroup.Text>
                            <Form.Control
                                value={fieldForEdit?.methodGettingEntities || ''}
                                placeholder={'Метод получения сущностей'}
                                onChange={e => setFieldForEdit({...fieldForEdit, methodGettingEntities: e.target.value})}
                            />
                        </InputGroup>
                        <InputGroup className={'mb-2'}>
                            <InputGroup.Text><ListIcon/></InputGroup.Text>
                            <Form.Control
                                value={fieldForEdit?.accessorForEntity || ''}
                                placeholder={'Accessor for entity'}
                                onChange={e => setFieldForEdit({...fieldForEdit, accessorForEntity: e.target.value})}
                            />
                        </InputGroup>
                        <InputGroup className={'mb-2'}>
                            <InputGroup.Text><ListIcon/></InputGroup.Text>
                            <Form.Control
                                value={fieldForEdit?.label || ''}
                                placeholder={'Label'}
                                onChange={e => setFieldForEdit({...fieldForEdit, label: e.target.value})}
                            />
                        </InputGroup>
                        <InputGroup className={'mb-2'}>
                            <InputGroup.Text><ListIcon/></InputGroup.Text>
                            <Form.Control
                                value={fieldForEdit?.position || ''}
                                placeholder={'Позиция'}
                                onChange={e => setFieldForEdit({...fieldForEdit, position: Number(e.target.value)})}
                            />
                        </InputGroup>
                        <Form.Check
                            type={'checkbox'}
                            checked={fieldForEdit?.isMoney || false}
                            label={'IsMoney'}
                            onChange={e => setFieldForEdit({...fieldForEdit, isMoney: !fieldForEdit?.isMoney})}
                        />
                        <Form.Check
                            type={'checkbox'}
                            checked={fieldForEdit?.isMultiple || false}
                            label={'Множественное'}
                            onChange={e => setFieldForEdit({...fieldForEdit, isMultiple: !fieldForEdit?.isMultiple})}
                        />
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"danger"} onClick={() => {
                        setFieldForEdit(null)
                        setShowModalEdit(false)
                    }}>Отменить</Button>
                    <Button variant={"primary"} onClick={() => changeField()}>Сохранить</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showModalEditBlock} onHide={() => {
                setBlockForEdit(null)
                setShowModalEditBlock(false)
            }}>
                <Modal.Header>
                    <Modal.Title>Редактирование блока</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <InputGroup className={'mb-2'}>
                            <InputGroup.Text><ListIcon/></InputGroup.Text>
                            <Form.Control
                                value={blockForEdit?.blockTitle || ''}
                                placeholder={'Наименование'}
                                onChange={e => setBlockForEdit({...blockForEdit, blockTitle: e.target.value})}
                            />
                        </InputGroup>
                        <InputGroup className={'mb-2'}>
                            <InputGroup.Text><ListIcon/></InputGroup.Text>
                            <Form.Control
                                value={blockForEdit?.position || ''}
                                placeholder={'Позиция'}
                                onChange={e => setBlockForEdit({...blockForEdit, position: Number(e.target.value)})}
                            />
                        </InputGroup>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"danger"} onClick={() => {
                        setBlockForEdit(null)
                        setShowModalEditBlock(false)
                    }}>Отменить</Button>
                    <Button variant={"primary"} onClick={() => changeBlock()}>Сохранить</Button>
                </Modal.Footer>
            </Modal>
            <Modal fullscreen={true} show={showModalItemsList} onHide={() => closeItemsList()}>
                <Modal.Header>
                </Modal.Header>
                <Modal.Body>
                    <FieldItemList
                        fieldInItemList={itemForItemsList}
                        setNewField={newItemForItemsList => setItemForItemsList(newItemForItemsList)}
                        updateBlocks={() => {
                            updateBlocks()
                        }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"danger"} onClick={() => closeItemsList()}>Отменить</Button>
                    <Button variant={"primary"} onClick={() => closeItemsList()}>Сохранить</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};


const CustomToggle = ({eventKey}) => {
    const { activeEventKey } = useContext(AccordionContext)
    const decoratedOnClick = useAccordionButton(eventKey, () => {})
    return (
        <div
            className={`flex center pointer custom-toggle ${activeEventKey?.find(x => x === eventKey) === eventKey ? 'custom-toggle-rotate' : ''}`}
            onClick={decoratedOnClick}
        >
            <ChevronDownIcon/>
        </div>

    )
}

export { CustomToggle };
export default BlocksList;