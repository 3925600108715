import './TextareaMultifield.css';
import {forwardRef} from "react";

const TextareaMultiField = forwardRef (function TextareaMultiField(props, ref) {
    const {text, onChange} = props;
    return (
        <textarea
            className={'multi-field__textarea'} value={text}
            onChange={e => onChange?.(e)}
            ref={ref}
        >
        </textarea>
    );
});

export default TextareaMultiField;