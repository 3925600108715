import './RemainsToolbar.css'
import {useEffect, useState} from "react";
import {ReactComponent as Settings} from "../../../../components/UI/icons/gear.svg";
import useRemainsStore from "../../store/remainsStore";
import RemainsSearchInput from "../remainssearchinput/RemainsSearchInput";
import {Modal} from "react-bootstrap";
import RemainsSettings from "../remainssettings/RemainsSettings";

const RemainsToolbar = () => {
    const [settingsVisible, setSettingsVisible] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const setSearchQuery = useRemainsStore.getState().setSearchQuery;

    const searchData = ({value}) => {
        let searchQuery = '';

        if (value) {
            if (value?.length !== 0) {
                searchQuery = value;
            }
        } else {
            if (searchValue.length !== 0) {
                searchQuery = searchValue;
            }
        }
        setSearchQuery(searchQuery);
    }

    useEffect(() => {
        searchData({value: ''});
    }, []);

    return (
        <div className='remains-toolbar'>
            <RemainsSearchInput searchValue={searchValue} setSearchValue={setSearchValue} search={searchData}/>
            <button className="remains-toolbar__settings"
                onClick={() => setSettingsVisible(!settingsVisible)}
            >
                <Settings className={'remains-toolbar__icon'}/>
            </button>
            <Modal
                show={settingsVisible}
                onHide={() => setSettingsVisible(!settingsVisible)}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Настройки</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <RemainsSettings/>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default RemainsToolbar;