import {useEffect, useState} from "react";
import PaginationModule from "../pagination/Pagination";


const usePaginationController = ({pagesCount, defaultAccessor = 'pages', paginationLimit = 8, changePageCallback}) => {
    const [controllerData, setControllerData] = useState({
        page: 1,
        paginationLimit: paginationLimit,
        pagesCount: pagesCount,
    });

    useEffect(() => {
        setControllerData({...controllerData, pagesCount})
    }, [pagesCount]);

    const changePage = (pageNumber) => {
        if (pageNumber !== controllerData.page) {
            setControllerData({...controllerData, page: pageNumber});
            if (changePageCallback) changePageCallback(pageNumber);
        }
    }

    return {
        controllerData: controllerData,
        changePage: changePage,
        paginationModule: (
            <PaginationModule
                controllerData={controllerData}
                changePage={changePage}
            />
        )
    }
};

export default usePaginationController;