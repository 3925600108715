import {Button, Stack,} from "react-bootstrap";
import DropdownDefault from "../../../../components/UI/selects/dropdowndefault/DropdownDefault";
import {
    converterEntityToOptionOrReverse,
    getOptionsFromArrayEntities
} from "../../../../features/entityhelper/EntityHelper";
import NumericInput from "../../../../components/UI/inputs/numeric/NumericInput";

const ProvidersItem = ({provideItem, changeProvideItem, removeProvideItem, warehouses, remains}) => {



    return (
        <div className={'flex justify-content-between align-items-center mb-3'}>
            <Stack direction={"horizontal"} className={'flex justify-content-start align-items-center gap-3'}>
                {provideItem?.isDefaultProvision
                    ? `Склад: ${provideItem?.warehouse?.fullName || ''}`
                    :
                        <DropdownDefault
                            placeholder={'Склад'}
                            options={getOptionsFromArrayEntities({
                                entities: warehouses?.filter(x => x?.isVisible) || [],
                                accessorName: 'warehouse',
                                valueCallback: itemC => itemC?.fullName,
                                titleCallback: itemC => `${itemC?.fullName} - ⚙️${remains?.find(x => x?.warehouse?.id === itemC?.id)?.canProduce || 0} л`,
                                idCallback: itemC => itemC?.id
                            }) || []}
                            currentValue={{
                                accessor: 'warehouse',
                                id: provideItem?.warehouse?.id,
                                title: provideItem?.warehouse?.fullName,
                                value: provideItem?.warehouse?.fullName,
                            }}
                            onChange={newValue => {
                                changeProvideItem(
                                    {
                                        ...provideItem, warehouse: converterEntityToOptionOrReverse({
                                            endpoint: 'entity',
                                            arrayEntities: warehouses || [],
                                            searchPropertyForArray: newValue?.id
                                        })
                                    }
                                )
                            }}
                        />
                }
                <NumericInput
                    defaultValue={provideItem?.quantityProvision || 0}
                    useDebounce
                    controlled
                    label={'Количество'}
                    className={'w-25'}
                    onChange={newValue => changeProvideItem({...provideItem, quantityProvision: newValue})}
                    suffics={' л'}
                    type={'number'}
                />
            </Stack>
            {provideItem?.isDefaultProvision
                ? <span>Склад по умолчанию</span>
                : <Button variant={'outline-danger'} onClick={() => removeProvideItem(provideItem?.guid)}>Удалить</Button>
            }
        </div>
    );
};

export default ProvidersItem;