import './ClientsTS.css'
import {useEffect, useMemo, useState} from "react";
import useLoader from "../../../../hooks/useLoader";
import {useNavigate} from "react-router";
import {AxiosHelper} from "../../../../hooks/useAxiosHelper";
import {usePaginationController} from "../../../../components/pagination/components";
import EditIcon from "../../../../components/UI/icons/EditIcon";
import ListIcon from "../../../../components/UI/icons/ListIcon";
import BackCrossIcon from "../../../../components/UI/icons/BackCrossIcon";
import useCheckAccess from "../../../../hooks/useCheckAccess";
import AccessDenied from "../../../../components/access/AccessDenied";
import GuidsWrapper from "../../../../components/guids/wrapper/GuidsWrapper";
import GuidsHeader from "../../../../components/guids/header/GuidsHeader";
import TitleDetailCard from "../../../../components/UI/titledetailcard/TitleDetailCard";
import GuidsSearchInput from "../../../../components/guids/search/GuidsSearchInput";
import GuidsBody from "../../../../components/guids/body/GuidsBody";
import TableBootstrap from "../../../../components/tableb/TableBootstrap";
import GuidsPagination from "../../../../components/guids/pagination/GuidsPagination";
import AssociatedEntities from "../../../../components/associatedentities/AssociatedEntities";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import useModals from "../../../../hooks/usemodal/useModals";
import useAxiosErrorHandler from "../../../../hooks/axioserrorhandler/useAxiosErrorHandler";
import {Button, Container} from "react-bootstrap";

const ClientsTs = ({assignedId}) => {
    const [search, setSearch] = useState('')
    const [filters, setFilters] = useState({
        assignedIds: assignedId ? [assignedId] : []
    })
    const {hideAll, show, hide} = useLoader()
    const [add, modals] = useModals()
    const navigate = useNavigate()
    const handleErrorResponse = useAxiosErrorHandler()

    const [page, setPage] = useState(1)

    const client = useQueryClient()

    const {data: items} = useQuery({
        queryKey: ['client-list', page, filters],
        queryFn: ({queryKey: [_, page, filters]}) => AxiosHelper.postMethod({
            urlWithoutBaseUrl: 'ancorr/api/client/list',
            queryParams: {
                offset: 17,
                page: page
            },
            data: filters,
        }),
        onError: (error) => handleErrorResponse(error),
        onMutate: () => show(),
        onSettled: () => hide()
    })



    const {mutate: addItem} = useMutation({
        mutationKey: ['client-list'],
        mutationFn: () => AxiosHelper.postMethod({
            urlWithoutBaseUrl: 'ancorr/api/client/add/client',
            data: {
                assignedId
            },
        }),
        onSuccess: () => client.invalidateQueries({queryKey: ['client-list']}),
        onError: (error) => handleErrorResponse(error),
        onMutate: () => show(),
        onSettled: () => hide()
    })
    const {mutate: removeItem} = useMutation({
        mutationKey: ['client-list'],
        mutationFn: (guid) => AxiosHelper.deleteMethod({
            urlWithoutBaseUrl: 'ancorr/api/client/delete/client',
            queryParams: {
                guid: guid
            },
        }),
        onSuccess: () => client.invalidateQueries({queryKey: ['client-list']}),
        onError: (error) => handleErrorResponse(error),
        onMutate: () => show(),
        onSettled: () => hide()
    })

    useEffect(() => {
        document.title = "Мой клиент: Клиенты"
    }, [])

    const {paginationModule} = usePaginationController({
        pagesCount: items?.pages || 1,
        changePageCallback: (data) => {
            setPage(data === 0 ? 1 : data)
        }
    })

    useEffect(() => {
        if(items?.currentPage > items?.pages){
            if(items?.pages === 0)
                setPage(1)
            else
                setPage(items?.pages)
        }
    }, [items]);

    const memoData = useMemo(() => items?.data/*?.filter(x => x?.generalName?.toLowerCase()?.includes(search?.toLowerCase()))*/, [items, search])
    const popoverActions = [
        {
            icon: <EditIcon classNames={'cl-light-purple'}/>,
            title: 'Редактировать',
            onClick: item => navigate(`/management/clients/detail/${item?.id}`)
        },
        {
            icon: <ListIcon/>,
            title: 'Связанные элементы',
            onClick: item => {
                let onRemoveItem = add({
                    children:
                        <AssociatedEntities
                            clientId={item?.id}
                            assignedId={assignedId}
                            project
                            dealBuy
                            dealRent
                            appealBuy
                            appealRent
                            offerBuy
                            offerRent
                        />,
                    fullscreen: true,
                    buttons: [
                        <Button onClick={() => onRemoveItem()} variant={"danger"}>Закрыть</Button>
                    ]
                })
            }
        },
        {
            icon: <BackCrossIcon classNames={'cl-red-tomato'}/>,
            title: 'Удалить',
            classNamesText: 'cl-red-tomato',
            onClick: item => removeItem(item?.guid)
        },
    ]


    const access = useCheckAccess("client/detail")
    if(!access){
        hideAll()
        return <AccessDenied />
    }

    return (
        <GuidsWrapper padding>
            <GuidsHeader>
                <TitleDetailCard text={'СПИСОК КЛИЕНТОВ'}/>
                <Container fluid className={'d-flex justify-content-end gap-2'}>
                    <GuidsSearchInput onChange={newValue => setSearch(newValue)}/>
                    <Button onClick={() => addItem()}>Добавить</Button>
                </Container>
            </GuidsHeader>
            <GuidsBody>
                <TableBootstrap
                    popoverCaption={'Действия'}
                    items={memoData || []}
                    schema={schema}
                    popoverActions={popoverActions}
                />
            </GuidsBody>

            <GuidsPagination>
                {
                    paginationModule
                }
            </GuidsPagination>
            {modals}
        </GuidsWrapper>
    );
};

const schema = [
    {
        title: 'ID',
        callbackItem: item => item?.id,
    },
    {
        title: 'Наименование',
        callbackItem: item => item?.generalName,
    },
    {
        title: 'GUID',
        callbackItem: item => item?.guid || '',
    },
    {
        title: 'Дата',
        callbackItem: item => new Date(item?.createdTimeDb)?.toLocaleDateString('ru-RU') || "Нет данных",
    },
]
export default ClientsTs;