import './Guids.css'
import DetailWrapper from "../../../components/detailcard/wrapper/DetailWrapper";
import TabsBlock from "../../../components/tabsblock/TabsBlock";
import DetailBody from "../../../components/detailcard/body/DetailBody";


const Guids = () => {
    return (
        <DetailWrapper>
            <TabsBlock>
            </TabsBlock>
            <DetailBody>
            </DetailBody>
        </DetailWrapper>
    );
};

export default Guids;