import {forwardRef, useImperativeHandle, useState} from "react";
import useLoader from "../../../../../hooks/useLoader";
import {useMutation} from "@tanstack/react-query";
import {AxiosHelper} from "../../../../../hooks/useAxiosHelper";
import {Button, Form, Modal, Stack} from "react-bootstrap";
import TextInput from "../../../../../components/UI/inputs/text/TextInput";
import ContactEditInputModule from "../../ContactEditInputModule";
import CompanyEditInputModule from "../../CompanyEditInputModule";
import useControlledValue from "../../../../../hooks/controlled/useControlledValue";

const EditClientModule = forwardRef(({onChange, defaultValue = null, controlled = false}, ref) => {

    const [visibleModal, setVisibleModal] = useState(false)
    const {show, hide} = useLoader()
    const [value, setValue] = useControlledValue({defaultValue, controlled})

    const {mutate: updateClient} = useMutation({
        mutationKey: ['client', 'modal', 'edit'],
        mutationFn: (clientDTO) => AxiosHelper.postMethod({
            urlWithoutBaseUrl: `ancorr/api/client/update/client`,
            data: clientDTO
        }),
        onMutate: () => show(),
        onSettled: () => hide(),
        onSuccess: (data, clientDTO) => {
            onChange?.(clientDTO)
        }
    })


    useImperativeHandle(ref, () => ({
        open: () => setVisibleModal(true),
        close: () => setVisibleModal(false),
    }), [])
    return (
        <Modal onHide={() => setVisibleModal(false)} show={visibleModal} size={"lg"}>
            <Modal.Header><Modal.Title>Редактирование клиента</Modal.Title></Modal.Header>
            <Modal.Body>
                <Form className={'overflow-auto'} style={{maxHeight: '70vh'}}>
                    <Stack className={'mb-3'}>
                        <TextInput
                            sx={{width: 500}}
                            label={'Наименование'}
                            controlled
                            defaultValue={value?.generalName}
                            onChange={newValue => setValue({...value, generalName: newValue})}
                        />
                    </Stack>
                    <Stack className={'mb-3'}>
                        <ContactEditInputModule
                            controlled
                            defaultValue={value?.mainContactInfo}
                            label={'Основной контакт'}
                            onChange={newValue => setValue({...value, mainContactInfo: newValue})}
                        />
                    </Stack>
                    <Stack className={'mb-3'}>
                        <CompanyEditInputModule
                            controlled
                            defaultValue={value?.mainCompany}
                            label={'Основная компания'}
                            onChange={newValue => setValue({...value, mainCompany: newValue})}
                        />
                    </Stack>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant={"danger"} onClick={() => setVisibleModal(false)}>Закрыть</Button>
                <Button
                    onClick={() => {
                        updateClient(value)
                    }}
                >
                    Применить
                </Button>
            </Modal.Footer>
        </Modal>
    )
});

export default EditClientModule;