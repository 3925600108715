import {useState} from 'react';
import './Register.css'
import {Button, Form, InputGroup} from "react-bootstrap";
import EmailIcon from "../../../components/UI/icons/EmailIcon";
import CastleIcon from "../../../components/UI/icons/CastleIcon";
const Register = () => {
    const [registerInfo, setRegisterInfo] = useState({
        email: '',
        password: '',
        userName: '',
    })

    const [isBadCredentials, setIsBadCredentials] = useState(false)
    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() !== false) {

        }

    };
    return (
        <Form className={'login__inputs'} onSubmit={handleSubmit}>
            <InputGroup className={'w-100'} hasValidation>
                <InputGroup.Text id={'basic-addon2'}>@</InputGroup.Text>
                <Form.Control
                    value={registerInfo?.userName}
                    type={'text'}
                    placeholder={'Имя пользователя'}
                    aria-label={'Имя пользователя'}
                    aria-describedby={'basic-addon2'}
                    onChange={e => setRegisterInfo({...registerInfo, userName: e.target.value})}
                    isInvalid={isBadCredentials}
                    required
                />
            </InputGroup>
            <InputGroup className={'w-100'} hasValidation>
                <InputGroup.Text id={'basic-addon1'}><EmailIcon/></InputGroup.Text>
                <Form.Control
                    value={registerInfo?.email}
                    type={'email'}
                    placeholder={'Email'}
                    aria-label={'Email'}
                    aria-describedby={'basic-addon1'}
                    onChange={e => setRegisterInfo({...registerInfo, email: e.target.value})}
                    isInvalid={isBadCredentials}
                    required
                />
            </InputGroup>
            <InputGroup className={'w-100'} hasValidation>
                <InputGroup.Text id={'basic-addon3'}><CastleIcon/></InputGroup.Text>
                <Form.Control
                    value={registerInfo?.password}
                    type={'password'}
                    placeholder={'Пароль'}
                    aria-label={'Пароль'}
                    aria-describedby={'basic-addon3'}
                    onChange={e => setRegisterInfo({...registerInfo, password: e.target.value})}
                    isInvalid={isBadCredentials}
                    required
                />
            </InputGroup>
                <Button bsPrefix={'register-btn'} type={'submit'}>Отправить запрос</Button>
        </Form>
    );
};

export default Register;