import {useEffect, useState} from 'react';
import './GuidsFieldsList.css'
import useLoader from "../../../../../hooks/useLoader";
import {useNavigate} from "react-router";
import EditIcon from "../../../../../components/UI/icons/EditIcon";
import BackCrossIcon from "../../../../../components/UI/icons/BackCrossIcon";
import useCheckAccess from "../../../../../hooks/useCheckAccess";
import AccessDenied from "../../../../../components/access/AccessDenied";
import GuidsHeader from "../../../../../components/guids/header/GuidsHeader";
import TitleDetailCard from "../../../../../components/UI/titledetailcard/TitleDetailCard";
import GuidsSearchInput from "../../../../../components/guids/search/GuidsSearchInput";
import GuidsBody from "../../../../../components/guids/body/GuidsBody";
import TableBootstrap from "../../../../../components/tableb/TableBootstrap";
import GuidsWrapper from "../../../../../components/guids/wrapper/GuidsWrapper";
import GuidsPagination from "../../../../../components/guids/pagination/GuidsPagination";
import {usePaginationController} from "../../../../../components/pagination/components";
import useAxiosHelper from "../../../../../hooks/useAxiosHelper";
import {Button, Container} from "react-bootstrap";
const GuidsFieldsList = () => {


    const [searchValue, setSearchValue] = useState([])
    const [items, setItems] = useState({})
    const [filters, setFilters] = useState({})
    const {postMethod, deleteMethod} = useAxiosHelper({})
    const [page, setPage] = useState(1)
    const {hideAll} = useLoader()
    const navigate = useNavigate()

    useEffect(() => {
        document.title = "Мой клиент: Fields"
    }, [])


    useEffect(() => {
        if(items?.currentPage > items?.pages)
            setPage(items?.pages)
    }, [items]);

    useEffect(() => {
        if (page === 1) changePage(page);

        updateItems()
    }, [page]);

    const updateItems = () => postMethod({
        urlWithoutBaseUrl: 'ancorr/api/field/entity/list',
        queryParams: {
            offset: 15,
            page: page
        },
        data: filters,
        successCallback: ({data}) => setItems(data)

    })

    const addItem = () => postMethod({
        urlWithoutBaseUrl: 'ancorr/api/field/add/entity',
        successCallback: () => updateItems()
    })
    const removeItem = (guid) => deleteMethod({
        urlWithoutBaseUrl: 'ancorr/api/field/delete/entity',
        queryParams: {
            guid: guid
        },
        successCallback: () => updateItems()
    })

    const {changePage, paginationModule} = usePaginationController({
        pagesCount: items?.pages || 1,
        changePageCallback: (data) => setPage(data)
    })

    const popoverActions = [
        {
            icon: <EditIcon classNames={'cl-light-purple'}/>,
            title: 'Редактировать',
            onClick: item => navigate(`detail/${item?.id}`)
        },
        {
            icon: <BackCrossIcon classNames={'cl-red-tomato'}/>,
            title: 'Удалить',
            classNamesText: 'cl-red-tomato',
            onClick: item => removeItem(item?.guid)
        },
    ]

    const access = useCheckAccess("fields/list")

    if(!access){
        hideAll()
        return <AccessDenied />
    }

    return (
        <GuidsWrapper>
            <GuidsHeader>
                <TitleDetailCard text={'СПИСОК ENTITIES'}/>
                <Container fluid className={'d-flex justify-content-end gap-2'}>
                    <GuidsSearchInput onChange={newValue => setSearchValue(newValue)}/>
                    <Button onClick={() => addItem()}>Добавить</Button>
                </Container>
            </GuidsHeader>
            <GuidsBody>
                <TableBootstrap
                    schema={schema}
                    items={items?.data || []}
                    popoverActions={popoverActions}
                    popoverCaption={"Действия"}
                />
            </GuidsBody>
            <GuidsPagination>
                {
                    paginationModule
                }
            </GuidsPagination>
        </GuidsWrapper>
    );
};

const schema = [
    {
        title: 'ID',
        callbackItem: item => item?.id,
    },
    {
        title: 'Полное наименование',
        callbackItem: item => item?.name,
    },
    {
        title: 'Тип сущности',
        callbackItem: item => item?.entityType?.title || 'Не заполнено',
    },
]

export default GuidsFieldsList;