import React from 'react';
import CustomItem from "../customitem/CustomItem";

const ObjectData = ({rules, data, itemClasses, textClasses}) => {

    return (
        rules.map(rule => {
            let result = null;
            let key = null; //key for map
            let itemKey = null;  // key for map Item components
            result = rule.text(data) || null; // Array items, or one item, or null
            key = rule.key(data); // Array keys or one key
            itemKey = rule.itemKey(data);
            if (result) return <CustomItem
                data={result}
                key={itemKey}
                textKey={key}
                itemClasses={itemClasses}
                textClasses={textClasses}
            />
        })
    );
};

export default ObjectData;