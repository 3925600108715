import React, {useEffect, useRef} from 'react';
import './RemainsSearchInput.css';
import {Container, Form, InputGroup} from "react-bootstrap";
import SearchIcon from "../../../../components/UI/icons/SearchIcon";

const RemainsSearchInput = ({searchValue, setSearchValue, search}) => {
    const refBtn = useRef();

    useEffect(() => {
        refBtn.current?.focus();
        refBtn.current?.addEventListener(
            'keydown',
            handlerEnterDown
        )
        return () => {
            refBtn.current?.removeEventListener(
                'keydown',
                handlerEnterDown
            )
        }
    }, [refBtn]);

    const handlerEnterDown = (e) => {
        if (e.key === 'Enter') {
            search({value: e.target.value});
        }
    }

    return (
        <Container fluid className={'flex center'}>
            <InputGroup
                ref={refBtn}
            >
                <Form.Control
                    value={searchValue}
                    onChange={e => setSearchValue?.(e.target.value)}
                    placeholder={'Поиск'}
                />
                <InputGroup.Text
                    className='remains-search-input__btn'
                    onClick={e => search?.(e)}
                >
                    <SearchIcon
                        classNames='remains-search-input__btn-icon'
                    />
                </InputGroup.Text>
            </InputGroup>
        </Container>
    );
};

export default RemainsSearchInput;