import {AxiosHelper} from "../../../hooks/useAxiosHelper";
import BX24 from "bx24-api";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {useNavigate, useParams} from "react-router";
import {useAuth} from "../../../features/authprovider/AuthProvider";
import useLoader from "../../../hooks/useLoader";

const UseTkp = () => {
    const {userInfo} = useAuth()
    const client = useQueryClient()
    const params = useParams()
    const id = Number(params['id'] || 0)
    const currentTkp = client.getQueryData(['current-tkp', id])
    const {show, hide} = useLoader()
    const navigate = useNavigate()

    const {mutate: addSystem} = useMutation({
        mutationKey: ['current-tkp', id],
        mutationFn: () => AxiosHelper.getMethod({
            urlWithoutBaseUrl: 'ancorr/api/tkp/v2/add/system',
            queryParams: {
                tkpId: currentTkp?.id,
                token: userInfo?.accessToken || ''
            }
        }),
        onSuccess: () => client.invalidateQueries({queryKey: ['current-tkp', id]}),
        onMutate: () => show(),
        onSettled: () => hide()
    })
    const {mutate: removeSystem} = useMutation({
        mutationKey: ['current-tkp', id],
        mutationFn: (guid) => AxiosHelper.deleteMethod({
            urlWithoutBaseUrl: 'ancorr/api/tkp/v2/delete/system',
            queryParams: {
                guid: guid,
                tkpId: currentTkp?.id,
                token: userInfo?.accessToken || '',
            },
        }),
        onSuccess: () => client.invalidateQueries({queryKey: ['current-tkp', id]}),
        onMutate: () => show(),
        onSettled: () => hide()
    })
    const {mutate: changeTkp} = useMutation({
        mutationKey: ['current-tkp', id],
        mutationFn: (newItem) => AxiosHelper.postMethod({
            urlWithoutBaseUrl: 'ancorr/api/tkp/v2/edit/tkp',
            data: newItem,
            queryParams: {
                token: userInfo?.accessToken || ''
            },
            useLoader: false
        }),
        onSuccess: () => client.invalidateQueries({queryKey: ['current-tkp', id]}),
        onMutate: (newItem) => client.setQueryData(['current-tkp', id], (oldCurrentTkp) => newItem)
    })

    const {mutate: AddProvisionItem} = useMutation({
        mutationKey: ['current-tkp', id],
        mutationFn: ({type, itemId}) => AxiosHelper.getMethod({
            urlWithoutBaseUrl: 'ancorr/api/tkp/v2/add/provision',
            queryParams: {
                type: type,
                itemId: itemId,
                tkpId: currentTkp?.id,
            },
        }),
        onSuccess: () => client.invalidateQueries({queryKey: ['current-tkp', id]}),
        onMutate: () => show(),
        onSettled: () => hide()
    })
    const {mutate: RemoveProvisionItem} = useMutation({
        mutationKey: ['current-tkp', id],
        mutationFn: ({type, guid, itemId}) => AxiosHelper.deleteMethod({
            urlWithoutBaseUrl: 'ancorr/api/tkp/v2/delete/provision',
            queryParams: {
                type: type,
                itemId: itemId,
                guid: guid,
                tkpId: currentTkp?.id,
            },
        }),
        onSuccess: () => client.invalidateQueries({queryKey: ['current-tkp', id]}),
        onMutate: () => show(),
        onSettled: () => hide()
    })

    const {mutate: ChangeProvisionItem} = useMutation({
        mutationKey: ['current-tkp', id],
        mutationFn: (item) => AxiosHelper.postMethod({
            urlWithoutBaseUrl: 'ancorr/api/tkp/v2/edit/provision',
            data: item,
            queryParams: {
                tkpId: currentTkp?.id,
            },
            useLoader: false
        }),
        onSuccess: () => client.invalidateQueries({queryKey: ['current-tkp', id]})
    })

    const {mutate: changeSystem} = useMutation({
        mutationKey: ['current-tkp', id],
        mutationFn: (newItem) => AxiosHelper.postMethod({
            urlWithoutBaseUrl: 'ancorr/api/tkp/v2/edit/system',
            data: newItem,
            queryParams: {
                tkpId: currentTkp?.id,
                token: userInfo?.accessToken || ''
            },
            useLoader: false
        }),
        onSuccess: () => client.invalidateQueries({queryKey: ['current-tkp', id]})
    })

    const {mutate: changeSummaryEquipment} = useMutation({
        mutationKey: ['current-tkp', id],
        mutationFn: (newItem) => AxiosHelper.postMethod({
            urlWithoutBaseUrl: 'ancorr/api/tkp/v2/edit/summary/materials',
            data: newItem,
            queryParams: {
                tkpId: currentTkp?.id,
                token: userInfo?.accessToken || ''
            },
            useLoader: false
        }),
        onSuccess: () => client.invalidateQueries({queryKey: ['current-tkp', id]})
    })

    const {mutate: changeSummaryDiluent} = useMutation({
        mutationKey: ['current-tkp', id],
        mutationFn: (newItem) => AxiosHelper.postMethod({
            urlWithoutBaseUrl: 'ancorr/api/tkp/v2/edit/summary/diluents',
            data: newItem,
            queryParams: {
                tkpId: currentTkp?.id,
                token: userInfo?.accessToken || ''
            },
            useLoader: false
        }),
        onSuccess: () => client.invalidateQueries({queryKey: ['current-tkp', id]})
    })

    const {mutate: changeSummaryAdditional} = useMutation({
        mutationKey: ['current-tkp', id],
        mutationFn: (newItem) => AxiosHelper.postMethod({
            urlWithoutBaseUrl: 'ancorr/api/tkp/v2/edit/summary/additional',
            data: newItem,
            queryParams: {
                tkpId: currentTkp?.id,
                token: userInfo?.accessToken || ''
            },
            useLoader: false
        }),
        onSuccess: () => client.invalidateQueries({queryKey: ['current-tkp', id]})
    })

    const {mutate: addSummaryAdditional} = useMutation({
        mutationKey: ['current-tkp', id],
        mutationFn: () => AxiosHelper.getMethod({
            urlWithoutBaseUrl: 'ancorr/api/tkp/v2/add/summary/additional',
            queryParams: {
                tkpId: currentTkp?.id,
                token: userInfo?.accessToken || ''
            },
        }),
        onSuccess: () => client.invalidateQueries({queryKey: ['current-tkp', id]}),
        onMutate: () => show(),
        onSettled: () => hide()
    })
    const {mutate: removeSummaryAdditional} = useMutation({
        mutationKey: ['current-tkp', id],
        mutationFn: (guid) => AxiosHelper.deleteMethod({
            urlWithoutBaseUrl: 'ancorr/api/tkp/v2/delete/summary/additional',
            queryParams: {
                guid: guid,
                tkpId: currentTkp?.id,
                token: userInfo?.accessToken || ''
            },
        }),
        onSuccess: () => client.invalidateQueries({queryKey: ['current-tkp', id]}),
        onMutate: () => show(),
        onSettled: () => hide()
    })

    const {mutate: addSlice} = useMutation({
        mutationKey: ['current-tkp', id],
        mutationFn: (systemId) => AxiosHelper.getMethod({
            urlWithoutBaseUrl: 'ancorr/api/tkp/v2/add/slice',
            queryParams: {
                tkpSystemId: systemId,
                token: userInfo?.accessToken || ''
            },
        }),
        onSuccess: () => client.invalidateQueries({queryKey: ['current-tkp', id]}),
        onMutate: () => show(),
        onSettled: () => hide()
    })
    const {mutate: removeSlice} = useMutation({
        mutationKey: ['current-tkp', id],
        mutationFn: ({systemId, guid}) => AxiosHelper.deleteMethod({
            urlWithoutBaseUrl: 'ancorr/api/tkp/v2/delete/slice',
            queryParams: {
                guid: guid,
                systemId: systemId,
                tkpId: currentTkp?.id,
                token: userInfo?.accessToken || ''
            },
        }),
        onSuccess: () => client.invalidateQueries({queryKey: ['current-tkp', id]}),
        onMutate: () => show(),
        onSettled: () => hide()
    })

    const {mutate: changeSlice} = useMutation({
        mutationKey: ['current-tkp', id],
        mutationFn: ({systemId, newItem}) => AxiosHelper.postMethod({
            urlWithoutBaseUrl: 'ancorr/api/tkp/v2/edit/slice',
            data: newItem,
            queryParams: {
                tkpId: currentTkp?.id,
                systemId: systemId,
                token: userInfo?.accessToken || '',
            },
            useLoader: false
        }),
        onSuccess: () => client.invalidateQueries({queryKey: ['current-tkp', id]}),
        onMutate: ({systemId, newItem}) => client.setQueryData(['current-tkp', id], (oldCurrentTkp) => ({
            ...oldCurrentTkp,
            systems: oldCurrentTkp?.systems?.map(system => system?.id === systemId ? ({
                ...system,
                slices: system?.slices?.map(slice => slice?.id === newItem?.id ? newItem : slice)
            }) : system)
        }))
    })

    const {mutate: changeSlicePositionTkp} = useMutation({
        mutationKey: ['current-tkp', id],
        mutationFn: ({oldItemKey, newItemKey}) => AxiosHelper.getMethod({
            urlWithoutBaseUrl: 'ancorr/api/tkp/v2/edit/position/slice',
            queryParams: {
                oldItemKey: oldItemKey,
                newItemKey: newItemKey
            },
        }),
        onSuccess: () => client.invalidateQueries({queryKey: ['current-tkp', id]}),
        onMutate: () => show(),
        onSettled: () => hide()
    })
    const {mutate: changeSystemPositionTkp} = useMutation({
        mutationKey: ['current-tkp', id],
        mutationFn: ({oldItemKey, newItemKey}) => AxiosHelper.getMethod({
            urlWithoutBaseUrl: 'ancorr/api/tkp/v2/edit/position/system',
            queryParams: {
                oldItemKey: oldItemKey,
                newItemKey: newItemKey
            },
        }),
        onSuccess: () => client.invalidateQueries({queryKey: ['current-tkp', id]}),
        onMutate: () => show(),
        onSettled: () => hide()
    })

    const addProvisionItemTkp = (type, itemId) => AddProvisionItem({type, itemId});
    const removeProvisionItemTkp = (type, guid, itemId) => RemoveProvisionItem({type, guid, itemId})
    const changeProvisionItemTkp = (newItem, tkpId) => ChangeProvisionItem(newItem)
    const addSystemTkp = () => addSystem();
    const deleteSystemTkp = guid => removeSystem(guid)
    const changeSystemTkp = (newSystem) => changeSystem(newSystem)
    const addSliceTkp = (systemId) => addSlice(systemId)
    const deleteSliceTkp = (systemId, guid) => removeSlice({systemId, guid})
    const changeSliceTkp = (systemId, newItem) => changeSlice({systemId, newItem})
    const changeSummarySliceDiluent = (newItem) => changeSummaryDiluent(newItem)
    const changeSummarySliceEquipment = (newItem) => changeSummaryEquipment(newItem)
    const changeSummaryAdditionalTkp = (newItem) => changeSummaryAdditional(newItem)
    const addSummaryAdditionalTkp = () => addSummaryAdditional();
    const deleteSummaryAdditionalTkp = guid => removeSummaryAdditional(guid)
    const changeTkpEx = (newTkp) => changeTkp(newTkp)
    const changeSettings = (newSettings) => changeTkp({...currentTkp, settings: newSettings})
    const changeSlicePosition = (oldItemKey, newItemKey) => changeSlicePositionTkp({oldItemKey, newItemKey})
    const changeSystemPosition = (oldItemKey, newItemKey) => changeSystemPositionTkp({oldItemKey, newItemKey})


    const handleBxDealProfit = () => {
        if (window.name.toLowerCase().includes('bitrix')) {
            // Ининциализация аккумулятивных переменных
            let profit = 0; // Аккумулятив конечного профита (прибыли)
            let pricePerLiterDelivery = 0; // Цена вычитаемая из стоимости литра за доставку
            let pricePerLiterService = 0; // Цена вычитаемая из стоимости литра за сервис

            // Расчет доп условий
            if (currentTkp?.settings?.distributeDeliveryToPrice)// Условие: Если стоит галка включить в стоимость доставку, идем вглубь и считаем строчку ниже
                pricePerLiterDelivery = currentTkp?.settings?.deliveryPrice / currentTkp?.totalLiters // Цена вычитаемая из стоимости литра за доставку = Цена доставки / количество литров в ТКП
            if (currentTkp?.settings?.distributeServiceToPrice)// Условие: Если стоит галка включить в стоимость доставку, идем вглубь и считаем строчку ниже
                pricePerLiterService = currentTkp?.settings?.servicePrice / currentTkp?.totalLiters // Цена вычитаемая из стоимости литра за доставку = Цена сервиса / количество литров в ТКП

            // Функции редьюсер для материалов, повторяется для каждого материала в сводной
            currentTkp?.summaryEquipments?.forEach(item => {

                // Берем стоимость литра одного материала с учетом скидки менеджера
                let priceForLiter = item?.priceForLiter;

                // Из стоимости литра выше, вычитаем стоимость доставки литра
                priceForLiter -= pricePerLiterDelivery
                // Из стоимости литра выше, вычитаем стоимость сервиса литра
                priceForLiter -= pricePerLiterService

                let priceForLitrWithoutDiscount = priceForLiter + (priceForLiter * (item?.discountManager / 100))
                //Расчитываем себестоимость без наценки с учетом скидки менеджера
                let costPrice = (priceForLitrWithoutDiscount - 15) / 1.56
                // Вычитаем из цены литра себестоимость
                priceForLiter -= costPrice;
                // К конечному аккумулятиву прибавляем цену за литр умноженную на количество с учетом упаковки указанное в сводной
                profit += priceForLiter * (item?.quantityIncludingPackaging || 0)
            })

            // Функции редьюсер для разбавителей, повторяется для каждого разбавителя в сводной
            currentTkp?.summaryDiluents?.forEach(item => {

                // Берем стоимость литра одного материала с учетом скидки менеджера
                let priceForLiter = item?.priceForLiter;

                // Из стоимости литра выше, вычитаем стоимость доставки литра
                priceForLiter -= pricePerLiterDelivery
                // Из стоимости литра выше, вычитаем стоимость сервиса литра
                priceForLiter -= pricePerLiterService

                let priceForLitrWithoutDiscount = priceForLiter + (priceForLiter * (item?.discountManagerForDiluent / 100))
                //Расчитываем себестоимость без наценки с учетом скидки менеджера
                let costPrice = (priceForLitrWithoutDiscount - 15) / 1.56
                // Вычитаем из цены литра себестоимость
                priceForLiter -= costPrice;
                // К конечному аккумулятиву прибавляем цену за литр умноженную на количество с учетом упаковки указанное в сводной
                profit += priceForLiter * (item?.quantityIncludingPackaging || 0)
            })

            // Математическая функция, округления
            profit = Math.floor(profit)

            if (currentTkp?.dealId) {
                let obj = {
                    id: currentTkp?.dealId,
                    fields: {
                        UF_CRM_6448E1D1AA4F7: profit || 0,
                        OPPORTUNITY: currentTkp?.totalPriceIncludingPackaging || 0
                    }
                }
                BX24.callMethod("crm.deal.update", obj)
                    .then(res => {

                    })
                    .finally(() => {

                    })
            }
        }
        navigate(-1);
    }
    return {
        changeSystemPosition,
        changeSlicePosition,
        handleBxDealProfit,
        addProvisionItemTkp,
        removeProvisionItemTkp,
        changeProvisionItemTkp,
        addSystemTkp,
        addSliceTkp,
        deleteSystemTkp,
        deleteSliceTkp,
        changeSettings,
        changeSystemTkp,
        changeSliceTkp,
        changeSummarySliceEquipment,
        changeSummarySliceDiluent,
        changeTkpEx,
        changeSummaryAdditionalTkp,
        addSummaryAdditionalTkp,
        deleteSummaryAdditionalTkp
    }
};

export default UseTkp;