import {useState} from 'react';
import {useMutation} from "@tanstack/react-query";
import {AxiosHelper} from "../useAxiosHelper";
import useDebouncingFunction from "../debouncingfunction/useDebouncingFunction";

const UseCompanyInnSearcher = () => {
    const {mutate: updateCompaniesOptionFn} = useMutation({
        mutationKey: ['companies', 'options', 'inn'],
        mutationFn: (prompt) => AxiosHelper.getMethod({
            urlWithoutBaseUrl: `ancorr/api/company/get/search`,
            queryParams: {
                search: prompt
            }
        }),
        onSuccess: (data) => setOptionsCompanies(data)
    })
    const [optionsCompanies, setOptionsCompanies] = useState([])
    const [inputCompanies, setInputCompanies] = useState('')
    const updateInputPrompt = useDebouncingFunction((newInputValue) => {
        if(newInputValue)
            updateCompaniesOptionFn(newInputValue)
    }, 1200)
    return {
        optionsCompanies,
        inputCompanies,
        updateInputCompanies: (newInputValue) => {
            setInputCompanies(newInputValue)
            updateInputPrompt(newInputValue)
        },
        onBlur: () => {
            setInputCompanies('')
            setOptionsCompanies([])
        }
    };
};

export default UseCompanyInnSearcher;