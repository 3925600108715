import './FolderNomenclaturesMaterials.css';
import {ReactComponent as NomenclatureIcon} from "../../../../../components/UI/icons/folder.svg";
import {useBreadcrumbsStore} from "../../../../../components/breadcrumbs";
import {useNavigate} from "react-router";


const FolderNomenclaturesMaterials = ({folder}) => {
    const addBreadcrumb = useBreadcrumbsStore.getState().addBreadcrumb;
    const navigate = useNavigate();
    return (
        <div className='folder-nomenclatures-materials'
             onClick={e => {
                 addBreadcrumb(
                     folder.name,
                     () => navigate(`/profile/materials/nomenclature/${folder.id}`)
                 );
                 navigate(`/profile/materials/nomenclature/${folder.id}`);
             }}
        >
            <NomenclatureIcon className='folder-nomenclatures-materials__icon'/>
            <span className="folder-nomenclatures-materials__name">
                {folder.name}
            </span>
        </div>
    );
};

export default FolderNomenclaturesMaterials;