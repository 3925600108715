import {Button, Form, InputGroup} from "react-bootstrap";
import EmailIcon from "../../../components/UI/icons/EmailIcon";
import CastleIcon from "../../../components/UI/icons/CastleIcon";
import {useState} from "react";
import {useAuth} from "../../../features/authprovider/AuthProvider";
import {useMutation} from "@tanstack/react-query";
import {AxiosHelper} from "../../../hooks/useAxiosHelper";
import useAxiosErrorHandler from "../../../hooks/axioserrorhandler/useAxiosErrorHandler";
import useLoader from "../../../hooks/useLoader";


const LoginForm = ({setIsBadCredentials, isBadCredentials}) => {

    const { setAccessToken, setUserInfo } = useAuth();
    const {show, hide} = useLoader()
    const handleErrorResponse = useAxiosErrorHandler()
    const [loginInfo, setLoginInfo] = useState({
        email: '',
        password: ''
    })

    const {mutate: sendAuthRequest} = useMutation({
        mutationKey: 'auth',
        mutationFn: (loginInfo) => AxiosHelper.postMethod({
            urlWithoutBaseUrl: 'accounts/login/',
            data: loginInfo
        }),
        onMutate: () => show(),
        onSettled: () => hide(),
        onSuccess: (localUserInfoLogin) => {
            setAccessToken(localUserInfoLogin?.accessToken);
            setUserInfo(localUserInfoLogin);
        },
        onError: (error) => {
            const {response: {data}} = error;
            if(data === 'Wrong password' || data === 'Bad credentials')
                setIsBadCredentials(true)
            handleErrorResponse(error)
        }
    })



    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() !== false) {
            sendAuthRequest(loginInfo)
        }

    };

    return (
        <Form className={'login__inputs'} onSubmit={handleSubmit}>
            <InputGroup className={'w-100'} hasValidation>
                <InputGroup.Text id={'basic-addon1'}><EmailIcon/></InputGroup.Text>
                <Form.Control
                    value={loginInfo?.email}
                    type={'email'}
                    placeholder={'Email'}
                    aria-label={'Email'}
                    aria-describedby={'basic-addon1'}
                    onChange={e => {
                        setIsBadCredentials(false)
                        setLoginInfo({...loginInfo, email: e.target.value})
                    }}
                    isInvalid={isBadCredentials}
                    required
                />

            </InputGroup>
            <InputGroup className={'w-100'} hasValidation>
                <InputGroup.Text id={'basic-addon2'}><CastleIcon/></InputGroup.Text>
                <Form.Control
                    value={loginInfo?.password}
                    type={'password'}
                    placeholder={'Пароль'}
                    aria-label={'Пароль'}
                    aria-describedby={'basic-addon2'}
                    onChange={e => {
                        setIsBadCredentials(false)
                        setLoginInfo({...loginInfo, password: e.target.value})
                    }}
                    isInvalid={isBadCredentials}
                    required
                />
                <Form.Control.Feedback type={'invalid'}>Неверный логин или пароль</Form.Control.Feedback>
            </InputGroup>
            <Form.Text className={'login__recovery'} style={{color: 'rgba(0, 160, 230, 1)'}}>Забыли пароль?</Form.Text>
            <Button className={'submit-login_button border-0'} style={{background: '#009Fe3'}} type={'submit'}>Авторизироваться</Button>
        </Form>
    );
};

export default LoginForm;