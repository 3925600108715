import React, {useState, useMemo, useEffect} from 'react';
import { animated, useTransition, config } from "@react-spring/web";
import './MessageHub.css'

let id = 0
const MessageHub = ({
                        config = { tension: 125, friction: 20, precision: 0.1 },
                        timeout = 15000,
                        children,
                    }) => {

    const refMap = useMemo(() => new WeakMap(), [])
    const cancelMap = useMemo(() => new WeakMap(), [])
    const [items, setItems] = useState([])
    const transitions = useTransition(items, {
        from: { opacity: 0, height: 0, life: '100%' },
        keys: item => item.key,
        enter: item => async (next, cancel) => {
            cancelMap.set(item, cancel)
            await next({ opacity: 1, height: refMap.get(item).offsetHeight })
            await next({ life: '0%' })
        },
        leave: [{ opacity: 0 }, { height: 0 }],
        onRest: (result, ctrl, item) => {
            setItems(state =>
                state.filter(i => {
                    return i.key !== item.key
                })
            )
        },
        config: (item, index, phase) => key => phase === 'enter' && key === 'life' ? { duration: timeout } : config,
    })

    useEffect(() => {
        children((msg) => {
            setItems(state => {
                if(!state.some(x => x.msg === msg)){
                    return [...state, { key: id++, msg }]
                }
                else return [...state]
            })
        })
    }, [])
    return (
        <div className={'message-hub__wrapper'}>
            {transitions(({ life, ...style}, item) => (
                <animated.div
                    style={style}
                    className={'message-hub__item'}
                >
                    <div
                        className={'message-hub__content'}
                        ref={ref => ref && refMap.set(item, ref)}
                    >
                        <div className={'message-hub__title_container'}>
                            <p className={'message-hub__text_notification'}>Уведомление</p>
                            <div
                                className={'message-hub__button'}
                                onClick={e => {
                                    e.stopPropagation()
                                    if(cancelMap.has(item) && life.get() !== '0%') cancelMap.get(item)();
                                }}
                            >
                                <i className="fa-solid fa-xmark fa-xl"></i>
                            </div>
                        </div>
                        <p className={'message-hub__text'}>{item.msg}</p>
                        <animated.div style={{right: life}} className={'message-hub__life'}></animated.div>
                    </div>
                </animated.div>
            ))}

        </div>
    );
};

export default MessageHub;