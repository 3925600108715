import './GuidsCoursesList.css'
import {Button} from "@mui/material";
import {useEffect, useState} from "react";
import CourseItem from "./components/courseItem/CourseItem";
import {useQuery} from "@tanstack/react-query";
import {AxiosHelper} from "../../../hooks/useAxiosHelper";
import {usePaginationController} from "../../../components/pagination/components";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import TitleDetailCard from "../../../components/UI/titledetailcard/TitleDetailCard";
import useLoader from "../../../hooks/useLoader";


const GuidsCoursesList = () => {
    const [filters, setFilters] = useState({})
    const [page, setPage] = useState(1)
    const {show, hide} = useLoader()
    const STATE_KEY_COURSES_LIST = 'courses-list'

    useDocumentTitle({title: 'Направления'});

    const client = useQueryClient();

    const {data: courses} = useQuery({
        queryKey: [STATE_KEY_COURSES_LIST, page, filters],
        queryFn: ({queryKey: [name, page, filters]}) => AxiosHelper.postMethod({
            urlWithoutBaseUrl: 'ancorr/api/course/list',
            queryParams: {
                offset: 10,
                page: page,
            },
            data: filters,
        })
    })

    const {mutate: addCourse} = useMutation({
        mutationKey: [STATE_KEY_COURSES_LIST, page, filters],
        mutationFn: () => AxiosHelper.postMethod({
            urlWithoutBaseUrl: 'ancorr/api/course/add/guid',
            data: {outputCourse: ''}
        }),
        onMutate: () => show(),
        onSettled: () => hide(),
        onSuccess: () => client.invalidateQueries({queryKey: [STATE_KEY_COURSES_LIST]}),
    })

    const {paginationModule} = usePaginationController({
        pagesCount: courses?.pages || 1,
        changePageCallback: page => {
            setPage(page === 0 ? 1 : page)
        }
    })

    useEffect(() => {
        if (courses?.currentPage > courses?.pages) {
            if (courses?.pages >= 0) setPage(1)
            else setPage(courses?.pages)
        }
    }, [courses]);

    return (
        <div className="courses">
            <div className="courses__add-btn-wrapper">
                <TitleDetailCard text={'СПИСОК НАПРАВЛЕНИЙ'}/>
                <Button
                    variant="contained"
                    onClick={addCourse}
                >
                    Добавить
                </Button>
            </div>
            <div className="courses__list">
                {courses?.data?.map((course) => (
                    <CourseItem
                        course={course}
                        key={course.guid}
                        keyStateCoursesList={STATE_KEY_COURSES_LIST}
                    />
                ))}
            </div>
            {
                paginationModule
            }
        </div>
    );
};

export default GuidsCoursesList;