import {Accordion} from 'react-bootstrap';

const Body = ({children}) => {
    return (
        <Accordion.Body id={'remains__accordion-body'}>
            {children}
        </Accordion.Body>
    );
};

export default Body;