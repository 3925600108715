import {getCurrencyString} from "../../../../features/formatters/forrmaterrub";
import './MaterialsTkp.css'
import TableBootstrap from "../../../../components/tableb/TableBootstrap";
import useCheckAccess from "../../../../hooks/useCheckAccess";
import AccessDenied from "../../../../components/access/AccessDenied";
import TkpSummaryMaterial from "../tkpsummarymaterial/TkpSummaryMaterial";
import useLoader from "../../../../hooks/useLoader";
import InheritInput from "../../../../components/UI/inputs/inheritinput/InheritInput";
import {useQueryClient} from "@tanstack/react-query";
import useTkp from "../../hooks/useTkp";
import {useParams} from "react-router";
import TotalBarTkp from "../totalbartkp/TotalBarTkp";
import ListIcon from "../../../../components/UI/icons/ListIcon";
import {useEffect, useState} from "react";
import {Button, Modal} from "react-bootstrap";
import ProvidersTkp from "../providers/ProvidersTkp";


const MaterialsTkp = () => {
    const params = useParams()
    const id = Number(params['id'] || 0)
    const {hideAll} = useLoader()
    const KEY_CLIENT_TKP = 'current-tkp'
    const client = useQueryClient()
    const [visibleProvision, setVisibleProvision] = useState(false)
    const [itemForProvision, setItemForProvision] = useState(null)
    const currentTkp = client.getQueryData([KEY_CLIENT_TKP, id])
    const {
        changeSummarySliceEquipment
    } = useTkp();

    useEffect(() => {
        if(itemForProvision){
            let slice = currentTkp?.summaryEquipments?.find(x => x?.id === itemForProvision?.item?.id)
            if(slice)
                setItemForProvision({type: itemForProvision?.type, item: slice})
        }
    }, [currentTkp]);

    const onClickSelectProvision = (item, type) => {
        setVisibleProvision(true)
        setItemForProvision({item, type})
    }
    const popoverActions = [
        {
            icon: <ListIcon classNames={'cl-light-purple'}/>,
            title: 'Обеспечение',
            onClick: item => onClickSelectProvision(item, "equipment")
        },
    ]

    const schema = [
        {
            title: 'Материал',
            callbackItem: item => <TkpSummaryMaterial sliceNumber={item?.slice} materialSlice={item}/>,
        },
        {
            title: 'Расчетное кол-во',
            callbackItem: item => `${item?.quantity?.toFixed(2) || 0} л`,
        },
        {
            title: 'Цена за литр',
            callbackItem: item => getCurrencyString(item?.priceForLiter || 0, currentTkp?.currencyForHandle?.currencyCode),
        },
        {
            title: 'Скидка',
            callbackItem: item => <InheritInput
                defaultValue={item?.discountManager}
                useDebounce
                isNumber
                suffics={'%'}
                onChange={newValue => changeSummarySliceEquipment({...item, discountManager: newValue}, true)}
                className={'materials-tkp__field'}
            />,//`${item?.discountManager || 0}%`,
        },
        {
            title: 'Стоимость кр. таре',
            callbackItem: item => getCurrencyString(item?.totalPriceIncludingPackaging || 0, currentTkp?.currencyForHandle?.currencyCode),
        },
        {
            title: 'Остатки',
            callbackItem: item =>
                <div className={'equipments-selector__item_column equipments-selector__item_remains'}>
                    {item?.equipment?.remains?.map((remains, idxRemain) => remains?.warehouse?.isVisibleShortReport && (
                        <div title={remains?.warehouse?.fullName || ''} className={'equipments-selector__remains_item'}
                             key={idxRemain}>
                            {remains?.warehouse?.shortName} -
                            <span style={{marginLeft: 5, fontSize: 12}}>⚙️: <b>{remains?.canProduce}</b></span>
                            <span style={{
                                marginLeft: 5,
                                fontSize: 12
                            }}>{item?.equipment?.equipmentInformation?.unitMeasurement?.unitName}</span>
                        </div>
                    ))}
                </div>
        },
    ]

    const access = useCheckAccess("sales/tkp")
    if (!access) {
        hideAll()
        return <AccessDenied/>
    }

    return (
        <div className={'slide-tkp-body__wrapper'}>
            <div className={'slide-tkp__equipments'}>
                <div className={'slide-tkp__table'}>
                    <TableBootstrap
                        schema={schema}
                        popoverActions={popoverActions}
                        popoverCaption={'Действия'}
                        items={currentTkp?.summaryEquipments}
                    />
                </div>
                <div className={'materials-slide-tkp__stripe_info'}>
                    <span>
                        Итого за материал без НДС: {getCurrencyString(
                            currentTkp?.priceForMaterialIncludingPackaging - currentTkp?.priceForMaterialIncludingPackagingVAT,
                            currentTkp?.currencyForHandle?.currencyCode
                        )}
                    </span>
                    <span>
                        НДС: {getCurrencyString(currentTkp?.priceForMaterialIncludingPackagingVAT, currentTkp?.currencyForHandle?.currencyCode)}
                    </span>
                    <span>
                         Итого за материал c НДС: {getCurrencyString(
                            currentTkp?.priceForMaterialIncludingPackaging,
                            currentTkp?.currencyForHandle?.currencyCode
                        )}
                    </span>
                </div>
            </div>
            <TotalBarTkp
                keyClient={KEY_CLIENT_TKP}
                idTkp={id}
            />
            <Modal
                show={visibleProvision}
                onHide={() => {
                    setVisibleProvision(false)
                    setItemForProvision(null)
                }}
            >
                <Modal.Header><Modal.Title>Обеспечение</Modal.Title></Modal.Header>
                <Modal.Body>
                    <ProvidersTkp
                        slice={itemForProvision?.item}
                        type={itemForProvision?.type}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"danger"} onClick={() => {
                        setVisibleProvision(false)
                        setItemForProvision(null)
                    }}>Закрыть</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};


export default MaterialsTkp;