import './KPDetailNew.css'
import {useParams} from "react-router";
import {useEffect, useState} from "react";
import bxWindowSizer from "./helpers/bxWindowSizer";
import {useInteractionBackend} from "../../../../hooks/useInteractionBackend";
import {KPService} from "./API/KPService";
import useCheckBxInfoKP from "./hooks/useCheckBxInfoKP";
import KpNav from "./components/kpnav/KpNav";
import ItemKpDetail from "./components/itemkpdetail/ItemKpDetail";
import KpButton from "./components/kpbutton/KpButton";
import {getCurrencyString} from "../../../../features/formatters/forrmaterrub";
import {ReactComponent as AddIcon} from "../../../../components/UI/icons/x.svg";
import {Button, Form, Modal} from "react-bootstrap";
import RemainsNew from "../../../remainsnew/RemainsNew";
import DefaultModal from "../../../../components/UI/modals/DefaultModal";
import {PDFViewer} from "@react-pdf/renderer";
import KpPdf from "../kppdf";
import DropdownDefault from "../../../../components/UI/selects/dropdowndefault/DropdownDefault";
import {getOptionsFromArrayEntities} from "../../../../features/entityhelper/EntityHelper";
import useCurrenciesService from "../../../../models/currencies/useCurrenciesService";
import {updateBxInfoKP} from "../../../newTkp/features/TkpHandlers";
import useMessageHub from "../../../../hooks/useMessageHub";
import UpdateInformationBX24KP from "../bxupdater/UpdateInformationBX24KP";
import useManyKeysDebouncing from "../../../../hooks/manykeysdebouncing/useManyKeysDebouncing";
import useModals from "../../../../hooks/usemodal/useModals";
import {InputAdornment, TextField} from "@mui/material";
import KpMaterialsList from "./components/kpmaterialslist/KpMaterialsList";

const KPDetailNew = () => {
    const [visiblePreviewPdf, setVisiblePreviewPdf] = useState(false);
    const [currentKp, setCurrentKp] = useState(null);
    const [newBxInfo, setNewBxInfo] = useState(null);
    const [isChanged, setIsChanged] = useState(false);
    const [isFirstLoad, setIsFirstLoad] = useState(0);
    const [visibleEditModalKPItem, setVisibleEditModalKPItem] = useState(false);
    const [itemKPForEdit, setItemKPForEdit] = useState(null);
    const [showSelectorMaterialModal, setShowSelectorMaterialModal] = useState(false);
    const addMessage = useMessageHub();
    const [add, modals] = useModals();

    const {id} = useParams();
    const {currencies} = useCurrenciesService();

    const [getKp] = useInteractionBackend({
        cb: KPService.getKP,
        thenCb: ({data}) => setCurrentKp(data),
        type: 'axios'
    })
    const [updateKp] = useInteractionBackend({
        cb: KPService.updateKP,
        thenCb: ({data}) => {
            getKp({id})
            setVisibleEditModalKPItem(false);
            setItemKPForEdit(null);
        },
        errorCb: () => {
            setVisibleEditModalKPItem(false);
            setItemKPForEdit(null);
        },
        type: 'axios',
    })
    const [editMaterial] = useInteractionBackend({
        cb: KPService.editElement,
        thenCb: ({data}) => {
            getKp({id})
        },
        type: 'axios'
    })
    const [deleteMaterial] = useInteractionBackend({
        cb: KPService.deleteElement,
        thenCb: ({data}, {material}) => {
            getKp({id})
        },
        type: 'axios',
    })
    const [addMaterial] = useInteractionBackend({
        cb: KPService.addElement,
        thenCb: ({data}) => {
            getKp({id})
        },
        type: 'axios'
    })

    // QUEUE EDIT MATERIAL ITEM

    const materialItemsDebouncing = useManyKeysDebouncing(editMaterial, 1200)
    const preChangeKPMaterial = (newItem, useDeb = true) =>
        useDeb
            ? materialItemsDebouncing(newItem?.guid, {itemKPForEdit: newItem})
            : editMaterial({itemKPForEdit: newItem})
    ///

    const {bxInfoIsUpdate, setBxInfoIsUpdate} = useCheckBxInfoKP({currentKp, setNewBxInfo});

    useEffect(() => {
        bxWindowSizer();
        getKp({id});
    }, []);
    useEffect(() => {
        if (!isChanged && isFirstLoad >= 2) setIsChanged(true);
        else if (isFirstLoad < 2) setIsFirstLoad(isFirstLoad + 1);
    }, [currentKp])
    useEffect(() => {
        if (!bxInfoIsUpdate && currentKp?.id) {
            updateBxInfoKP().then(res => {
                setTimeout(() => {
                    let onRemoveItem = add({
                        children:
                            <UpdateInformationBX24KP
                                newBxInfo={res}
                                currentKp={currentKp}
                            />,
                        buttons: [
                            <Button onClick={() => onRemoveItem()} variant={"danger"}>Закрыть</Button>,
                            <Button onClick={() => {
                                updateKp({kp: currentKp, bxInfo: res})
                                onRemoveItem()
                            }}>Применить</Button>
                        ]
                    })
                }, 5000)
                if (!res?.dealIdB24) {
                    addMessage('Внимание вы находитесь вне зоны видимости сделки, данное ТКП не будет закреплено за сделкой')
                } else if (res?.dealIdB24 && currentKp?.dealIdB24 !== res?.dealIdB24) {
                    addMessage('Внимание, данные из битрикса строго перепривязываются при открытии ТКП из сделки, обновление данных успешно произведено, ТКП закреплено за вами')
                }
            })
            setBxInfoIsUpdate(true);
        }
    }, [currentKp, bxInfoIsUpdate])

    return (
        <div className='kp-detail'>
            <KpNav setVisiblePreviewPdf={setVisiblePreviewPdf}/>
            <div className="kp-detail__info">
                <div className="kp-detail__column">
                    <ItemKpDetail
                        title={'Наименование КП'}
                        text={currentKp?.title}
                        onChange={(value) => setCurrentKp({...currentKp, title: value})}
                    />
                    <ItemKpDetail
                        title={'Клиент в CRM'}
                        text={''}
                        disabled
                        placeholder={'В разработке'}
                    />
                    <ItemKpDetail
                        title={'ФИО клиента для PDF'}
                        text={currentKp?.previewTitleClient}
                        onChange={(value) => setCurrentKp({...currentKp, previewTitleClient: value})}
                    />
                    <ItemKpDetail
                        title={'Условия оплаты'}
                        text={currentKp?.paymentTerms}
                        onChange={(value) => setCurrentKp({...currentKp, paymentTerms: value})}
                        templatesValue={paymentTerms}
                    />
                    <ItemKpDetail
                        title={'Сроки отгрузки'}
                        text={currentKp?.shippingTime}
                        onChange={(value) => setCurrentKp({...currentKp, shippingTime: value})}
                        templatesValue={shippingTime}

                    />
                </div>
                <div className="kp-detail__column">
                    <ItemKpDetail
                        title={'Склад поставщика'}
                        text={currentKp?.supplyWarehouse}
                        onChange={(value) => setCurrentKp({...currentKp, supplyWarehouse: value})}
                        templatesValue={supplyWarehouse}
                    />
                    <ItemKpDetail
                        title={'Способ поставки'}
                        text={currentKp?.deliveryMethod}
                        onChange={(value) => setCurrentKp({...currentKp, deliveryMethod: value})}
                        templatesValue={deliveryMethod}
                    />
                    <ItemKpDetail
                        title={'Адрес доставки'}
                        text={currentKp?.deliveryAddress}
                        onChange={(value) => setCurrentKp({...currentKp, deliveryAddress: value})}
                    />
                    <ItemKpDetail
                        title={'Иные условия'}
                        text={currentKp?.otherTerms}
                        large
                        onChange={(value) => setCurrentKp({...currentKp, otherTerms: value})}
                    />
                </div>
            </div>
            <div className="kp-detail__controls">
                <Form className={'kp-detail__form'}>
                    <DropdownDefault
                        currentValue={{
                            id: currentKp?.currencyForHandle?.id,
                            title: currentKp?.currencyForHandle?.name,
                            value: currentKp?.currencyForHandle?.name,
                            accessor: 'currency'
                        }}
                        options={getOptionsFromArrayEntities({
                            entities: currencies,
                            valueCallback: item => item?.name,
                            titleCallback: item => item?.name,
                            idCallback: item => item?.id,
                            accessorName: 'currency'
                        })}
                        onChange={(option) => {
                            const currencyFound = currencies.find(currency => currency?.id === option?.id);
                            setIsChanged(true);
                            setCurrentKp({...currentKp, currencyForHandle: currencyFound})
                        }}
                        placeholder={'Валюта расчета'}
                        controlled
                    />
                    <Form.Check
                        type="switch"
                        label={!currentKp?.isFillableAllShippingTime ? 'Общий срок поставки' : ''}
                        bsPrefix={'kp-detail__switch'}
                        onChange={e => {
                            setCurrentKp({
                                ...currentKp,
                                isFillableAllShippingTime: !currentKp?.isFillableAllShippingTime
                            })
                        }}
                        checked={currentKp?.isFillableAllShippingTime}
                    />
                    {currentKp?.isFillableAllShippingTime &&
                        <TextField
                            variant="standard"
                            defaultValue={currentKp?.shippingTimeToFillable || ''}
                            onChange={e => {
                                setCurrentKp({...currentKp, shippingTimeToFillable: e.target.value})
                            }}
                            label={'Общий срок поставки'}
                        />
                    }
                    <Form.Check
                        type="switch"
                        label={'Выделить НДС'}
                        bsPrefix={'kp-detail__switch'}
                        checked={currentKp?.isHandleVAT}
                        onChange={e => {
                            setCurrentKp({
                                ...currentKp,
                                isHandleVAT: !currentKp?.isHandleVAT
                            })
                        }}
                    />
                    <TextField
                        variant="standard"
                        defaultValue={currentKp?.vat || '0'}
                        onChange={e => {
                            setCurrentKp({...currentKp, vat: e.target.value})
                        }}
                        label={'НДС'}
                        InputProps={{
                            endAdornment: <InputAdornment position="start">%</InputAdornment>,
                        }}
                        sx={{maxWidth: '50px', width: '100%'}}
                    />
                </Form>
                {isChanged &&
                    <KpButton className='kp-detail__submit' onClick={() => {
                        updateKp({kp: currentKp, bxInfo: newBxInfo})
                        setIsChanged(false);
                        setIsFirstLoad(1)
                    }}>
                        Применить
                    </KpButton>
                }
            </div>

            <div className="kp-detail__prices">
                <div className="kp-detail__prices-item">
                    Стоимость без
                    НДС: {getCurrencyString(currentKp?.totalPriceIncludingPackaging - currentKp?.totalPriceIncludingPackagingVAT, currentKp?.currencyForHandle?.currencyCode)}
                </div>
                <div className="kp-detail__prices-item">
                    НДС {currentKp?.vat}%: {getCurrencyString(currentKp?.totalPriceIncludingPackagingVAT, currentKp?.currencyForHandle?.currencyCode)}
                </div>
                <div className="kp-detail__prices-item">
                    Итого с
                    НДС: {getCurrencyString(currentKp?.totalPriceIncludingPackaging, currentKp?.currencyForHandle?.currencyCode)}
                </div>
            </div>
            <div className={'kp-detail__items-wrapper'}>
                <div className="kp-detail__table-controls">
                    <h4 className={'m-0'}>Продукция</h4>
                    <KpButton
                        className={'kp-detail__add-btn'}
                        onClick={() => setShowSelectorMaterialModal(true)}
                    >
                        <AddIcon className="kp-detail__add-icon"/>
                        Добавить продукт
                    </KpButton>
                </div>
                <div className={'kp-detail__items'}>
                    <KpMaterialsList
                        currentKp={currentKp}
                        changeKp={preChangeKPMaterial}
                        setItemKPForEdit={setItemKPForEdit}
                        setIsFirstLoad={setIsFirstLoad}
                        setVisibleEditModalKPItem={setVisibleEditModalKPItem}
                        deleteMaterial={deleteMaterial}
                        getKp={() => {
                            getKp({id})
                        }}
                    />
                </div>
            </div>
            <Modal
                show={showSelectorMaterialModal}
                onHide={() => setShowSelectorMaterialModal(false)}
                fullscreen
            >
                <Modal.Header><Modal.Title>Выберите элементы</Modal.Title></Modal.Header>
                <Modal.Body>
                    <RemainsNew
                        isSelector
                        clickElement={e => {
                            addMaterial({kpId: currentKp.id, event: e});
                            setIsFirstLoad(1);
                        }}
                        selectedIdsComponents={currentKp?.materials?.reduce((acc, item) => [...acc, item?.component?.id], [])}
                        selectedIdsEquipments={currentKp?.materials?.reduce((acc, item) => [...acc, item?.equipment?.id], [])}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"primary"} onClick={() => setShowSelectorMaterialModal(false)}>Закрыть</Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={visibleEditModalKPItem}
                onHide={() => {
                    setVisibleEditModalKPItem(false)
                    setItemKPForEdit(null)
                }}
            >
                <Modal.Header><Modal.Title>Редактирование элемента КП</Modal.Title></Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Срок поставки:</Form.Label>
                            <Form.Control
                                type={'text'}
                                className={'mb-2 w-auto'}
                                value={itemKPForEdit?.supplyDate || ''}
                                placeholder={'Срок поставки'}
                                onChange={e => setItemKPForEdit({...itemKPForEdit, supplyDate: e.target.value})}
                            />
                            <Form.Label>Количество:</Form.Label>
                            <Form.Control
                                type={'number'}
                                className={'mb-2 w-auto'}
                                value={itemKPForEdit?.quantity || ''}
                                placeholder={'Количество'}
                                onChange={e => setItemKPForEdit({...itemKPForEdit, quantity: Number(e.target.value)})}
                            />
                            <Form.Label>Скидка:</Form.Label>
                            <Form.Control
                                type={'number'}
                                className={'mb-2 w-auto'}
                                value={itemKPForEdit?.discount || ''}
                                placeholder={'Скидка'}
                                onChange={e => setItemKPForEdit({...itemKPForEdit, discount: Number(e.target.value)})}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"danger"} onClick={() => {
                        setVisibleEditModalKPItem(false)
                        setItemKPForEdit(null)
                    }}>Отменить</Button>
                    <Button variant={"primary"} onClick={() => {
                        editMaterial({itemKPForEdit});
                        setVisibleEditModalKPItem(false);
                        setItemKPForEdit(null);
                        setIsFirstLoad(1);
                    }}>Сохранить</Button>
                </Modal.Footer>
            </Modal>
            {visiblePreviewPdf &&
                <DefaultModal visible={visiblePreviewPdf} setVisible={setVisiblePreviewPdf}>
                    <PDFViewer className={'kp__preview-modal'}>
                        <KpPdf kp={currentKp}/>
                    </PDFViewer>
                </DefaultModal>
            }
            {modals}
        </div>
    );
};

const paymentTerms = [
    {
        id: 1,
        value: '100 % предварительная оплата товара по счету. в срок в течение 5 календарных дней с момента выставления счета Поставщиком',
        title: '100 %',
        accessor: 'paymentTerms'
    },
    {
        id: 2,
        value: '50% предоплата согласно счета в срок в течение 5 календарных дней с момента подписания данной спецификации. Окончательная оплата производится в размере 50% - в течение 20 (двадцати) дней с даты приемки Товара Покупателем (подписание УПД).',
        title: '50 %',
        accessor: 'paymentTerms'
    },
    {
        id: 3,
        value: '100% отсрочка платежа на срок 10 (десять) календарных дней с с даты приемки Товара Покупателем (подписание УПД).',
        title: 'Отсрочка',
        accessor: 'paymentTerms'
    },
]
const deliveryMethod = [
    {id: 1, value: 'Самовывоз со склада Поставщика', title: 'Самовывоз', accessor: 'deliveryMethod'},
    {
        id: 2,
        value: 'Сборным грузом транспортной компанией на склад "Покупателя" за счет «Поставщика».',
        title: 'На склад за счет поставщика',
        accessor: 'deliveryMethod'
    },
    {
        id: 3,
        value: 'Сборным грузом транспортной компанией на склад "Покупателя" за счет «Покупателя».',
        title: 'На склад за счет покупателя',
        accessor: 'deliveryMethod'
    },
    {
        id: 4,
        value: 'Сборным грузом до терминал транспортной компании за счет «Поставщика».',
        title: 'В терминал за счет поставщика',
        accessor: 'deliveryMethod'
    },
    {
        id: 5,
        value: 'Сборным грузом до терминал транспортной компании за счет «Покупателя».',
        title: 'В терминал за счет покупателя',
        accessor: 'deliveryMethod'
    },
    {
        id: 6,
        value: 'Отдельной машиной транспортной компании на склад "Покупателя" за счет «Поставщика».',
        title: 'Отдельной машиной',
        accessor: 'deliveryMethod'
    },
]
const shippingTime = [
    {
        id: 1,
        value: '2–5 рабочих дня, с даты получения оплаты / или подписания спецификации',
        title: '2–5 рабочих дня',
        accessor: 'shippingTime'
    },
    {
        id: 2,
        value: 'до 5 рабочих дня, с даты получения оплаты / или подписания спецификации',
        title: 'до 5 рабочих дня',
        accessor: 'shippingTime'
    },
]
const supplyWarehouse = [
    {
        id: 1,
        value: 'г. Астрахань, ул. Ст. Кутумная, 28А. Режим работы: 9:00ч до 18:00ч.',
        title: 'Астрахань',
        accessor: 'supplyWarehouse'
    },
    {
        id: 2,
        value: 'г. Ярославль, 2-ой Промышленный проезд, 11. Режим работы: 8:30ч до 17:00 ',
        title: 'Ярославль',
        accessor: 'supplyWarehouse'
    },
    {
        id: 3,
        value: 'г. Ростов-на-Дону, ул.Орская, 31. Режим работы: 9:00ч до 18:00ч.',
        title: 'Ростов-на-Дону',
        accessor: 'supplyWarehouse'
    },
    {
        id: 4,
        value: 'г. Новороссийск, Линейный проезд, 11. Режим работы: 9:00ч до 18:00ч.',
        title: 'Новороссийск',
        accessor: 'supplyWarehouse'
    },
]

export default KPDetailNew;