import './ToggleDropdownMultiField.css'
import {forwardRef} from "react";

const ToggleDropdownMultiField = forwardRef(({children, onClick}, ref) => {
    return (
        <div
            ref={ref}
            onClick={e=> {
                e.preventDefault();
                onClick(e);
            }}
            className={'toggle-dropdown-multi-field'}
        >
            {children}
        </div>
    );
});

export default ToggleDropdownMultiField;