import './Breadcrumbs.css';
import {ReactComponent as HomeIcon} from "../UI/icons/house-fill.svg";
import {useBreadcrumbsStore} from './index'

const Breadcrumbs = ({rootOnClick}) => {
    const goToBreadcrumb = useBreadcrumbsStore.getState().goToBreadcrumb;
    const clearBreadcrumbs = useBreadcrumbsStore.getState().clearBreadcrumbs;
    const breadcrumbs = useBreadcrumbsStore(state => state.breadcrumbs);

    return (
        <div className="breadcrumbs">
            <HomeIcon
                className="breadcrumbs__home"
                onClick={() => {
                    rootOnClick();
                    clearBreadcrumbs();
                }}
            />
            <span className='breadcrumbs__separator'>/</span>
            {breadcrumbs.map((item) => (
                <div className='breadcrumbs__item'  key={item.name}>
                    <div

                        className='breadcrumbs__item-title'
                        onClick={() => {
                            if (breadcrumbs[breadcrumbs.length - 1].name !== item.name) {
                                goToBreadcrumb(item.onClick, item.name);
                            }
                        }}
                    >
                        {item.name}
                    </div>
                    <span className='breadcrumbs__separator'>/</span>
                </div>
            ))}
        </div>
    );
};

export default Breadcrumbs;