import {Autocomplete, CircularProgress, TextField} from "@mui/material";
import useAddressSearcher from "../../../hooks/address/useAddressSearcher";
import {useState} from "react";


const AddressEditInputModule = ({defaultValue, onChange, label= '', sx}) => {

    const [value, setValue] = useState(defaultValue)
    const {optionsAddress, inputAddress, updateInputAddress, onBlur: onBlurAddress} = useAddressSearcher()

    return (
        <Autocomplete
            value={{value}}
            onChange={(event, newValue) => {
                onChange?.(newValue?.unrestricted_value || '')
                setValue(newValue?.unrestricted_value || '')
            }}
            onInputChange={(event, newInputValue) => {
                onChange?.(newInputValue)
                setValue(newInputValue)
                updateInputAddress(newInputValue)
            }}
            onBlur={() => onBlurAddress()}
            isOptionEqualToValue={(option, value) => true}
            inputValue={inputAddress}
            options={optionsAddress}
            getOptionLabel={option => option?.value || ''}
            sx={{ width: 400 }}
            size="small"
            filterOptions={(options, params) => {
                return options
            }}
            renderInput={(params) => <TextField {...params} label={label} />}
        />
    );
};

export default AddressEditInputModule;