import {useNavigate, useParams} from "react-router";
import DetailWrapper from "../../../../../components/detailcard/wrapper/DetailWrapper";
import TabsBlock from "../../../../../components/tabsblock/TabsBlock";
import ButtonTab from "../../../../../components/UI/buttons/buttontab/ButtonTab";
import BackCrossIcon from "../../../../../components/UI/icons/BackCrossIcon";
import DetailBody from "../../../../../components/detailcard/body/DetailBody";
import FieldsBlockRenderer from "../../../../../modules/fieldblockrenderer/FieldsBlockRenderer";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {AxiosHelper} from "../../../../../hooks/useAxiosHelper";

const GuidsBrandsDetail = () => {
    const params = useParams()
    const navigate = useNavigate()

    const client = useQueryClient()

    const {data: currentEntity} = useQuery({
        queryKey: ['current-brand'],
        queryFn: () => AxiosHelper.getMethod({
            urlWithoutBaseUrl: `ancorr/api/brand/get/${Number(params['id'] || 0)}`,
        }),
    })

    const {data: schema} = useQuery({
        queryKey: ['schema', 'brand'],
        queryFn: () => AxiosHelper.getMethod({
            urlWithoutBaseUrl: 'ancorr/api/helper/get/schema',
            queryParams: {
                destinationEntity: 'brand'
            },
        }),
        refetchOnWindowFocus: false
    })

    const {mutate: changeCurrentEntity} = useMutation({
        mutationKey: ['current-brand'],
        mutationFn: (newItem) => AxiosHelper.postMethod({
            urlWithoutBaseUrl: 'ancorr/api/brand/update',
            data: newItem,
        }),
        onSuccess: () => client.invalidateQueries({queryKey: ['current-brand']}),
        onMutate: (newItem) => client?.setQueryData(['current-brand'], (oldItem) => newItem)
    })
    return (
        <DetailWrapper>
            <TabsBlock>
                <ButtonTab
                    icon={<BackCrossIcon classNames={'icon-extension'}/>}
                    title={'Назад к списку'}
                    onClick={() => navigate(-1)}
                />
            </TabsBlock>
            <DetailBody>
                <FieldsBlockRenderer
                    schema={schema}
                    model={currentEntity}
                    onSave={newItem => changeCurrentEntity?.(newItem)}
                />
            </DetailBody>
        </DetailWrapper>
    );
};

export default GuidsBrandsDetail;