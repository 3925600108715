import {useMutation} from "@tanstack/react-query";
import {AxiosHelper} from "../useAxiosHelper";
import {useState} from "react";
import useDebouncingFunction from "../debouncingfunction/useDebouncingFunction";

const UseContactsSearcher = () => {
    const {mutate: updateContactsOptionFn} = useMutation({
        mutationKey: ['contacts', 'options'],
        mutationFn: (prompt) => AxiosHelper.postMethod({
            urlWithoutBaseUrl: 'ancorr/api/contact/search',
            queryParams: {
                prompt: prompt
            }
        }),
        onSuccess: (data) => setOptionsContacts(data)
    })
    const [optionsContacts, setOptionsContacts] = useState([])
    const [inputContacts, setInputContacts] = useState('')
    const updateInputPrompt = useDebouncingFunction((newInputValue) => {
        if(newInputValue)
            updateContactsOptionFn(newInputValue)
    }, 1200)
    return {
        optionsContacts,
        inputContacts,
        updateInputContacts: (newInputValue) => {
            setInputContacts(newInputValue)
            updateInputPrompt(newInputValue)
        },
        onBlur: () => {
            setInputContacts('')
            setOptionsContacts([])
        }
    };
};

export default UseContactsSearcher;