import {useEffect, useState} from 'react';
import './GuidsEquipmentDetail.css'
import {useNavigate, useParams} from "react-router";
import {createSignalRContext} from "react-signalr/signalr";
import {BaseUrl} from "../../../../../features/databaseinfo/BaseUrl";
import useCheckConnectionToHub from "../../../../../hooks/useCheckConnectionToHub";
import useSignalRFunk from "../../../../../hooks/useSignalRFunk";
import DetailWrapper from "../../../../../components/detailcard/wrapper/DetailWrapper";
import TabsBlock from "../../../../../components/tabsblock/TabsBlock";
import ButtonTab from "../../../../../components/UI/buttons/buttontab/ButtonTab";
import ListIcon from "../../../../../components/UI/icons/ListIcon";
import BackCrossIcon from "../../../../../components/UI/icons/BackCrossIcon";
import DetailBody from "../../../../../components/detailcard/body/DetailBody";
import FieldsBlockRenderer from "../../../../../modules/fieldblockrenderer/FieldsBlockRenderer";
import useChangeItemSignalR from "../../../../../hooks/useChangeItemSignalR";
import {Button, Modal} from "react-bootstrap";
import RemainsList from "../modals/remainslist/RemainsList";
import EquipmentComponentsList from "../modals/componentslist/EquipmentComponentsList";
import {useAuth} from "../../../../../features/authprovider/AuthProvider";
const SignalRContext = createSignalRContext()

const GuidsEquipmentDetail = () => {
    const params = useParams()
    const [currentEquipment, setCurrentEquipment] = useState(null)
    const [components, setComponents] = useState([])
    const navigate = useNavigate()
    const [schema, setSchema] = useState([])
    const {userInfo} = useAuth()

    useEffect(() => {
        document.title = "ANCORR: Рецепт"
    }, [])

    const {
        onReconnect,
        onConnect,
        onError,
        checkConnection
    } = useCheckConnectionToHub({
        SignalRContext,
        initialFunk: () => {
            updateSchema()
            updateCurrentEquipment()
            updateComponents()
        },
        onReconnectCallback: () => {
            updateSchema()
            updateCurrentEquipment()
            updateComponents()
        }
    })
    const {signalRFunk} = useSignalRFunk({
        SignalRContext,
        checkConnection
    })
    const updateCurrentEquipment = () => signalRFunk('GetCurrentDetailEquipment', [Number(params['id'])], res => setCurrentEquipment(res))
    const updateSchema = () => signalRFunk('GetSchemaForRender', ['equipment'], res => {
        setSchema(res)
    })
    const updateComponents = () => signalRFunk('GetAllComponents', [], res => setComponents(res))

    const addItem = (id) => signalRFunk('AddComponent', [currentEquipment?.id, id], res => {
        let newArrayComponents = [...currentEquipment?.components, res];
        setCurrentEquipment(prev => { return {
            ...prev,
            components: newArrayComponents
        }})
    })

    const removeItem = (guid) => signalRFunk('RemoveComponent', [guid], res => {
        let newArrayComponents = currentEquipment?.components?.filter(x => x?.guid !== guid);
        setCurrentEquipment(prev => { return {
            ...prev,
            components: newArrayComponents
        }})
        setTimeout(() => {
            updateCurrentEquipment()
        }, 1500)
    })
    const componentChanger = useChangeItemSignalR({
        SignalRContext,
        checkConnection,
        method: 'ChangeComponent',
        delay: 1000,
        callbackChangeItemFunk: newItem => setCurrentEquipment(prev => {
            return {
                ...prev,
                components: currentEquipment?.components?.map(item => {
                    if(item?.guid === newItem?.guid) return newItem
                    else return item;
                })
            }
        })
    })
    const {changeItem} = useChangeItemSignalR({
        SignalRContext,
        checkConnection,
        method: 'ChangeEquipment',
        delay: 1000,
        callbackChangeItemFunk: newItem => setCurrentEquipment(newItem)
    })

    const getCurrentIdsComponents = () => {

        return currentEquipment?.components?.reduce((acc, item) => {
            return [...acc, item?.component?.id]
        }, [])
    }

    SignalRContext.useSignalREffect("UpdateRemains", () => {
        updateCurrentEquipment()
    })



    const [showModalRemainsList, setShowModalRemainsList] = useState(false)
    const [remainsForRemainsList, setRemainsForRemainsList] = useState(null)

    const [showModalComponentsList, setShowModalComponentsList] = useState(false)
    const [equipmentForComponentsList, setEquipmentForComponentsList] = useState(null)

    return (
        <SignalRContext.Provider
            withCredentials={false}
            connectEnabled={true}
            url={`${BaseUrl}/ancorr/api/equipmentdetailhub/?token=${userInfo?.accessToken || ''}`}
            accessTokenFactory={() => userInfo?.accessToken || ''}
            onReconnect={onReconnect}
            onOpen={onConnect}
            onError={onError}
        >
            <DetailWrapper>
                <TabsBlock>
                    <ButtonTab
                        icon={<ListIcon classNames={'icon-extension'}/>}
                        title={'Информация'}
                        onClick={() => {}}
                    />
                    <ButtonTab
                        icon={<ListIcon classNames={'icon-extension'}/>}
                        title={'Остатки'}
                        onClick={() => { setShowModalRemainsList(true); setRemainsForRemainsList(currentEquipment?.remains || []);}}
                    />
                    <ButtonTab
                        icon={<ListIcon classNames={'icon-extension'}/>}
                        title={'Список компонентов'}
                        onClick={() => {
                            setShowModalComponentsList(true)
                            setEquipmentForComponentsList(currentEquipment)
                        }}
                    />
                    <ButtonTab
                        icon={<BackCrossIcon classNames={'icon-extension'}/>}
                        title={'Назад к списку'}
                        onClick={() => navigate(-1)}
                    />
                </TabsBlock>
                <DetailBody>
                    <div className={'flex left gap-4'}>
                        <FieldsBlockRenderer
                            schema={schema}
                            model={currentEquipment}
                            signalRFunk={signalRFunk}
                            dependencyEntity={[
                                'GetAllCurrencies',
                                'GetAllPackaging',
                                'GetAllBrands',
                            ]}
                            onSave={newItem => {
                                changeItem(newItem)
                            }}
                        />
                        <FieldsBlockRenderer
                            schema={schemaRemains}
                            model={currentEquipment}
                            onSave={newItem => {
                                changeItem(newItem)
                            }}
                            isEditable={false}
                        />
                    </div>
                </DetailBody>
            </DetailWrapper>

            <Modal fullscreen={true} show={showModalRemainsList} onHide={() => {
                setShowModalRemainsList(false)
                setRemainsForRemainsList(null)
            }}>
                <Modal.Header>
                    <Modal.Title>Остатки</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <RemainsList
                        remains={currentEquipment?.remains}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"primary"} onClick={() => {
                        setShowModalRemainsList(false)
                        setRemainsForRemainsList(null)
                    }}>Закрыть</Button>
                </Modal.Footer>
            </Modal>
            <Modal fullscreen={true} show={showModalComponentsList} onHide={() => {
                setShowModalComponentsList(false)
                setEquipmentForComponentsList(null)
            }}>
                <Modal.Header>

                </Modal.Header>
                <Modal.Body>
                    <EquipmentComponentsList
                        addComponent={addItem}
                        removeComponent={removeItem}
                        changeComponent={componentChanger.changeItem}
                        components={currentEquipment?.components}
                        componentsList={components}
                        getCurrentIdsComponents={getCurrentIdsComponents}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant={"primary"} onClick={() => {
                        setShowModalComponentsList(false)
                        setEquipmentForComponentsList(null)
                    }}>Закрыть</Button>
                </Modal.Footer>
            </Modal>
        </SignalRContext.Provider>
    );
};

const schemaRemains = [
    {
        blockTitle: 'Остатки',
        fields: [
            {
                type: 'label',
                accessor: 'available',
                label: 'Всего доступно',
            },
            {
                type: 'label',
                accessor: 'availableWithSupply',
                label: 'В наличии с поставками',
            },
            {
                type: 'label',
                accessor: 'inStock',
                label: 'В наличии',
            },
            {
                type: 'label',
                accessor: 'reserved',
                label: 'В резерве',
            },
            {
                type: 'label',
                accessor: 'shipment',
                label: 'К отгрузке',
            },
            {
                type: 'label',
                accessor: 'supply',
                label: 'Поступит',
            },
            {
                type: 'label',
                accessor: 'nearestSupplyDate',
                label: 'Ближайшая дата доставки',
            },
        ],
    },
]


export default GuidsEquipmentDetail;