import {resizeWindow} from "bx24-api";

const checkBitrix = (cb) => {
    if(window?.name?.toLowerCase()?.includes('bitrix')) {
        cb?.();
    }
}
const resizeBitrixWindow = (intervalId) => {
    resizeWindow(document?.body?.clientWidth, document.getElementsByClassName("remains-list")[0]?.clientHeight + 500).then(() => {
        if (intervalId) clearInterval(intervalId);
    })
}

export {checkBitrix, resizeBitrixWindow}