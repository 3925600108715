export default [
    {
        title: 'ID',
        callbackItem: item => item?.id,
    },
    {
        title: 'Полное наименование',
        callbackItem: item => item?.name,
    },
    {
        title: 'Высота',
        callbackItem: item => item?.height,
    },
    {
        title: 'Ширина',
        callbackItem: item => item?.width,
    },
    {
        title: 'Длина',
        callbackItem: item => item?.lenght,
    },
    {
        title: 'Объем',
        callbackItem: item => item?.volume,
    },
    {
        title: 'Вес',
        callbackItem: item => item?.weight,
    },
    {
        title: 'Вместимость на паллете',
        callbackItem: item => item?.multiplePallets,
    },
]