import {useEffect, useState} from "react";
import {updateBxInfoKP} from "../../../../newTkp/features/TkpHandlers";
import useMessageHub from "../../../../../hooks/useMessageHub";


const UseCheckBxInfoKp = ({currentKp, setNewBxInfo}) => {
    const [bxInfoIsUpdate, setBxInfoIsUpdate] = useState(false);
    const addMessage = useMessageHub();

    useEffect(() => {
        if(!bxInfoIsUpdate && currentKp?.id) {
            updateBxInfoKP().then(res => {
                setTimeout(() => {
                    setNewBxInfo(res);
                }, 5000);

                if(!res?.dealIdB24){
                    addMessage('Внимание вы находитесь вне зоны видимости сделки, данное ТКП не будет закрепленно за сделкой')
                }
                else if(res?.dealIdB24 && currentKp?.dealIdB24 !== res?.dealIdB24){
                    addMessage('Внимание, данные из битрикса строго перепривязываются при открытии ТКП из сделки, обновление данных успешно произведенно, ТКП закреплено за вами')
                }
            }).catch(res => {
                addMessage("Не удалось загрузить данные из битрикса, обратитесь к администратору")
                addMessage(res.message || "Нет данных ошибки")
            })
            setBxInfoIsUpdate(true)
        }
    }, [currentKp, bxInfoIsUpdate])

    return {bxInfoIsUpdate, setBxInfoIsUpdate}
};

export default UseCheckBxInfoKp;