import './UpdateInformationBX24.css'
import {Button} from "react-bootstrap";
const UpdateInformationBx24 = ({newBxInfo, currentTkp, updateInfo}) => {
    return (
        <div className={'modal-update-bxinfo__wrapper'}>
            <div className={'modal-update-bxinfo__container'}>
                <div className={'modal-update-bxinfo__block'}>
                    <h3 className={'modal-update-bxinfo__title'}>Текущая информация Б24</h3>
                    <div className={'modal-update-bxinfo__info'}>
                        <label className={'modal-update-bxinfo__label'}>Сделка:</label>
                        <span className={'modal-update-bxinfo__text'}>{currentTkp?.dealName || ''}</span>
                    </div>
                    <div className={'modal-update-bxinfo__info'}>
                        <label className={'modal-update-bxinfo__label'}>Компания:</label>
                        <span className={'modal-update-bxinfo__text'}>{currentTkp?.companyName || ''}</span>
                    </div>
                    <div className={'modal-update-bxinfo__info'}>
                        <label className={'modal-update-bxinfo__label'}>Судно:</label>
                        <span className={'modal-update-bxinfo__text'}>{currentTkp?.ship || ''}</span>
                    </div>
                    <div className={'modal-update-bxinfo__info'}>
                        <label className={'modal-update-bxinfo__label'}>ID сделки:</label>
                        <span className={'modal-update-bxinfo__text'}>{currentTkp?.dealId || ''}</span>
                    </div>
                    <div className={'modal-update-bxinfo__info'}>
                        <label className={'modal-update-bxinfo__label'}>ID пользователя:</label>
                        <span className={'modal-update-bxinfo__text'}>{currentTkp?.userId || ''}</span>
                    </div>
                    <div className={'modal-update-bxinfo__info'}>
                        <label className={'modal-update-bxinfo__label'}>Ответственный</label>
                        <span className={'modal-update-bxinfo__text'}>{currentTkp?.assigned || ''}</span>
                    </div>
                </div>
                <div className={'modal-update-bxinfo__block'}>
                    <h3 className={'modal-update-bxinfo__title'}>Новая информация Б24</h3>
                    <div className={'modal-update-bxinfo__info'}>
                        <label className={'modal-update-bxinfo__label'}>Сделка:</label>
                        <span className={'modal-update-bxinfo__text'}>{newBxInfo?.dealName || ''}</span>
                    </div>
                    <div className={'modal-update-bxinfo__info'}>
                        <label className={'modal-update-bxinfo__label'}>Компания:</label>
                        <span className={'modal-update-bxinfo__text'}>{newBxInfo?.companyName || ''}</span>
                    </div>
                    <div className={'modal-update-bxinfo__info'}>
                        <label className={'modal-update-bxinfo__label'}>Судно:</label>
                        <span className={'modal-update-bxinfo__text'}>{newBxInfo?.ship || ''}</span>
                    </div>
                    <div className={'modal-update-bxinfo__info'}>
                        <label className={'modal-update-bxinfo__label'}>ID сделки:</label>
                        <span className={'modal-update-bxinfo__text'}>{newBxInfo?.dealId || ''}</span>
                    </div>
                    <div className={'modal-update-bxinfo__info'}>
                        <label className={'modal-update-bxinfo__label'}>ID пользователя:</label>
                        <span className={'modal-update-bxinfo__text'}>{newBxInfo?.userId || ''}</span>
                    </div>
                    <div className={'modal-update-bxinfo__info'}>
                        <label className={'modal-update-bxinfo__label'}>Ответственный</label>
                        <span className={'modal-update-bxinfo__text'}>{newBxInfo?.assigned || ''}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdateInformationBx24;