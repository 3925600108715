import {create} from "zustand";
import {createJSONStorage, devtools, persist} from "zustand/middleware";


const useRemainsSettingsStore = create()(persist(devtools((set) => ({
    settings: {
        isAllAllowedWarehouses: true,
        allowedWarehouses: [],
        inStock: false,
        supply: false,
        canProduce: true,
        unitMeasurement: true
    },
    setSettings: (data) => {
        set({settings: data});
    }
}), {name: 'remainsSettingsStore'}),{
    name: 'remains-settings-storage',
    storage: createJSONStorage(() => localStorage),
}));

export default useRemainsSettingsStore;