import './DropdownDefault.css'
import {Form} from "react-bootstrap";
import {forwardRef, useState, Children, useEffect} from "react";
import {Autocomplete,TextField} from "@mui/material";
import useDebouncingFunction from "../../../../hooks/debouncingfunction/useDebouncingFunction";

const DropdownDefault = ({
                             currentValue,
                             options = [],
                             onChange,
                             placeholder = 'Не выбрано',
                             disabled = false,
                             useDebounce = false,
                             sx = { width: 300 },
                             controlled = false}) => {
    const [inputValue, setInputValue] = useState('')
    const [value, setValue] = useState(currentValue)


    useEffect(() => {
        if(controlled)
            setValue(currentValue)
    }, [currentValue]);

    const updateValue = (newValue) => {
        onChange?.(newValue)
    }

    const debounceUpdateValue = useDebouncingFunction(updateValue, 1200)

    return (
        <Autocomplete
            disabled={disabled}
            value={value}
            onChange={(event, newValue) => {
                setValue(newValue)
                if(useDebounce)
                    debounceUpdateValue(newValue)
                else
                    updateValue(newValue)
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            isOptionEqualToValue={(option, value) => option?.value === value?.value}
            inputValue={inputValue}
            options={options}
            getOptionLabel={option => option?.value || ''}
            sx={sx}
            size="small"
            renderInput={(params) => <TextField {...params} label={placeholder} />}
        />
    );
};

export const CustomMenu = forwardRef(({children, style, className, 'aria-labelledby': labeledBy}, ref) => {
    const [value, setValue] = useState('')
    return (
        <div
            ref={ref}
            style={style}
            className={className}
            aria-labelledby={labeledBy}
        >
            <Form.Control
                autoFocus
                id={'dropdown__item-input'}
                className={'mx-3 my-2 w-auto'}
                placeholder={'Фильтр'}
                onChange={e => setValue(e.target.value)}
                value={value}
            />
            {Children?.toArray?.(children)?.filter?.(child => !value || child?.props?.children?.toLowerCase?.()?.includes?.(value))}
        </div>
    )
})

export default DropdownDefault;