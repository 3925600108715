import {useEffect, useRef, useState} from 'react';
import {Form} from "react-bootstrap";
import FilterInput from "../../../UI/filterinput/FilterInput";

const ThicknessInputs = ({filters, setFilters, updatePosition}) => {
    const refInputs = useRef(null);
    const [isFirstLoad, setIsFirstLoad] = useState(true);

    useEffect(() => {
        if (isFirstLoad) setIsFirstLoad(false);
        else updatePosition({target: refInputs.current});
    }, [filters.thicknessMax, filters.thicknessMin])

    return (
        <div className={'remains-filters__none-accordion'}>
            <Form.Label className={'filter-inputs__label'}>Толщина</Form.Label>
            <div
                className="filters-inputs__inputs"
                ref={refInputs}
            >
                <FilterInput
                    value={filters.thicknessMin}
                    setValue={value => setFilters({...filters, thicknessMin: value})}
                    placeholder={"От"}
                />
                <FilterInput
                    value={filters.thicknessMax}
                    setValue={value => setFilters({...filters, thicknessMax: value})}
                    placeholder={"До"}
                />
            </div>
        </div>
    );
};

export default ThicknessInputs;