import './InputMultiField.css';
import InheritInput from "../../../UI/inputs/inheritinput/InheritInput";

const InputMultiField = ({onChange, value, placeholder, suffix, useDebounce, controlled, isNumber}) => {
    return (
        <InheritInput
            controlled={controlled}
            isNumber={isNumber}
            useDebounce={useDebounce}
            suffics={suffix}
            onChange={onChange}
            defaultValue={value}
            placeholder={placeholder}
            //className={'input-multi-field'}
        />
    );
};

export default InputMultiField;