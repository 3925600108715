import './RemainsSettings.css'
import useRemainsSettingsStore from "../../store/remainsSettingsStore";
import {useEffect, useState} from "react";
import {useInteractionBackend} from "../../../../hooks/useInteractionBackend";
import {getWarehouses} from "../../API/remainsAPI";
import {Accordion, Form} from "react-bootstrap";
import Toggle from "../remainsaccordion/toggle/Toggle";
import RemainsCheckbox from "../remainscheckbox/RemainsCheckbox";
import newArrayIds from "../../helpers/newArrayIds";
import {useAuth} from "../../../../features/authprovider/AuthProvider";

const RemainsSettings = () => {
    let token;
    const {userInfo} = useAuth();
    const settings = useRemainsSettingsStore(state => state.settings);
    const setSettings = useRemainsSettingsStore.getState().setSettings;
    const [warehouses, setWarehouses] = useState([]);

    const [fetchWarehouses] = useInteractionBackend({
        type: 'axios',
        cb: getWarehouses,
        thenCb: (data) => setWarehouses(data.data)
    })

    useEffect(() => {
        token = userInfo?.accessToken || '';
        fetchWarehouses({token});
    }, []);

    return (
        <Form className='w-100'>
            <Accordion id={'remains__accordion'} alwaysOpen={true}>
                <Accordion.Item eventKey="0" className='w-100' id={'remains__accordion-item'}>
                    <Toggle eventKey='0'>
                        Разрешенные склады:
                    </Toggle>
                    <Accordion.Body id={'remains__accordion-body'}>
                        {
                            warehouses?.map(warehouse => warehouse?.isVisibleShortReport && (
                                    <RemainsCheckbox
                                        checked={settings.allowedWarehouses.includes(warehouse.id)}
                                        onClick={() => {
                                            let newArr = newArrayIds(warehouse.id, settings.allowedWarehouses)
                                            setSettings({...settings, allowedWarehouses: newArr});
                                        }}
                                    >
                                        {warehouse?.fullName}
                                    </RemainsCheckbox>
                                )
                            )
                        }
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <RemainsCheckbox
                checked={settings.inStock}
                onClick={() => setSettings({...settings, inStock: !settings.inStock})}
            >
                {'🏚️ Показывать сколько в наличии'}
            </RemainsCheckbox>
            <RemainsCheckbox
                checked={settings.supply}
                onClick={() => setSettings({...settings, supply: !settings.supply})}
            >
                {'⌛ Показывать будущие поступления'}
            </RemainsCheckbox>
            <RemainsCheckbox
                checked={settings.canProduce}
                onClick={() => setSettings({...settings, canProduce: !settings.canProduce})}
            >
                {'⚙️ Показывать количество готовой продукции, которое можно произвести'}
            </RemainsCheckbox>
        </Form>
    );
};

export default RemainsSettings;