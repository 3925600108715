import {useEffect, useState} from "react";
import {useInteractionBackend} from "../../../../../hooks/useInteractionBackend";
import {getFamiliesNomenclatures} from "../../../API/remainsAPI";
import RemainsCheckbox from "../../remainscheckbox/RemainsCheckbox";
import newArrayIds from "../../../helpers/newArrayIds";
import ClearBtn from "../../../UI/clearbtn/ClearBtn";


const FamiliesFilter = ({filters, setFilters, updatePosition}) => {
    const [initialFilters, setInitialFilters] = useState(filters.nomenclatureIds);
    const [families, setFamilies] = useState([]);

    const [getFamilies] = useInteractionBackend({
        type: 'axios',
        cb: getFamiliesNomenclatures,
        thenCb: ({data}) => setFamilies(data)
    })

    useEffect(() => {
        getFamilies({ids: filters.brandsIds});
    }, [filters.brandsIds]);
    return (
        <div>
            {
                families?.map(family => {
                    return (
                        <RemainsCheckbox
                            key={family.id}
                            checked={filters.nomenclatureIds.includes(family.id)}
                            onClick={(e) => {
                                let newArr = newArrayIds(family.id, filters.nomenclatureIds);
                                setFilters({...filters, nomenclatureIds: newArr});
                                updatePosition(e);
                            }}
                        >
                            {family?.name || "Не известно"}
                        </RemainsCheckbox>
                    )
                })
            }
            <ClearBtn
                isActive={filters.nomenclatureIds.length > 0}
                onClick={(e) => {
                    setFilters({...filters, nomenclatureIds: initialFilters});
                    updatePosition(e);
                }}
            />
        </div>
    );
};

export default FamiliesFilter;