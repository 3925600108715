import {useCallback, useEffect, useState} from 'react';
import './FieldBlockRenderer.css'
import Button from '@mui/material/Button';
import {AxiosHelper} from "../../hooks/useAxiosHelper";
import Block from "./components/Block";
import setNewValueFromKeys from "./helpers/setNewValueFromKeys";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {Box, CircularProgress, LinearProgress, Skeleton, Stack} from "@mui/material";
import {useSnackbar} from "notistack";
import useAxiosErrorHandler from "../../hooks/axioserrorhandler/useAxiosErrorHandler";
import useControlledValue from "../../hooks/controlled/useControlledValue";

const FieldsBlockRenderer = ({
                                 model,
                                 controlled = true,
                                 schema,
                                 onSave,
                                 onCancel,
                                 isEditable = true,
                                 dependencyEntity = [],
                                 signalRFunk,
                                 backendMode = 'axios',
                                 waitingSave
                             }) => {
    const [dataModel, setDataModel] = useControlledValue({defaultValue: model, controlled})
    const [localSchema, setLocalSchema] = useState(null)
    const [isEditing, setIsEditing] = useState(false)
    const [isWaitSuccess, setIsWaitSuccess] = useState(false)
    const [entities, setEntities] = useState([])
    const [editingFieldGuids, setEditingFieldGuids] = useState([])
    const {enqueueSnackbar} = useSnackbar()
    const handleErrorResponse = useAxiosErrorHandler()

    useEffect(() => {
        if (dependencyEntity) {
            dependencyEntity?.forEach(obj => {
                if (backendMode === 'axios')
                    updateDependencyEntity(obj?.method)
                else if (backendMode === 'signalr')
                    getEntitiesFromBackendSignalR(obj?.method)
            })
        }
    }, [])

    useEffect(() => {
        if(schema)
            setLocalSchema(schema)
    }, [schema]);

    const client = useQueryClient()

    const getEntitiesFromBackendSignalR = useCallback((method) => signalRFunk(method, [], res => setEntities(prev => [...prev, {
        method: method,
        items: res
    }])), [signalRFunk])

    const {mutate: updateDependencyEntity} = useMutation({
        mutationFn: (path) => AxiosHelper.getMethod({
            urlWithoutBaseUrl: path,
        }),
        onSuccess: (data, path) => {
            client.invalidateQueries({queryKey: ['dependency', 'entities', path]})
            setEntities(prev => [...prev, {method: path, items: data}])
        },
        onError: (error) => handleErrorResponse(error)
    })


    const handleSave = () => {
        if(waitingSave){
            setIsWaitSuccess(true)
            waitingSave?.(dataModel, (isSuccessHandle) => {
                if(isSuccessHandle){
                    enqueueSnackbar('Данные успешно обновлены', {variant: "success"})

                    setIsWaitSuccess(false)
                    setIsEditing(false)
                    setEditingFieldGuids([])
                }
                else{
                    enqueueSnackbar('Не удалось обновить данные', {variant: "error",})
                    setIsWaitSuccess(false)
                }
            })
        }
        else if(onSave){
            onSave?.(dataModel)
            setIsEditing(false)
            setEditingFieldGuids([])
        }
        else{
            setIsEditing(false)
            setEditingFieldGuids([])
        }

    }
    const handleCancel = () => {
        setIsEditing(false)
        setEditingFieldGuids([])
        setDataModel(model)
        onCancel?.(dataModel)
    }

    const handleChangeValue = useCallback((e, accessor, keys, fieldType) => {
        const {value, type, checked} = fieldType === 'enum' ? {
            value: e,
            type: 'enum',
            checked: false,
            files: []
        } : fieldType === 'entity' ? {
            value: e,
            type: 'entity',
            checked: false,
            files: []
        } : fieldType === 'number' ? {
            value: e,
            type: 'entity',
            checked: false,
            files: []
        } : fieldType === 'text' ? {
            value: e,
            type: 'entity',
            checked: false,
            files: []
        } : fieldType === 'photo' ? {
            value: e,
            type: 'entity',
            checked: false,
            files: []
        }  : fieldType === 'address' ? {
            value: e,
            type: 'entity',
            checked: false,
            files: []
        } : fieldType === 'selector' ? {
            value: e,
            type: 'entity',
            checked: false,
            files: []
        }  : fieldType === 'crm_client' ? {
            value: e,
            type: 'entity',
            checked: false,
            files: []
        }  : fieldType === 'crm_company' ? {
            value: e,
            type: 'entity',
            checked: false,
            files: []
        }  : fieldType === 'crm_contact' ? {
            value: e,
            type: 'entity',
            checked: false,
            files: []
        } : e.target;
        let fieldValue = null;
        if(type === 'checkbox') fieldValue = checked;
        else if(type === 'photo')
            fieldValue = e;
        else if(type === 'number' && value)
            fieldValue = Number(value);
        else fieldValue = value

        if(type !== 'file'){
            let newModel = {...dataModel}
            setNewValueFromKeys(newModel, keys, fieldValue)
            setDataModel(newModel)
        }
    }, [dataModel, setDataModel])


    if(!localSchema || !dataModel)
        return (
            <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                <Stack spacing={1}>
                    <Skeleton variant="text" width={410} sx={{ fontSize: '1rem' }} />
                    <Skeleton variant="circular" width={120} height={120} />
                    <Skeleton variant="rectangular" width={410} height={150} />
                    <Skeleton variant="rounded" width={410} height={150} />

                </Stack>
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            </Box>
        )

    return (
        <Stack className={'flex flex-column border-0 gap-3'}>
            {localSchema?.map((block) => (
                <Block
                    key={block?.guid}
                    block={block}
                    dataModel={dataModel}
                    isEditing={isEditing}
                    dependencyEntity={dependencyEntity}
                    handleChangeValue={handleChangeValue}
                    entities={entities}
                    onNeedEditCallback={(guid) => {
                        if(!guid)
                            setIsEditing(true)
                        let field = editingFieldGuids?.find(x => x === guid)
                        if(!field)
                            setEditingFieldGuids([...editingFieldGuids, guid])
                    }}
                    editingFieldGuids={editingFieldGuids}
                />
            ))}
            {isEditable && dataModel
                ? isEditing || editingFieldGuids?.length > 0
                    ?
                        <div className={'flex gap-2 field-btns__wrapper'}>
                            <Button variant={"contained"} disabled={isWaitSuccess} className={'field__button_extension border-0'}
                                    onClick={() => handleSave()}>
                                {isWaitSuccess
                                    ? <CircularProgress size={20} color={'info'} />
                                    : 'Применить'
                                }
                            </Button>
                            <Button variant={"contained"} className={'field__button_extension border-0'}
                                    onClick={() => setIsEditing(true)}>Редактировать все</Button>
                            <Button variant={"contained"} color={'error'} className={'field__button_extension border-0'}
                                    onClick={() => handleCancel()}>Отмена</Button>
                        </div>
                    : null
                : null

            }
        </Stack>
    );
};

export default FieldsBlockRenderer;