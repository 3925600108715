import TabsBlock from "../../../../components/tabsblock/TabsBlock";
import ButtonTab from "../../../../components/UI/buttons/buttontab/ButtonTab";
import ListIcon from "../../../../components/UI/icons/ListIcon";
import DetailBody from "../../../../components/detailcard/body/DetailBody";
import DetailWrapper from "../../../../components/detailcard/wrapper/DetailWrapper";
import {useMultiStepComponent} from "../../../../hooks/usemultistepcomponent/UseMultiStepComponents";
import MaterialsTkp from "../materials/MaterialsTkp";
import DiluentsTkp from "../diluents/DiluentsTkp";
import AdditionalMaterial from "../additionalMaterial/AdditionalMaterial";
import TkpSettings from "../tkpsettings/TkpSettings";

const OffcanvasTkp = () => {
    const {
        goTO,
        step,
        titleStep
    } = useMultiStepComponent([
        {
            titleStep: 'Сводная материалы',
            component:
                <MaterialsTkp />
        },
        {
            titleStep: 'Сводная разбавители',
            component:
                <DiluentsTkp />
        },
        {
            titleStep: 'Дополнительная сводная',
            component:
                <AdditionalMaterial/>
        },
        {
            titleStep: 'Настройки',
            component:
                <TkpSettings/>
        },
    ])

    return (
        <DetailWrapper>
            <TabsBlock>
                <ButtonTab
                    icon={<ListIcon classNames={'icon-extension'}/>}
                    title={'Сводная материалы'}
                    onClick={() => goTO(0)}
                    isActive={titleStep === 'Сводная материалы'}
                />
                <ButtonTab
                    icon={<ListIcon classNames={'icon-extension'}/>}
                    title={'Сводная разбавители'}
                    onClick={() => goTO(1)}
                    isActive={titleStep === 'Сводная разбавители'}
                />
                <ButtonTab
                    icon={<ListIcon classNames={'icon-extension'}/>}
                    title={'Доп. сводная'}
                    onClick={() => goTO(2)}
                    isActive={titleStep === 'Дополнительная сводная'}
                />
                <ButtonTab
                    icon={<ListIcon classNames={'icon-extension'}/>}
                    title={'Настройки'}
                    onClick={() => goTO(3)}
                    isActive={titleStep === 'Настройки'}
                />
            </TabsBlock>
            <DetailBody>
                {step}
            </DetailBody>
        </DetailWrapper>
    );
};

export default OffcanvasTkp;