import Label from "./Label";
import Input from "./Input";
import getValueFromKeys from "../helpers/getValueFromKeys";

const Fields = ({
                    fields,
                    keys = [],
                    dataModel,
                    isEditing,
                    dependencyEntity,
                    handleChangeValue,
                    entities,
                    onNeedEditCallback,
                    editingFieldGuids
                }) => {
    return (
        <>
            {fields?.map(field => {
                let newKeys = [...keys, field?.accessor]
                const value = newKeys?.length > 0 ? getValueFromKeys(newKeys, dataModel) : dataModel[field?.accessor]

                if (field?.type === 'object') {
                    return <Fields
                        key={field?.guid}
                        fields={field?.fields || []}
                        keys={newKeys}
                        dataModel={dataModel}
                        isEditing={isEditing}
                        dependencyEntity={dependencyEntity}
                        handleChangeValue={handleChangeValue}
                        entities={entities}
                        onNeedEditCallback={onNeedEditCallback}
                        editingFieldGuids={editingFieldGuids}
                    />
                } else {
                    return (
                        <div className={'flex flex-column gap-1'} key={field?.guid}>
                            {isEditing || editingFieldGuids?.some(x => x === field?.guid) ?
                                <Input
                                    field={field}
                                    value={value}
                                    keys={newKeys}
                                    handleChangeValue={handleChangeValue}
                                    dependencyEntity={dependencyEntity}
                                    entities={entities}
                                />
                                : <Label
                                    field={field}
                                    value={value}
                                    dependencyEntity={dependencyEntity}
                                    onNeedEditCallback={onNeedEditCallback}
                                />
                            }
                        </div>
                    )
                }
            })
            }
        </>
    );
};

export default Fields;