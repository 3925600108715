

const RemainsWarehouse = ({remain, settings, data, type}) => {
    const information = type === 'equipment' ? 'equipmentInformation' : 'componentInformation';
    return (
        <div title={remain?.warehouse?.fullName || ''} className="remains-line__warehouse">
            {remain?.warehouse?.shortName} -
            {(settings.inStock || type === 'component') && (
                <span className='remains-line__warehouse-item'
                    title={'в наличии'}
                >
                    🏚️: {remain?.inStock}
                </span>
            )}
            {settings.supply && (
                <span title={remain?.nearestSupplyDate || 'будущие поступления'} className='remains-line__warehouse-item'>
                    ⌛: {Math.floor(type === 'component' ? remain?.supply : remain?.canProduceWithSupply)}
                </span>)}
            {settings.canProduce && type !== 'component' && (
                <span className='remains-line__warehouse-item' title={'можно произвести'}>⚙️:
                    {data?.equipmentInformation?.typeNomenclature?.value === "ЛКМ"
                        ?
                        Math.floor(remain?.canProduce / data?.equipmentInformation?.packageSize) * data?.equipmentInformation?.packageSize
                        :
                        remain?.canProduce
                    }
                </span>
            )}
            {settings.unitMeasurement && (<span
                className='remains-line__warehouse-item'>{data?.[information]?.unitMeasurement?.unitName}</span>)}
        </div>
    );
};

export default RemainsWarehouse;