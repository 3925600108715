import React from 'react';
import './ContractsDetail.css'
const ContractsDetail = () => {
    return (
        <div>
            Контракт детальная
        </div>
    );
};

export default ContractsDetail;