import './Equipment.css'
import TableBootstrap from "../../../../../components/tableb/TableBootstrap";
import EquipmentColumn from "./components/EquipmentColumn/EquipmentColumn";
import schema from "./constants/schema";
import {getFields} from "./helpers/getFields";

const Equipment = ({equipment}) => {

    const fields = getFields(equipment);

    return (
        <div>
            <div className="equipment__header">
                Информация о продукте
            </div>
            <div className="equipment__fields">
                {fields.map((fieldsColumn, idx) => (
                    <EquipmentColumn
                        key={idx}
                        fields={fieldsColumn}
                    />
                ))}
            </div>
            <div className="equipment__header">
                Остатки по складам
            </div>
            <div>
                <TableBootstrap
                    schema={schema}
                    items={equipment?.remains}
                />
            </div>
        </div>
    );
};

export default Equipment;