import './ProjectDetail.css'
import {AxiosHelper} from "../../../../hooks/useAxiosHelper";
import {useMultiStepComponent} from "../../../../hooks/usemultistepcomponent/UseMultiStepComponents";
import FieldsBlockRenderer from "../../../../modules/fieldblockrenderer/FieldsBlockRenderer";
import DetailWrapper from "../../../../components/detailcard/wrapper/DetailWrapper";
import TabsBlock from "../../../../components/tabsblock/TabsBlock";
import ButtonTab from "../../../../components/UI/buttons/buttontab/ButtonTab";
import UserIcon from "../../../../components/UI/icons/UserIcon";
import DetailBody from "../../../../components/detailcard/body/DetailBody";
import ListIcon from "../../../../components/UI/icons/ListIcon";
import {useParams} from "react-router";
import RentVariationList from "../../rentvariation/list/RentVariationList";
import SellVariationList from "../../sellvariation/list/SellVariationList";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import useAxiosErrorHandler from "../../../../hooks/axioserrorhandler/useAxiosErrorHandler";
import {Offcanvas} from "react-bootstrap";
import {useState} from "react";
const ProjectDetail = () => {
    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const params = useParams();
    const id = Number(params['id'] || 0);
    const client = useQueryClient()
    const handleErrorResponse = useAxiosErrorHandler()
    const {data: schema} = useQuery({
        queryKey: ['schema', 'project'],
        queryFn: () => AxiosHelper.getMethod({
            urlWithoutBaseUrl: 'ancorr/api/helper/get/schema',
            queryParams: {
                destinationEntity: 'project'
            },
        }),
        refetchOnWindowFocus: false,
        onError: (error) => handleErrorResponse(error)
    })
    const {data: currentProject} = useQuery({
        queryKey: ['current-project', id],
        queryFn: () => AxiosHelper.getMethod({
            urlWithoutBaseUrl: `ancorr/api/project/get/${id}`,
        }),
        onError: (error) => handleErrorResponse(error)
    })

    const {mutate: updateChangesProject} = useMutation({
        mutationKey: ['current-project', id],
        mutationFn: ({newItem}) => AxiosHelper.postMethod({
            urlWithoutBaseUrl: 'ancorr/api/project/update',
            data: newItem,
        }),
        onSuccess: (data, {setterCallback}) => {
            setterCallback(true)
            client.invalidateQueries({queryKey: ['current-project', id]})
        },
        onError: (error, {setterCallback}) => {
            setterCallback(false)
            handleErrorResponse(error)
        },
        onMutate: ({newItem}) => client?.setQueryData(['current-project', id], (oldItem) => newItem)
    })

    const {
        step,
        titleStep,
        goTO
    } = useMultiStepComponent([
        {
            titleStep: 'Информация',
            component: schema && currentProject
                ?
                    <FieldsBlockRenderer
                        schema={schema}
                        model={currentProject}
                        waitingSave={(newItem, setterCallback) => {
                            updateChangesProject?.({newItem, setterCallback})
                        }}
                        dependencyEntity={[
                            {
                                method: 'ancorr/api/helper/get/users',
                                keySelector: item => item?.id,
                                titleSelector: item => item ? `${item?.lastName || 'Без фамилии'} ${item?.firstName || 'Без имени'} ` : 'Не заполнен',
                                valueSelector: item => item?.id,
                            },
                            {
                                method: 'ancorr/api/helper/get/clients',
                                keySelector: item => item?.id,
                                titleSelector: item => item ? item?.generalName : 'Не заполнен',
                                valueSelector: item => item?.id,
                            },
                        ]}
                        backendMode={'axios'}
                    />
                : null
        },
        {
            titleStep: 'Вариации аренды',
            component: <RentVariationList />
        },
        {
            titleStep: 'Вариации покупки',
            component: <SellVariationList />
        },
    ])

    return (
        <DetailWrapper>
            <TabsBlock>
                <ButtonTab
                    icon={<UserIcon />}
                    title={'Информация'}
                    onClick={() => goTO(0)}
                    isActive={titleStep === "Информация"}
                />
                <ButtonTab
                    icon={<ListIcon />}
                    title={'Вариации аренды'}
                    onClick={() => {
                        goTO(1)
                        setShowOffcanvas(true)
                    }}
                    isActive={titleStep === "Вариации аренды"}
                />
                <ButtonTab
                    icon={<ListIcon />}
                    title={'Вариации покупки'}
                    onClick={() => {
                        goTO(2)
                        setShowOffcanvas(true)
                    }}
                    isActive={titleStep === "Вариации покупки"}
                />
            </TabsBlock>
            <DetailBody>
                {titleStep === 'Информация' && step}
            </DetailBody>
            <Offcanvas
                style={{width: '90%'}}
                show={showOffcanvas}
                onHide={() => {
                   setShowOffcanvas(false)
                   goTO(0)
                }}
                placement={'end'}
            >
                <Offcanvas.Header>
                    <Offcanvas.Title>Слайд</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {step}
                </Offcanvas.Body>
            </Offcanvas>
        </DetailWrapper>
    );
};

export default ProjectDetail;